import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Share: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={props.color || 'currentColor'} strokeWidth="1.5">
        <g transform="translate(9.000000, 6.750000)">
          <path d="M0,11.25 L0,20.25 C0,21.4926407 1.00735931,22.5 2.25,22.5 L15.75,22.5 C16.9926407,22.5 18,21.4926407 18,20.25 L18,11.25" />
          <polyline points="13.5 4.5 9 0 4.5 4.5" />
          <line x1="9" y1="0" x2="9" y2="14.625" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

Share.displayName = 'Share';

export default Share;
