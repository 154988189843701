import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Warning: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 20 19" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-421.000000, -1152.000000)">
        <g transform="translate(404.000000, 1092.000000)">
          <g transform="translate(17.000000, 60.000000)">
            <g
              transform="translate(10.000000, 11.103614) scale(1, -1) translate(-10.000000, -11.103614) translate(9.500000, 7.103614)"
              stroke={props.color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            >
              <line x1="0.5" y1="8" x2="0.5" y2="4" id="Path"></line>
              <line x1="0.5" y1="1.5" x2="0.5" y2="0" id="Path"></line>
            </g>
            <path
              d="M7.59282528,2.80671388 L0.982413326,14.7739482 C0.757540148,15.1810499 0.639588045,15.6385332 0.639588045,16.1036136 C0.639588045,17.6223966 1.87080498,18.8536136 3.38958804,18.8536136 L16.610412,18.8536136 C17.0754923,18.8536136 17.5329756,18.7356615 17.9400773,18.5107883 C19.2695232,17.7764344 19.7519406,16.1033941 19.0175867,14.7739482 L12.4071747,2.80671388 C12.1566737,2.35321654 11.7831627,1.9797055 11.3296653,1.72920449 C10.0002194,0.994850609 8.32717916,1.477268 7.59282528,2.80671388 Z M10.6043933,3.04220888 C10.8105285,3.15607298 10.9803062,3.32585072 11.0941703,3.53198588 L17.7045823,15.4992202 C18.0383795,16.1035138 17.8190989,16.8639867 17.2148053,17.1977839 C17.0297591,17.299999 16.8218121,17.3536136 16.610412,17.3536136 L3.38958804,17.3536136 C2.69923211,17.3536136 2.13958804,16.7939695 2.13958804,16.1036136 C2.13958804,15.8922134 2.19320264,15.6842665 2.29541772,15.4992202 L8.90582967,3.53198588 C9.23962689,2.92769229 10.0000997,2.70841166 10.6043933,3.04220888 Z"
              fill={props.color}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Warning.displayName = 'Warning';

export default Warning;
