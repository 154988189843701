import * as React from 'react';
import { Button, FormikErrors, FormikTouched, TextField } from '@r360-tours/ui';
import { Trans, useTranslation } from '../../../../core/i18n';
import * as Styled from './request-code-form.styled';

export interface FormValues {
  username: string;
}

interface Props {
  fieldErrors: FormikErrors<FormValues>;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isSubmitting: boolean;
  isValid: boolean;
  status: {
    errors: Array<{
      code?: string;
      message: string;
      name: string;
    }>;
  };
  touched: FormikTouched<FormValues>;
  required: boolean;
}

const RequestCodeForm: React.FunctionComponent<Props> = ({
  fieldErrors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
  status,
  touched,
  required,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Form onSubmit={handleSubmit} noValidate>
      <Styled.Message>
        <Trans
          i18nKey={
            required
              ? 'RequestCodeForm.Message.Required'
              : 'RequestCodeForm.Message.Requested'
          }
        />
      </Styled.Message>
      <Styled.TextFieldsWrapper>
        <TextField
          autoComplete="username"
          error={
            touched.username && fieldErrors.username
              ? t(fieldErrors.username)
              : undefined
          }
          validated={touched.username && !fieldErrors.username}
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t('RequestCodeForm.EmailPlaceholder')}
          required
          type="email"
          data-cy="requestCodeFormUsername"
        />
      </Styled.TextFieldsWrapper>
      <Button
        disabled={isSubmitting || !isValid}
        type="submit"
        variant="secondary"
        data-cy="requestCodeFormSubmit"
        heightType="big"
      >
        {t('RequestCodeForm.Submit')}
      </Button>
      {status.errors.map((error, index) => (
        <Styled.ErrorMessage key={index} data-cy="requestCodeFormErrorMessage">
          {t([
            `Error.${error.code}`,
            `Error.${error.message}`,
            'Error.Uncaught',
          ])}
        </Styled.ErrorMessage>
      ))}
    </Styled.Form>
  );
};

RequestCodeForm.displayName = 'RequestCodeForm';

export default RequestCodeForm;
