import styled from 'styled-components';

export const Heading = styled.h1`
  color: inherit;
  display: inline-block;
  font-size: 28px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.2em;
  margin: 0;
`;
