import * as React from 'react';
import { Formik, FormikHelpers, Yup } from '@r360-tours/ui';
import Auth from '../../../../core/auth';
import RequestCodeForm, { FormValues } from './request-code-form';

const formSchema = Yup.object().shape({
  username: Yup.string()
    .email('Validation.EmailInvalid')
    .required('Validation.EmailRequired'),
});

interface Props {
  onSubmitted: (username: string) => void;
  required: boolean;
}

const RequestCodeFormContainer: React.FunctionComponent<Props> = ({
  onSubmitted,
  required,
}) => {
  const onSubmit = React.useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        await Auth.forgotPassword(values.username.toLowerCase());
        onSubmitted(values.username);
      } catch (error) {
        actions.setStatus({
          errors: [error],
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [onSubmitted],
  );

  return (
    <Formik<FormValues>
      initialValues={{ username: '' }}
      initialStatus={{ errors: [] }}
      onSubmit={onSubmit}
      validationSchema={formSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        status,
        touched,
      }) => (
        <RequestCodeForm
          fieldErrors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          isValid={isValid}
          status={status}
          touched={touched}
          required={required}
        />
      )}
    </Formik>
  );
};

RequestCodeFormContainer.displayName = 'RequestCodeFormContainer';

export default RequestCodeFormContainer;
