import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Help: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(6.000000, 6.000000)">
        <circle
          id="circle"
          stroke={props.color || 'currentColor'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="10"
          cy="10"
          r="10"
        />
        <g
          id="question"
          fill={props.color || 'currentColor'}
          transform="translate(7.000000, 5.000000)"
        >
          <path d="M3.20644925,7.802 C2.73544925,7.802 2.47644925,7.524 2.47644925,7.017 L2.47644925,5.833 C2.47644925,5.35 2.76844925,5.056 3.29944925,5.004 C4.68944925,4.841 5.52144925,4.115 5.52144925,3.064 C5.52144925,2.069 4.69544925,1.347 3.55744925,1.347 C2.67544925,1.347 1.92544925,1.712 1.33044925,2.431 C0.917449248,2.9 0.530449248,2.766 0.278449248,2.573 C0.114449248,2.448 0.0214492479,2.302 0.00344924792,2.14 C-0.0175507521,1.959 0.0574492479,1.765 0.225449248,1.565 C1.08844925,0.527 2.20944925,0 3.55744925,0 C4.52944925,0 5.35244925,0.281 6.00344925,0.836 C6.66344925,1.384 6.99944925,2.119 6.99944925,3.02 C6.99944925,4.66 5.80944925,5.918 3.95344925,6.254 L3.95344925,7.017 C3.95344925,7.524 3.68744925,7.802 3.20644925,7.802" />
          <path d="M3.20644925,10.9993 C2.61744925,10.9993 2.15544925,10.5513 2.15544925,9.9783 C2.15544925,9.4063 2.62744925,8.9403 3.20644925,8.9403 C3.83344925,8.9403 4.28844925,9.3773 4.28844925,9.9783 C4.28844925,10.5513 3.81344925,10.9993 3.20644925,10.9993" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

Help.displayName = 'Help';

export default Help;
