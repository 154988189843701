import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const ArrowRightBold: React.FC<Props> = ({ ...props }) => (
  <SvgIcon viewBox="0 0 19 28" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.8"
    >
      <g transform="translate(-508.000000, -766.000000)" fill="#6F6A6A">
        <g transform="translate(0.000000, 579.000000)">
          <g transform="translate(335.000000, 133.000000)">
            <path d="M173,81.2407404 L173,54.7591792 C173,54.0843811 174.050837,53.7460349 174.665533,54.2234327 L191.714557,67.4640239 C192.095571,67.7603137 192.095571,68.2399848 191.714557,68.5358957 L174.665533,81.7764868 C174.050837,82.2538847 173,81.9159173 173,81.2407404" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ArrowRightBold.displayName = 'ArrowRightBold';

export default ArrowRightBold;
