import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

const Path: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g fillRule="evenodd" fill="none" stroke={props.color || 'black'}>
      <circle cx="12" cy="12" r="11.4" strokeWidth="1.2" />
      <g strokeLinecap="round" strokeLinejoin="round">
        <path d="M6.167 14.5 12 8.667l5.832 5.833" strokeWidth="1.2" />
      </g>
    </g>
  </SvgIcon>
);
Path.displayName = 'Path';
export default Path;
