import styled from 'styled-components';

export const Menu = styled.ul`
  background-color: ${(props): string => props.theme.background.primary.main};
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.075);
  border-radius: 6px;
  list-style-type: none;
  margin: 0;
  overflow: auto;
  padding: 0;
`;
