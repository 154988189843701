import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

interface AspectRatioProps {
  active?: boolean;
}

export const FourToThree: React.FC<Props & AspectRatioProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 24" width="32px" height="24px">
    <g
      id="Cropping"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop-/-02.08.-Photo-/-Manual-Crop-/-Ratio-Selection-Change-the-ratio-of-the-clipping."
        transform="translate(-809.000000, -635.000000)"
        stroke={props.active ? '#2C2828' : '#FFFFFF'}
      >
        <g id="Drop-down" transform="translate(768.000000, 531.000000)">
          <g id="Group-4" transform="translate(41.000000, 100.000000)">
            <rect id="Rectangle" x="0.5" y="4.5" width="31" height="23"></rect>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

FourToThree.displayName = 'FourToThree';

export default FourToThree;
