import * as React from 'react';
import classNames from 'classnames';
import { Check, Close } from '../../icons';
import * as Styled from './select-all-button.styled';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  deselectAllText: string;
  isSelectAll: boolean;
  onButtonClick: () => void;
  selectAllText: string;
}

const SelectAllButton: React.FC<Props> = ({
  deselectAllText,
  isSelectAll,
  onButtonClick,
  selectAllText,
  ...props
}) => {
  return (
    <Styled.Button
      className={classNames({ isSelectAll })}
      onClick={onButtonClick}
      variant="critical"
      {...props}
    >
      <Styled.CheckmarkWrapper className={classNames({ isSelectAll })}>
        {isSelectAll ? (
          <Close diameter={30} color="currentcolor" />
        ) : (
          <Check diameter={30} color="currentcolor" />
        )}
      </Styled.CheckmarkWrapper>
      <Styled.ButtonText>
        {isSelectAll ? deselectAllText : selectAllText}
      </Styled.ButtonText>
    </Styled.Button>
  );
};

SelectAllButton.displayName = 'SelectAllButton';

export default SelectAllButton;
