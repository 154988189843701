import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

const File: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 16 20" {...props}>
    <g fillRule="evenodd" fill="none" stroke={props.color || 'black'}>
      <path
        d="M9 1v4.8A1.2 1.2 0 0 0 10.2 7H15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 19H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zM4.25 8.25h.917M4.25 11.917h5.5M4.25 15.583h5.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);
File.displayName = 'File';
export default File;
