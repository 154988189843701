import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Users: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Common"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Members" stroke="#000000" strokeWidth="1.5">
        <g id="Group" transform="translate(9.000000, 8.000000)">
          <path
            d="M13.0506564,9 L13.0506564,9 C11.3440508,9 9.9,7.515 9.9,5.76 L9.9,4.14 C9.9,2.385 11.3440508,0.9 13.0506564,0.9 C14.7572619,0.9 16.2,2.385 16.2,4.14 L16.2,5.76 C16.2,7.515 14.7572619,9 13.0506564,9 Z"
            id="Stroke-1"
          ></path>
          <path
            d="M18,19.8 L18,16.7318182 C18,13.9090909 15.8268293,11.7 13.05,11.7 C10.2731707,11.7 8.1,13.9090909 8.1,16.7318182 L8.1,19.8"
            id="Stroke-3"
          ></path>
          <path
            d="M4.04550714,6.3 L4.04550714,6.3 C2.74547669,6.3 1.8,5.11875 1.8,3.80625 L1.8,2.49375 C1.8,1.05 2.86366128,0 4.04550714,0 C5.34553759,0 6.29101428,1.18125 6.29101428,2.49375 L6.29101428,3.80625 C6.40919886,5.25 5.34553759,6.3 4.04550714,6.3 Z"
            id="Stroke-5"
          ></path>
          <path
            d="M7.2,10.2857143 C6.44210526,9.51428571 5.43157895,9 4.16842105,9 C1.89473684,9 0,10.9285714 0,13.2428571 L0,15.3"
            id="Stroke-7"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Users.displayName = 'Users';

export default Users;
