import * as React from 'react';
import { ReactNode } from 'react';
import { useTranslation } from '../../../core/i18n';
import RequestCodeForm from './request-code-form';
import * as Styled from './reset-password.styled';
import ResetPasswordForm from './reset-password-form';

export enum ResetPasswordStep {
  RequestCode,
  ResetPassword,
  Finished,
}

interface Props {
  onRequestCodeSubmitted: (username: string) => void;
  onResetPasswordSubmitted: () => void;
  step: ResetPasswordStep;
  username: string;
  required: boolean;
  links: ReactNode;
  onResetPasswordSuccessClick: () => void;
  title: ReactNode;
}

const ResetPassword: React.FunctionComponent<Props> = ({
  onRequestCodeSubmitted,
  onResetPasswordSubmitted,
  step,
  username,
  required,
  links,
  onResetPasswordSuccessClick,
  title,
}) => {
  const { t } = useTranslation();

  switch (step) {
    case ResetPasswordStep.RequestCode:
      return (
        <Styled.ResetPassword>
          {title}
          <RequestCodeForm
            onSubmitted={onRequestCodeSubmitted}
            required={required}
          />
          {links}
        </Styled.ResetPassword>
      );
    case ResetPasswordStep.ResetPassword:
      return (
        <Styled.ResetPassword>
          <ResetPasswordForm
            onSubmitted={onResetPasswordSubmitted}
            username={username}
          />
          {links}
        </Styled.ResetPassword>
      );
    case ResetPasswordStep.Finished:
      return (
        <Styled.FinishdResetPassword>
          <Styled.Message>
            {t('ResetPasswordFinished.SuccessMessage')}
            <br />{' '}
            <a onClick={onResetPasswordSuccessClick}>
              {t('ResetPasswordFinished.ClickHere')}
            </a>{' '}
            {t('ResetPasswordFinished.ToLoginNow')}
          </Styled.Message>
        </Styled.FinishdResetPassword>
      );
  }

  return null;
};

ResetPassword.displayName = 'ResetPassword';

export default ResetPassword;
