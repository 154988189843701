import * as React from 'react';
import * as Styled from './hero.styled';

export const Hero: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => <Styled.Hero {...props}>{children}</Styled.Hero>;

Hero.displayName = 'Hero';

export default Hero;
