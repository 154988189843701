import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

export const Image = styled.img`
  display: block;
  flex: 0 1 auto;
  max-height: 100%;
  max-width: 100%;
`;

export const Overlay = styled.div`
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
`;
