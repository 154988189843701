import styled from 'styled-components';
import UIButton from '../button';

export const CheckmarkWrapper = styled.div`
  align-items: center;
  border-radius: 100%;
  border: 1px solid
    ${(props): string => props.theme.button.critical.default.fontColor};
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  width: 32px;

  svg > g {
    stroke: ${(props): string => props.theme.button.critical.default.fontColor};
  }
`;

export const Button = styled(UIButton)`
  border-color: ${(props): string => props.theme.modals.button.borderColor};
  padding: 7px 11px;

  > span {
    min-width: 142px;
  }

  :hover {
    ${CheckmarkWrapper} {
      border-color: ${(props): string =>
        props.theme.button.critical.hover.fontColor};
      svg > g {
        stroke: ${(props): string =>
          props.theme.button.critical.hover.fontColor};
      }
    }
  }

  &.isSelectAll {
    border-color: ${(props): string => props.theme.palette.primary.main};
  }
`;

export const ButtonText = styled.span`
  padding-left: 13.5px;
`;
