import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const CreateTourStep2: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 196 170" {...props}>
    <defs>
      <path
        d="M-4.36258782e-16,0 L-4.36258782e-16,119.402833 C-4.36258782e-16,124.107882 3.8140416,127.922415 8.51859928,127.922415 L8.51859928,127.922415 L59.5201698,127.922415 C64.2247275,127.922415 68.0387691,124.107882 68.0387691,119.402833 L68.0387691,119.402833 L68.0387691,0 L-4.36258782e-16,0 Z"
        id="step2-path-1"
      ></path>
      <polygon
        id="step2-path-3"
        points="0 0 103.317549 0 103.317549 79.3472919 0 79.3472919"
      ></polygon>
      <polygon
        id="step2-path-5"
        points="0 0 51.6587744 0 51.6587744 57.8575129 0 57.8575129"
      ></polygon>
      <polygon
        id="step2-path-7"
        points="0 157 195.657632 157 195.657632 0 0 0"
      ></polygon>
      <path
        d="M11.7336699,11.3409409 C10.6515922,12.4249834 9.11124002,12.9181319 7.6003589,12.6632075 L7.6003589,12.6632075 L2.47780386,11.7726914 C1.50378654,11.6032331 0.577414046,12.2565074 0.408446846,13.2300335 L0.408446846,13.2300335 C0.336242839,13.6460662 0.413849867,14.0743784 0.626532418,14.4393279 L0.626532418,14.4393279 L3.24994467,18.9243264 C4.01766483,20.257399 4.09183357,21.8802701 3.44936527,23.2776877 L3.44936527,23.2776877 C-5.43860417,43.1195454 3.44199751,66.4095127 23.2828728,75.296991 L23.2828728,75.296991 C28.4604404,77.6158693 33.9111061,78.7318387 39.2944797,78.74068 L39.2944797,78.74068 L39.4123638,78.74068 C52.1772464,78.7239798 64.5550761,72.4869289 72.0559427,61.3179022 L72.0559427,61.3179022 L72.0569251,61.3174111 C83.8178281,43.8209557 79.5415827,19.8266309 62.4582128,7.47336032 L62.4582128,7.47336032 C55.5305576,2.46132163 47.4412529,0 39.3917341,0 L39.3917341,0 C29.3352386,0 19.3396498,3.83909197 11.7336699,11.3409409"
        id="step2-path-9"
      ></path>
      <path
        d="M11.7336699,11.3409409 C10.6515922,12.4249834 9.11124002,12.9181319 7.6003589,12.6632075 L7.6003589,12.6632075 L2.47780386,11.7726914 C1.50378654,11.6032331 0.577414046,12.2565074 0.408446846,13.2300335 L0.408446846,13.2300335 C0.336242839,13.6460662 0.413849867,14.0743784 0.626532418,14.4393279 L0.626532418,14.4393279 L3.24994467,18.9243264 C4.01766483,20.257399 4.09183357,21.8802701 3.44936527,23.2776877 L3.44936527,23.2776877 C-5.43860417,43.1195454 3.44199751,66.4095127 23.2828728,75.296991 L23.2828728,75.296991 C28.4604404,77.6158693 33.9111061,78.7318387 39.2944797,78.74068 L39.2944797,78.74068 L39.4123638,78.74068 C52.1772464,78.7239798 64.5550761,72.4869289 72.0559427,61.3179022 L72.0559427,61.3179022 L72.0569251,61.3174111 C83.8178281,43.8209557 79.5415827,19.8266309 62.4582128,7.47336032 L62.4582128,7.47336032 C55.5305576,2.46132163 47.4412529,0 39.3917341,0 L39.3917341,0 C29.3352386,0 19.3396498,3.83909197 11.7336699,11.3409409"
        id="step2-path-11"
      ></path>
      <path
        d="M11.7336699,11.3409409 C10.6515922,12.4249834 9.11124002,12.9181319 7.6003589,12.6632075 L7.6003589,12.6632075 L2.47780386,11.7726914 C1.50378654,11.6032331 0.577414046,12.2565074 0.408446846,13.2300335 L0.408446846,13.2300335 C0.336242839,13.6460662 0.413849867,14.0743784 0.626532418,14.4393279 L0.626532418,14.4393279 L3.24994467,18.9243264 C4.01766483,20.257399 4.09183357,21.8802701 3.44936527,23.2776877 L3.44936527,23.2776877 C-5.43860417,43.1195454 3.44199751,66.4095127 23.2828728,75.296991 L23.2828728,75.296991 C28.4604404,77.6158693 33.9111061,78.7318387 39.2944797,78.74068 L39.2944797,78.74068 L39.4123638,78.74068 C52.1772464,78.7239798 64.5550761,72.4869289 72.0559427,61.3179022 L72.0559427,61.3179022 L72.0569251,61.3174111 C83.8178281,43.8209557 79.5415827,19.8266309 62.4582128,7.47336032 L62.4582128,7.47336032 C55.5305576,2.46132163 47.4412529,0 39.3917341,0 L39.3917341,0 C29.3352386,0 19.3396498,3.83909197 11.7336699,11.3409409"
        id="step2-path-13"
      ></path>
      <path
        d="M11.7336699,11.3409409 C10.6515922,12.4249834 9.11124002,12.9181319 7.6003589,12.6632075 L7.6003589,12.6632075 L2.47780386,11.7726914 C1.50378654,11.6032331 0.577414046,12.2565074 0.408446846,13.2300335 L0.408446846,13.2300335 C0.336242839,13.6460662 0.413849867,14.0743784 0.626532418,14.4393279 L0.626532418,14.4393279 L3.24994467,18.9243264 C4.01766483,20.257399 4.09183357,21.8802701 3.44936527,23.2776877 L3.44936527,23.2776877 C-5.43860417,43.1195454 3.44199751,66.4095127 23.2828728,75.296991 L23.2828728,75.296991 C28.4604404,77.6158693 33.9111061,78.7318387 39.2944797,78.74068 L39.2944797,78.74068 L39.4123638,78.74068 C52.1772464,78.7239798 64.5550761,72.4869289 72.0559427,61.3179022 L72.0559427,61.3179022 L72.0569251,61.3174111 C83.8178281,43.8209557 79.5415827,19.8266309 62.4582128,7.47336032 L62.4582128,7.47336032 C55.5305576,2.46132163 47.4412529,0 39.3917341,0 L39.3917341,0 C29.3352386,0 19.3396498,3.83909197 11.7336699,11.3409409"
        id="step2-path-15"
      ></path>
      <path
        d="M11.7336699,11.3409409 C10.6515922,12.4249834 9.11124002,12.9181319 7.6003589,12.6632075 L7.6003589,12.6632075 L2.47780386,11.7726914 C1.50378654,11.6032331 0.577414046,12.2565074 0.408446846,13.2300335 L0.408446846,13.2300335 C0.336242839,13.6460662 0.413849867,14.0743784 0.626532418,14.4393279 L0.626532418,14.4393279 L3.24994467,18.9243264 C4.01766483,20.257399 4.09183357,21.8802701 3.44936527,23.2776877 L3.44936527,23.2776877 C-5.43860417,43.1195454 3.44199751,66.4095127 23.2828728,75.296991 L23.2828728,75.296991 C28.4604404,77.6158693 33.9111061,78.7318387 39.2944797,78.74068 L39.2944797,78.74068 L39.4123638,78.74068 C52.1772464,78.7239798 64.5550761,72.4869289 72.0559427,61.3179022 L72.0559427,61.3179022 L72.0569251,61.3174111 C83.8178281,43.8209557 79.5415827,19.8266309 62.4582128,7.47336032 L62.4582128,7.47336032 C55.5305576,2.46132163 47.4412529,0 39.3917341,0 L39.3917341,0 C29.3352386,0 19.3396498,3.83909197 11.7336699,11.3409409"
        id="step2-path-17"
      ></path>
      <path
        d="M11.7336699,11.3409409 C10.6515922,12.4249834 9.11124002,12.9181319 7.6003589,12.6632075 L7.6003589,12.6632075 L2.47780386,11.7726914 C1.50378654,11.6032331 0.577414046,12.2565074 0.408446846,13.2300335 L0.408446846,13.2300335 C0.336242839,13.6460662 0.413849867,14.0743784 0.626532418,14.4393279 L0.626532418,14.4393279 L3.24994467,18.9243264 C4.01766483,20.257399 4.09183357,21.8802701 3.44936527,23.2776877 L3.44936527,23.2776877 C-5.43860417,43.1195454 3.44199751,66.4095127 23.2828728,75.296991 L23.2828728,75.296991 C28.4604404,77.6158693 33.9111061,78.7318387 39.2944797,78.74068 L39.2944797,78.74068 L39.4123638,78.74068 C52.1772464,78.7239798 64.5550761,72.4869289 72.0559427,61.3179022 L72.0559427,61.3179022 L72.0569251,61.3174111 C83.8178281,43.8209557 79.5415827,19.8266309 62.4582128,7.47336032 L62.4582128,7.47336032 C55.5305576,2.46132163 47.4412529,0 39.3917341,0 L39.3917341,0 C29.3352386,0 19.3396498,3.83909197 11.7336699,11.3409409"
        id="step2-path-19"
      ></path>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="For-those-who-are-referred-for-PC."
        transform="translate(-532.000000, -942.000000)"
      >
        <g id="How-it-work-?" transform="translate(0.000000, 738.000000)">
          <g id="Group-2" transform="translate(532.000000, 204.000000)">
            <rect
              id="Rectangle-Copy-2"
              fill="#ED7872"
              opacity="0.3"
              x="13"
              y="0"
              width="170"
              height="170"
              rx="85"
            ></rect>
            <g id="Group-90-Copy" transform="translate(-0.000000, 5.000000)">
              <g id="Group-5" transform="translate(2.221624, 12.178655)">
                <path
                  d="M8.51859928,0 L59.5201698,0 C64.2247275,0 68.0387691,3.8140416 68.0387691,8.51859928 L68.0387691,134.077929 C68.0387691,138.782487 64.2247275,142.596529 59.5201698,142.596529 L8.51859928,142.596529 C3.8140416,142.596529 1.74503513e-15,138.782487 1.74503513e-15,134.077929 L1.74503513e-15,8.51859928 C1.74503513e-15,3.8140416 3.8140416,0 8.51859928,0"
                  id="Fill-1"
                  fill="#E6E6E6"
                ></path>
                <path
                  d="M8.51909047,140.374413 C3.81453278,140.374413 0.000491183721,136.559881 0.000491183721,131.855323 L0.000491183721,15.7994156 L68.0392603,15.7994156 L68.0392603,131.855323 C68.0392603,136.559881 64.2252187,140.374413 59.520661,140.374413 L8.51909047,140.374413 Z"
                  id="Fill-3"
                  fill="#FFFFFF"
                ></path>
              </g>
              <g id="Group-16" transform="translate(2.222115, 24.630899)">
                <mask id="step2-mask-2" fill="white">
                  <use xlinkHref="#step2-path-1"></use>
                </mask>
                <g id="Clip-7"></g>
                <polygon
                  id="Fill-6"
                  fill="#C5DCFD"
                  mask="url(#step2-mask-2)"
                  points="0.24018884 86.6266347 68.9921567 86.6266347 68.9921567 1.57719093 0.24018884 1.57719093"
                ></polygon>
                <polygon
                  id="Fill-8"
                  fill="#7ABEFF"
                  mask="url(#step2-mask-2)"
                  points="46.487101 34.2087359 0.240680024 15.2441324 0.240680024 -1.76998054 68.9926479 -1.76998054 68.9926479 18.2870155"
                ></polygon>
                <polygon
                  id="Fill-9"
                  fill="#7ABEFF"
                  mask="url(#step2-mask-2)"
                  points="0.24018884 129.221596 68.9921567 129.221596 68.9921567 74.7115 0.24018884 74.7115"
                ></polygon>
                <polygon
                  id="Fill-10"
                  fill="#E3EFFD"
                  mask="url(#step2-mask-2)"
                  points="46.487101 34.2087359 46.487101 68.3214453 68.9926479 86.6273714 68.9926479 18.2870155"
                ></polygon>
                <path
                  d="M11.7692532,43.6954583 L27.0151047,43.6954583 C27.5578627,43.6954583 27.9974721,44.1350677 27.9974721,44.6778257 L27.9974721,74.7117455 L10.7868857,74.7117455 L10.7868857,44.6778257 C10.7868857,44.1350677 11.2264951,43.6954583 11.7692532,43.6954583"
                  id="Fill-11"
                  fill="#EED86D"
                  mask="url(#step2-mask-2)"
                ></path>
                <path
                  d="M15.835272,48.2344379 L22.9485947,48.2344379 C23.4913527,48.2344379 23.9309621,48.6740474 23.9309621,49.2168054 L23.9309621,55.9519166 C23.9309621,56.4946746 23.4913527,56.934284 22.9485947,56.934284 L15.835272,56.934284 C15.292514,56.934284 14.8529046,56.4946746 14.8529046,55.9519166 L14.8529046,49.2168054 C14.8529046,48.6740474 15.292514,48.2344379 15.835272,48.2344379"
                  id="Fill-12"
                  fill="#FFFFFF"
                  mask="url(#step2-mask-2)"
                ></path>
                <polygon
                  id="Fill-13"
                  fill="#FFFFFF"
                  mask="url(#step2-mask-2)"
                  points="51.1867468 42.9286714 51.1867468 64.7593319 60.2928018 69.4506276 60.2928018 40.4452465"
                ></polygon>
                <polygon
                  id="Fill-14"
                  fill="#E3EFFD"
                  mask="url(#step2-mask-2)"
                  points="40.4347351 74.7116964 68.9926479 114.566344 74.8416636 111.113813 74.8416636 104.513286 40.4347351 68.320905"
                ></polygon>
                <polygon
                  id="Fill-15"
                  fill="#7ABEFF"
                  mask="url(#step2-mask-2)"
                  points="46.487101 68.3209541 40.4347351 68.3209541 74.8416636 104.513335 74.8416636 89.0218921"
                ></polygon>
              </g>
              <g id="Group-64">
                <path
                  d="M70.2603931,23.7469647 L2.22162397,23.7469647 L2.22162397,20.6972049 C2.22162397,15.9926473 6.03566557,12.1786057 10.7402233,12.1786057 L10.7402233,12.1786057 L61.7417938,12.1786057 C66.4463515,12.1786057 70.2603931,15.9926473 70.2603931,20.6972049 L70.2603931,23.7469647 Z"
                  id="Fill-17"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M68.0382779,146.256535 C68.0382779,149.734116 65.2193745,152.553019 61.7417938,152.553019 L61.7417938,152.553019 L10.7407144,152.553019 C7.26313369,152.553019 4.44423031,149.734116 4.44423031,146.256535 L4.44423031,146.256535 L4.44423031,20.6972049 C4.44423031,17.2196242 7.26313369,14.4007208 10.7407144,14.4007208 L61.7417938,14.4007208 C65.2193745,14.4007208 68.0382779,17.2196242 68.0382779,20.6972049 L68.0382779,20.6972049 L68.0382779,146.256535 Z M61.7417938,9.95649051 L10.7407144,9.95649051 C4.80868863,9.95649051 0,14.7651791 0,20.6972049 L0,20.6976961 L0,146.256535 C0,152.188561 4.80868863,156.997249 10.7407144,156.997249 L10.7407144,156.997249 L61.7417938,156.997249 C67.6738196,156.997249 72.4829994,152.188561 72.4829994,146.256535 L72.4829994,20.6976961 C72.4829994,14.7656703 67.6738196,9.95649051 61.7417938,9.95649051 L61.7417938,9.95649051 Z"
                  id="Fill-19"
                  fill="#3F4448"
                ></path>
                <path
                  d="M9.67238984,18.6097232 C10.3541528,18.6097232 10.9072257,19.1627961 10.9072257,19.8445591 C10.9072257,20.5263221 10.3541528,21.079395 9.67238984,21.079395 C8.99062684,21.079395 8.43755397,20.5263221 8.43755397,19.8445591 C8.43755397,19.1627961 8.99062684,18.6097232 9.67238984,18.6097232"
                  id="Fill-21"
                  fill="#CCCCCC"
                ></path>
                <path
                  d="M13.6229805,18.6097232 C14.3047435,18.6097232 14.8578164,19.1627961 14.8578164,19.8445591 C14.8578164,20.5263221 14.3047435,21.079395 13.6229805,21.079395 C12.9412175,21.079395 12.3881446,20.5263221 12.3881446,19.8445591 C12.3881446,19.1627961 12.9412175,18.6097232 13.6229805,18.6097232"
                  id="Fill-23"
                  fill="#CCCCCC"
                ></path>
                <path
                  d="M17.5740624,18.6097232 C18.2558254,18.6097232 18.8088982,19.1627961 18.8088982,19.8445591 C18.8088982,20.5263221 18.2558254,21.079395 17.5740624,21.079395 C16.8922994,21.079395 16.3392265,20.5263221 16.3392265,19.8445591 C16.3392265,19.1627961 16.8922994,18.6097232 17.5740624,18.6097232"
                  id="Fill-25"
                  fill="#CCCCCC"
                ></path>
                <g id="Group-29" transform="translate(92.340084, 16.936702)">
                  <mask id="step2-mask-4" fill="white">
                    <use xlinkHref="#step2-path-3"></use>
                  </mask>
                  <g id="Clip-28"></g>
                  <polygon
                    id="Fill-27"
                    fill="#7ABEFF"
                    mask="url(#step2-mask-4)"
                    points="51.6587744 0 0 21.489779 0 57.8575129 51.6587744 79.3472919 103.317549 57.8575129 103.317549 21.489779"
                  ></polygon>
                </g>
                <g id="Group-32" transform="translate(143.998858, 16.936702)">
                  <mask id="step2-mask-6" fill="white">
                    <use xlinkHref="#step2-path-5"></use>
                  </mask>
                  <g id="Clip-31"></g>
                  <polygon
                    id="Fill-30"
                    fill="#D0E3FD"
                    mask="url(#step2-mask-6)"
                    points="0 36.3677339 51.6587744 57.8575129 51.6587744 21.489779 0 0"
                  ></polygon>
                </g>
                <path
                  d="M162.434457,37.0974365 L162.434457,48.5680499 C162.434457,48.9251405 162.649104,49.247357 162.978688,49.3843973 L177.075661,55.2496221 C177.526568,55.4372543 178.043784,55.2235893 178.231416,54.7726827 C178.276114,54.6651134 178.2992,54.5496853 178.2992,54.4332747 L178.2992,42.9626613 C178.2992,42.6055707 178.084552,42.2833542 177.754968,42.1463139 L163.657995,36.2810891 C163.207089,36.0934569 162.689872,36.3071219 162.50224,36.7580285 C162.457542,36.8655977 162.434457,36.9810259 162.434457,37.0974365"
                  id="Fill-33"
                  fill="#FFFFFF"
                ></path>
                <polygon
                  id="Fill-35"
                  fill="#ACCCFD"
                  points="143.998858 53.3044363 110.371438 67.2933487 92.3405749 74.7942153 92.3405749 38.4264814 143.999349 16.9367024"
                ></polygon>
                <path
                  d="M123.348512,37.5684816 L110.914196,42.7411374 C110.584612,42.8786689 110.369965,43.2003942 110.369965,43.5574848 L110.369965,67.2934469 L124.572051,61.3854891 L124.572051,38.384829 C124.572051,37.8965924 124.176157,37.5006983 123.68792,37.5006983 C123.571509,37.5006983 123.456081,37.5237839 123.348512,37.5684816"
                  id="Fill-37"
                  fill="#EED86D"
                ></path>
                <path
                  d="M114.008654,44.8055335 L120.254055,42.2076628 C120.704961,42.0200306 121.222178,42.2336955 121.40981,42.6846022 C121.454508,42.7921714 121.477593,42.9075996 121.477593,43.0240101 L121.477593,48.386754 C121.477593,48.7438446 121.262455,49.0660611 120.932871,49.2031013 L114.687961,51.800972 C114.237054,51.9886042 113.719838,51.7749393 113.532205,51.3240326 C113.487508,51.2164634 113.464422,51.1010352 113.464422,50.9846247 L113.464422,45.6213896 C113.464422,45.2647903 113.679069,44.9425737 114.008654,44.8055335"
                  id="Fill-39"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M132.715877,70.8244685 L132.715877,15.5550026 C132.71735,13.8142475 133.785184,12.251792 135.406581,11.618165 C140.919136,9.40243527 147.075142,9.40243527 152.587697,11.618165 C154.209094,12.251792 155.277419,13.8142475 155.278892,15.5550026 L155.278892,70.8244685 C155.277419,71.798977 154.487595,72.5888004 153.513087,72.5902739 L134.481682,72.5902739 C133.507174,72.5888004 132.71735,71.798977 132.715877,70.8244685"
                  id="Fill-41"
                  fill="#838287"
                ></path>
                <path
                  d="M143.997139,14.0226076 L143.997139,14.0226076 C148.733624,14.0226076 152.573207,17.8626819 152.573207,22.5991666 L152.573207,48.0636042 C152.573207,52.8000889 148.733624,56.6401632 143.997139,56.6401632 C139.260654,56.6401632 135.42058,52.8000889 135.42058,48.0636042 L135.42058,22.5991666 C135.42058,17.8626819 139.260654,14.0226076 143.997139,14.0226076"
                  id="Fill-43"
                  fill="#676767"
                ></path>
                <path
                  d="M143.997335,15.743175 C147.783871,15.743175 150.853278,18.8125821 150.853278,22.5991174 C150.853278,26.3856527 147.783871,29.4550598 143.997335,29.4550598 C140.2108,29.4550598 137.141393,26.3856527 137.141393,22.5991174 C137.141393,18.8125821 140.2108,15.743175 143.997335,15.743175"
                  id="Fill-45"
                  fill="#333333"
                ></path>
                <path
                  d="M143.997385,16.4096622 C147.415532,16.4096622 150.186791,19.1809208 150.186791,22.5990683 C150.186791,26.0172158 147.415532,28.7884744 143.997385,28.7884744 C140.579237,28.7884744 137.807978,26.0172158 137.807978,22.5990683 C137.807978,19.1809208 140.579237,16.4096622 143.997385,16.4096622"
                  id="Fill-47"
                  fill="#333333"
                ></path>
                <path
                  d="M143.996893,44.8929151 C145.747963,44.8929151 147.167484,46.312436 147.167484,48.063506 C147.167484,49.814576 145.747963,51.2340969 143.996893,51.2340969 C142.245823,51.2340969 140.826302,49.814576 140.826302,48.063506 C140.826302,46.312436 142.245823,44.8929151 143.996893,44.8929151 Z"
                  id="Stroke-49"
                  stroke="#333333"
                  strokeWidth="0.799"
                ></path>
                <path
                  d="M143.997335,20.30136 C145.266063,20.30136 146.294602,21.3298987 146.294602,22.5986262 C146.294602,23.8673538 145.266063,24.8958925 143.997335,24.8958925 C142.728608,24.8958925 141.700069,23.8673538 141.700069,22.5986262 C141.700069,21.3298987 142.728608,20.30136 143.997335,20.30136 Z"
                  id="Stroke-51"
                  stroke="#F2F2F2"
                  strokeWidth="0.935"
                ></path>
                <path
                  d="M143.052838,69.726722 C143.054803,70.0184851 143.292536,70.2532709 143.584299,70.2513062 C143.604438,70.2513062 143.625067,70.2498326 143.645206,70.2473767 C143.802876,70.2498326 143.959072,70.2164321 144.101515,70.14914 L144.240029,70.3387369 C144.037662,70.4482708 143.810244,70.5013187 143.58037,70.4929686 C142.990949,70.4929686 142.554287,70.2085732 142.554287,69.7247572 C142.554287,69.2414324 142.988984,68.9565459 143.58037,68.9565459 C143.802876,68.9491781 144.023908,68.9924023 144.226767,69.0837625 L144.084324,69.3013569 C143.949249,69.2306264 143.797473,69.1962435 143.645206,69.2031201 C143.355899,69.1672637 143.092624,69.3730697 143.056768,69.6618857 C143.054312,69.6820242 143.052838,69.702654 143.052838,69.7227925"
                  id="Fill-53"
                  fill="#333333"
                ></path>
                <path
                  d="M145.308845,70.2563162 C145.016591,70.2798931 144.760684,70.0618075 144.737107,69.7695532 C144.714022,69.4768077 144.932107,69.220901 145.224361,69.1978153 C145.516616,69.1742385 145.772522,69.3923241 145.796099,69.6845784 C145.797082,69.6988227 145.797573,69.7125759 145.797573,69.726329 C145.823606,69.9915682 145.629588,70.2278276 145.364349,70.2538603 C145.345684,70.2558251 145.32751,70.2568074 145.308845,70.2563162 M145.308845,68.9586089 C144.736125,68.9586089 144.323531,69.2434954 144.323531,69.7268202 C144.323531,70.210145 144.736125,70.4950315 145.308845,70.4950315 C145.881565,70.4950315 146.293668,70.2106362 146.293668,69.7268202 C146.293668,69.2430042 145.881074,68.9586089 145.308845,68.9586089"
                  id="Fill-55"
                  fill="#333333"
                ></path>
                <polygon
                  id="Fill-57"
                  fill="#333333"
                  points="147.780924 69.8377786 147.780924 70.4561789 148.253934 70.4561789 148.253934 68.9968721 147.780924 68.9968721 147.780924 69.5533832 147.057901 69.5533832 147.057901 68.9968721 146.585874 68.9968721 146.585874 70.4561789 147.057901 70.4561789 147.057901 69.8377786"
                ></polygon>
                <mask id="step2-mask-8" fill="white">
                  <use xlinkHref="#step2-path-7"></use>
                </mask>
                <g id="Clip-60"></g>
                <polygon
                  id="Fill-59"
                  fill="#333333"
                  mask="url(#step2-mask-8)"
                  points="141.77134 70.4558842 142.2694 70.4558842 142.2694 68.9965774 141.77134 68.9965774"
                ></polygon>
                <path
                  d="M140.342486,69.6648328 L140.342486,69.804329 C140.585131,69.9934347 140.802726,70.2125027 140.990849,70.4561298 L141.548342,70.4561298 C141.341063,70.213485 141.101856,70.0003113 140.8376,69.8215204 C141.09498,69.8107144 141.381831,69.7065834 141.381831,69.411382 C141.381831,69.0321882 140.976605,68.9629313 140.567449,68.9629313 C140.232952,68.9629313 139.924489,68.978158 139.739804,68.9933847 L139.739804,70.4561298 L140.204464,70.4561298 L140.204464,69.213435 C140.325786,69.1991906 140.4476,69.1928053 140.569413,69.1937876 C140.809111,69.1937876 140.929942,69.2728682 140.929942,69.411382 C140.929942,69.5287749 140.880824,69.6702358 140.342978,69.6648328"
                  id="Fill-61"
                  fill="#333333"
                  mask="url(#step2-mask-8)"
                ></path>
                <path
                  d="M137.7009,59.559268 L150.293377,59.559268 C150.991841,59.559268 151.558176,60.1256029 151.558176,60.8240661 L151.558176,66.0635229 C151.558176,66.7619861 150.991841,67.328321 150.293377,67.328321 L137.7009,67.328321 C137.002437,67.328321 136.436102,66.7619861 136.436102,66.0635229 L136.436102,60.8240661 C136.436102,60.1256029 137.002437,59.559268 137.7009,59.559268"
                  id="Fill-62"
                  fill="#333333"
                  mask="url(#step2-mask-8)"
                ></path>
                <path
                  d="M157.905252,139.576977 C169.666155,122.080521 165.389909,98.0857053 148.306539,85.7329259 C132.723736,74.4587859 111.275707,76.0934454 97.5819964,89.6000153 C96.4999187,90.684549 94.9595665,91.1776974 93.4486854,90.9227731 L88.3261303,90.0317658 C87.352113,89.8627986 86.4257405,90.5155818 86.2567733,91.4895991 C86.1845693,91.9056317 86.2616852,92.3339439 86.4748589,92.6984022 L89.0982712,97.1834008 C89.8659913,98.5169646 89.9401601,100.139344 89.2976917,101.537253 C80.4102135,121.379111 89.2898328,144.668587 109.13169,153.556065 C126.624216,161.391919 147.218076,155.489364 157.904269,139.576977 L157.905252,139.576977 Z"
                  id="Fill-63"
                  fill="#FFFFFF"
                  mask="url(#step2-mask-8)"
                ></path>
              </g>
              <g id="Group-80" transform="translate(85.848326, 78.259320)">
                <g id="Group-67">
                  <mask id="step2-mask-10" fill="white">
                    <use xlinkHref="#step2-path-9"></use>
                  </mask>
                  <g id="Clip-66"></g>
                  <path
                    d="M15.2387569,12.6595236 L107.091586,12.6595236 C107.439344,12.6595236 107.721284,12.9414631 107.721284,13.2892212 L107.721284,27.7118488 C107.721284,28.0596069 107.439344,28.3415463 107.091586,28.3415463 L15.2387569,28.3415463 C14.8909989,28.3415463 14.6090594,28.0596069 14.6090594,27.7118488 L14.6090594,13.2892212 C14.6090594,12.9414631 14.8909989,12.6595236 15.2387569,12.6595236"
                    id="Fill-65"
                    fill="#C5DCFD"
                    mask="url(#step2-mask-10)"
                  ></path>
                </g>
                <g id="Group-69">
                  <mask id="step2-mask-12" fill="white">
                    <use xlinkHref="#step2-path-11"></use>
                  </mask>
                  <g id="Clip-68"></g>
                  <text
                    id="1.-Living-Room"
                    mask="url(#step2-mask-12)"
                    fontFamily="Helvetica"
                    fontSize="14.141"
                    fontWeight="normal"
                    fill="#014585"
                  >
                    <tspan x="21.1815397" y="25.6956081">
                      1.{' '}
                    </tspan>
                    <tspan x="21.1815397" y="42.6956081">
                      Living{' '}
                    </tspan>
                    <tspan x="21.1815397" y="59.6956081">
                      Room
                    </tspan>
                  </text>
                </g>
                <g id="Group-72">
                  <mask id="step2-mask-14" fill="white">
                    <use xlinkHref="#step2-path-13"></use>
                  </mask>
                  <g id="Clip-71"></g>
                  <path
                    d="M15.2387569,31.5268236 L107.091586,31.5268236 C107.439344,31.5268236 107.721284,31.8087631 107.721284,32.1565212 L107.721284,46.5791488 C107.721284,46.9269069 107.439344,47.2088463 107.091586,47.2088463 L15.2387569,47.2088463 C14.8909989,47.2088463 14.6090594,46.9269069 14.6090594,46.5791488 L14.6090594,32.1565212 C14.6090594,31.8087631 14.8909989,31.5268236 15.2387569,31.5268236"
                    id="Fill-70"
                    fill="#C5DCFD"
                    mask="url(#step2-mask-14)"
                  ></path>
                </g>
                <g id="Group-74">
                  <mask id="step2-mask-16" fill="white">
                    <use xlinkHref="#step2-path-15"></use>
                  </mask>
                  <g id="Clip-73"></g>
                  <text
                    id="2.-Bed-room"
                    mask="url(#step2-mask-16)"
                    fontFamily="Helvetica"
                    fontSize="14.141"
                    fontWeight="normal"
                    fill="#014585"
                  >
                    <tspan x="21.1815397" y="44.5625643">
                      2.{' '}
                    </tspan>
                    <tspan x="21.1815397" y="61.5625643">
                      Bed{' '}
                    </tspan>
                    <tspan x="21.1815397" y="78.5625643">
                      room
                    </tspan>
                  </text>
                </g>
                <g id="Group-77">
                  <mask id="step2-mask-18" fill="white">
                    <use xlinkHref="#step2-path-17"></use>
                  </mask>
                  <g id="Clip-76"></g>
                  <path
                    d="M15.2387569,50.3942219 L107.091586,50.3942219 C107.439344,50.3942219 107.721284,50.6761613 107.721284,51.0239194 L107.721284,65.446547 C107.721284,65.7943051 107.439344,66.0762445 107.091586,66.0762445 L15.2387569,66.0762445 C14.8909989,66.0762445 14.6090594,65.7943051 14.6090594,65.446547 L14.6090594,51.0239194 C14.6090594,50.6761613 14.8909989,50.3942219 15.2387569,50.3942219"
                    id="Fill-75"
                    fill="#C5DCFD"
                    mask="url(#step2-mask-18)"
                  ></path>
                </g>
                <g id="Group-79">
                  <mask id="step2-mask-20" fill="white">
                    <use xlinkHref="#step2-path-19"></use>
                  </mask>
                  <g id="Clip-78"></g>
                  <text
                    id="3.-Kitchen"
                    mask="url(#step2-mask-20)"
                    fontFamily="Helvetica"
                    fontSize="14.141"
                    fontWeight="normal"
                    fill="#014585"
                  >
                    <tspan x="21.1815397" y="63.4308958">
                      3.{' '}
                    </tspan>
                    <tspan x="21.1815397" y="80.4308958">
                      Kitc
                    </tspan>
                    <tspan x="21.1815397" y="97.4308958">
                      hen
                    </tspan>
                  </text>
                </g>
              </g>
              <g id="Group-89" transform="translate(82.224646, 1.114741)">
                <path
                  d="M2.20492373,6.82691343 C6.48608104,6.82691343 9.95678522,10.2976176 9.95678522,14.5787749"
                  id="Stroke-81"
                  stroke="#EA5851"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M2.20492373,2.7920562e-14 C10.2559161,0.000982367443 16.781783,6.52783166 16.781783,14.578824"
                  id="Stroke-83"
                  stroke="#EA5851"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M2.20492373,12.3739003 C3.42256817,12.3739003 4.40984745,13.3611796 4.40984745,14.578824 C4.40984745,15.7964685 3.42256817,16.7837478 2.20492373,16.7837478 C0.98727928,16.7837478 0,15.7964685 0,14.578824 C0,13.3611796 0.98727928,12.3739003 2.20492373,12.3739003"
                  id="Fill-85"
                  fill="#EA5851"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

CreateTourStep2.displayName = 'Step2';

export default CreateTourStep2;
