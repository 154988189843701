import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const TimesSmall: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 8 8">
    <path d="M4 3.371L7.241.131a.444.444 0 1 1 .629.628L4.629 4l3.24 3.241a.444.444 0 0 1-.628.629L4 4.629.759 7.869a.444.444 0 0 1-.629-.628L3.371 4 .131.759A.444.444 0 1 1 .758.13L4 3.371z" />
  </SvgIcon>
);

TimesSmall.displayName = 'TimesSmall';

export default TimesSmall;
