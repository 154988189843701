import { ArrowDown } from '@r360-tours/ui/icons';
import styled from 'styled-components';
import UIMenu from '../menu';

export const Toggle = styled.div<{ disabled: boolean }>`
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
  padding: 0;
  min-width: 240px;

  &.mouseDown {
    outline: none;
  }
`;

export const Popper = styled.div<{ aspectRatioPopper?: boolean }>`
  min-width: 240px;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.16));
  z-index: 2;

  &[data-placement^='bottom'] {
    padding-top: 10px;
  }

  opacity: ${(props): string => (props.aspectRatioPopper ? '70%' : 'unset')};

  ul {
    background-color: ${(props): string =>
      props.aspectRatioPopper ? '#2c2828' : '#fff'};
    color: ${(props): string => (props.aspectRatioPopper ? '#fff' : 'unset')};
  }

  ul > li.highlighted {
    background: ${(props): string =>
      props.aspectRatioPopper
        ? 'rgba(255, 255, 255, 0.2)'
        : props.theme.palette.grey.action};
  }

  .selected {
    background: #e2e2e2 !important;
  }
`;

export const Menu = styled(UIMenu)`
  padding: 8px 0;
`;

export const Wrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  margin: auto;
  display: block;
  opacity: ${(props): number => (props.disabled ? 0.3 : 1)};
`;

export const Label = styled.span<{
  error?: string;
  float?: string;
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  will-change: transform, animation;
  transform-origin: 0 0;
  transition: all 0.2s ease;
  pointer-events: none;
  color: ${(props): string =>
    props.error
      ? props.theme.palette.primary.main
      : props.theme.palette.grey.dark};
  white-space: nowrap;
  ${(props): string =>
    props.float ? 'transform: translate(1px, -26px) scale(0.75);' : ''}
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Select = styled.div<{ error?: string }>`
  display: flex;
  align-items: center;
  border: none;
  border-bottom-color: ${(props): string =>
    props.error
      ? props.theme.palette.primary.main
      : props.theme.input.borderColor};
  border-bottom-style: ${(props): string => props.theme.input.borderStyle};
  border-bottom-width: ${(props): string | number =>
    props.theme.input.borderWidth};
  color: ${(props): string => props.theme.palette.text.primary};
  font-size: ${(props): string | number => props.theme.input.fontSize};
  width: 100%;
  height: 54px;
  padding-top: 12px;
`;

export const ArrowDownIcon = styled(ArrowDown)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

export const Border = styled.span<{
  disabled: boolean;
  error?: string;
  show: boolean;
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: ${(props): string =>
    props.error
      ? props.theme.palette.primary.main
      : props.theme.palette.grey.dark};
  will-change: scaleX, animation;
  transform: scaleX(0);
  transform-origin: 50% 50%;
  transition: all 0.15s ease;
  ${(props): string =>
    props.show && !props.disabled ? 'transform: scaleX(1);' : ''}
`;

export const ErrorMessage = styled.div`
  color: ${(props): string => props.theme.palette.text.alert};
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 0.5rem;
`;
