import * as React from 'react';
import { ReactNode } from 'react';
import ResetPassword, { ResetPasswordStep } from './reset-password';

interface Props {
  required?: boolean;
  links: ReactNode;
  onResetPasswordSuccessClick: () => void;
  title?: ReactNode;
}

const ResetPasswordContainer: React.FunctionComponent<Props> = ({
  required = false,
  links,
  onResetPasswordSuccessClick,
  title,
}) => {
  const [username, setUsername] = React.useState<string>('');
  const [step, setStep] = React.useState<ResetPasswordStep>(
    ResetPasswordStep.RequestCode,
  );

  const onRequestCodeSubmitted = (email: string) => {
    setUsername(email.toLowerCase());
    setStep(ResetPasswordStep.ResetPassword);
  };

  const onResetPasswordSubmitted = () => {
    setStep(ResetPasswordStep.Finished);
  };

  return (
    <ResetPassword
      onRequestCodeSubmitted={onRequestCodeSubmitted}
      onResetPasswordSubmitted={onResetPasswordSubmitted}
      step={step}
      username={username}
      required={required}
      links={links}
      onResetPasswordSuccessClick={onResetPasswordSuccessClick}
      title={title}
    />
  );
};

ResetPasswordContainer.displayName = 'ResetPasswordContainer';

export default ResetPasswordContainer;
