import styled from 'styled-components';

export const Size = styled.button`
  background: ${(props): string => props.theme.background.secondary.main};
  border: 2px solid ${(props): string => props.theme.palette.secondary.light};
  border-color: #706a6a;
  border-radius: 4px;
  border-style: ${(props): string => props.theme.button.borderStyle};
  border-width: ${(props): string | number => props.theme.button.borderWidth};
  box-sizing: content-box;
  color: ${(props): string => props.theme.palette.text.primary};
  cursor: pointer;
  padding: 0;

  &.sm {
    height: 16px;
    width: 24px;
  }
  &.md {
    height: 21px;
    width: 32px;
  }
  &.lg {
    height: 26px;
    width: 40px;
  }

  &[disabled] {
    background: ${(props): string => props.theme.background.primary.light};
    color: ${(props): string => props.theme.palette.text.disabled};
    cursor: not-allowed;
  }
`;
