import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const House: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" fill={'currentColor'} {...props}>
    <path id="d" d="M9.237 17.42L8 23l10-6.884L28 23l-1.237-5.58L18.001 11z" />
  </SvgIcon>
);

House.displayName = 'House';

export default House;
