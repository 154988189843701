import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const CompactTour: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 60 60" {...props}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Compact-Tour" stroke="#2C2828" strokeWidth="1.2">
        <g id="Compact-Tour-Icon" transform="translate(3.000000, 14.000000)">
          <path
            d="M51.9272727,32 L2.47272727,32 C1.11272727,32 0,30.8872727 0,29.5272727 L0,2.47272727 C0,1.11272727 1.11272727,0 2.47272727,0 L51.9272727,0 C53.2872727,0 54.4,1.11272727 54.4,2.47272727 L54.4,29.5272727 C54.4,30.8872727 53.2872727,32 51.9272727,32 Z"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <circle id="Oval" cx="40.5619403" cy="4.05970149" r="1"></circle>
          <circle id="Oval" cx="45.1819403" cy="4.05970149" r="1"></circle>
          <circle id="Oval" cx="49.8119403" cy="4.05970149" r="1"></circle>
          <line
            x1="0.4"
            y1="7.6"
            x2="54"
            y2="7.6"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <path
            d="M5.34824901,24.8883345 C6.09883268,24.9961448 6.84941634,25.0500499 7.6,25.0500499 C8.35058087,25.0500499 9.10116174,24.9961452 9.85174261,24.8883357 C10.1141516,24.8506314 10.3574325,25.0328026 10.3951238,25.2952135 C10.3983704,25.3178172 10.4,25.3406239 10.4,25.3634596 L10.4,27.4319792 C10.4000933,27.6971274 10.1851482,27.9120724 9.92,27.9120724 C9.89605808,27.9120724 9.8721497,27.9102815 9.84847497,27.9067146 C9.09898331,27.793794 8.34949166,27.7373337 7.6,27.7373337 C6.85051288,27.7373337 6.10102576,27.7937933 5.35153864,27.9067125 C5.08938932,27.9462367 4.84485363,27.7657376 4.80535706,27.5035841 C4.80179068,27.4799128 4.8,27.4560078 4.8,27.4320693 L4.8,25.3634896 C4.79996847,25.0983755 5.01488591,24.8834581 5.28,24.8834581 C5.3028367,24.8834581 5.3256443,24.8850877 5.34824901,24.8883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M13.348249,24.8883345 C14.0988327,24.9961448 14.8494163,25.0500499 15.6,25.0500499 C16.3505809,25.0500499 17.1011617,24.9961452 17.8517426,24.8883357 C18.1141516,24.8506314 18.3574325,25.0328026 18.3951238,25.2952135 C18.3983704,25.3178172 18.4,25.3406239 18.4,25.3634596 L18.4,27.4319792 C18.4000933,27.6971274 18.1851482,27.9120724 17.92,27.9120724 C17.8960581,27.9120724 17.8721497,27.9102815 17.848475,27.9067146 C17.0989833,27.793794 16.3494917,27.7373337 15.6,27.7373337 C14.8505129,27.7373337 14.1010258,27.7937933 13.3515386,27.9067125 C13.0893893,27.9462367 12.8448536,27.7657376 12.8053571,27.5035841 C12.8017907,27.4799128 12.8,27.4560078 12.8,27.4320693 L12.8,25.3634896 C12.7999685,25.0983755 13.0148859,24.8834581 13.28,24.8834581 C13.3028367,24.8834581 13.3256443,24.8850877 13.348249,24.8883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M21.348249,24.8883345 C22.0988327,24.9961448 22.8494163,25.0500499 23.6,25.0500499 C24.3505809,25.0500499 25.1011617,24.9961452 25.8517426,24.8883357 C26.1141516,24.8506314 26.3574325,25.0328026 26.3951238,25.2952135 C26.3983704,25.3178172 26.4,25.3406239 26.4,25.3634596 L26.4,27.4319792 C26.4000933,27.6971274 26.1851482,27.9120724 25.92,27.9120724 C25.8960581,27.9120724 25.8721497,27.9102815 25.848475,27.9067146 C25.0989833,27.793794 24.3494917,27.7373337 23.6,27.7373337 C22.8505129,27.7373337 22.1010258,27.7937933 21.3515386,27.9067125 C21.0893893,27.9462367 20.8448536,27.7657376 20.8053571,27.5035841 C20.8017907,27.4799128 20.8,27.4560078 20.8,27.4320693 L20.8,25.3634896 C20.7999685,25.0983755 21.0148859,24.8834581 21.28,24.8834581 C21.3028367,24.8834581 21.3256443,24.8850877 21.348249,24.8883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M29.348249,24.8883345 C30.0988327,24.9961448 30.8494163,25.0500499 31.6,25.0500499 C32.3505809,25.0500499 33.1011617,24.9961452 33.8517426,24.8883357 C34.1141516,24.8506314 34.3574325,25.0328026 34.3951238,25.2952135 C34.3983704,25.3178172 34.4,25.3406239 34.4,25.3634596 L34.4,27.4319792 C34.4000933,27.6971274 34.1851482,27.9120724 33.92,27.9120724 C33.8960581,27.9120724 33.8721497,27.9102815 33.848475,27.9067146 C33.0989833,27.793794 32.3494917,27.7373337 31.6,27.7373337 C30.8505129,27.7373337 30.1010258,27.7937933 29.3515386,27.9067125 C29.0893893,27.9462367 28.8448536,27.7657376 28.8053571,27.5035841 C28.8017907,27.4799128 28.8,27.4560078 28.8,27.4320693 L28.8,25.3634896 C28.7999685,25.0983755 29.0148859,24.8834581 29.28,24.8834581 C29.3028367,24.8834581 29.3256443,24.8850877 29.348249,24.8883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M37.348249,24.8883345 C38.0988327,24.9961448 38.8494163,25.0500499 39.6,25.0500499 C40.3505809,25.0500499 41.1011617,24.9961452 41.8517426,24.8883357 C42.1141516,24.8506314 42.3574325,25.0328026 42.3951238,25.2952135 C42.3983704,25.3178172 42.4,25.3406239 42.4,25.3634596 L42.4,27.4319792 C42.4000933,27.6971274 42.1851482,27.9120724 41.92,27.9120724 C41.8960581,27.9120724 41.8721497,27.9102815 41.848475,27.9067146 C41.0989833,27.793794 40.3494917,27.7373337 39.6,27.7373337 C38.8505129,27.7373337 38.1010258,27.7937933 37.3515386,27.9067125 C37.0893893,27.9462367 36.8448536,27.7657376 36.8053571,27.5035841 C36.8017907,27.4799128 36.8,27.4560078 36.8,27.4320693 L36.8,25.3634896 C36.7999685,25.0983755 37.0148859,24.8834581 37.28,24.8834581 C37.3028367,24.8834581 37.3256443,24.8850877 37.348249,24.8883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M45.348249,24.8883345 C46.0988327,24.9961448 46.8494163,25.0500499 47.6,25.0500499 C48.3505809,25.0500499 49.1011617,24.9961452 49.8517426,24.8883357 C50.1141516,24.8506314 50.3574325,25.0328026 50.3951238,25.2952135 C50.3983704,25.3178172 50.4,25.3406239 50.4,25.3634596 L50.4,27.4319792 C50.4000933,27.6971274 50.1851482,27.9120724 49.92,27.9120724 C49.8960581,27.9120724 49.8721497,27.9102815 49.848475,27.9067146 C49.0989833,27.793794 48.3494917,27.7373337 47.6,27.7373337 C46.8505129,27.7373337 46.1010258,27.7937933 45.3515386,27.9067125 C45.0893893,27.9462367 44.8448536,27.7657376 44.8053571,27.5035841 C44.8017907,27.4799128 44.8,27.4560078 44.8,27.4320693 L44.8,25.3634896 C44.7999685,25.0983755 45.0148859,24.8834581 45.28,24.8834581 C45.3028367,24.8834581 45.3256443,24.8850877 45.348249,24.8883345 Z"
            id="Rectangle"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

CompactTour.displayName = 'CompactTour';

export default CompactTour;
