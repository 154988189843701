import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';
import theme from '../core/theme/default';

export const User: React.FC<Props> = ({
  color = theme.palette.text.primary,
  ...props
}) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={color} strokeWidth="1.5">
        <g transform="translate(11.000000, 7.000000)">
          <path d="M7.66697333,9.35333333 L7.66697333,9.35333333 C5.67364,9.35333333 3.98697333,7.66666667 3.98697333,5.67333333 L3.98697333,3.83333333 C3.98697333,1.84 5.67364,0.153333333 7.66697333,0.153333333 C9.66030667,0.153333333 11.34544,1.84 11.34544,3.83333333 L11.34544,5.67333333 C11.34544,7.66666667 9.66030667,9.35333333 7.66697333,9.35333333 Z" />
          <path d="M13.9530267,22.5393867 L13.9530267,18.7060533 C13.9530267,15.1793867 11.1930267,12.4193867 7.66636,12.4193867 C4.13969333,12.4193867 1.37969333,15.1793867 1.37969333,18.7060533 L1.37969333,22.5393867" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

User.displayName = 'User';

export default User;
