import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const ZoomIn: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={props.color} strokeWidth="1.5">
        <g transform="translate(7.000000, 7.000000)">
          <circle cx="9.77777778" cy="9.77777778" r="9.77777778" />
          <line x1="22" y1="22" x2="16.6833333" y2="16.6833333" />
          <line
            x1="9.77777778"
            y1="6.11111111"
            x2="9.77777778"
            y2="13.4444444"
          />
          <line
            x1="6.11111111"
            y1="9.77777778"
            x2="13.4444444"
            y2="9.77777778"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

ZoomIn.displayName = 'ZoomIn';

export default ZoomIn;
