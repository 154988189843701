import styled from 'styled-components';

export const Address = styled.address`
  margin-top: 14px;
  font-size: ${(props): string | number => props.theme.typography.fontSize};
  font-style: normal;

  > span {
    display: block;
  }
`;

export const Emphasis = styled.span`
  :nth-child(1) {
    color: ${(props): string => props.theme.palette.text.primary};
    line-height: 26px;
  }

  :nth-child(n + 2) {
    color: #706a6a;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Muted = styled.span`
  && {
    color: ${(props): string => props.theme.palette.grey.normal};
    font-size: 12px;
    line-height: 22px;
  }
`;
