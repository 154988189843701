import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';
import theme from '../core/theme/default';

export const ArrowDown: React.FC<Props> = ({
  color = theme.palette.text.primary,
  ...props
}) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={color} strokeWidth="1.5">
        <polyline
          transform="translate(18.000000, 17.500000) scale(-1, 1) rotate(-270.000000) translate(-18.000000, -17.500000) "
          points="14.5 24.5 21.5 17.4984812 14.5 10.5"
        />
      </g>
    </g>
  </SvgIcon>
);

ArrowDown.displayName = 'ArrowDown';

export default ArrowDown;
