import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const NoPhotos: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 100 100" {...props}>
    <g id="Tours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group">
        <g transform="translate(15.000000, 15.000000)">
          <path
            d="M7.77777776,0 L62.2222224,0 C66.5155556,0 70,3.48444444 70,7.77777776 L70,62.2222224 C70,66.5155556 66.5155556,70 62.2222224,70 L7.77777776,70 C3.48444444,70 0,66.5155556 0,62.2222224 L0,7.77777776 C0,3.48444444 3.48444444,0 7.77777776,0 Z"
            id="Stroke-1"
            stroke="#000000"
            strokeWidth="1.5"
          ></path>
          <path
            d="M22.4,16.1 C25.8818,16.1 28.7,18.9182 28.7,22.4 C28.7,25.8818 25.8818,28.7 22.4,28.7 C18.9182,28.7 16.1,25.8818 16.1,22.4 C16.1,18.9182 18.9182,16.1 22.4,16.1 Z"
            id="Stroke-3"
            stroke="#000000"
            strokeWidth="1.5"
          ></path>
          <path
            d="M70,47.4727272 L52.1520194,30.6410653 C50.9962752,29.5511327 49.1912248,29.5511327 48.0354806,30.6410653 L6.3,70 L6.3,70"
            id="Stroke-5"
            stroke="#000000"
            strokeWidth="1.5"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

NoPhotos.displayName = 'NoPhotos';

export default NoPhotos;
