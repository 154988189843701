import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  avatar: {
    picture: {
      background: 'transparent',
      borderColor: '#2b2828',
      stroke: '#2b2828',
    },
    tripodCover: {
      background: '#ffffff',
      borderColor: '#e2e2e2',
      stroke: '#2b2828',
    },
  },
  background: {
    primary: {
      contrast: '#2c2828',
      light: '#ffffff',
      main: '#ffffff',
    },
    secondary: {
      contrast: '#80a7ba',
      light: '#f6f6f5',
      main: '#f6f6f5',
    },
  },
  breakPoints: {
    large: 1024,
    medium: 768,
    small: 0,
  },
  button: {
    borderRadius: '22px',
    borderStyle: 'solid',
    borderWidth: '1px',
    critical: {
      default: {
        background: 'transparent',
        borderColor: '#ea5851',
        fontColor: '#ea5851',
      },
      hover: {
        background: '#ea5851',
        borderColor: '#ea5851',
        fontColor: '#ffffff',
      },
    },
    dropdown: {
      default: {
        background: 'transparent',
        borderColor: '#2c2828',
        fontColor: '#2c2828',
      },
      hover: {
        background: 'transparent',
        borderColor: '#2c2828',
        fontColor: '#2c2828',
      },
    },
    fontSize: '16px',
    margin: '0.125rem',
    padding: '9px 16px',
    primary: {
      default: {
        background: '#ea5851',
        borderColor: '#ea5851',
        fontColor: '#ffffff',
      },
      hover: {
        background: '#d34c46',
        borderColor: '#d34c46',
        fontColor: '#ffffff',
      },
    },
    secondary: {
      default: {
        background: '#2c2828',
        borderColor: '#2c2828',
        fontColor: '#ffffff',
      },
      hover: {
        background: '#4f4a4a',
        borderColor: '#4f4a4a',
        fontColor: '#ffffff',
      },
    },
    secondaryNegative: {
      default: {
        background: '#ffffff',
        borderColor: '#ffffff',
        fontColor: '#2b2828',
      },
      hover: {
        background: '#f6f6f5',
        borderColor: '#f6f6f5',
        fontColor: '#2b2828',
      },
    },
    tertiary: {
      default: {
        background: 'transparent',
        borderColor: '#2b2828',
        fontColor: '#2b2828',
      },
      hover: {
        background: 'transparent',
        borderColor: '#6f6a6a',
        fontColor: '#6f6a6a',
      },
    },
    tertiaryNegative: {
      default: {
        background: 'transparent',
        borderColor: '#ffffff',
        fontColor: '#ffffff',
      },
      hover: {
        background: 'transparent',
        borderColor: '#ea5851',
        fontColor: '#ea5851',
      },
    },
    viewer: {
      default: {
        background: 'transparent',
        borderColor: '#ffffff',
        fontColor: '#ffffff',
      },
      hover: {
        background: 'rgba(255, 255, 255, 0.3)',
        borderColor: '#ffffff',
        fontColor: '#ffffff',
      },
    },
  },
  checkmark: {
    default: {
      background: '#2c2828',
      borderColor: '#ffffff',
    },
    selected: {
      background: '#ea5851',
      borderColor: '#ffffff',
    },
  },
  dialog: {
    alert: '#d02820',
    approval: '#65C084',
    informative: '#ffda76',
  },
  header: {
    background: {
      contrast: '#2c2828',
      light: '#ea5851',
      main: '#ffffff',
    },
    button: {
      background: 'transparent',
      borderColor: '#ea5851',
      color: '#ea5851',
    },
    navMenu: {
      activeBackground: '#ea5851',
    },
  },
  hyperlink: {
    color: {
      light: '#0084c1',
      main: '#ea5851',
    },
    decoration: 'none',
  },
  iconButton: {
    background: 'transparent',
    borderColor: 'transparent',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '0',
    fontSize: '1rem',
    margin: '0',
    padding: '0.5rem',
  },
  icons: {
    infoInner: '#f19a00',
  },
  input: {
    background: '#ffffff',
    borderColor: '#c9c8cb',
    borderRadius: '0',
    borderStyle: 'solid',
    borderWidth: '1px',
    disabledBackground: '#e1e1e1',
    fontSize: '16px',
    margin: '0.125rem',
    padding: '0.625rem',
    placeholderTextColor: '#706a6a',
  },
  modals: {
    button: {
      borderColor: '#ea5851',
    },
  },
  notification: {
    alert: {
      background: '#e02020',
      color: '#ffffff',
    },
    primary: {
      background: '#ffda76',
      color: '#2c2828',
    },
    secondary: {
      background: '#2b2828',
      color: '#ffffff',
    },
  },
  palette: {
    danger: {
      contrast: '#ffffff',
      light: '#fed0cd',
      main: '#e02020',
    },
    grey: {
      action: '#e2e2e2',
      dark: '#6f6a6a',
      light: '#d9d9da',
      normal: '#a5a4a5',
    },
    primary: {
      contrast: '#ffffff',
      light: '#d34c46',
      main: '#ea5851',
    },
    secondary: {
      contrast: '#2b2828',
      light: '#ea5851',
      main: '#eaafaa',
    },
    success: {
      contrast: '#ffffff',
      light: '#33ffe6',
      main: '#00dc66',
    },
    text: {
      alert: '#e02020',
      disabled: '#a0a0a0',
      hint: '#3c3c3c',
      muted: '#706a6a',
      primary: '#2c2828',
      secondary: '#a0a0a0',
    },
  },
  scrollbar: {
    thumb: '#2c2828',
    track: '#d8d8d8',
  },
  separator: {
    color: '#d9d9da',
    style: 'solid',
    width: '0.0625rem',
  },
  tabs: {
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  typography: {
    fontFamily:
      '"Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic UI", "Segoe UI", Meiryo, Arial, sans-serif',
    fontSize: '16px',
  },
  viewer: {
    breakpoint: {
      medium: 768,
      small: 480,
    },
    footer: {
      height: 24,
    },
    thumbnails: {
      height: 120,
      normalWidth: 120,
      smallWidth: 81,
    },
  },
  viewerFooter: {
    background: {
      contrast: '#ffffff',
      light: '#80a7ba',
      main: '#2c2828',
    },
  },
  viewerOverlay: {
    activeBackground: 'rgba(250, 250, 250, 0.8)',
    activeColor: '#2c2828',
    background: 'rgba(44, 40, 40, 0.7)',
    borderRadius: '6px',
    button: {
      hoverBackground: 'rgba(255, 255, 255, 0.2)',
    },
    color: '#ffffff',
    disabledBackground: 'rgba(44,40,40, 0.3)',
    hoverBackground: 'rgba(79, 74, 74, 0.7)',
    hoverColor: 'rgba(255,255,255, 0.7)',
  },
};

export default theme;
