// ref: https://www.site-convert.com/archives/2188
export const isMobile = (userAgent?: string): boolean | undefined => {
  return userAgent
    ? !!userAgent.match(/iPhone|Android.+Mobile/)
    : typeof window !== 'undefined'
    ? !!navigator.userAgent.match(/iPhone|Android.+Mobile/)
    : undefined;
};

export const isIOS = (userAgent?: string): boolean | undefined => {
  return userAgent
    ? !!userAgent.match(/iP(hone|(o|a)d)/)
    : typeof window !== 'undefined'
    ? !!navigator.userAgent.match(/iP(hone|(o|a)d)/)
    : undefined;
};

export const isAndroid = (userAgent?: string): boolean | undefined => {
  return userAgent
    ? !!userAgent.match(/Android.+Mobile/)
    : typeof window !== 'undefined'
    ? !!navigator.userAgent.match(/Android.+Mobile/)
    : undefined;
};
