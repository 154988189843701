import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const CreateTourStep3: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 243 170" {...props}>
    <defs>
      <path
        d="M-2.61760183e-15,0 L-2.61760183e-15,119.405075 C-2.61760183e-15,124.109721 3.81411319,127.924325 8.51875919,127.924325 L8.51875919,127.924325 L59.5207959,127.924325 C64.2259331,127.924325 68.0400463,124.110212 68.0400463,119.405075 L68.0400463,119.405075 L68.0400463,0 L-2.61760183e-15,0 Z"
        id="step3-path-1"
      ></path>
      <polygon
        id="step3-path-3"
        points="0 0 72.4848512 0 72.4848512 147.04347 0 147.04347"
      ></polygon>
      <polygon
        id="step3-path-5"
        points="0 157 242.195942 157 242.195942 0 0 0"
      ></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="For-those-who-are-referred-for-PC."
        transform="translate(-818.000000, -942.000000)"
      >
        <g id="How-it-work-?" transform="translate(0.000000, 738.000000)">
          <g id="Group-3" transform="translate(818.000000, 204.000000)">
            <rect
              id="Rectangle-Copy-3"
              fill="#ED7872"
              opacity="0.3"
              x="36"
              y="0"
              width="170"
              height="170"
              rx="85"
            ></rect>
            <g id="Group-63-Copy" transform="translate(0.000000, 5.000000)">
              <g id="Group-5" transform="translate(2.222648, 12.178736)">
                <path
                  d="M8.51875919,0 L59.5212871,0 C64.2259331,0 68.0400463,3.81411319 68.0400463,8.51875919 L68.0400463,134.080446 C68.0400463,138.785092 64.2259331,142.599205 59.5212871,142.599205 L8.51875919,142.599205 C3.81411319,142.599205 -1.74506788e-15,138.785092 -1.74506788e-15,134.080446 L-1.74506788e-15,8.51875919 C-1.74506788e-15,3.81411319 3.81411319,0 8.51875919,0"
                  id="Fill-1"
                  fill="#E6E6E6"
                ></path>
                <path
                  d="M8.51875919,140.376999 C3.81411319,140.376999 -1.74506788e-15,136.562395 -1.74506788e-15,131.857749 L-1.74506788e-15,15.799663 L68.0400463,15.799663 L68.0400463,131.857749 C68.0400463,136.562886 64.2259331,140.376999 59.5207959,140.376999 L8.51875919,140.376999 Z"
                  id="Fill-3"
                  fill="#FFFFFF"
                ></path>
              </g>
              <g id="Group-16" transform="translate(2.222648, 24.631361)">
                <mask id="step3-mask-2" fill="white">
                  <use xlinkHref="#step3-path-1"></use>
                </mask>
                <g id="Clip-7"></g>
                <polygon
                  id="Fill-6"
                  fill="#C5DCFD"
                  mask="url(#step3-mask-2)"
                  points="0.240684542 86.6282608 68.993943 86.6282608 68.993943 1.57722054 0.240684542 1.57722054"
                ></polygon>
                <polygon
                  id="Fill-8"
                  fill="#7ABEFF"
                  mask="url(#step3-mask-2)"
                  points="46.4880227 34.2091816 0.240733661 15.2442221 0.240733661 -1.77021024 68.9939921 -1.77021024 68.9939921 18.2871623"
                ></polygon>
                <polygon
                  id="Fill-9"
                  fill="#7ABEFF"
                  mask="url(#step3-mask-2)"
                  points="0.240684542 129.224022 68.993943 129.224022 68.993943 74.7129024 0.240684542 74.7129024"
                ></polygon>
                <polygon
                  id="Fill-10"
                  fill="#E3EFFD"
                  mask="url(#step3-mask-2)"
                  points="46.4880227 34.2091816 46.4880227 68.3225314 68.9944833 86.6288011 68.9944833 18.2871623"
                ></polygon>
                <path
                  d="M11.7695232,43.696082 L27.0156609,43.696082 C27.5584291,43.696082 27.9980468,44.1356997 27.9980468,44.6784679 L27.9980468,74.7129515 L10.7871373,74.7129515 L10.7871373,44.6784679 C10.7871373,44.1356997 11.226755,43.696082 11.7695232,43.696082"
                  id="Fill-11"
                  fill="#EED86D"
                  mask="url(#step3-mask-2)"
                ></path>
                <path
                  d="M15.8360604,48.235196 L22.9495166,48.235196 C23.4922848,48.235196 23.9319025,48.6748137 23.9319025,49.2175819 L23.9319025,55.9528195 C23.9319025,56.4955877 23.4922848,56.9352054 22.9495166,56.9352054 L15.8360604,56.9352054 C15.2932922,56.9352054 14.8536746,56.4955877 14.8536746,55.9528195 L14.8536746,49.2175819 C14.8536746,48.6748137 15.2932922,48.235196 15.8360604,48.235196"
                  id="Fill-12"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-2)"
                ></path>
                <polygon
                  id="Fill-13"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-2)"
                  points="51.1862341 42.9293299 51.1862341 64.7604002 60.29246 69.4517839 60.29246 40.4458583"
                ></polygon>
                <polygon
                  id="Fill-14"
                  fill="#E3EFFD"
                  mask="url(#step3-mask-2)"
                  points="40.4355433 74.7129515 68.9939921 114.568347 74.8431177 111.115752 74.8431177 104.515101 40.4355433 68.3220402"
                ></polygon>
                <polygon
                  id="Fill-15"
                  fill="#7ABEFF"
                  mask="url(#step3-mask-2)"
                  points="46.4880227 68.3220402 40.4360345 68.3220402 74.8436089 104.515101 74.8436089 89.0233667"
                ></polygon>
              </g>
              <g id="Group-62">
                <path
                  d="M70.2626944,23.7472631 L2.22264806,23.7472631 L2.22264806,20.6974461 C2.22264806,15.9928001 6.03676126,12.1786869 10.7414073,12.1786869 L61.743444,12.1786869 C66.44809,12.1786869 70.2626944,15.9928001 70.2626944,20.6974461 L70.2626944,23.7472631 Z"
                  id="Fill-17"
                  fill="#FFFFFF"
                ></path>
                <g id="Group-21" transform="translate(0.000000, 9.956530)">
                  <mask id="step3-mask-4" fill="white">
                    <use xlinkHref="#step3-path-3"></use>
                  </mask>
                  <g id="Clip-20"></g>
                  <path
                    d="M68.0400463,136.302603 C68.0400463,139.780249 65.22109,142.599205 61.743444,142.599205 L61.743444,142.599205 L10.7414073,142.599205 C7.26376122,142.599205 4.44480493,139.780249 4.44480493,136.302603 L4.44480493,10.7409161 C4.44480493,7.26327003 7.26376122,4.44431374 10.7414073,4.44431374 L10.7414073,4.44431374 L61.743444,4.44431374 C65.22109,4.44431374 68.0400463,7.26327003 68.0400463,10.7409161 L68.0400463,136.302603 Z M61.7439352,0 L10.7414073,0 C4.80927009,0 0,4.80927009 0,10.7414073 L0,136.302603 C0,142.23474 4.80927009,147.043519 10.7414073,147.043519 L61.7439352,147.043519 C67.6760723,147.043519 72.4848512,142.23474 72.4848512,136.302603 L72.4848512,10.7414073 C72.4848512,4.80927009 67.6765635,0 61.7444264,0 L61.7439352,0 Z"
                    id="Fill-19"
                    fill="#3F4448"
                    mask="url(#step3-mask-4)"
                  ></path>
                </g>
                <path
                  d="M9.6730626,18.6099252 C10.3548384,18.6099252 10.9079217,19.1630085 10.9079217,19.8447843 C10.9079217,20.5265601 10.3548384,21.0796433 9.6730626,21.0796433 C8.9912868,21.0796433 8.43820355,20.5265601 8.43820355,19.8447843 C8.43820355,19.1630085 8.9912868,18.6099252 9.6730626,18.6099252"
                  id="Fill-22"
                  fill="#CCCCCC"
                ></path>
                <path
                  d="M13.6242186,18.6099252 C14.3059944,18.6099252 14.8590777,19.1630085 14.8590777,19.8447843 C14.8590777,20.5265601 14.3059944,21.0796433 13.6242186,21.0796433 C12.9424428,21.0796433 12.3893596,20.5265601 12.3893596,19.8447843 C12.3893596,19.1630085 12.9424428,18.6099252 13.6242186,18.6099252"
                  id="Fill-24"
                  fill="#CCCCCC"
                ></path>
                <path
                  d="M17.5748835,18.6099252 C18.2566593,18.6099252 18.8097425,19.1630085 18.8097425,19.8447843 C18.8097425,20.5265601 18.2566593,21.0796433 17.5748835,21.0796433 C16.8931077,21.0796433 16.3400244,20.5265601 16.3400244,19.8447843 C16.3400244,19.1630085 16.8931077,18.6099252 17.5748835,18.6099252"
                  id="Fill-26"
                  fill="#CCCCCC"
                ></path>
                <path
                  d="M84.4321863,7.9416566 C88.713424,7.9416566 92.1841933,11.4124259 92.1841933,15.6936636"
                  id="Stroke-28"
                  stroke="#EA5851"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M84.4321863,1.11461502 C92.483821,1.11461502 99.011284,7.64207803 99.011284,15.6937127"
                  id="Stroke-30"
                  stroke="#EA5851"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M84.4321372,13.4886985 C85.6498045,13.4886985 86.6371023,14.4759963 86.6371023,15.6936636 C86.6371023,16.9113309 85.6498045,17.8986287 84.4321372,17.8986287 C83.2144699,17.8986287 82.227172,16.9113309 82.227172,15.6936636 C82.227172,14.4759963 83.2144699,13.4886985 84.4321372,13.4886985"
                  id="Fill-32"
                  fill="#EA5851"
                ></path>
                <mask id="step3-mask-6" fill="white">
                  <use xlinkHref="#step3-path-5"></use>
                </mask>
                <g id="Clip-35"></g>
                <polygon
                  id="Fill-34"
                  fill="#E7F0FE"
                  mask="url(#step3-mask-6)"
                  points="101.427904 122.341426 227.944961 122.341426 227.944961 38.0355255 101.427904 38.0355255"
                ></polygon>
                <polygon
                  id="Fill-36"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-6)"
                  points="101.258443 47.8692081 228.113932 47.8692081 228.113932 39.7895754 101.258443 39.7895754"
                ></polygon>
                <path
                  d="M107.988817,42.8266705 C108.542392,42.8266705 108.991342,43.2756209 108.991342,43.8291953 C108.991342,44.3827698 108.542392,44.8317201 107.988817,44.8317201 C107.435243,44.8317201 106.986293,44.3827698 106.986293,43.8291953 C106.986293,43.2756209 107.435243,42.8266705 107.988817,42.8266705"
                  id="Fill-37"
                  fill="#CCCCCC"
                  mask="url(#step3-mask-6)"
                ></path>
                <path
                  d="M111.196798,42.8266705 C111.750373,42.8266705 112.199323,43.2756209 112.199323,43.8291953 C112.199323,44.3827698 111.750373,44.8317201 111.196798,44.8317201 C110.643224,44.8317201 110.194274,44.3827698 110.194274,43.8291953 C110.194274,43.2756209 110.643224,42.8266705 111.196798,42.8266705"
                  id="Fill-38"
                  fill="#CCCCCC"
                  mask="url(#step3-mask-6)"
                ></path>
                <path
                  d="M114.405271,42.8266705 C114.958845,42.8266705 115.407796,43.2756209 115.407796,43.8291953 C115.407796,44.3827698 114.958845,44.8317201 114.405271,44.8317201 C113.851696,44.8317201 113.402746,44.3827698 113.402746,43.8291953 C113.402746,43.2756209 113.851696,42.8266705 114.405271,42.8266705"
                  id="Fill-39"
                  fill="#CCCCCC"
                  mask="url(#step3-mask-6)"
                ></path>
                <path
                  d="M226.071601,120.587425 L103.301363,120.587425 L103.301363,39.7915893 L226.071601,39.7915893 L226.071601,120.587425 Z M225.281762,35.8419069 L104.09071,35.8419069 C101.474617,35.8419069 99.3536455,37.962878 99.3536455,40.5789716 L99.3536455,124.535143 L230.019318,124.535143 L230.019318,40.577498 C230.019318,37.9618956 227.897365,35.8419069 225.281762,35.8419069 L225.281762,35.8419069 Z"
                  id="Fill-40"
                  fill="#CCCCCC"
                  mask="url(#step3-mask-6)"
                ></path>
                <path
                  d="M87.1754989,124.535094 L242.195991,124.535094 L242.195991,127.204727 C242.195991,129.364012 240.44538,131.114623 238.286095,131.114623 L91.0853947,131.114623 C88.9261105,131.114623 87.1754989,129.364012 87.1754989,127.204727 L87.1754989,124.535094 Z"
                  id="Fill-41"
                  fill="#B3B3B3"
                  mask="url(#step3-mask-6)"
                ></path>
                <path
                  d="M118.964966,57.9818884 L138.674083,57.9818884 L138.674083,56.5014329 L118.964966,56.5014329 L118.964966,57.9818884 Z M118.964966,55.200754 L145.076291,55.200754 L145.076291,53.7202985 L118.964966,53.7202985 L118.964966,55.200754 Z"
                  id="Fill-42"
                  fill="#CCDBFF"
                  mask="url(#step3-mask-6)"
                ></path>
                <path
                  d="M109.092331,55.1043328 C109.504934,52.8988765 111.627378,51.4459278 113.832835,51.8585299 C116.038291,52.2716231 117.49124,54.3940678 117.078637,56.5990329 C116.665544,58.8044893 114.5431,60.257438 112.338134,59.8448359 C110.132678,59.4322338 108.679729,57.3097891 109.092331,55.1043328"
                  id="Fill-43"
                  fill="#3998FE"
                  mask="url(#step3-mask-6)"
                ></path>
                <polygon
                  id="Fill-44"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-6)"
                  points="140.026337 103.10631 168.713478 103.10631 168.713478 101.98197 140.026337 101.98197"
                ></polygon>
                <polygon
                  id="Fill-45"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-6)"
                  points="140.026337 106.900776 168.713478 106.900776 168.713478 105.776435 140.026337 105.776435"
                ></polygon>
                <polygon
                  id="Fill-46"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-6)"
                  points="140.026337 110.695241 168.713478 110.695241 168.713478 109.570901 140.026337 109.570901"
                ></polygon>
                <polygon
                  id="Fill-47"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-6)"
                  points="140.026337 114.489216 156.942039 114.489216 156.942039 113.364875 140.026337 113.364875"
                ></polygon>
                <polygon
                  id="Fill-48"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-6)"
                  points="184.581466 103.10631 213.268607 103.10631 213.268607 101.98197 184.581466 101.98197"
                ></polygon>
                <polygon
                  id="Fill-49"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-6)"
                  points="184.581466 106.900776 213.268607 106.900776 213.268607 105.776435 184.581466 105.776435"
                ></polygon>
                <polygon
                  id="Fill-50"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-6)"
                  points="184.581466 110.695241 213.268607 110.695241 213.268607 109.570901 184.581466 109.570901"
                ></polygon>
                <polygon
                  id="Fill-51"
                  fill="#FFFFFF"
                  mask="url(#step3-mask-6)"
                  points="184.581466 114.489216 201.497169 114.489216 201.497169 113.364875 184.581466 113.364875"
                ></polygon>
                <polygon
                  id="Fill-52"
                  fill="#EED86D"
                  mask="url(#step3-mask-6)"
                  points="136.257904 95.7624847 140.624119 95.7624847 140.624119 79.7063699 136.257904 79.7063699"
                ></polygon>
                <polygon
                  id="Fill-53"
                  fill="#EED86D"
                  mask="url(#step3-mask-6)"
                  points="128.958777 95.7624847 133.324991 95.7624847 133.324991 75.4447799 128.958777 75.4447799"
                ></polygon>
                <polygon
                  id="Fill-54"
                  fill="#EED86D"
                  mask="url(#step3-mask-6)"
                  points="121.65965 95.7629759 126.025864 95.7629759 126.025864 88.230041 121.65965 88.230041"
                ></polygon>
                <polygon
                  id="Fill-55"
                  fill="#EED86D"
                  mask="url(#step3-mask-6)"
                  points="114.360523 95.7624847 118.726737 95.7624847 118.726737 91.63892 114.360523 91.63892"
                ></polygon>
                <polygon
                  id="Fill-56"
                  fill="#EED86D"
                  mask="url(#step3-mask-6)"
                  points="150.856159 95.7624847 155.222373 95.7624847 155.222373 62.66001 150.856159 62.66001"
                ></polygon>
                <polygon
                  id="Fill-57"
                  fill="#EED86D"
                  mask="url(#step3-mask-6)"
                  points="143.557032 95.7624847 147.923246 95.7624847 147.923246 66.9216 143.557032 66.9216"
                ></polygon>
                <polygon
                  id="Fill-58"
                  fill="#DD5A4F"
                  mask="url(#step3-mask-6)"
                  points="165.454413 95.7624847 169.820627 95.7624847 169.820627 75.4447799 165.454413 75.4447799"
                ></polygon>
                <polygon
                  id="Fill-59"
                  fill="#DD5A4F"
                  mask="url(#step3-mask-6)"
                  points="158.155286 95.7624847 162.5215 95.7624847 162.5215 71.1831899 158.155286 71.1831899"
                ></polygon>
                <path
                  d="M198.925282,63.5034865 C207.600241,63.5034865 214.633141,70.5358959 214.633141,79.2113456 C214.633141,87.8867954 207.600241,94.9192047 198.925282,94.9192047 C190.249833,94.9192047 183.217423,87.8867954 183.217423,79.2113456 C183.217423,70.5358959 190.249833,63.5034865 198.925282,63.5034865"
                  id="Fill-60"
                  fill="#EED86D"
                  mask="url(#step3-mask-6)"
                ></path>
                <path
                  d="M198.925282,79.2112965 L214.633141,79.2112965 C214.63265,70.5358468 207.599259,63.5034374 198.923809,63.5039286 C190.248359,63.5044198 183.21595,70.5373203 183.216441,79.2127701 C183.216932,87.888711 190.250324,94.9211204 198.925774,94.9206292 C201.031518,94.920138 203.116141,94.4967297 205.054879,93.6749639 L198.925282,79.2112965 Z"
                  id="Fill-61"
                  fill="#7ABEFF"
                  mask="url(#step3-mask-6)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

CreateTourStep3.displayName = 'Step3';

export default CreateTourStep3;
