import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const DottedLineArrowRight: React.FC<Props> = ({ ...props }) => (
  <SvgIcon viewBox="0 0 165 12" {...props}>
    <defs>
      <polygon
        id="dotted-line-arrow-right-path-1"
        points="152.010839 5.68434189e-14 163 5.68434189e-14 163 12 152.010839 12"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-557.000000, -338.000000)">
        <g transform="translate(272.000000, 128.000000)">
          <g transform="translate(287.000000, 210.000000)">
            <line
              x1="0"
              y1="5.99990508"
              x2="152.502882"
              y2="5.99990508"
              stroke="#0080FF"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="0,7"
            />
            <mask id="dotted-line-arrow-right-mask-2" fill="white">
              <use xlinkHref="#dotted-line-arrow-right-path-1" />
            </mask>
            <g />
            <path
              d="M162.916369,5.31705344 L153.561547,0.106916348 C153.016473,-0.196826387 152.335621,0.182852032 152.335621,0.789388305 L152.335621,11.2106117 C152.335621,11.817148 153.016473,12.1968264 153.561547,11.8930837 L162.916369,6.68294656 C163.461444,6.37920382 163.461444,5.62079618 162.916369,5.31705344"
              fill="#0080FF"
              mask="url(#dotted-line-arrow-right-mask-2)"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

DottedLineArrowRight.displayName = 'DottedLineArrowRight';

export default DottedLineArrowRight;
