import * as React from 'react';
import classnames from 'classnames';
import * as Styled from './button.styled';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'secondaryNegative'
  | 'tertiary'
  | 'tertiaryNegative'
  | 'critical'
  | 'dropdown'
  | 'viewer';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * button height type
   */
  heightType?: 'default' | 'big' | 'mini';
  /**
   * The label for cypress.
   */
  'data-cy'?: string;
  /**
   * The behavior you want the button to have.
   *
   * @default button
   */
  type?: 'button' | 'submit' | 'reset';

  /**
   * The button variant you want to render.
   */
  variant?: ButtonVariant;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  type = 'button',
  ...props
}) => {
  const [mouseDown, setMouseDown] = React.useState(false);
  const onMouseDown = (): void => {
    setMouseDown(true);
  };
  const onKeyDown = (): void => {
    setMouseDown(false);
  };
  React.useEffect(() => {
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('keydown', onKeyDown);
    return (): void => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <Styled.Button
      className={classnames(className, { mouseDown })}
      type={type}
      {...props}
    >
      <Styled.Span>{children}</Styled.Span>
    </Styled.Button>
  );
};

Button.displayName = 'Button';

export default Button;
