import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const CounterClockWise: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Rotate-left" stroke="#000000" strokeWidth="1.5">
        <g id="Group-3" transform="translate(6.000000, 7.000000)">
          <polyline
            id="Path"
            transform="translate(3.600000, 4.797930) scale(-1, 1) translate(-3.600000, -4.797930) "
            points="7.2 1.19793008 7.2 8.39793008 0 8.39793008"
          ></polyline>
          <path
            d="M20.9840067,14.3979301 C19.3815529,18.9336715 14.9723849,21.8627767 10.1701062,21.5818372 C5.36782743,21.3008976 1.33028042,17.8776486 0.267641117,13.1859958 C-0.794998189,8.49434302 1.37347544,3.6654568 5.58602732,1.34269664 C9.79857921,-0.980063511 15.0395516,-0.236673792 18.4400067,3.16593008 L23.9960067,8.39793008"
            id="Path"
            transform="translate(11.998003, 10.800207) scale(-1, 1) translate(-11.998003, -10.800207) "
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

CounterClockWise.displayName = 'CounterClockWise';

export default CounterClockWise;
