import styled from 'styled-components';

export const UniqueCounter = styled.figure`
  margin: 0;
  position: relative;
`;

export interface UniqueLabelProps {
  height: number;
  width: number;
}

export const UniqueLabel = styled.div<UniqueLabelProps>`
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  strong {
    font-size: 32px;
    font-weight: normal;
  }

  span {
    color: ${(props): string => props.theme.palette.grey.dark};
    line-height: 26px;
  }
`;
