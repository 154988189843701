import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const RewardColor: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 57 57" {...props}>
    <defs>
      <polygon
        id="reward-color-path-1"
        points="0 0 38 0 38 11.9997 0 11.9997"
      />
      <polygon id="reward-color-path-3" points="0 56 57 56 57 0 0 0" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-849.000000, -747.000000)">
        <g transform="translate(0.000000, 579.000000)">
          <g transform="translate(335.000000, 133.000000)">
            <g transform="translate(480.000000, 0.000000)">
              <g transform="translate(34.000000, 35.000000)">
                <path
                  d="M55.5,28.0001 L1.5,28.0001 C0.672,28.0001 0,27.3281 0,26.5001 L0,13.5001 C0,12.6721 0.672,12.0001 1.5,12.0001 L55.5,12.0001 C56.328,12.0001 57,12.6721 57,13.5001 L57,26.5001 C57,27.3281 56.328,28.0001 55.5,28.0001"
                  fill="#FFD63D"
                />
                <path
                  d="M50,56 L7,56 C6.172,56 5.5,55.328 5.5,54.5 L5.5,28 L51.5,28 L51.5,54.5 C51.5,55.328 50.828,56 50,56"
                  fill="#FFD63D"
                />
                <g transform="translate(9.499800, 0.000000)">
                  <mask fill="white" id="reward-color-mask-2">
                    <use xlinkHref="#reward-color-path-1" />
                  </mask>
                  <g />
                  <path
                    d="M35.383,6.7487 C35.312,9.0057 33.384,10.7747 30.993,10.7747 L21.873,10.7747 C22.286,9.7207 22.761,8.7307 23.288,7.8227 C25.384,4.2227 27.904,2.4727 30.993,2.4727 C33.414,2.4727 35.384,4.3347 35.384,6.6047 L35.383,6.7487 Z M7.007,10.7747 C4.586,10.7747 2.616,8.9127 2.616,6.6227 C2.616,4.3347 4.586,2.4727 7.007,2.4727 C10.096,2.4727 12.617,4.2227 14.711,7.8227 C15.24,8.7327 15.715,9.7217 16.127,10.7747 L7.007,10.7747 Z M30.993,-0.0003 C26.92,-0.0003 23.558,2.2317 20.996,6.6317 C20.215,7.9757 19.545,9.4707 19,11.0717 C18.455,9.4687 17.785,7.9747 17.004,6.6317 C14.443,2.2317 11.079,-0.0003 7.007,-0.0003 C3.144,-0.0003 0,2.9717 0,6.6227 C0,8.8377 1.16,10.7967 2.931,11.9997 L35.093,11.9997 C36.801,10.8377 37.93,8.9667 37.998,6.8037 L38,6.6227 C38,2.9717 34.857,-0.0003 30.993,-0.0003 L30.993,-0.0003 Z"
                    fill="#EA5851"
                    mask="url(#reward-color-mask-2)"
                  />
                </g>
                <mask fill="white" id="reward-color-mask-4">
                  <use xlinkHref="#reward-color-path-3" />
                </mask>
                <g />
                <polygon
                  fill="#D9B742"
                  mask="url(#reward-color-mask-4)"
                  points="5.5 31 51.5 31 51.5 28 5.5 28"
                />
                <polygon
                  fill="#EA5851"
                  mask="url(#reward-color-mask-4)"
                  points="26.5 28 30.5 28 30.5 12 26.5 12"
                />
                <polygon
                  fill="#EA5851"
                  mask="url(#reward-color-mask-4)"
                  points="30 56 27 56 27 28 30 28"
                />
                <polygon
                  fill="#B6615D"
                  mask="url(#reward-color-mask-4)"
                  points="27 31 30 31 30 28 27 28"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

RewardColor.displayName = 'RewardColor';

export default RewardColor;
