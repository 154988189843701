import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Grid: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g id="Tours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Atom-/-grid" stroke="#000000" strokeWidth="1.5">
        <g id="Group" transform="translate(10.000000, 11.000000)">
          <rect id="Rectangle" x="0" y="0" width="6" height="5" rx="1"></rect>
          <rect id="Rectangle" x="0" y="9" width="6" height="5" rx="1"></rect>
          <rect id="Rectangle" x="10" y="0" width="6" height="5" rx="1"></rect>
          <rect id="Rectangle" x="10" y="9" width="6" height="5" rx="1"></rect>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Grid.displayName = 'Grid';

export default Grid;
