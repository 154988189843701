import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Lock: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={props.color}>
        <path d="M18.0004067,5 C21.8596991,5 25,8.1705742 25,12.0664046 L25,16 L27.6139333,16 C29.4807333,16 31,17.4813888 31,19.3005943 L31,28.6985716 C31,30.5186112 29.4807333,32 27.6139333,32 L8.38606667,32 C6.51926667,32 5,30.5186112 5,28.6985716 L5,19.3005943 C5,17.4813888 6.51926667,16 8.38606667,16 L11,16 L11,12.0664046 C11,8.1705742 14.1403009,5 18.0004067,5 Z M27.6139333,17.2311542 L8.38606667,17.2311542 C7.22386667,17.2311542 6.27833333,18.1595246 6.27833333,19.3005943 L6.27833333,28.6985716 C6.27833333,29.8404754 7.22386667,30.7688458 8.38606667,30.7688458 L27.6139333,30.7688458 C28.7752667,30.7688458 29.7216667,29.8404754 29.7216667,28.6985716 L29.7216667,19.3005943 C29.7216667,18.1595246 28.7752667,17.2311542 27.6139333,17.2311542 Z M18.0004067,6.24329636 C14.8023587,6.24329636 12.200488,8.85539801 12.200488,12.0664046 L12.2,16 L23.8,16 L23.8003253,12.0664046 C23.8003253,8.85539801 21.1976413,6.24329636 18.0004067,6.24329636 Z" />
      </g>
    </g>
  </SvgIcon>
);

Lock.displayName = 'Lock';

export default Lock;
