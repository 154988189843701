import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Times: React.FC<Props> = (props) => (
  <SvgIcon {...props}>
    <path d="M12.5 11.793L23.646.646a.5.5 0 0 1 .708.708L13.207 12.5l11.147 11.146a.5.5 0 0 1-.708.708L12.5 13.207 1.354 24.354a.5.5 0 0 1-.708-.708L11.793 12.5.646 1.354a.5.5 0 0 1 .708-.708L12.5 11.793z" />
  </SvgIcon>
);

Times.displayName = 'Times';

export default Times;
