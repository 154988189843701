import * as React from 'react';
import Auth from '@r360-tours/core/auth';
import { removeAuthCookies } from '@r360-tours/core/lib/remove-cookie';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Spinner from '../form-loading-spinner';
import LoginForm, {
  LoginFormTranslationKeys,
  LoginFormValues,
} from './login-form';
import * as Styled from './login-form.styled';

export interface LoginFormContainerProps
  extends React.HTMLAttributes<HTMLFormElement> {
  onComplete: (signup: boolean) => Promise<void>;
  onPasswordResetRequired: () => Promise<void>;
  setIsProcessing?: React.Dispatch<React.SetStateAction<boolean>>;
  translationKeys?: LoginFormTranslationKeys;
}

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required('Validation.PasswordRequired')
    .min(6, 'Validation.PasswordTooShort')
    .max(99, 'Validation.PasswordTooLong'),
  username: Yup.string()
    .required('Validation.EmailRequired')
    .email('Validation.EmailInvalid'),
});

const initialValues = {
  password: '',
  username: '',
};

const initialStatus = {
  errors: [],
};

const LoginFormContainer: React.FC<LoginFormContainerProps> = ({
  onComplete,
  onPasswordResetRequired,
  setIsProcessing,
  ...props
}) => {
  const [isComplete, setIsComplete] = React.useState(false);

  const onSubmit = React.useCallback(
    async (
      { password, username }: LoginFormValues,
      actions: FormikHelpers<LoginFormValues>,
    ): Promise<void> => {
      removeAuthCookies();
      try {
        await Auth.signIn({ password, username: username.toLowerCase() });
        await onComplete(false);
        setIsComplete(true);
      } catch (error) {
        if (error.code === 'PasswordResetRequiredException') {
          await onPasswordResetRequired();
        } else {
          actions.setStatus({ errors: [error] });
        }
      } finally {
        actions.setSubmitting(false);
      }
    },
    [onComplete, onPasswordResetRequired],
  );

  return (
    <Formik<LoginFormValues>
      initialValues={initialValues}
      initialStatus={initialStatus}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnMount
    >
      {({
        values,
        setValues,
        errors,
        setErrors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        status,
        touched,
        setTouched,
        validateForm,
      }): JSX.Element => {
        const isProcessing = isComplete || (isSubmitting && isValid);
        if (setIsProcessing) setIsProcessing(isProcessing);

        return (
          <>
            {isProcessing && (
              <Styled.SpinnerContainer>
                <Spinner />
              </Styled.SpinnerContainer>
            )}
            <LoginForm
              {...props}
              values={values}
              setValues={setValues}
              fieldErrors={errors}
              setFieldErrors={setErrors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isProcessing={isProcessing}
              isSubmitting={isSubmitting}
              status={status}
              touched={touched}
              setTouched={setTouched}
              validateForm={validateForm}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default LoginFormContainer;
