import styled from 'styled-components';

const CAPTION_DIVISOR = 125;
const CAPTION_MAX_FONT_SIZE = 1.75;
const CAPTION_MIN_FONT_SIZE = 0.875;
const TRANSITION_MS = 600;

export const Caption = styled.figcaption.attrs<
  {
    diameter: number;
  },
  {
    fontSize: string;
    lineHeight: string;
    transitionDuration: string;
  }
>((props) => {
  const fontSize = Math.min(
    CAPTION_MAX_FONT_SIZE,
    Math.max(CAPTION_MIN_FONT_SIZE, props.diameter / CAPTION_DIVISOR),
  );

  return {
    fontSize: `${fontSize}rem`,
    lineHeight: `${fontSize * 1.5}rem`,
    transitionDuration: `${TRANSITION_MS}ms`,
  };
})`
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: -0.0375rem;
  text-align: center;
  text-overflow: ellipsis;
  transition-duration: ${(props) => props.transitionDuration};
  transition-property: line-height, font-size;
  overflow: hidden;
  padding: 0 5px;
  white-space: nowrap;
  width: 197px;
`;

export const Figure = styled.figure`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0;
`;

export const Image = styled.img`
  background-color: ${(props) => props.theme.palette.secondary.light};
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
  transition-duration: ${TRANSITION_MS}ms;
  transition-property: height, width;
  white-space: nowrap;
`;

export const Default = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.palette.secondary.light};
  border-radius: 100%;
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition-duration: ${TRANSITION_MS}ms;
  transition-property: height, width;
  white-space: nowrap;
`;
