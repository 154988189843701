import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const UnbrandedTour: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 60 60" {...props}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Unbranded-Tour" stroke="#2C2828" strokeWidth="1.2">
        <g id="Unbranded-Tour-Icon" transform="translate(3.000000, 6.000000)">
          <path
            d="M5.34824901,22.4883345 C6.09883268,22.5961448 6.84941634,22.6500499 7.6,22.6500499 C8.35058087,22.6500499 9.10116174,22.5961452 9.85174261,22.4883357 C10.1141516,22.4506314 10.3574325,22.6328026 10.3951238,22.8952135 C10.3983704,22.9178172 10.4,22.9406239 10.4,22.9634596 L10.4,25.0319792 C10.4000933,25.2971274 10.1851482,25.5120724 9.92,25.5120724 C9.89605808,25.5120724 9.8721497,25.5102815 9.84847497,25.5067146 C9.09898331,25.393794 8.34949166,25.3373337 7.6,25.3373337 C6.85051288,25.3373337 6.10102576,25.3937933 5.35153864,25.5067125 C5.08938932,25.5462367 4.84485363,25.3657376 4.80535706,25.1035841 C4.80179068,25.0799128 4.8,25.0560078 4.8,25.0320693 L4.8,22.9634896 C4.79996847,22.6983755 5.01488591,22.4834581 5.28,22.4834581 C5.3028367,22.4834581 5.3256443,22.4850877 5.34824901,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M13.348249,22.4883345 C14.0988327,22.5961448 14.8494163,22.6500499 15.6,22.6500499 C16.3505809,22.6500499 17.1011617,22.5961452 17.8517426,22.4883357 C18.1141516,22.4506314 18.3574325,22.6328026 18.3951238,22.8952135 C18.3983704,22.9178172 18.4,22.9406239 18.4,22.9634596 L18.4,25.0319792 C18.4000933,25.2971274 18.1851482,25.5120724 17.92,25.5120724 C17.8960581,25.5120724 17.8721497,25.5102815 17.848475,25.5067146 C17.0989833,25.393794 16.3494917,25.3373337 15.6,25.3373337 C14.8505129,25.3373337 14.1010258,25.3937933 13.3515386,25.5067125 C13.0893893,25.5462367 12.8448536,25.3657376 12.8053571,25.1035841 C12.8017907,25.0799128 12.8,25.0560078 12.8,25.0320693 L12.8,22.9634896 C12.7999685,22.6983755 13.0148859,22.4834581 13.28,22.4834581 C13.3028367,22.4834581 13.3256443,22.4850877 13.348249,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M21.348249,22.4883345 C22.0988327,22.5961448 22.8494163,22.6500499 23.6,22.6500499 C24.3505809,22.6500499 25.1011617,22.5961452 25.8517426,22.4883357 C26.1141516,22.4506314 26.3574325,22.6328026 26.3951238,22.8952135 C26.3983704,22.9178172 26.4,22.9406239 26.4,22.9634596 L26.4,25.0319792 C26.4000933,25.2971274 26.1851482,25.5120724 25.92,25.5120724 C25.8960581,25.5120724 25.8721497,25.5102815 25.848475,25.5067146 C25.0989833,25.393794 24.3494917,25.3373337 23.6,25.3373337 C22.8505129,25.3373337 22.1010258,25.3937933 21.3515386,25.5067125 C21.0893893,25.5462367 20.8448536,25.3657376 20.8053571,25.1035841 C20.8017907,25.0799128 20.8,25.0560078 20.8,25.0320693 L20.8,22.9634896 C20.7999685,22.6983755 21.0148859,22.4834581 21.28,22.4834581 C21.3028367,22.4834581 21.3256443,22.4850877 21.348249,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M29.348249,22.4883345 C30.0988327,22.5961448 30.8494163,22.6500499 31.6,22.6500499 C32.3505809,22.6500499 33.1011617,22.5961452 33.8517426,22.4883357 C34.1141516,22.4506314 34.3574325,22.6328026 34.3951238,22.8952135 C34.3983704,22.9178172 34.4,22.9406239 34.4,22.9634596 L34.4,25.0319792 C34.4000933,25.2971274 34.1851482,25.5120724 33.92,25.5120724 C33.8960581,25.5120724 33.8721497,25.5102815 33.848475,25.5067146 C33.0989833,25.393794 32.3494917,25.3373337 31.6,25.3373337 C30.8505129,25.3373337 30.1010258,25.3937933 29.3515386,25.5067125 C29.0893893,25.5462367 28.8448536,25.3657376 28.8053571,25.1035841 C28.8017907,25.0799128 28.8,25.0560078 28.8,25.0320693 L28.8,22.9634896 C28.7999685,22.6983755 29.0148859,22.4834581 29.28,22.4834581 C29.3028367,22.4834581 29.3256443,22.4850877 29.348249,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M37.348249,22.4883345 C38.0988327,22.5961448 38.8494163,22.6500499 39.6,22.6500499 C40.3505809,22.6500499 41.1011617,22.5961452 41.8517426,22.4883357 C42.1141516,22.4506314 42.3574325,22.6328026 42.3951238,22.8952135 C42.3983704,22.9178172 42.4,22.9406239 42.4,22.9634596 L42.4,25.0319792 C42.4000933,25.2971274 42.1851482,25.5120724 41.92,25.5120724 C41.8960581,25.5120724 41.8721497,25.5102815 41.848475,25.5067146 C41.0989833,25.393794 40.3494917,25.3373337 39.6,25.3373337 C38.8505129,25.3373337 38.1010258,25.3937933 37.3515386,25.5067125 C37.0893893,25.5462367 36.8448536,25.3657376 36.8053571,25.1035841 C36.8017907,25.0799128 36.8,25.0560078 36.8,25.0320693 L36.8,22.9634896 C36.7999685,22.6983755 37.0148859,22.4834581 37.28,22.4834581 C37.3028367,22.4834581 37.3256443,22.4850877 37.348249,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M45.348249,22.4883345 C46.0988327,22.5961448 46.8494163,22.6500499 47.6,22.6500499 C48.3505809,22.6500499 49.1011617,22.5961452 49.8517426,22.4883357 C50.1141516,22.4506314 50.3574325,22.6328026 50.3951238,22.8952135 C50.3983704,22.9178172 50.4,22.9406239 50.4,22.9634596 L50.4,25.0319792 C50.4000933,25.2971274 50.1851482,25.5120724 49.92,25.5120724 C49.8960581,25.5120724 49.8721497,25.5102815 49.848475,25.5067146 C49.0989833,25.393794 48.3494917,25.3373337 47.6,25.3373337 C46.8505129,25.3373337 46.1010258,25.3937933 45.3515386,25.5067125 C45.0893893,25.5462367 44.8448536,25.3657376 44.8053571,25.1035841 C44.8017907,25.0799128 44.8,25.0560078 44.8,25.0320693 L44.8,22.9634896 C44.7999685,22.6983755 45.0148859,22.4834581 45.28,22.4834581 C45.3028367,22.4834581 45.3256443,22.4850877 45.348249,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M51.9272727,48 L2.47272727,48 C1.11272727,48 0,47.0181818 0,45.8181818 L0,2.18181818 C0,0.981818182 1.11272727,0 2.47272727,0 L51.9272727,0 C53.2872727,0 54.4,0.981818182 54.4,2.18181818 L54.4,45.8181818 C54.4,47.0181818 53.2872727,48 51.9272727,48 Z"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <circle id="Oval" cx="39.8716418" cy="4.05970149" r="1"></circle>
          <circle id="Oval" cx="44.7432836" cy="4.05970149" r="1"></circle>
          <circle id="Oval" cx="49.6149254" cy="4.05970149" r="1"></circle>
          <rect
            id="Rectangle"
            strokeLinecap="round"
            strokeLinejoin="round"
            x="0"
            y="7.30746269"
            width="54.4"
            height="21.1104478"
          ></rect>
          <line
            x1="16.9313433"
            y1="35.7253731"
            x2="38.041791"
            y2="35.7253731"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="38.041791"
            y1="40.5970149"
            x2="16.9313433"
            y2="40.5970149"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
        </g>
      </g>
    </g>
  </SvgIcon>
);

UnbrandedTour.displayName = 'UnbrandedTour';

export default UnbrandedTour;
