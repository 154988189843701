import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Plus: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Plus" stroke={props.color} strokeWidth="1.5">
        <g id="Group-3" transform="translate(9.000000, 9.000000)">
          <polyline id="Stroke-1" points="9 0 9 9 -4.99600361e-16 9" />
          <polyline id="Stroke-3" points="18 9 9 9 9 18" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

Plus.displayName = 'Plus';

export default Plus;
