import styled from 'styled-components';

export const AnchorButton = styled.a`
  align-items: center;
  background-color: ${(props): string =>
    props.theme.button.primary.default.background};
  border-color: ${(props): string => props.theme.palette.primary.main};
  border-radius: ${(props): string | number => props.theme.button.borderRadius};
  border-style: ${(props): string => props.theme.button.borderStyle};
  border-width: ${(props): string | number => props.theme.button.borderWidth};
  color: ${(props): string => props.theme.button.primary.default.fontColor};
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  justify-content: center;
  margin: ${(props): string | number => props.theme.button.margin};
  padding: ${(props): string | number => props.theme.button.padding};
  vertical-align: middle;

  &:visited {
    color: ${(props): string => props.theme.button.primary.default.fontColor};
    background-color: ${(props): string =>
      props.theme.button.primary.default.background};
  }

  &:hover {
    color: ${(props): string => props.theme.button.primary.hover.fontColor};
    background: ${(props): string =>
      props.theme.button.primary.hover.background};
  }
`;

export const Span = styled.span`
  align-items: center;
  display: flex;
  line-height: 1.5rem;

  > svg {
    display: inline-block;
    fill: currentColor;
    font-size: 24px;
    height: 1em;
    width: 1em;
  }
`;
