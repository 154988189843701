import styled, { ErrorMessage as UIErrorMessage } from '@r360-tours/ui';

export const Form = styled.form`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`;

export const TextFieldsWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const Message = styled.p`
  text-align: center;
`;

export const ErrorMessage = styled(UIErrorMessage)`
  margin: 0.625rem;
  text-align: center;
`;
