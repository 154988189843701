import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@r360-tours/ui';
import { FormikErrors, FormikTouched } from 'formik';
import * as Styled from './signup-form.styled';

export interface SignupError {
  code?: string;
  message: string;
  name: string;
}

export interface SignupFormValues {
  username: string;
  name: string;
  password: string;
}

export interface SignupFormTranslationKeys {
  namePlaceholder?: string;
  usernamePlaceholder?: string;
  passwordPlaceholder?: string;
  submitButtonText?: string;
}

export interface SignupFormProps extends React.HTMLAttributes<HTMLFormElement> {
  fieldErrors: FormikErrors<SignupFormValues>;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FocusEvent<HTMLFormElement>) => void;
  isProcessing: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  status: {
    errors: SignupError[];
  };
  touched: FormikTouched<SignupFormValues>;
  translationKeys?: SignupFormTranslationKeys;
  username?: string;
  usernameReadOnly?: boolean;
  marketing?: boolean;
}

const SignupForm: React.FC<SignupFormProps> = ({
  fieldErrors,
  handleBlur,
  handleChange,
  handleSubmit,
  isProcessing,
  isSubmitting,
  isValid,
  status,
  touched,
  translationKeys = {},
  username,
  usernameReadOnly,
  marketing,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Form
      id="tours-signup"
      {...props}
      onSubmit={handleSubmit}
      data-marketing={marketing}
      hidden={isProcessing}
      method="post"
      noValidate
    >
      <Styled.TextFieldsWrapper>
        <TextField
          error={
            touched.name && fieldErrors.name ? t(fieldErrors.name) : undefined
          }
          validated={touched.name && !fieldErrors.name}
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t(
            translationKeys.namePlaceholder || 'SignupForm.NamePlaceholder',
          )}
          required
          data-cy="signupFormName"
          width="100%"
        />
      </Styled.TextFieldsWrapper>
      <Styled.TextFieldsWrapper>
        <TextField
          autoComplete="username"
          data-marketing={marketing}
          defaultValue={username || ''}
          error={
            touched.username && fieldErrors.username
              ? t(fieldErrors.username)
              : undefined
          }
          validated={touched.username && !fieldErrors.username}
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t(
            translationKeys.usernamePlaceholder ||
              'SignupForm.UsernamePlaceholder',
          )}
          required
          readOnly={usernameReadOnly}
          type="email"
          data-cy="signupFormUsername"
          width="100%"
        />
      </Styled.TextFieldsWrapper>
      <Styled.TextFieldsWrapper>
        <TextField
          autoComplete="new-password"
          error={
            touched.password && fieldErrors.password
              ? t(fieldErrors.password)
              : undefined
          }
          validated={touched.password && !fieldErrors.password}
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t(
            translationKeys.passwordPlaceholder ||
              'SignupForm.PasswordPlaceholder',
          )}
          required
          type="password"
          data-cy="signupFormPassword"
          data-marketing={marketing}
          width="100%"
        />
      </Styled.TextFieldsWrapper>
      <Styled.Button
        disabled={isSubmitting || !isValid}
        id="signup"
        type="submit"
        variant="secondary"
        data-cy="signupFormSubmit"
        data-marketing={marketing}
        heightType="big"
      >
        {t(
          marketing
            ? 'SignupForm.Continue'
            : translationKeys.submitButtonText || 'SignupForm.SubmitButtonText',
        )}
      </Styled.Button>
      {status.errors.length > 0 && (
        <div>
          {status.errors.map((error, index) => (
            <Styled.ErrorMessage key={index}>
              {t([
                `Error.${error.code}`,
                `Error.${error.message}`,
                `${error.code}`,
                'Error.Uncaught',
              ])}
            </Styled.ErrorMessage>
          ))}
        </div>
      )}
    </Styled.Form>
  );
};

export default SignupForm;
