import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

const Annotation: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 25 25" {...props}>
    <g
      strokeWidth="1.2"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke={props.color || 'black'}
    >
      <path d="M12.286.857c6.31 0 11.428 5.117 11.428 11.429 0 6.31-5.117 11.428-11.428 11.428-6.312 0-11.429-5.117-11.429-11.428C.857 5.974 5.974.857 12.286.857zM12.286 16.857v-4.571M12.286 9.429V7.714" />
    </g>
  </SvgIcon>
);
Annotation.displayName = 'Annotation';
export default Annotation;
