import theme from '../theme/default';

export enum Size {
  LARGE,
  MEDIUM,
  SMALL,
}

const sizeToBreakPoints = (size: Size) => {
  switch (size) {
    case Size.LARGE:
      return theme.breakPoints.large;
    case Size.MEDIUM:
      return theme.breakPoints.medium;
    case Size.SMALL:
      return theme.breakPoints.small;
  }
};

export const larger = (size: Size): string => {
  return `@media all and (min-width: ${sizeToBreakPoints(size) + 1}px)`;
};

export const smaller = (size: Size): string => {
  return `@media all and (max-width: ${sizeToBreakPoints(size)}px)`;
};

export const range = (min: Size, max: Size): string => {
  return (
    `@media all and ` +
    `(min-width: ${sizeToBreakPoints(min) + 1}px` +
    `(max-width: ${sizeToBreakPoints(max)}px)`
  );
};
