import styled from 'styled-components';
import { Ellipsis as UIEllipsis } from '../../icons';
import UIButton from '../button';
import UIMenu from '../menu';

export const Toggle = styled.div`
  cursor: pointer;
  padding: 0;

  &.mouseDown {
    outline: none;
  }
`;

export const Arrow = styled.div`
  height: 10px;
  position: absolute;
  display: block;
  opacity: 0;
  width: 10px;

  &.isOpen {
    opacity: 1;
  }

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
  }

  &::before {
    top: 0;
    border-bottom-color: #fff;
  }
`;

export const Popper = styled.div<{ aspectRatioPopper?: boolean }>`
  min-width: 240px;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.16));
  z-index: 2;

  &[data-placement^='bottom'] {
    padding-top: 10px;

    > ${Arrow} {
      top: 0;

      &::before,
      &::after {
        border-width: 0 10px 10px;
      }
    }
  }

  opacity: ${(props): string => (props.aspectRatioPopper ? '70%' : 'unset')};

  ul {
    background-color: ${(props): string =>
      props.aspectRatioPopper ? '#2c2828' : '#fff'};
    color: ${(props): string => (props.aspectRatioPopper ? '#fff' : 'unset')};
  }

  ul > li.highlighted {
    background: ${(props): string =>
      props.aspectRatioPopper
        ? 'rgba(255, 255, 255, 0.2)'
        : props.theme.palette.grey.action};
  }

  .selected {
    background: #e2e2e2 !important;
  }
`;

export const Ellipsis = styled(UIEllipsis)`
  path {
    stroke: ${(props): string => props.theme.palette.text.primary};
  }
  :hover path {
    stroke: ${(props): string => props.theme.palette.primary.main};
  }
`;

export const Button = styled(UIButton)`
  align-items: center;
  display: flex;
  min-width: 182px;
  position: relative;
  text-align: left;
  padding-left: 20px;
  padding-right: 13px;

  span {
    flex: 1;
    padding: 0 0 2px 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    margin: 0;
  }
`;

export const Menu = styled(UIMenu)`
  padding: 8px 0;
`;
