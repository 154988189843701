import NextDocument, { DocumentContext } from 'next/document';
import auth from 'basic-auth';
import { IncomingMessage } from 'http';
import compare from 'tsscmp';

// NOTE: Porting from https://github.com/labd/nextjs-basic-auth-middleware

const BASIC_AUTH_URL_RE =
  process.env.BASIC_AUTH_URL && new RegExp(process.env.BASIC_AUTH_URL);

type AuthCredentials = Array<{
  name: string;
  password: string;
}>;

export const parseCredentials = (credentials: string): AuthCredentials =>
  credentials.split(' ').map((item) => {
    if (item.length < 3) {
      throw new Error(
        `Received incorrect basic auth syntax, use <username>:<password>, received ${item}`,
      );
    }
    const parsedCredentials = item.split(':');
    if (
      parsedCredentials[0].length === 0 ||
      parsedCredentials[1].length === 0
    ) {
      throw new Error(
        `Received incorrect basic auth syntax, use <username>:<password>, received ${item}`,
      );
    }

    return {
      name: parsedCredentials[0],
      password: parsedCredentials[1],
    };
  });

const compareCredentials = (
  user: auth.BasicAuthResult,
  authCredentials: AuthCredentials,
): boolean => {
  const credential = authCredentials.find((item) => item.name === user.name);
  if (!credential) {
    return false;
  }

  return (
    compare(user.name, credential.name) &&
    compare(user.pass, credential.password)
  );
};

const getAbsoluteUrl = (req: IncomingMessage) => {
  const host = req.headers['x-forwarded-host'] || req.headers['host'];
  const protocol = host?.indexOf('localhost') !== -1 ? 'http' : 'https';

  return `${protocol}://${host}${req.url}`;
};

export interface WithBasicAuthOptions {
  realm?: string;
}

export default (
  Document: typeof NextDocument,
  withBasicAuthOptions: WithBasicAuthOptions = {},
): typeof NextDocument => {
  const { realm = 'protected' } = withBasicAuthOptions;

  return class BasicAuthDocument extends Document {
    public static async getInitialProps(context: DocumentContext) {
      const initialProps = await Document.getInitialProps(context);

      const { req, res } = context;

      if (req && res && process.env.BASIC_AUTH_CREDENTIALS) {
        const absoluteUrl = getAbsoluteUrl(req);

        if (!BASIC_AUTH_URL_RE || BASIC_AUTH_URL_RE.test(absoluteUrl)) {
          const currentUser = auth(req);
          const authCredentials = parseCredentials(
            process.env.BASIC_AUTH_CREDENTIALS,
          );

          if (
            !currentUser ||
            !compareCredentials(currentUser, authCredentials)
          ) {
            res.statusCode = 401;
            res.setHeader('WWW-Authenticate', `Basic realm="${realm}"`);
            res.end('401 Access Denied');
          }
        }
      }

      return initialProps;
    }
  };
};
