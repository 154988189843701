import * as React from 'react';
import * as Styled from './radio.styled';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  checked: boolean;
  disabled?: boolean;
  label: string;
  name: string;
  value: string;
}

const Radio: React.FC<Props> = ({
  checked,
  disabled = false,
  label,
  name,
  onChange,
  value,
}): JSX.Element => {
  return (
    <Styled.RadioWrapper disabled={disabled}>
      <Styled.Radio
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <Styled.Circle>
        <Styled.Check />
      </Styled.Circle>
      <Styled.Label>{label}</Styled.Label>
    </Styled.RadioWrapper>
  );
};

Radio.displayName = 'Radio';

export default Radio;
