import styled from 'styled-components';
import UIButton from '../button';
import UIErrorMessage from '../error-message';

export const SpinnerContainer = styled.form`
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  width: 100%;
  &[data-marketing='true'] {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 2rem 0 0;
  }
`;

export const TextFieldsWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const Button = styled(UIButton)`
  margin-top: 1rem;
  width: 100%;
  &[data-marketing='true'] {
    width: auto;
    margin: 1rem 0;
  }
`;

export const ErrorMessage = styled(UIErrorMessage)`
  margin: 0.625rem;
  text-align: center;
`;
