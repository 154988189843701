import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Check: React.FC<Props> = ({ color = '#000000', ...props }) => (
  <SvgIcon viewBox="0 0 36 36" color={color} {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={color} strokeWidth="1.5">
        <polyline points="27 11 14.6511628 24.0909091 9 18.2" />
      </g>
    </g>
  </SvgIcon>
);

Check.displayName = 'Check';

export default Check;
