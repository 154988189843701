import introspectionResult from '@r360-tours/graph/introspection-result';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { AuthOptions } from 'aws-appsync-auth-link';
import AWS from 'aws-sdk';
import { CredentialsOptions } from 'aws-sdk/lib/credentials';
import Auth from './auth';

const createAppSyncClient = (
  auth: AuthOptions,
  initialState: NormalizedCacheObject,
): AWSAppSyncClient<NormalizedCacheObject> => {
  if (!process.env.APPSYNC_REGION || !process.env.APPSYNC_URL) {
    throw new Error('No region and/or URL provided for Appsync.');
  }

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: introspectionResult,
  });

  return new AWSAppSyncClient(
    {
      auth,
      disableOffline: true,
      region: process.env.APPSYNC_REGION,
      url: process.env.APPSYNC_URL,
    },
    {
      cache: new InMemoryCache({
        cacheRedirects: {
          Query: {
            getRoom: (_, args, { getCacheKey }) =>
              getCacheKey({ __typename: 'Room', id: args.id }),
            getTour: (_, args, { getCacheKey }) =>
              getCacheKey({ __typename: 'Tour', id: args.id }),
          },
        },
        fragmentMatcher,
      }).restore(initialState),
      connectToDevTools: typeof window !== 'undefined',
      ssrMode: typeof window === 'undefined',
    },
  );
};

export const createApiKeyClient = (
  initialState: NormalizedCacheObject,
): AWSAppSyncClient<NormalizedCacheObject> => {
  return createAppSyncClient(
    {
      apiKey: process.env.APPSYNC_API_KEY || '',
      type: AUTH_TYPE.API_KEY,
    },
    initialState,
  );
};

export const createUserPoolClient = (
  initialState: NormalizedCacheObject,
): AWSAppSyncClient<NormalizedCacheObject> => {
  // NOTE: Auth.currentSession() always fails with 'Error: Local storage is missing an ID Token,
  // Please authenticate' after token refresh. In that case, the last token saved is used.
  let jwtToken = '';

  return createAppSyncClient(
    {
      jwtToken: async () => {
        try {
          const session = await Auth.currentSession();
          jwtToken = session.getIdToken().getJwtToken();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error while running `currentSession`', error);
        }
        return jwtToken;
      },
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    },
    initialState,
  );
};

export const createIAMClient = (
  initialState: NormalizedCacheObject,
  credentials: CredentialsOptions,
): AWSAppSyncClient<NormalizedCacheObject> => {
  AWS.config.update({
    region: process.env.APPSYNC_REGION as string,
    ...credentials,
  });

  return createAppSyncClient(
    {
      credentials: AWS.config.credentials as AWS.Credentials,
      type: AUTH_TYPE.AWS_IAM,
    },
    initialState,
  );
};
