import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const NoFloorPlans: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 100 100" {...props}>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g id="Tours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icon-/-Blueprint"
        transform="translate(15.000000, 15.000000)"
        filter="url(#filter-1)"
      >
        <g>
          <rect
            id="Rectangle"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            x="0.41015625"
            y="0.41015625"
            width="69.1796875"
            height="69.1796875"
            rx="8"
          ></rect>
          <line
            x1="52.8698819"
            y1="47.1079826"
            x2="69.3999839"
            y2="47.1079826"
            id="Stroke-1"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="round"
          ></line>
          <line
            x1="1.29341189"
            y1="47.1079826"
            x2="37.7934119"
            y2="47.1079826"
            id="Stroke-3"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="round"
          ></line>
          <line
            x1="37.8832556"
            y1="42.8780291"
            x2="37.8832556"
            y2="51.3333242"
            id="Stroke-5"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="round"
          ></line>
          <line
            x1="52.1042934"
            y1="42.8780291"
            x2="52.1042934"
            y2="51.3333242"
            id="Stroke-7"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="round"
          ></line>
          <line
            x1="15.59087"
            y1="1.29264323"
            x2="15.59087"
            y2="46.2926432"
            id="Stroke-9"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="round"
          ></line>
        </g>
      </g>
    </g>
  </SvgIcon>
);

NoFloorPlans.displayName = 'NoFloorPlans';

export default NoFloorPlans;
