import { NextPageContext } from 'next';
import { destroyCookie, parseCookies, setCookie } from './nookies';

class SsrAuthStorage {
  private ctx?: NextPageContext;
  private data: { [key: string]: string } = {};
  private readonly options: { [key: string]: string };

  public constructor(ctx?: NextPageContext) {
    this.ctx = ctx;
    const domain = process.env.AUTH_COOKIE_DOMAIN;
    const path = process.env.AUTH_COOKIE_PATH;
    if (!domain || !path) {
      throw new Error('No domain and/or path provided for auth cookies');
    }
    this.options = { domain, path };
  }

  public clear(): void {
    Object.keys(this.data).forEach(this.removeItem);
  }

  public getItem(key: string): string | undefined {
    return this.data[key] || parseCookies(this.ctx)[key];
  }

  public setItem(key: string, value: string): string {
    const maxAge = 365 * 24 * 60 * 60;
    const sameSite = 'lax' as const;
    const secure = process.env.NODE_ENV === 'production';
    const options = { ...this.options, maxAge, sameSite, secure };
    this.data[key] = value;
    setCookie(this.ctx, key, value, options);
    return this.getItem(key) || '';
  }

  public removeItem(key: string): void {
    destroyCookie(this.ctx, key, this.options);
  }
}

export default SsrAuthStorage;
