import styled from 'styled-components';
import UIButton from '../button';
import UIErrorMessage from '../error-message';

export const SpinnerContainer = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`;

export const Form = styled.form`
  width: 100%;
`;

export const TextFieldsWrapper = styled.div`
  width: 100%;
  min-height: 124px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Button = styled(UIButton)`
  width: 100%;
  margin-top: 32px;
`;

export const ErrorMessage = styled(UIErrorMessage)`
  margin: 0.625rem;
  text-align: center;

  > a {
    display: inline-block;
  }
`;
