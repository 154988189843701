import { Address } from '@r360-tours/graph';

export default function getMapQueryString({
  locality,
  postalCode,
  region,
  street,
}: Address): string {
  const address = [street ? street[0] : '', locality, region, postalCode]
    .filter(Boolean)
    .join(' ');

  const query = [
    `key=${process.env.GOOGLE_MAPS_KEY}`,
    `q=${encodeURIComponent(address)}`,
  ];

  return query.join('&');
}
