import * as React from 'react';
import { Button, FormikErrors, FormikTouched, TextField } from '@r360-tours/ui';
import { Trans, useTranslation } from '../../../../core/i18n';
import * as Styled from './reset-password-form.styled';

export interface FormValues {
  code: string;
  confirmPassword: string;
  newPassword: string;
  username: string;
}

interface Props {
  fieldErrors: FormikErrors<FormValues>;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isSubmitting: boolean;
  isValid: boolean;
  status: {
    errors: Array<{
      code?: string;
      message: string;
      name: string;
    }>;
  };
  touched: FormikTouched<FormValues>;
  username: string;
}

const ResetPasswordForm: React.FunctionComponent<Props> = ({
  fieldErrors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
  status,
  touched,
  username,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Form onSubmit={handleSubmit} method="post" noValidate>
      <Styled.Message>
        <Trans i18nKey="ResetPasswordForm.Message" />
      </Styled.Message>
      <Styled.TextFieldsWrapper>
        <TextField
          autoComplete="one-time-code"
          error={
            touched.code && fieldErrors.code ? t(fieldErrors.code) : undefined
          }
          validated={touched.code && !fieldErrors.code}
          name="code"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t('ResetPasswordForm.VerificationCodePlaceholder')}
          required
          type="text"
          data-cy="resetPasswordFormCode"
        />
      </Styled.TextFieldsWrapper>
      <Styled.HiddenInput
        autoComplete="username"
        name="username"
        readOnly
        type="text"
        value={username}
      />
      <Styled.TextFieldsWrapper>
        <TextField
          autoComplete="new-password"
          error={
            touched.newPassword && fieldErrors.newPassword
              ? t(fieldErrors.newPassword)
              : undefined
          }
          validated={touched.newPassword && !fieldErrors.newPassword}
          name="newPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t('ResetPasswordForm.PasswordPlaceholder')}
          required
          type="password"
          data-cy="resetPasswordFormNewPassword"
        />
      </Styled.TextFieldsWrapper>
      <Styled.TextFieldsWrapper>
        <TextField
          autoComplete="new-password"
          error={
            touched.confirmPassword && fieldErrors.confirmPassword
              ? t(fieldErrors.confirmPassword)
              : undefined
          }
          validated={touched.confirmPassword && !fieldErrors.confirmPassword}
          name="confirmPassword"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t('ResetPasswordForm.ConfirmPasswordPlaceholder')}
          required
          type="password"
          data-cy="resetPasswordFormConfirmPassword"
        />
      </Styled.TextFieldsWrapper>
      <Button
        disabled={isSubmitting || !isValid}
        type="submit"
        variant="secondary"
        data-cy="resetPasswordFormSubmit"
        heightType="big"
      >
        {t('ResetPasswordForm.Submit')}
      </Button>
      {status.errors.map((error, index) => (
        <Styled.ErrorMessage key={index}>
          {t([
            `Error.${error.code}`,
            `Error.${error.message}`,
            'Error.Uncaught',
          ])}
        </Styled.ErrorMessage>
      ))}
    </Styled.Form>
  );
};

ResetPasswordForm.displayName = 'ResetPasswordForm';

export default ResetPasswordForm;
