import styled from 'styled-components';

export const MenuItem = styled.li`
  cursor: pointer;
  padding: 1rem;
  font-size: 1rem;

  &:last-child {
    border-bottom: none;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.alert {
    color: ${(props): string => props.theme.palette.danger.main};
  }

  &.selected {
    background: ${(props): string => props.theme.palette.grey.action};
  }
`;
