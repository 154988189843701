import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Remove: React.FC<Props> = (props) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="8" />
      <path
        d="M8 7.371l3.241-3.24a.444.444 0 11.629.628L8.629 8l3.24 3.241a.444.444 0 01-.628.629L8 8.629l-3.241 3.24a.444.444 0 01-.629-.628L7.371 8l-3.24-3.241a.444.444 0 11.628-.629L8 7.371z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
);

Remove.displayName = 'Remove';

export default Remove;
