import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const External: React.FC<Props> = ({ color = '#000000', ...props }) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-External-link" stroke={color} strokeWidth="1.5">
        <g id="Group" transform="translate(7.000000, 7.000000)">
          <path
            d="M18.3333333,12.2222222 L18.3333333,19.5555556 C18.3333333,20.9055849 17.2389183,22 15.8888889,22 L2.44444444,22 C1.09441506,22 0,20.9055849 0,19.5555556 L0,6.11111111 C0,4.76108172 1.09441506,3.66666667 2.44444444,3.66666667 L9.77777778,3.66666667"
            id="Path"
          ></path>
          <polyline
            id="Path"
            points="14.6666667 0 22 0 22 7.33333333"
          ></polyline>
          <line
            x1="8.55555556"
            y1="13.4444444"
            x2="21.3888889"
            y2="0.611111111"
            id="Path"
          ></line>
        </g>
      </g>
    </g>
  </SvgIcon>
);

External.displayName = 'External';

export default External;
