import styled from 'styled-components';

interface MediaProps {
  aspectRatio: number;
  image: string | undefined;
}

export const Media = styled.div.attrs<MediaProps>((props) => ({
  style: {
    backgroundImage: props.image ? `url(${props.image})` : undefined,
  },
}))<MediaProps>`
  background-color: #e6e6e6;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: ${(props): number => 100 / props.aspectRatio}%;
`;
