import { getParamByISO } from 'iso-country-currency';

const defaultCurrency = 'usd';
const Currencies = ['usd', 'eur', 'jpy'] as const;
export type Currency = typeof Currencies[number];

export const getCurrency = (country?: string): Currency => {
  if (!country) {
    return defaultCurrency;
  }
  if (country === 'GB') {
    return 'eur';
  }
  try {
    const currency = getParamByISO(country, 'currency').toLowerCase();
    if (Currencies.includes(currency as Currency)) {
      return currency as Currency;
    }
    return defaultCurrency;
  } catch (error) {
    // Ignore error and return default currency
    return defaultCurrency;
  }
};
