import styled from 'styled-components';

export const IconButton = styled.button`
  background: ${(props): string => props.theme.iconButton.background};
  border-radius: ${(props): string | number =>
    props.theme.iconButton.borderRadius};
  border: ${(props): string =>
    `${props.theme.iconButton.borderWidth} ${props.theme.iconButton.borderStyle} ${props.theme.iconButton.borderColor}`};
  cursor: pointer;
  display: inline-flex;
  font-size: ${(props): string | number => props.theme.iconButton.fontSize};
  margin: ${(props): string | number => props.theme.iconButton.margin};
  padding: ${(props): string | number => props.theme.iconButton.padding};

  :focus {
    outline: none;
  }

  &[disabled] {
    color: ${(props): string => props.theme.palette.text.disabled};
    cursor: not-allowed;
  }
`;
