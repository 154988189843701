import * as React from 'react';
import * as Styled from './card.styled';

export const Card: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  ...props
}) => <Styled.Card {...props} />;

Card.displayName = 'Card';

export default Card;
