import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Reward: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 100 100" {...props}>
    <g stroke="none" strokeWidth="3" fill="none" fillRule="evenodd">
      <g transform="translate(12.000000, 10.000000)">
        <path
          d="M67.1573095,78.9473684 L7.76374316,78.9473684 C6.58678851,78.9473684 5.63157895,78.019557 5.63157895,76.8763608 L5.63157895,40 L69.2894737,40 L69.2894737,76.8763608 C69.2894737,78.019557 68.3342641,78.9473684 67.1573095,78.9473684 Z"
          stroke="#231916"
        />
        <path
          d="M23.2769293,20.8878049 C6.98698359,11.8482146 15.3104065,4.28811852 15.3104065,4.28811852 C25.3227323,-4.40440541 37.4594641,20.3721635 37.4594641,20.3721635 C37.4594641,20.3721635 49.5983203,-4.40440541 59.6106461,4.28811852 C59.6106461,4.28811852 67.934069,11.8482146 51.6441234,20.8878049 L23.2769293,20.8878049 Z"
          stroke="#231916"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.5315076,40 L3.4684924,40 C2.28020038,40 1.31578947,39.0688437 1.31578947,37.9215261 L1.31578947,23.1311055 C1.31578947,21.9837879 2.28020038,21.0526316 3.4684924,21.0526316 L72.5315076,21.0526316 C73.7197996,21.0526316 74.6842105,21.9837879 74.6842105,23.1311055 L74.6842105,37.9215261 C74.6842105,39.0688437 73.7197996,40 72.5315076,40 Z"
          stroke="#231916"
        />
        <mask id="reward-mask" fill="white">
          <polygon points="0 80 76 80 76 0 0 0" />
        </mask>
        <polygon
          stroke="#231916"
          mask="url(#reward-mask)"
          points="31.5263158 78.9473684 44.4736842 78.9473684 44.4736842 40 31.5263158 40"
        />
        <polygon
          stroke="#231916"
          mask="url(#reward-mask)"
          points="29.3684211 40 45.5526316 40 45.5526316 21.0526316 29.3684211 21.0526316"
        />
      </g>
    </g>
  </SvgIcon>
);

Reward.displayName = 'Reward';

export default Reward;
