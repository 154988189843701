import styled from 'styled-components';

export const Header = styled.header`
  background-color: ${(props): string => props.theme.background.primary.main};
  border-bottom-color: ${(props): string => props.theme.separator.color};
  border-bottom-style: ${(props): string => props.theme.separator.style};
  border-bottom-width: ${(props): string | number =>
    props.theme.separator.width};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8125rem 40px;
  font-weight: normal;
  line-height: 26px;

  &.primary {
    background-color: ${(props): string => props.theme.palette.primary.main};
    color: ${(props): string => props.theme.palette.primary.contrast};
  }

  &.secondary {
    background-color: ${(props): string =>
      props.theme.background.secondary.main};
    color: #807b84;
    border-bottom-color: ${(props): string =>
      props.theme.background.secondary.contrast};
  }

  &.noBorder {
    border-bottom: none;
  }

  a,
  a:hover,
  a:visited {
    color: inherit;
  }
`;
