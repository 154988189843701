import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

const Movie: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 21 15" {...props}>
    <g
      transform="translate(.75 1)"
      strokeWidth="1.5"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke={props.color || 'black'}
    >
      <path d="m13 4.446 4.553-2.338a.977.977 0 0 1 .973.045c.294.187.474.518.474.874v6.946c0 .356-.18.687-.474.874a.977.977 0 0 1-.973.045L13 8.554V4.446z" />
      <rect width="13" height="13" rx="1.833" />
    </g>
  </SvgIcon>
);
Movie.displayName = 'Movie';
export default Movie;
