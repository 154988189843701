import styled from 'styled-components';
import Wordmark from '../wordmark';

export const CustomWordMark = styled(Wordmark)`
  margin-top: 8%;
  && {
    height: 14%;
    width: 40%;
  }
`;

export const DefaultTripod = styled.div`
  align-content: center;
  align-items: center;
  background-color: ${(props): string =>
    props.theme.avatar.tripodCover.background};
  border: 0.02px solid
    ${(props): string => props.theme.avatar.tripodCover.borderColor};
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  width: inherit;
`;
