import * as CSS from 'csstype';
import styled from 'styled-components';
import { Check, Eye, EyeSlash, Times } from '../../icons';
import theme from '../theme/default';
import { Variant } from './text-field';

interface VariantStyle {
  background: CSS.BackgroundColorProperty;
  errorColor: {
    label: CSS.ColorProperty;
    message: CSS.ColorProperty;
  };
  labelColor: CSS.ColorProperty;
  valueColor: CSS.ColorProperty;
}

interface VariantStyles {
  normal: VariantStyle;
  viewer: VariantStyle;
}

const variantStyles: VariantStyles = {
  normal: {
    background: 'inherit',
    errorColor: {
      label: theme.palette.danger.main,
      message: theme.palette.danger.main,
    },
    labelColor: theme.palette.grey.dark,
    valueColor: theme.palette.text.primary,
  },
  viewer: {
    background: 'transparent',
    errorColor: {
      label: theme.palette.primary.main,
      message: theme.palette.primary.main,
    },
    labelColor: theme.viewerOverlay.color,
    valueColor: theme.viewerOverlay.color,
  },
};

export const Wrapper = styled.div<{ width: string }>`
  display: block;
  width: ${(props): string | number | undefined => props.width};
`;

export const InputWrapper = styled.label<{ multiline: boolean }>`
  position: relative;
  margin: auto;
  display: block;
  ${(props): string | false =>
    props.multiline && 'padding-top: calc(1rem + 5px);'}
`;

export const Label = styled.span<{
  error?: string;
  disabled?: boolean;
  requiredMark?: boolean;
  variant: Variant;
}>`
  position: absolute;
  top: calc(100% - calc(1rem + 18px));
  left: 0;
  will-change: transform, top;
  transform-origin: 0 0;
  transition: all 0.2s ease;
  pointer-events: none;
  opacity: ${(props): number => (props.disabled ? 0.3 : 1)};
  color: ${(props): string =>
    props.error
      ? props.theme.palette.primary.main
      : variantStyles[props.variant].labelColor};
  white-space: nowrap;
  ${(props): string | undefined | false =>
    props.requiredMark && '::after { content: "*"; margin-left: 3px; }'}
`;

export const Validated = styled(Check)``;

export const Error = styled(Times)<{ variant: Variant }>`
  color: ${(props): string => variantStyles[props.variant].errorColor.label};
`;

export const Input = styled.input<{ error?: string; variant: Variant }>`
  background: ${(props) => variantStyles[props.variant].background};
  border: none;
  border-bottom-color: ${(props): string =>
    props.error
      ? props.theme.palette.primary.main
      : props.theme.input.borderColor};
  border-bottom-style: ${(props): string => props.theme.input.borderStyle};
  border-bottom-width: ${(props): string | number =>
    props.theme.input.borderWidth};
  color: ${(props): string => variantStyles[props.variant].valueColor};
  font-size: ${(props): string | number => props.theme.input.fontSize};
  width: 100%;
  height: 34px;
  margin-top: 20px;
  padding-right: 20px;
  :disabled {
    opacity: 0.3;
  }
  :-webkit-autofill + span {
    color: ${(props): string =>
      props.error
        ? props.theme.palette.danger.main
        : variantStyles[props.variant].labelColor};
    transform: translate(1px) scale(0.75);
    top: 0;
  }
  :focus {
    outline: none;
    + span {
      color: ${(props): string =>
        props.error
          ? variantStyles[props.variant].errorColor.label
          : variantStyles[props.variant].labelColor};
      transform: translate(1px) scale(0.75);
      top: 0;
      + span {
        transform: scaleX(1);
      }
    }
  }
  :not(:focus):not(:placeholder-shown) + span {
    color: ${(props): string =>
      props.error
        ? variantStyles[props.variant].errorColor.label
        : variantStyles[props.variant].labelColor};
    transform: translate(1px) scale(0.75);
    top: 0;
  }
`;

export const Textarea = styled(Input)`
  font-family: ${(props): string => props.theme.typography.fontFamily};
  font-size: ${(props): string | number => props.theme.typography.fontSize};
  display: block;
  resize: none;
  margin-top: 0;
  height: auto;
  line-height: 22px;
`;

export const Border = styled.span<{ error?: string; variant: Variant }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: ${(props): string =>
    props.error
      ? props.theme.palette.primary.main
      : variantStyles[props.variant].labelColor};
  will-change: transform;
  transform: scaleX(0);
  transform-origin: 50% 50%;
  transition: all 0.15s ease;
`;

export const ErrorMessage = styled.div<{ variant: Variant }>`
  color: ${(props): string => variantStyles[props.variant].errorColor.message};
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 0.5rem;
`;

export const Icons = styled.div`
  position: absolute;
  top: calc(50% + 12px);
  transform: translateY(-50%);
  right: 5px;
  display: flex;
`;

export const PasswordVisibleIcon = styled.div`
  cursor: pointer;
  margin: 0 8px;
  svg {
    width: 19px;
    height: 19px;
  }
`;

export const EyeIcon = styled(Eye)``;

export const EyeSlashIcon = styled(EyeSlash)``;
