import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const ArrowLeft: React.FC<Props> = ({ color = '#000000', ...props }) => (
  <SvgIcon viewBox="0 0 36 36" color={color} {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={color} strokeWidth="1.5">
        <polyline
          transform="translate(18.500000, 18.000000) scale(-1, 1) translate(-18.500000, -18.000000) "
          points="15 25 22 17.9984812 15 11"
        />
      </g>
    </g>
  </SvgIcon>
);

ArrowLeft.displayName = 'ArrowLeft';

export default ArrowLeft;
