import styled from 'styled-components';

export const Tabs = styled.nav`
  border-bottom-color: ${(props): string => props.theme.separator.color};
  border-bottom-style: ${(props): string => props.theme.separator.style};
  border-bottom-width: ${(props): string | number =>
    props.theme.separator.width};
  width: 100%;
`;

export const Nav = styled.div`
  display: flex;
  margin: 0 20px;

  > div {
    display: flex;
    align-items: center;
  }
`;
