import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Twitter: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 23 19" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-979.000000, -438.000000)">
        <g transform="translate(793.000000, 247.000000)">
          <g transform="translate(107.000000, 178.000000)">
            <g transform="translate(68.000000, 0.000000)">
              <g transform="translate(9.000000, 9.000000)">
                <g>
                  <path
                    d="M24.375,6.27409373 C23.5579848,6.65737368 22.6839734,6.90809713 21.7864673,7.01665371 C22.7420419,6.41111723 23.4466354,5.48674483 23.7724424,4.41120741 C22.8906721,4.9520808 21.9219163,5.33975932 20.9080653,5.55747924 C20.0831952,4.61358286 18.8862856,4.06852364 17.6252017,4.0625 C15.0658894,4.13651903 13.0512298,6.24846675 13.1241125,8.78094716 C13.1237754,9.14024264 13.1619214,9.49856178 13.2379115,9.84990142 C9.56633965,9.63378285 6.17539532,7.83481947 3.96146192,4.92856052 C3.55998843,5.65564051 3.35107258,6.4714569 3.3541846,7.29997848 C3.33766152,8.8512764 4.08496192,10.3135284 5.35746558,11.2198234 C4.6393557,11.1952905 3.93883033,10.9935481 3.31957293,10.6329364 L3.31957293,10.6972812 C3.27873768,12.8855739 4.77921805,14.8083937 6.92914963,15.3228435 C6.54214593,15.4293709 6.14197721,15.4817703 5.74029152,15.4785164 C5.45739504,15.4832352 5.17491162,15.4551965 4.89859886,15.394972 C5.42643368,17.2781013 7.12850642,18.6042762 9.10234241,18.6703309 C7.53689381,19.9732201 5.55708747,20.6878416 3.51151033,20.6883712 C3.15244349,20.689403 2.79368704,20.6673896 2.4375,20.6224697 C4.46663177,22.006246 6.87239484,22.7481133 9.33675775,22.75 C17.6147133,22.75 22.1441211,15.5589475 22.1441211,9.3263214 C22.1441211,9.12706002 22.1388769,8.92468518 22.128913,8.72231035 C23.01552,8.0407862 23.7755465,7.21183672 24.375,6.272537"
                    fill={props.color}
                    fillRule="nonzero"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Twitter.displayName = 'Twitter';

export default Twitter;
