import styled from 'styled-components';

export const Background = styled.span`
  background-color: ${(props): string => props.theme.palette.grey.light};
  width: 100%;
`;

export const Bar = styled.span`
  background-color: ${(props): string => props.theme.palette.primary.main};
  left: 0;
  position: absolute;
  top: 0;
`;

export const ProgressBar = styled.div`
  height: 8px;
  position: relative;

  ${Background}, ${Bar} {
    display: block;
    height: 100%;
    transition-duration: 500ms;
    transition-property: width;
  }
`;
