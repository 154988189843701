/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { usePrevious } from 'react-use';
import * as Styled from './panorama.styled';

const TYPE_CUBEMAP = 'cubemap';
const TYPE_EQUIRECTANGULAR = 'equirectangular';

export type PanoramaOnClick = (
  event: React.MouseEvent<HTMLDivElement>,
  position: { pitch: number; yaw: number },
) => any;

export type PanoramaOnFullRotation = () => any;

export interface PanoramaProps {
  /**
   * Whether or not auto rotate should be enabled on the panorama.
   *
   * @default false
   */
  autoRotate?: boolean;

  /**
   * If auto rotate is enabled, how much idle time (in milliseconds) should
   * trigger the rotation to start again.
   *
   * @default 30000
   */
  autoRotateIdleTime?: number;

  /**
   * An optional CSS class name.
   */
  className?: string;

  /**
   * The height of the canvas.
   *
   * @default auto
   */
  height?: number | string;

  /**
   * Optional handler for when a user clicks on the canvas.
   */
  onClick?: PanoramaOnClick;

  /**
   * Optional handler for when the 360 image makes a full rotation
   */
  onFullRotation?: PanoramaOnFullRotation;

  /**
   * Whether or not the user has been idle for a specified time.
   *
   * @default false
   */
  idle?: boolean;

  /**
   * Whether or not the user can control the fov/zoom by using the mouse wheel.
   *
   * @default false
   */
  scrollZoom?: boolean;

  /**
   * The optional S3 file key
   */
  key?: string;

  /**
   * The source URL of the panorama. If this is a cubemap texture, be sure to
   * use `{f}` to denote the side.
   *
   * For example: http://example.com/file/{f}/image.jpg
   */
  src: string;

  /**
   * An optional style object.
   */
  style?: React.CSSProperties;

  /**
   * Denotes whether this panorama is Equirectangular or Cubemap.
   */
  type?: 'cubemap' | 'equirectangular';

  /**
   * The width of the canvas.
   *
   * @default auto
   */
  width?: number | string;

  /**
   * Get a reference object to the Marzipano viewer
   */
  viewerRef?: React.MutableRefObject<any>;

  /**
   * The initial starting view of the image
   *
   * @default pitch: 0, roll: 0, yaw: 0
   */
  initialViewParams?: ViewerInitialPosition;
}

interface ViewerInitialPosition {
  /**
   * The id of the room that is being viewed.
   */
  key?: string;

  /**
   * The initial yaw of the 360 view.
   *
   * @default 0
   */
  yaw: number;

  /**
   * The initial pitch of the 360 view.
   *
   * @default 0
   */
  pitch: number;

  /**
   * The initial roll of the 360 view.
   *
   * @default 0
   */
  roll: number;
}

const initialViewParams = {
  fov: (100 * Math.PI) / 180,
  pitch: 0,
  roll: 0,
  yaw: 0,
};

let initialAutorotate: any;

let continueAutorotate: any;

let Marzipano: any;

/**
 * This **Panorama** component should render any given `equirectangular` or
 * `cubemap` image URL in a 360 degree canvas.
 */
const Panorama: React.FC<PanoramaProps> = (props) => {
  const [initialViewerPosition, setInitialViewerPosition] = React.useState({
    key: props.key,
    ...(props.initialViewParams ?? initialViewParams),
  } as ViewerInitialPosition);

  const canvas = React.useRef<HTMLDivElement>(null);
  const didCanvasDrag = React.useRef(false);
  const parameters = React.useRef('{}');
  const scenes = React.useRef<{ [src: string]: any }>({});
  const view = React.useRef<any>();
  const viewer = React.useRef<any>();

  const prevProps = usePrevious(props);

  React.useEffect(() => {
    // Grab the global instance of Marzipano.
    Marzipano = (window as any).Marzipano;

    // Error if Marzipano is not available globally.
    if (!Marzipano) {
      throw new Error('The Panorama component requires Marzipano.');
    }

    const autorotateOpts = {
      fovAccel: 0.01,
      fovSpeed: 0.1,
      pitchAccel: 0.01,
      pitchSpeed: 0.1,
      targetFov: (100 * Math.PI) / 180,
      targetPitch: 0,
      yawAccel: 0.01,
      yawSpeed: 0.1,
    };

    // Create the autorotate object.
    initialAutorotate = Marzipano.autorotate(autorotateOpts);

    continueAutorotate = Marzipano.autorotate({
      ...autorotateOpts,
      fovAccel: 0.1,
      pitchAccel: 0.1,
      yawAccel: 0.1,
    });

    // Create the viewer objects.
    view.current = createView();
    viewer.current = createViewer();

    if (props.viewerRef) {
      props.viewerRef.current = viewer.current;
    }
    // Add listener for when the view is changed
    viewer.current.addEventListener('viewChange', () => {
      const parameters = view.current.parameters();
      const isRotating =
        props.autoRotate && scenes.current[props.src].movement() !== null;

      if (
        initialViewerPosition.yaw - parameters.yaw < 0.02 &&
        initialViewerPosition.yaw - parameters.yaw > 0.01 &&
        isRotating
      ) {
        if (typeof props.onFullRotation === 'function') {
          props.onFullRotation();
        }
      }
    });

    // Add listeners that will manage whether or not the click event
    // should be activated. The "active" event is triggered when the
    // user starts interacting with the canvas, while the "inactive"
    // event is triggered when they stop.
    viewer.current.controls().addEventListener('active', () => {
      parameters.current = JSON.stringify(view.current.parameters());
    });
    viewer.current.controls().addEventListener('inactive', () => {
      const params = JSON.stringify(view.current.parameters());
      didCanvasDrag.current = params !== params;
      setInitialViewerPosition({
        key: props.key,
        ...view.current.parameters(),
      });
      parameters.current = params;
    });

    // Switch to the current screen.
    switchToCurrentScene();

    // Start rotation immediately on mount if enabled.
    if (props.autoRotate) {
      viewer.current.startMovement(
        props.idle ? continueAutorotate : initialAutorotate,
      );
      viewer.current.setIdleMovement(
        props.autoRotateIdleTime,
        initialAutorotate,
      );
    }

    return () => {
      if (props.viewerRef) {
        props.viewerRef.current = null;
      }

      if (!viewer.current) {
        return;
      }

      viewer.current.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (prevProps) {
      const isRotating =
        props.autoRotate && scenes.current[prevProps.src].movement() !== null;

      if (isRotating) {
        viewer.current.stopMovement();
      }

      switchToCurrentScene();

      if (isRotating) {
        const movement = !props.idle ? initialAutorotate : continueAutorotate;
        viewer.current.startMovement(movement);
      }

      setInitialViewerPosition({
        key: props.key,
        ...view.current.parameters(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);

  const createScene = (): any => {
    if (scenes.current[props.src]) {
      return scenes.current[props.src];
    }

    let geometry;

    if (props.type === TYPE_EQUIRECTANGULAR) {
      geometry = new Marzipano.EquirectGeometry([{ width: 4096 }]);
    }

    if (props.type === TYPE_CUBEMAP) {
      geometry = new Marzipano.CubeGeometry([{ size: 1536, tileSize: 1536 }]);
    }

    const scene = viewer.current.createScene({
      geometry,
      pinFirstLevel: true,
      source: Marzipano.ImageUrlSource.fromString(props.src),
      view: view.current,
    });

    scenes.current[props.src] = scene;

    return scene;
  };

  const createView = (): any => {
    const limiter = Marzipano.RectilinearView.limit.traditional(
      4096,
      (100 * Math.PI) / 180,
      (120 * Math.PI) / 180,
    );

    return new Marzipano.RectilinearView(initialViewerPosition, limiter);
  };

  const createViewer = (): any => {
    return new Marzipano.Viewer(canvas.current, {
      controls: {
        scrollZoom: props.scrollZoom,
      },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (
      !canvas.current ||
      didCanvasDrag.current ||
      typeof props.onClick !== 'function' ||
      event.isDefaultPrevented()
    ) {
      return;
    }

    const rect = canvas.current.getBoundingClientRect();

    const position = {
      ...view.current.screenToCoordinates({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      }),
      fov: view.current.fov(),
    };

    props.onClick(event, position);
  };

  const switchToCurrentScene = (): void => {
    viewer.current.switchScene(createScene());
  };

  return (
    <Styled.Scene
      className={props.className}
      onClick={handleClick}
      ref={canvas}
      style={{
        ...props.style,
        height: props.height,
        width: props.width,
      }}
    />
  );
};

Panorama.displayName = 'Panorama';

export default Panorama;
