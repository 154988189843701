import * as React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { debounce } from '@r360-tours/core/lodash-utils';
import classNames from 'classnames';
import Downshift from 'downshift';
import { Placement } from 'popper.js';
import TextField, { Props as TextFieldProps } from '../text-field/text-field';
import * as Styled from './autocomplete.styled';

export interface Props extends TextFieldProps {
  defaultValue?: string;
  onValueSelected: (value: string) => void;
  placement?: Placement;
  suggestions: string[];
}

export const Autocomplete: React.FunctionComponent<Props> = ({
  defaultValue = '',
  suggestions,
  onChange,
  onValueSelected,
  placement = 'bottom',
  ...inputProps
}) => {
  const [items, setItems] = React.useState<string[]>([]);
  const [value, setValue] = React.useState<string>(defaultValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStateChange = (changes: any): void => {
    if (Object.prototype.hasOwnProperty.call(changes, 'selectedItem')) {
      setValue(changes.selectedItem);
      onValueSelected(changes.selectedItem);
    } else if (Object.prototype.hasOwnProperty.call(changes, 'inputValue')) {
      setValue(changes.inputValue);
    }
  };

  // useCallback is required to avoid regenerating the debounce function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSuggestions = React.useCallback(
    debounce(
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputValue = event.target.value;
        if (inputValue) {
          const regex = new RegExp(
            `^${
              event.target.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') || []
            }`,
            'i',
          );
          const newItems = suggestions.filter((suggestion) =>
            regex.test(suggestion),
          );
          setItems(newItems);
        } else {
          setItems([]);
        }
      },
      350,
    ),
    [items],
  );

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (onChange) {
      onChange(event);
    }
    updateSuggestions(event);
  };

  const inputRef = React.useRef<HTMLElement | null>(null);

  return (
    <Manager>
      <Downshift onStateChange={handleStateChange} selectedItem={value}>
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getRootProps,
          isOpen,
          highlightedIndex,
        }): JSX.Element => (
          <Styled.Autocomplete {...getRootProps()}>
            <Reference
              innerRef={(ref): void => {
                inputRef.current = ref;
              }}
            >
              {({ ref }): JSX.Element => (
                <TextField
                  {...getInputProps({
                    ...inputProps,
                    onChange: (e) => {
                      e.persist();
                      onInputChange(e);
                    },
                  })}
                  ref={ref}
                  autoComplete="off"
                />
              )}
            </Reference>
            {isOpen && items.length > 0 && (
              <Popper
                modifiers={{
                  preventOverflow: { enabled: false },
                }}
                placement={placement}
                positionFixed
              >
                {({ placement, ref, style }): JSX.Element => (
                  <div
                    data-placement={placement}
                    ref={ref}
                    style={{
                      ...style,
                      width: inputRef.current
                        ? inputRef.current.clientWidth
                        : 'auto',
                      zIndex: 2,
                    }}
                  >
                    <Styled.Dropdown {...getMenuProps()}>
                      {items.map((item, index) => (
                        <Styled.DropdownItem
                          {...getItemProps({
                            className: classNames({
                              highlighted: highlightedIndex === index,
                            }),
                            item,
                          })}
                          key={item}
                        >
                          {item}
                        </Styled.DropdownItem>
                      ))}
                    </Styled.Dropdown>
                  </div>
                )}
              </Popper>
            )}
          </Styled.Autocomplete>
        )}
      </Downshift>
    </Manager>
  );
};

Autocomplete.displayName = 'Autocomplete';

export default Autocomplete;
