export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `AWSTimestamp` scalar type provided by AWS AppSync, represents the number of
   * seconds that have elapsed since `1970-01-01T00:00Z`. Negative values are also
   * accepted and these represent the number of seconds till `1970-01-01T00:00Z`. 
   * Timestamps are serialized and deserialized as integers. The minimum supported
   * timestamp value is **`-31557014167219200`** which corresponds to
   * `-1000000000-01-01T00:00Z`. The maximum supported timestamp value is
   * **`31556889864403199`** which corresponds to
   * `1000000000-12-31T23:59:59.999999999Z`.
   */
  AWSTimestamp: any;
  /** Long type */
  Long: any;
};










export enum AccountState {
  FreeTrial = 'FREE_TRIAL',
  ActiveSubscriber = 'ACTIVE_SUBSCRIBER',
  ExpiringSubscriber = 'EXPIRING_SUBSCRIBER',
  Expired = 'EXPIRED'
}

export type Address = {
   __typename?: 'Address';
  country?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddressInput = {
  country?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Aggregate = {
   __typename?: 'Aggregate';
  key: Scalars['String'];
  count: Scalars['Int'];
};

export type Analytics = {
   __typename?: 'Analytics';
  histogram: Array<Aggregate>;
  referrers: Array<Aggregate>;
  visits: VisitCount;
};

export type Annotation = {
   __typename?: 'Annotation';
  id: Scalars['ID'];
  position: Position;
  text: Scalars['String'];
};

export type Artifact = {
   __typename?: 'Artifact';
  id: Scalars['ID'];
  image?: Maybe<Image>;
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type ArtifactInput = {
  file?: Maybe<S3ObjectInput>;
  image?: Maybe<ImageInput>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ArtifactsZipJob = Job & {
   __typename?: 'ArtifactsZipJob';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ArtifactsZipJobEvent = Event & {
   __typename?: 'ArtifactsZipJobEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export enum AuthorizationStatus {
  Authorized = 'AUTHORIZED',
  Unauthorized = 'UNAUTHORIZED'
}

export type AutoCropJob = Job & {
   __typename?: 'AutoCropJob';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  tourId: Scalars['ID'];
  roomAutoCropJobs: RoomAutoCropJobsPage;
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};


export type AutoCropJobRoomAutoCropJobsArgs = {
  page?: Maybe<PageInput>;
};

export type AutoCropJobEvent = Event & {
   __typename?: 'AutoCropJobEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  jobId: Scalars['ID'];
};


export type BlurJob = Job & {
   __typename?: 'BlurJob';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  roomId: Scalars['ID'];
  mask: S3Object;
  radius: Scalars['Float'];
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  usesEnhancedImage?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type BlurJobEvent = Event & {
   __typename?: 'BlurJobEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  roomId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export type BrandLogo = {
   __typename?: 'BrandLogo';
  picture: S3Object;
  url?: Maybe<Scalars['String']>;
};

export type BrandLogoInput = {
  picture?: Maybe<S3ObjectInput>;
  url?: Maybe<Scalars['String']>;
};

export type BusinessInfo = {
   __typename?: 'BusinessInfo';
  industry1?: Maybe<Scalars['String']>;
  industry2?: Maybe<Scalars['String']>;
  industryOther?: Maybe<Scalars['String']>;
  hasTheta?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type BusinessInfoInput = {
  industry1?: Maybe<Scalars['String']>;
  industry2?: Maybe<Scalars['String']>;
  industryOther?: Maybe<Scalars['String']>;
  hasTheta?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CreateArtifactsZipJobInput = {
  id?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  contents: Array<ZipContentsInput>;
};

export type CreateAutoCropJobInput = {
  id?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  roomIds: Array<Scalars['ID']>;
};

export type CreateBlurJobInput = {
  teamId: Scalars['ID'];
  roomId: Scalars['ID'];
  mask: S3ObjectInput;
  radius: Scalars['Float'];
};

export type CreateManualCropJobInput = {
  id?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  roomId: Scalars['ID'];
  position: EquirectPositionInput;
  cropSize?: Maybe<SizeInput>;
};

export type CreateStagingJobInput = {
  id?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  items: Array<CreateStagingJobItemInput>;
};

export type CreateStagingJobItemInput = {
  roomId: Scalars['ID'];
  roomType: Scalars['String'];
};

export type CreateVideoGenerationJobInput = {
  id?: Maybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  roomIds: Array<Scalars['ID']>;
  quality?: Maybe<VideoGenerationQuality>;
};

export type Details = {
   __typename?: 'Details';
  area?: Maybe<Scalars['String']>;
  baths?: Maybe<Scalars['Float']>;
  beds?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type DetailsInput = {
  area?: Maybe<Scalars['String']>;
  baths?: Maybe<Scalars['Float']>;
  beds?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export enum EnhancementStatus {
  Unexecuted = 'UNEXECUTED',
  InProcessing = 'IN_PROCESSING',
  Completed = 'COMPLETED',
  Error = 'ERROR'
}

export type EquirectPosition = {
   __typename?: 'EquirectPosition';
  fov: Scalars['Float'];
  pitch: Scalars['Float'];
  yaw: Scalars['Float'];
};

export type EquirectPositionInput = {
  fov: Scalars['Float'];
  pitch: Scalars['Float'];
  yaw: Scalars['Float'];
};

export type EquirectView = {
   __typename?: 'EquirectView';
  fov: Scalars['Float'];
  pitch: Scalars['Float'];
  yaw: Scalars['Float'];
};

export type EquirectViewInput = {
  fov: Scalars['Float'];
  pitch: Scalars['Float'];
  yaw: Scalars['Float'];
};

export type EsCursor = {
   __typename?: 'EsCursor';
  time: Scalars['Long'];
  id: Scalars['ID'];
};

export type EsCursorInput = {
  time: Scalars['Long'];
  id: Scalars['ID'];
};

export type Event = {
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum EventFilterField {
  TeamId = 'TEAM_ID',
  UserId = 'USER_ID',
  TourId = 'TOUR_ID',
  RoomId = 'ROOM_ID'
}

export type EventFilterInput = {
  field: EventFilterField;
  value: Scalars['String'];
};

export type Floor = {
   __typename?: 'Floor';
  image: Image;
  id: Scalars['ID'];
  markers: Array<FloorMarker>;
  name: Scalars['String'];
};

export type FloorEvent = Event & {
   __typename?: 'FloorEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  floorId: Scalars['ID'];
};

export type FloorMarker = {
   __typename?: 'FloorMarker';
  room: Room;
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type FloorMarkerEvent = Event & {
   __typename?: 'FloorMarkerEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  roomId: Scalars['ID'];
  floorId: Scalars['ID'];
};

export type Heading = {
   __typename?: 'Heading';
  pitch: Scalars['Float'];
  roll: Scalars['Float'];
  yaw: Scalars['Float'];
};

export type HeadingInput = {
  pitch: Scalars['Float'];
  roll: Scalars['Float'];
  yaw: Scalars['Float'];
};

export type Hotspot = Tourpath | Annotation | TextAnnotation | ImageAnnotation;

export type HotspotEvent = Event & {
   __typename?: 'HotspotEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  roomId: Scalars['ID'];
  hotspotId: Scalars['ID'];
};

export type Image = {
   __typename?: 'Image';
  file: S3Object;
  exif?: Maybe<Scalars['String']>;
  projectionType?: Maybe<ProjectionType>;
  heading?: Maybe<Heading>;
};

export type ImageAnnotation = {
   __typename?: 'ImageAnnotation';
  id: Scalars['ID'];
  position: Position;
  picture: S3Object;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ImageEnhancementJob = Job & {
   __typename?: 'ImageEnhancementJob';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  tourId: Scalars['ID'];
  roomId: Scalars['ID'];
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ImageInput = {
  exif?: Maybe<Scalars['String']>;
  heading?: Maybe<HeadingInput>;
  projectionType?: Maybe<ProjectionType>;
};

export type ImageUpdateInput = {
  exif?: Maybe<Scalars['String']>;
  heading?: Maybe<HeadingInput>;
  projectionType?: Maybe<ProjectionType>;
};

export type ImportStagingJobInput = {
  id: Scalars['ID'];
  items: Array<ImportStagingJobItemInput>;
};

export type ImportStagingJobItemInput = {
  artifactId: Scalars['ID'];
  roomName: Scalars['String'];
};

export type ImportStagingJobPayload = {
   __typename?: 'ImportStagingJobPayload';
  teamId: Scalars['ID'];
  rooms: Array<Room>;
};

export type InvitedMemberInput = {
  email: Scalars['String'];
  role: TeamMemberRole;
};

export type InviteTeamMembersInput = {
  id: Scalars['ID'];
  members: Array<InvitedMemberInput>;
};

export type InviteTeamMembersPayload = {
   __typename?: 'InviteTeamMembersPayload';
  teamId: Scalars['ID'];
  members: Array<TeamMember>;
};

export type Job = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type JobsPage = {
   __typename?: 'JobsPage';
  cursor?: Maybe<Scalars['String']>;
  items: Array<Job>;
};

export type LeadGenerator = {
   __typename?: 'LeadGenerator';
  notificationRecipients?: Maybe<Recipients>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
};

export type LeadGeneratorInput = {
  notificationRecipients?: Maybe<RecipientsInput>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
};

export enum LockStatus {
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED'
}


export type ManualCropJob = Job & {
   __typename?: 'ManualCropJob';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  roomId: Scalars['ID'];
  position: EquirectPosition;
  cropSize?: Maybe<Size>;
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  usesEnhancedImage?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ManualCropJobEvent = Event & {
   __typename?: 'ManualCropJobEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  roomId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export type Mutation = {
   __typename?: 'Mutation';
  addVisit: Scalars['String'];
  addFloorMarker: FloorMarker;
  addTourpath: Hotspot;
  addTextAnnotation: TextAnnotation;
  addImageAnnotation: ImageAnnotation;
  updateImageAnnotation: ImageAnnotation;
  removeHotspot: Hotspot;
  createFloor: Floor;
  createRoom: Room;
  createTour: Tour;
  deleteFloor: Floor;
  deleteUser: User;
  deleteRoom: Room;
  deleteTeam: Team;
  deleteTour: Tour;
  removeFloorMarker: FloorMarker;
  removeUserPicture: User;
  removeTeamPicture: Team;
  removeTeamTripodCover: Team;
  removeTeamBrandLogo: Team;
  reorderRoom?: Maybe<Room>;
  updateUser: User;
  updateFloor: Floor;
  updateRoom: Room;
  updateTour: Tour;
  reassignTour: Tour;
  createTeam: Team;
  updateTeam: Team;
  cancelSubscription: Team;
  reactivateSubscription: Team;
  inviteTeamMembers: InviteTeamMembersPayload;
  acceptTeamInvitation: TeamMember;
  removeTeamMember: TeamMember;
  updateTeamMemberRole: TeamMember;
  createAutoCropJob: AutoCropJob;
  createStagingJob: StagingJob;
  createManualCropJob: ManualCropJob;
  createBlurJob: BlurJob;
  createArtifactsZipJob: ArtifactsZipJob;
  createVideoGenerationJob: VideoGeneration;
  deleteJob: Job;
  importStagingJob: ImportStagingJobPayload;
  moveTour: Tour;
  copyTour: Tour;
  duplicateTour: Tour;
  updateRoomEnhancedImage: Room;
  updateTourEnhancementStatus: Tour;
  updateImageEnhancementJob: ImageEnhancementJob;
  updateRoomAutoCropJob: RoomAutoCropJob;
  updateRoomStagingJob: RoomStagingJob;
  updateVideoGenerationJob: VideoGenerationJob;
  updateManualCropJob: ManualCropJob;
  updateBlurJob: BlurJob;
  updateArtifactsZipJob: ArtifactsZipJob;
  adminResetPassword: User;
  adminUpdateTrialExpiration: Team;
  adminMoveTour: Tour;
  adminCopyTour: Tour;
  adminUpdateUser: User;
  createTourAuthorization: TourAuthorization;
};


export type MutationAddVisitArgs = {
  tourId: Scalars['ID'];
  referrer: Scalars['String'];
  fingerprint: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
};


export type MutationAddFloorMarkerArgs = {
  floorId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  roomId: Scalars['ID'];
  tourId: Scalars['ID'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};


export type MutationAddTourpathArgs = {
  roomId: Scalars['ID'];
  position: PositionInput;
  toRoomId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
};


export type MutationAddTextAnnotationArgs = {
  roomId: Scalars['ID'];
  position: PositionInput;
  title?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};


export type MutationAddImageAnnotationArgs = {
  roomId: Scalars['ID'];
  position: PositionInput;
  picture: S3ObjectInput;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type MutationUpdateImageAnnotationArgs = {
  roomId: Scalars['ID'];
  position?: Maybe<PositionInput>;
  picture?: Maybe<S3ObjectInput>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationRemoveHotspotArgs = {
  roomId: Scalars['ID'];
  hotspotId: Scalars['ID'];
};


export type MutationCreateFloorArgs = {
  file: S3ObjectInput;
  image?: Maybe<ImageInput>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  tourId: Scalars['ID'];
};


export type MutationCreateRoomArgs = {
  file: S3ObjectInput;
  image?: Maybe<ImageInput>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  tourId: Scalars['ID'];
};


export type MutationCreateTourArgs = {
  teamId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  details?: Maybe<DetailsInput>;
  id?: Maybe<Scalars['ID']>;
  privacy?: Maybe<PrivacyLevel>;
  isEnhancementEnabled?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteFloorArgs = {
  id: Scalars['ID'];
  tourId: Scalars['ID'];
};


export type MutationDeleteRoomArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeamArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTourArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveFloorMarkerArgs = {
  floorId: Scalars['ID'];
  roomId: Scalars['ID'];
  tourId: Scalars['ID'];
};


export type MutationRemoveTeamPictureArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveTeamTripodCoverArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveTeamBrandLogoArgs = {
  id: Scalars['ID'];
};


export type MutationReorderRoomArgs = {
  id: Scalars['ID'];
  afterId?: Maybe<Scalars['ID']>;
  tourId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileInput>;
  picture?: Maybe<S3ObjectInput>;
  tripodCover?: Maybe<S3ObjectInput>;
  shouldHideOnboarding?: Maybe<Scalars['Boolean']>;
  businessInfo?: Maybe<BusinessInfoInput>;
};


export type MutationUpdateFloorArgs = {
  file?: Maybe<S3ObjectInput>;
  image?: Maybe<ImageUpdateInput>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tourId: Scalars['ID'];
};


export type MutationUpdateRoomArgs = {
  id: Scalars['ID'];
  file?: Maybe<S3ObjectInput>;
  enhancedImageFile?: Maybe<S3ObjectInput>;
  image?: Maybe<ImageUpdateInput>;
  name?: Maybe<Scalars['String']>;
  firstView?: Maybe<HeadingInput>;
  startingView?: Maybe<ViewInput>;
  lockStatus?: Maybe<LockStatus>;
};


export type MutationUpdateTourArgs = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  details?: Maybe<DetailsInput>;
  id: Scalars['ID'];
  privacy?: Maybe<PrivacyLevel>;
  isEnhancementEnabled?: Maybe<Scalars['Boolean']>;
  leadGenerator?: Maybe<LeadGeneratorInput>;
  rooms?: Maybe<Array<RoomInput>>;
};


export type MutationReassignTourArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationCreateTeamArgs = {
  name: Scalars['String'];
};


export type MutationUpdateTeamArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  picture?: Maybe<S3ObjectInput>;
  tripodCover?: Maybe<S3ObjectInput>;
  brandLogo?: Maybe<BrandLogoInput>;
  isEnhancementEnabled?: Maybe<Scalars['Boolean']>;
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationReactivateSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationInviteTeamMembersArgs = {
  input: InviteTeamMembersInput;
};


export type MutationAcceptTeamInvitationArgs = {
  code: Scalars['ID'];
};


export type MutationRemoveTeamMemberArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateTeamMemberRoleArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
  role: TeamMemberRole;
};


export type MutationCreateAutoCropJobArgs = {
  input: CreateAutoCropJobInput;
};


export type MutationCreateStagingJobArgs = {
  input: CreateStagingJobInput;
};


export type MutationCreateManualCropJobArgs = {
  input: CreateManualCropJobInput;
};


export type MutationCreateBlurJobArgs = {
  input: CreateBlurJobInput;
};


export type MutationCreateArtifactsZipJobArgs = {
  input: CreateArtifactsZipJobInput;
};


export type MutationCreateVideoGenerationJobArgs = {
  input: CreateVideoGenerationJobInput;
};


export type MutationDeleteJobArgs = {
  id: Scalars['ID'];
};


export type MutationImportStagingJobArgs = {
  input: ImportStagingJobInput;
};


export type MutationMoveTourArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationCopyTourArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type MutationDuplicateTourArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateRoomEnhancedImageArgs = {
  id: Scalars['ID'];
  status?: Maybe<EnhancementStatus>;
  file?: Maybe<S3ObjectInput>;
  image?: Maybe<ImageUpdateInput>;
  shouldCreateThumbnail?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateTourEnhancementStatusArgs = {
  id: Scalars['ID'];
  status: EnhancementStatus;
};


export type MutationUpdateImageEnhancementJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateRoomAutoCropJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateRoomStagingJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateVideoGenerationJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateManualCropJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateBlurJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateArtifactsZipJobArgs = {
  input: UpdateJobInput;
};


export type MutationAdminResetPasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationAdminUpdateTrialExpirationArgs = {
  teamId: Scalars['ID'];
  expiresAt: Scalars['String'];
};


export type MutationAdminMoveTourArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationAdminCopyTourArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  isDemo?: Maybe<Scalars['Boolean']>;
};


export type MutationAdminUpdateUserArgs = {
  id: Scalars['ID'];
  developerMode?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateTourAuthorizationArgs = {
  tourId: Scalars['ID'];
  personalInformation: PersonalInformationInput;
};

export type NormalPosition = {
   __typename?: 'NormalPosition';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type NormalPositionInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type NormalView = {
   __typename?: 'NormalView';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type NormalViewInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export enum OperationType {
  Create = 'CREATE',
  Read = 'READ',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

export type Page = {
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  items: Array<PageableItems>;
};

export type PageableItems = Tour | Room | RoomAutoCropJob | RoomStagingJob | TeamMember;

export type PageInput = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type Period = {
   __typename?: 'Period';
  startAt: Scalars['String'];
  endAt: Scalars['String'];
};

export type PeriodInput = {
  startAt?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['String']>;
};

export type Permission = {
   __typename?: 'Permission';
  canAddMember: Scalars['Boolean'];
  canAutoCrop: Scalars['Boolean'];
  canVideoGeneration: Scalars['Boolean'];
  maxStaging: Scalars['Int'];
};

export type PersonalInformationInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type Position = NormalPosition | EquirectPosition;

export type PositionInput = {
  normal?: Maybe<NormalPositionInput>;
  equirect?: Maybe<EquirectPositionInput>;
};

export enum PrivacyLevel {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type Profile = {
   __typename?: 'Profile';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  localAgency?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  bookingUrl?: Maybe<Scalars['String']>;
};

export type ProfileInput = {
  address?: Maybe<AddressInput>;
  email?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  localAgency?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  bookingUrl?: Maybe<Scalars['String']>;
};

export enum Progress {
  Waiting = 'WAITING',
  RetryWaiting = 'RETRY_WAITING',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Error = 'ERROR'
}

export enum ProjectionType {
  Cylindrical = 'CYLINDRICAL',
  Dualfish = 'DUALFISH',
  Equirect = 'EQUIRECT',
  None = 'NONE',
  Rectilinear = 'RECTILINEAR'
}

export type Query = {
   __typename?: 'Query';
  getAnalytics: Analytics;
  getRoom?: Maybe<Room>;
  getTour?: Maybe<Tour>;
  getPublishedTour?: Maybe<ViewerTour>;
  getTours: ToursPage;
  getUserTours: UserToursPage;
  getUpdatedTours: Array<Tour>;
  getUser: User;
  getTeam: Team;
  getTeams: Array<TeamMember>;
  getTeamInvite: TeamInvite;
  getStateOfOwnTeams: Array<TeamState>;
  getManualCropJob: ManualCropJob;
  getJob: Job;
  getReferral: Referral;
  searchUserEvents: SearchedEventsPage;
  searchTeamEvents: SearchedEventsPage;
  getJobsByProgresses: JobsPage;
  getAllJobs: JobsPage;
  adminGetSummary: Summary;
  adminGetUser: User;
  adminGetTeam: Team;
  adminGetTour: Tour;
  adminGetViewerTour?: Maybe<ViewerTour>;
  adminSearchUsers: SearchedUsersPage;
  adminSearchTeams: SearchedTeamsPage;
  adminSearchTeamMembers: TeamMembersPage;
  adminSearchTours: SearchedToursPage;
  adminSearchEvents: SearchedEventsPage;
};


export type QueryGetAnalyticsArgs = {
  teamId: Scalars['ID'];
  tourId?: Maybe<Scalars['ID']>;
  period?: Maybe<PeriodInput>;
};


export type QueryGetRoomArgs = {
  id: Scalars['ID'];
};


export type QueryGetTourArgs = {
  id: Scalars['ID'];
};


export type QueryGetPublishedTourArgs = {
  id: Scalars['ID'];
  viewMode?: Maybe<ViewMode>;
  authorizationCode?: Maybe<Scalars['String']>;
};


export type QueryGetToursArgs = {
  teamId: Scalars['ID'];
  privacy?: Maybe<PrivacyLevel>;
  query?: Maybe<Scalars['String']>;
  filters?: Maybe<TourFiltersInput>;
  page?: Maybe<PageInput>;
  order?: Maybe<SortOrder>;
};


export type QueryGetUserToursArgs = {
  teamId?: Maybe<Scalars['ID']>;
  page?: Maybe<PageInput>;
  order?: Maybe<SortOrder>;
};


export type QueryGetUpdatedToursArgs = {
  teamId: Scalars['ID'];
  lastSync?: Maybe<Scalars['AWSTimestamp']>;
};


export type QueryGetTeamArgs = {
  id: Scalars['ID'];
};


export type QueryGetTeamInviteArgs = {
  code: Scalars['ID'];
};


export type QueryGetManualCropJobArgs = {
  teamId: Scalars['ID'];
  roomId: Scalars['ID'];
};


export type QueryGetJobArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type QueryGetReferralArgs = {
  code: Scalars['String'];
};


export type QuerySearchUserEventsArgs = {
  period?: Maybe<PeriodInput>;
  page?: Maybe<SearchPageInput>;
  filter?: Maybe<EventFilterInput>;
  order?: Maybe<SortOrder>;
};


export type QuerySearchTeamEventsArgs = {
  teamId: Scalars['ID'];
  period?: Maybe<PeriodInput>;
  page?: Maybe<SearchPageInput>;
  filter?: Maybe<EventFilterInput>;
  order?: Maybe<SortOrder>;
};


export type QueryGetJobsByProgressesArgs = {
  progresses: Array<Progress>;
  teamId?: Maybe<Scalars['String']>;
  page?: Maybe<PageInput>;
};


export type QueryGetAllJobsArgs = {
  page?: Maybe<PageInput>;
};


export type QueryAdminGetUserArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryAdminGetTeamArgs = {
  id: Scalars['ID'];
};


export type QueryAdminGetTourArgs = {
  id: Scalars['ID'];
};


export type QueryAdminGetViewerTourArgs = {
  id: Scalars['ID'];
  viewMode?: Maybe<ViewMode>;
};


export type QueryAdminSearchUsersArgs = {
  page?: Maybe<SearchPageInput>;
  filter?: Maybe<UserFilterInput>;
  order?: Maybe<SortOrder>;
};


export type QueryAdminSearchTeamsArgs = {
  page?: Maybe<SearchPageInput>;
  filter?: Maybe<TeamFilterInput>;
  order?: Maybe<SortOrder>;
};


export type QueryAdminSearchTeamMembersArgs = {
  userId: Scalars['ID'];
  page?: Maybe<PageInput>;
};


export type QueryAdminSearchToursArgs = {
  page?: Maybe<SearchPageInput>;
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<TourFilterInput>;
  order?: Maybe<SortOrder>;
};


export type QueryAdminSearchEventsArgs = {
  period?: Maybe<PeriodInput>;
  page?: Maybe<SearchPageInput>;
  filter?: Maybe<EventFilterInput>;
  order?: Maybe<SortOrder>;
};

export type Recipients = {
   __typename?: 'Recipients';
  all: Scalars['Boolean'];
  members?: Maybe<Array<Scalars['String']>>;
};

export type RecipientsInput = {
  all: Scalars['Boolean'];
  members?: Maybe<Array<Scalars['String']>>;
};

export type Referral = {
   __typename?: 'Referral';
  code: Scalars['String'];
};

export type ReferrerRewardsEvent = Event & {
   __typename?: 'ReferrerRewardsEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  referrerTeamId: Scalars['ID'];
  currency: Scalars['String'];
  amount: Scalars['Int'];
};

export type Room = {
   __typename?: 'Room';
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  enhancedImage?: Maybe<Image>;
  enhancementStatus: EnhancementStatus;
  hotspots: Array<Hotspot>;
  name: Scalars['String'];
  sequenceNo: Scalars['Float'];
  tour: Tour;
  updatedAt?: Maybe<Scalars['String']>;
  teamId: Scalars['ID'];
  firstView?: Maybe<Heading>;
  startingView?: Maybe<View>;
  lockStatus?: Maybe<LockStatus>;
  isStaged?: Maybe<Scalars['Boolean']>;
};

export type RoomAutoCropJob = Job & {
   __typename?: 'RoomAutoCropJob';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  roomId: Scalars['ID'];
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  usesEnhancedImage?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type RoomAutoCropJobsPage = Page & {
   __typename?: 'RoomAutoCropJobsPage';
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  items: Array<RoomAutoCropJob>;
};

export type RoomEvent = Event & {
   __typename?: 'RoomEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  roomId: Scalars['ID'];
};

export type RoomInput = {
  id: Scalars['ID'];
  lockStatus?: Maybe<LockStatus>;
};

export type RoomsPage = Page & {
   __typename?: 'RoomsPage';
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  items: Array<Room>;
};

export type RoomStagingJob = Job & {
   __typename?: 'RoomStagingJob';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  roomId: Scalars['ID'];
  roomName: Scalars['String'];
  roomType: Scalars['String'];
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  usesEnhancedImage?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type RoomStagingJobsPage = Page & {
   __typename?: 'RoomStagingJobsPage';
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  items: Array<RoomStagingJob>;
};

export type S3Object = {
   __typename?: 'S3Object';
  bucket: Scalars['String'];
  key: Scalars['String'];
  region: Scalars['String'];
  mimeType: Scalars['String'];
};

export type S3ObjectInput = {
  bucket: Scalars['String'];
  key: Scalars['String'];
  localUri?: Maybe<Scalars['String']>;
  mimeType: Scalars['String'];
  region: Scalars['String'];
};

export type SearchedEventsPage = {
   __typename?: 'SearchedEventsPage';
  cursor?: Maybe<EsCursor>;
  limit: Scalars['Int'];
  items: Array<Event>;
};

export type SearchedPage = {
  cursor?: Maybe<EsCursor>;
  limit: Scalars['Int'];
  items: Array<SearchPageableItems>;
};

export type SearchedTeamsPage = SearchedPage & {
   __typename?: 'SearchedTeamsPage';
  cursor?: Maybe<EsCursor>;
  limit: Scalars['Int'];
  items: Array<Team>;
};

export type SearchedToursPage = SearchedPage & {
   __typename?: 'SearchedToursPage';
  cursor?: Maybe<EsCursor>;
  limit: Scalars['Int'];
  items: Array<Tour>;
};

export type SearchedUsersPage = SearchedPage & {
   __typename?: 'SearchedUsersPage';
  cursor?: Maybe<EsCursor>;
  limit: Scalars['Int'];
  items: Array<User>;
};

export type SearchPageableItems = User | Team | Tour;

export type SearchPageInput = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<EsCursorInput>;
};

export type SignupContext = {
   __typename?: 'SignupContext';
  country?: Maybe<Scalars['String']>;
  referrerTeamId?: Maybe<Scalars['ID']>;
};

export type Size = {
   __typename?: 'Size';
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type SizeInput = {
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StagingJob = Job & {
   __typename?: 'StagingJob';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  tourId: Scalars['ID'];
  roomStagingJobs: RoomStagingJobsPage;
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};


export type StagingJobRoomStagingJobsArgs = {
  page?: Maybe<PageInput>;
};

export type StagingJobEvent = Event & {
   __typename?: 'StagingJobEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export type StagingUsage = {
   __typename?: 'StagingUsage';
  available: Scalars['Int'];
};

export type Stripe = {
   __typename?: 'Stripe';
  account: StripeAccount;
  customer: StripeCustomer;
  invoices: Array<StripeInvoice>;
};

export enum StripeAccount {
  Us = 'US',
  JpUs = 'JP_US',
  Jp = 'JP'
}

export type StripeBasePlan = {
   __typename?: 'StripeBasePlan';
  type: StripeBasePlanType;
  priceId: Scalars['ID'];
  unitAmount: Scalars['Int'];
};

export enum StripeBasePlanType {
  Entry = 'entry',
  Standard = 'standard',
  Pro = 'pro',
  Business = 'business'
}

export type StripeCard = {
   __typename?: 'StripeCard';
  id?: Maybe<Scalars['ID']>;
  brand: Scalars['String'];
  last4: Scalars['String'];
  address?: Maybe<Address>;
};

export enum StripeCurrency {
  Usd = 'usd',
  Eur = 'eur',
  Jpy = 'jpy'
}

export type StripeCustomer = {
   __typename?: 'StripeCustomer';
  id: Scalars['ID'];
  balance: Scalars['Int'];
  currency?: Maybe<StripeCurrency>;
  card?: Maybe<StripeCard>;
  contractorType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxId?: Maybe<StripeTaxId>;
  subscription?: Maybe<StripeSubscription>;
};

export enum StripeInterval {
  Month = 'month',
  Year = 'year'
}

export type StripeInvoice = {
   __typename?: 'StripeInvoice';
  id: Scalars['ID'];
  created: Scalars['Int'];
  currency: Scalars['String'];
  hosted_invoice_url?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  amount_due: Scalars['Int'];
  number: Scalars['String'];
};

export type StripeSubscription = {
   __typename?: 'StripeSubscription';
  id: Scalars['ID'];
  currency: StripeCurrency;
  interval: StripeInterval;
  basePlan: StripeBasePlan;
  teamPlan?: Maybe<StripeTeamPlan>;
  taxRate?: Maybe<StripeTaxRate>;
  renewsAt: Scalars['Int'];
  period: Period;
};

export type StripeTaxId = {
   __typename?: 'StripeTaxId';
  type: StripeTaxIdType;
  value: Scalars['String'];
};

export enum StripeTaxIdType {
  EuVat = 'eu_vat'
}

export type StripeTaxRate = {
   __typename?: 'StripeTaxRate';
  id: Scalars['ID'];
  name: Scalars['String'];
  percentage: Scalars['Float'];
};

export type StripeTeamPlan = {
   __typename?: 'StripeTeamPlan';
  priceId: Scalars['ID'];
  unitAmount: Scalars['Int'];
  quantity: Scalars['Int'];
  includedQuantity: Scalars['Int'];
};

export type Subscription = {
   __typename?: 'Subscription';
  onCreateRoom?: Maybe<Room>;
  onCreateTour?: Maybe<Tour>;
  onDeleteRoom?: Maybe<Room>;
  onDeleteTour?: Maybe<Tour>;
  onDeltaTour?: Maybe<Tour>;
  onUpdateRoom?: Maybe<Room>;
  onUpdateTour?: Maybe<Tour>;
  onUpdateRoomAutoCropJob?: Maybe<RoomAutoCropJob>;
  onUpdateManualCropJob?: Maybe<ManualCropJob>;
  onUpdateBlurJob?: Maybe<BlurJob>;
  onUpdateArtifactsZipJob?: Maybe<ArtifactsZipJob>;
  onInviteTeamMembers?: Maybe<InviteTeamMembersPayload>;
  onAcceptTeamInvitation?: Maybe<TeamMember>;
  onRemoveTeamMember?: Maybe<TeamMember>;
};


export type SubscriptionOnCreateRoomArgs = {
  teamId: Scalars['ID'];
};


export type SubscriptionOnCreateTourArgs = {
  teamId: Scalars['ID'];
};


export type SubscriptionOnDeleteRoomArgs = {
  teamId: Scalars['ID'];
};


export type SubscriptionOnDeleteTourArgs = {
  teamId: Scalars['ID'];
};


export type SubscriptionOnUpdateRoomArgs = {
  teamId: Scalars['ID'];
};


export type SubscriptionOnUpdateTourArgs = {
  teamId: Scalars['ID'];
};


export type SubscriptionOnUpdateRoomAutoCropJobArgs = {
  userId: Scalars['ID'];
};


export type SubscriptionOnUpdateManualCropJobArgs = {
  userId: Scalars['ID'];
};


export type SubscriptionOnUpdateBlurJobArgs = {
  userId: Scalars['ID'];
};


export type SubscriptionOnUpdateArtifactsZipJobArgs = {
  userId: Scalars['ID'];
};


export type SubscriptionOnInviteTeamMembersArgs = {
  teamId: Scalars['ID'];
};


export type SubscriptionOnAcceptTeamInvitationArgs = {
  teamId: Scalars['ID'];
};


export type SubscriptionOnRemoveTeamMemberArgs = {
  teamId: Scalars['ID'];
};

export type Summary = {
   __typename?: 'Summary';
  userCount: Scalars['Int'];
  roomCount: Scalars['Int'];
  teamCount: Scalars['Int'];
  teamSummary: TeamSummary;
  tourCount: Scalars['Int'];
};

export type Team = {
   __typename?: 'Team';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  state: AccountState;
  stripe: Stripe;
  picture?: Maybe<S3Object>;
  tripodCover?: Maybe<S3Object>;
  brandLogo?: Maybe<BrandLogo>;
  permission: Permission;
  usage: Usage;
  expiresAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  members: Array<TeamMember>;
  isEnhancementEnabled: Scalars['Boolean'];
  referralCode: Scalars['String'];
};

export type TeamEvent = Event & {
   __typename?: 'TeamEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
};

export enum TeamFilterField {
  Id = 'ID',
  TeamName = 'TEAM_NAME'
}

export type TeamFilterInput = {
  field: TeamFilterField;
  value: Scalars['String'];
};

export type TeamInvite = {
   __typename?: 'TeamInvite';
  teamName: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type TeamMember = {
   __typename?: 'TeamMember';
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  teamName: Scalars['String'];
  teamPicture?: Maybe<S3Object>;
  userName: Scalars['String'];
  userRole?: Maybe<TeamMemberRole>;
  userPicture?: Maybe<S3Object>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type TeamMemberEvent = Event & {
   __typename?: 'TeamMemberEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  memberUserId?: Maybe<Scalars['ID']>;
};

export enum TeamMemberRole {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  Owner = 'OWNER'
}

export type TeamMembersPage = Page & {
   __typename?: 'TeamMembersPage';
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  items: Array<TeamMember>;
};

export type TeamState = {
   __typename?: 'TeamState';
  state: AccountState;
  teamId: Scalars['ID'];
  teamName: Scalars['String'];
};

export type TeamSubscriptionEvent = Event & {
   __typename?: 'TeamSubscriptionEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  subscriptionId?: Maybe<Scalars['ID']>;
  referrerTeamId?: Maybe<Scalars['ID']>;
  fingerprint?: Maybe<Scalars['String']>;
};

export type TeamSummary = {
   __typename?: 'TeamSummary';
  activeSubscriber: Scalars['Int'];
  all: Scalars['Int'];
  expired: Scalars['Int'];
  expiringSubscriber: Scalars['Int'];
  freeTrial: Scalars['Int'];
};

export type TextAnnotation = {
   __typename?: 'TextAnnotation';
  id: Scalars['ID'];
  position: Position;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Tour = {
   __typename?: 'Tour';
  teamId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  details?: Maybe<Details>;
  floorplan: Array<Floor>;
  id: Scalars['ID'];
  privacy: PrivacyLevel;
  rooms: RoomsPage;
  updatedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  isEnhancementEnabled: Scalars['Boolean'];
  enhancementStatus: EnhancementStatus;
  coverImage?: Maybe<Image>;
  leadGenerator?: Maybe<LeadGenerator>;
  isDemo?: Maybe<Scalars['Boolean']>;
};


export type TourRoomsArgs = {
  page?: Maybe<PageInput>;
};

export type TourAuthorization = {
   __typename?: 'TourAuthorization';
  id: Scalars['ID'];
  authorizationCode: Scalars['String'];
  tourId: Scalars['String'];
};

export type TourAuthorizationEvent = Event & {
   __typename?: 'TourAuthorizationEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  tourAuthorizationId: Scalars['ID'];
};

export type TourEvent = Event & {
   __typename?: 'TourEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
};

export enum TourFilterField {
  Id = 'ID',
  TeamId = 'TEAM_ID',
  Street = 'STREET'
}

export type TourFilterInput = {
  field: TourFilterField;
  value: Scalars['String'];
};

export type TourFiltersInput = {
  userId?: Maybe<Scalars['ID']>;
  privacy?: Maybe<PrivacyLevel>;
};

export type Tourpath = {
   __typename?: 'Tourpath';
  id: Scalars['ID'];
  position: Position;
  toRoom: Room;
};

export type ToursPage = Page & {
   __typename?: 'ToursPage';
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  items: Array<Tour>;
};

export type UpdateJobInput = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  progress?: Maybe<Progress>;
  artifacts?: Maybe<Array<ArtifactInput>>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
};

export type Usage = {
   __typename?: 'Usage';
  publishedTours: Scalars['Int'];
  rooms: Scalars['Int'];
  monthlyPv: Scalars['Int'];
  staging: StagingUsage;
};

export type User = {
   __typename?: 'User';
  id: Scalars['ID'];
  businessInfo?: Maybe<BusinessInfo>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  picture?: Maybe<S3Object>;
  locale?: Maybe<Scalars['String']>;
  signupContext?: Maybe<SignupContext>;
  shouldHideOnboarding?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['String'];
  createdAt: Scalars['String'];
  stripe?: Maybe<Stripe>;
  developerMode?: Maybe<Scalars['Boolean']>;
  contractedAt?: Maybe<Scalars['String']>;
};

export enum UserFilterField {
  Id = 'ID',
  Email = 'EMAIL',
  Name = 'NAME'
}

export type UserFilterInput = {
  field: UserFilterField;
  value: Scalars['String'];
};

export type UserToursPage = {
   __typename?: 'UserToursPage';
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  items: Array<Tour>;
};

export type VideoGeneration = {
   __typename?: 'VideoGeneration';
  id: Scalars['ID'];
};

export type VideoGenerationJob = Job & {
   __typename?: 'VideoGenerationJob';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  progress: Progress;
  artifacts: Array<Artifact>;
  tourId: Scalars['ID'];
  parentJobId?: Maybe<Scalars['ID']>;
  validationKey?: Maybe<Scalars['String']>;
  errorCount?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  videoRoomIds: Array<Scalars['String']>;
  videoSize?: Maybe<Size>;
  usesEnhancedImage?: Maybe<Scalars['Boolean']>;
};

export type VideoGenerationJobEvent = Event & {
   __typename?: 'VideoGenerationJobEvent';
  id: Scalars['ID'];
  operationType: OperationType;
  operationName: Scalars['String'];
  requestId: Scalars['String'];
  arguments?: Maybe<Scalars['String']>;
  isSystem: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teamId: Scalars['ID'];
  tourId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export enum VideoGenerationQuality {
  Resolution_1080P = 'RESOLUTION_1080P',
  Resolution_720P = 'RESOLUTION_720P',
  Resolution_480P = 'RESOLUTION_480P'
}

export type View = NormalView | EquirectView;

export type ViewerTour = {
   __typename?: 'ViewerTour';
  team: Team;
  tour: Tour;
  user: User;
  authorizationStatus: AuthorizationStatus;
};

export type ViewInput = {
  normal?: Maybe<NormalViewInput>;
  equirect?: Maybe<EquirectViewInput>;
};

export enum ViewMode {
  View = 'VIEW',
  Mls = 'MLS',
  Embed = 'EMBED'
}

export type VisitCount = {
   __typename?: 'VisitCount';
  total: Scalars['Int'];
  unique: Scalars['Int'];
};

export type ZipContentsInput = {
  jobId: Scalars['ID'];
  artifactIds: Array<Scalars['ID']>;
};
