import * as React from 'react';
import * as Styled from './tabs.styled';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Children should only be <Tab /> components.
   */
  children: React.ReactElement | React.ReactElement[];
}

export const Tabs: React.FC<Props> = ({ children, ...props }) => (
  <Styled.Tabs {...props}>
    <Styled.Nav>{children}</Styled.Nav>
  </Styled.Tabs>
);

Tabs.displayName = 'Tabs';

export default Tabs;
