import * as React from 'react';
import * as Styled from './separator.styled';

const Separator: React.FC<React.HTMLAttributes<HTMLHRElement>> = (props) => (
  <Styled.Separator {...props} />
);

Separator.displayName = 'Separator';

export default Separator;
