// http://jsfiddle.net/ripper234/Xv9WL/28/

/**
 * Compare two software version numbers (e.g. 1.7.1)
 * Returns:
 *
 *  0 if they're identical
 *  negative if v1 < v2
 *  positive if v1 > v2
 *  False if versionToCheckAgainst is all
 *
 *  E.g.:
 *
 *  assert(version_number_compare("1.7.1", "1.6.10") > 0);
 *  assert(version_number_compare("1.7.1", "1.7.10") < 0);
 *
 *
 *  Taken from http://stackoverflow.com/a/6832721/11236
 */

function isPositiveInteger(x: string) {
  // http://stackoverflow.com/a/1019526/11236
  return /^\d+$/.test(x);
}

export default function compareVersionNumbers(
  currentVersion: string,
  versionToCheckAgainst: string | 'all',
): false | 0 | 1 | -1 {
  const currentVersionParts = currentVersion.split('.');
  const versionToCheckAgainstParts =
    versionToCheckAgainst !== 'all' ? versionToCheckAgainst.split('.') : [''];

  function validateParts(parts: string[]) {
    for (let i = 0; i < parts.length; ++i) {
      if (!isPositiveInteger(parts[i])) {
        return false;
      }
    }
    return true;
  }
  if (
    !validateParts(currentVersionParts) ||
    !validateParts(versionToCheckAgainstParts)
  ) {
    return false;
  }

  for (let i = 0; i < currentVersionParts.length; ++i) {
    if (versionToCheckAgainstParts.length === i) {
      return 1;
    }

    if (currentVersionParts[i] === versionToCheckAgainstParts[i]) {
      continue;
    }
    if (currentVersionParts[i] > versionToCheckAgainstParts[i]) {
      return 1;
    }
    return -1;
  }

  if (currentVersionParts.length != versionToCheckAgainstParts.length) {
    return -1;
  }

  return 0;
}
