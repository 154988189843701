import * as React from 'react';
import roundToNearestHalf from '@r360-tours/core/lib/roundToNearestHalf';
import * as i18next from 'i18next';
import { Details } from '../../../graph';
import * as Styled from './tour-details.styled';

interface Trans {
  area: string;
  baths: string;
  beds: string;
  price: string;
  unavailable: string;
  year: string;
  t: i18next.TFunction;
}

export interface Props {
  /**
   * The tour details to be displayed.
   *
   *
   */
  details: Pick<Details, 'area' | 'baths' | 'beds' | 'price' | 'year'>;
  /**
   * A translation object for labels and unavailable details.
   *
   *
   */
  trans: Trans;
}

const TourDetails: React.FC<Props> = ({
  details: { area, baths, beds, price, year },
  trans,
}) => {
  const { t } = trans;

  return (
    <Styled.Information>
      <div
        className={price !== null && price !== undefined ? '' : 'notAvailable'}
      >
        <span>{t(trans.price)}</span>
        <span>
          {price !== null && price !== undefined ? price : t(trans.unavailable)}
        </span>
      </div>
      <div
        className={area !== null && area !== undefined ? '' : 'notAvailable'}
      >
        <span>{t(trans.area)}</span>
        <span>
          {area !== null && area !== undefined ? area : t(trans.unavailable)}
        </span>
      </div>
      <div
        className={beds !== null && beds !== undefined ? '' : 'notAvailable'}
      >
        <span>{t(trans.beds)}</span>
        <span>
          {beds !== null && beds !== undefined
            ? roundToNearestHalf(beds)
            : t(trans.unavailable)}
        </span>
      </div>
      <div
        className={baths !== null && baths !== undefined ? '' : 'notAvailable'}
      >
        <span>{t(trans.baths)}</span>
        <span>
          {baths !== null && baths !== undefined
            ? roundToNearestHalf(baths)
            : t(trans.unavailable)}
        </span>
      </div>
      <div
        className={year !== null && year !== undefined ? '' : 'notAvailable'}
      >
        <span>{t(trans.year)}</span>
        <span>
          {year !== null && year !== undefined ? year : t(trans.unavailable)}
        </span>
      </div>
    </Styled.Information>
  );
};

TourDetails.displayName = 'TourDetails';

export default TourDetails;
