import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Collapse: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 61 61" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <rect
          fill={props.color || 'currentColor'}
          x="0"
          y="0"
          width="61"
          height="61"
          rx="6"
        />
        <g
          transform="translate(21.000000, 21.000000)"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <g transform="translate(15.660000, 3.500000) scale(-1, -1) translate(-15.660000, -3.500000) translate(12.160000, 0.000000)">
            <polyline points="7.28306304e-14 -4.08562073e-14 6.84 -4.08562073e-14 6.84 6.84" />
            <line
              x1="6.84"
              y1="-4.08562073e-14"
              x2="7.28306304e-14"
              y2="6.84"
            />
          </g>
          <g transform="translate(3.500000, 15.660000) scale(-1, -1) translate(-3.500000, -15.660000) translate(0.000000, 12.160000)">
            <polyline points="6.84 6.84 7.28306304e-14 6.84 7.28306304e-14 7.28306304e-14" />
            <line x1="7.28306304e-14" y1="6.84" x2="6.84" y2="7.28306304e-14" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Collapse.displayName = 'Collapse';

export default Collapse;
