type BasePlanType = 'pro' | 'business' | undefined;
type Currency = 'usd' | 'eur' | 'jpy' | undefined;
type Interval = 'monthly' | 'annual' | undefined;

export const getBasePriceId = (
  type: BasePlanType,
  currency: Currency,
  interval: Interval,
): string | undefined => {
  const basePriceIds = {
    business: {
      eur: {
        annual: process.env.STRIPE_PRICE_BUSINESS_EUR_ANNUAL,
        monthly: process.env.STRIPE_PRICE_BUSINESS_EUR_MONTHLY,
      },
      jpy: {
        annual: process.env.STRIPE_PRICE_BUSINESS_JPY_ANNUAL,
        monthly: process.env.STRIPE_PRICE_BUSINESS_JPY_MONTHLY,
      },
      usd: {
        annual: process.env.STRIPE_PRICE_BUSINESS_USD_ANNUAL,
        monthly: process.env.STRIPE_PRICE_BUSINESS_USD_MONTHLY,
      },
    },
    pro: {
      eur: {
        annual: process.env.STRIPE_PRICE_PRO_EUR_ANNUAL,
        monthly: process.env.STRIPE_PRICE_PRO_EUR_MONTHLY,
      },
      jpy: {
        annual: process.env.STRIPE_PRICE_PRO_JPY_ANNUAL,
        monthly: process.env.STRIPE_PRICE_PRO_JPY_MONTHLY,
      },
      usd: {
        annual: process.env.STRIPE_PRICE_PRO_USD_ANNUAL,
        monthly: process.env.STRIPE_PRICE_PRO_USD_MONTHLY,
      },
    },
  } as const;
  if (!type || !currency || !interval) {
    return;
  }
  return basePriceIds[type][currency][interval];
};

export const getTeamPriceId = (
  type: BasePlanType,
  currency: Currency,
  interval: Interval,
): string | undefined => {
  const teamPriceIds = {
    business: {
      eur: {
        annual: process.env.STRIPE_PRICE_BUSINESS_TEAM_EUR_ANNUAL,
        monthly: process.env.STRIPE_PRICE_BUSINESS_TEAM_EUR_MONTHLY,
      },
      jpy: {
        annual: process.env.STRIPE_PRICE_BUSINESS_TEAM_JPY_ANNUAL,
        monthly: process.env.STRIPE_PRICE_BUSINESS_TEAM_JPY_MONTHLY,
      },
      usd: {
        annual: process.env.STRIPE_PRICE_BUSINESS_TEAM_USD_ANNUAL,
        monthly: process.env.STRIPE_PRICE_BUSINESS_TEAM_USD_MONTHLY,
      },
    },
    pro: {
      eur: {
        annual: process.env.STRIPE_PRICE_PRO_TEAM_EUR_ANNUAL,
        monthly: process.env.STRIPE_PRICE_PRO_TEAM_EUR_MONTHLY,
      },
      jpy: {
        annual: process.env.STRIPE_PRICE_PRO_TEAM_JPY_ANNUAL,
        monthly: process.env.STRIPE_PRICE_PRO_TEAM_JPY_MONTHLY,
      },
      usd: {
        annual: process.env.STRIPE_PRICE_PRO_TEAM_USD_ANNUAL,
        monthly: process.env.STRIPE_PRICE_PRO_TEAM_USD_MONTHLY,
      },
    },
  } as const;

  if (!type || !currency || !interval) {
    return;
  }
  return teamPriceIds[type][currency][interval];
};
