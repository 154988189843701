import * as React from 'react';
import ReactModal from 'react-modal';

type ModalStyles = ReactModal.Styles;

interface Props extends ReactModal.Props {
  style?: ModalStyles;
}

export const Modal: React.FunctionComponent<Props> = ({
  children,
  style,
  ...props
}) => {
  let contentStyle: ModalStyles['content'] = {};
  let overlayStyle: ModalStyles['overlay'] = {};

  if (style) {
    contentStyle = style.content || {};
    overlayStyle = style.overlay || {};
  }

  return (
    <ReactModal
      {...props}
      style={{
        content: {
          border: 'none',
          bottom: 'auto',
          boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
          left: 'auto',
          maxWidth: '720px',
          padding: '0',
          right: 'auto',
          top: 'auto',
          width: '80vw',
          ...contentStyle,
        },
        overlay: {
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 1,
          ...overlayStyle,
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

if (typeof document !== 'undefined') {
  ReactModal.setAppElement('body');
}

Modal.displayName = 'Modal';

export default Modal;
