import * as React from 'react';
import * as Styled from './address.styled';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  /**
   * The tour name.
   */
  name?: string | null;

  /**
   * The country name or code.
   */
  country?: string | null;

  /**
   * Whether or not to print the country text.
   *
   * @default false
   */
  includeCountry?: boolean;

  /**
   * The locality or city.
   */
  locality?: string | null;

  /**
   * The postal or zip code.
   */
  postalCode?: string | null;

  /**
   * The region or state.
   */
  region?: string | null;

  /**
   * The street address lines.
   */
  street?: Array<string | null> | null;
}

export const Address: React.FC<Props> = ({
  name,
  country,
  includeCountry = false,
  locality,
  postalCode,
  region,
  street = [],
  ...props
}) => (
  <Styled.Address {...props}>
    <Styled.Emphasis>{name || street?.[0]}</Styled.Emphasis>
    <Styled.Emphasis>
      {[...(name ? [street?.[0], street?.[1]] : [street?.[1]])]
        .filter(Boolean)
        .join(', ')}
    </Styled.Emphasis>
    <Styled.Muted>
      {[
        [locality, region].filter(Boolean).join(', '),
        postalCode,
        includeCountry ? country : null,
      ]
        .filter(Boolean)
        .join(' ')}
    </Styled.Muted>
  </Styled.Address>
);

Address.displayName = 'Address';

export default Address;
