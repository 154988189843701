import * as React from 'react';
import classNames from 'classnames';
import * as CSS from 'csstype';
import { WidthProperty } from 'csstype';
import * as Styled from './svg-icon.styled';

export interface Props extends React.SVGAttributes<SVGElement> {
  /**
   * The color to apply to the icon.
   *
   * @default currentColor
   */
  color?: CSS.Color;

  /**
   * The diameter of the icon.
   *
   * @default 25
   */
  diameter?: WidthProperty<number>;

  /**
   * Displays the icon in a disabled state.
   */
  disabled?: boolean;

  /**
   * Number of degrees to rotate the icon. Positive for clockwise,
   * negative for counter-clockwise.
   *
   * @default 0
   */
  rotate?: number;
  /**
   * the matrix values to transform the icon along.
   *
   */
  matrix?: [number, number, number, number, number, number];

  /**
   * Enable animation to spin the svg.
   *
   * @default false
   */
  spin?: boolean;

  /**
   * If spin is enabled what direction the animation will spin.
   *
   * @default normal
   */
  spinDirection?: 'normal' | 'reverse';

  /**
   * Cursor type when disabled.
   *
   * @default default
   */
  disabledCursor?: 'default' | 'not-allowed';

  /**
   * If provided, will include a <title /> element alongside the SVG
   * with the given text.
   */
  titleAccess?: string;

  /**
   * The viewbox for the SVG.
   *
   * @default "0 0 25 25"
   */
  viewBox?: string;
}

export const SvgIcon: React.FC<Props> = ({
  children,
  className,
  color = 'currentColor',
  diameter = 25,
  matrix,
  rotate = 0,
  spin = false,
  spinDirection = 'normal',
  disabledCursor = 'default',
  titleAccess,
  viewBox = '0 0 25 25',
  ...props
}) => {
  const matrixValues = matrix ? `matrix(${matrix})` : '';
  return (
    <Styled.Svg
      {...props}
      aria-hidden={titleAccess ? 'false' : 'true'}
      className={classNames(className, {
        spin,
      })}
      focusable="false"
      spinDirection={spinDirection}
      disabledCursor={disabledCursor}
      diameter={diameter}
      fill={color}
      matrixValues={matrixValues}
      rotate={rotate}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {titleAccess ? <title>{titleAccess}</title> : null}
      {children}
    </Styled.Svg>
  );
};

SvgIcon.displayName = 'SvgIcon';

export default SvgIcon;
