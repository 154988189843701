import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Phone: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={props.color} strokeWidth="1.5">
        <path d="M29.9992464,23.968604 L29.9992464,27.5815703 C30.0020047,28.2597784 29.7181403,28.9077197 29.2173907,29.3662129 C28.7166411,29.8247062 28.0454148,30.0512593 27.3686133,29.9902145 C23.6553586,29.5875383 20.0885161,28.3211982 16.9546849,26.2929457 C14.0390603,24.4439064 11.5671195,21.9768623 9.71441028,19.0670131 C7.67503978,15.9251824 6.405898,12.3480936 6.00980309,8.62554057 C5.94883178,7.95217077 6.17424422,7.28422976 6.63097736,6.78488004 C7.0877105,6.28553032 7.73370553,6.00075512 8.41116085,6 L12.0312982,6 C13.2426734,5.98821952 14.275181,6.87441709 14.4447231,8.07155241 C14.5975206,9.22778165 14.8808886,10.3630484 15.2894218,11.4556975 C15.6209479,12.3359064 15.4089045,13.328185 14.7464012,13.9968171 L13.2138764,15.5263062 C14.9316985,18.5413817 17.4330911,21.0378194 20.454151,22.7522387 L21.9866758,21.2227497 C22.656635,20.5615587 23.6508831,20.3499353 24.5328391,20.6808047 C25.6276568,21.0885286 26.7651769,21.3713353 27.923701,21.5238302 C29.137126,21.6946757 30.0296435,22.7459673 29.9992464,23.968604 Z" />
      </g>
    </g>
  </SvgIcon>
);

Phone.displayName = 'Phone';

export default Phone;
