import styled from 'styled-components';

export const Label = styled.label`
  color: ${(props): string => props.theme.palette.text.primary};
  font-family: ${(props): string => props.theme.typography.fontFamily};
  vertical-align: middle;
  &:before {
    border: 1px solid
      ${(props): string => props.theme.palette.secondary.contrast};
    border-radius: 0.25rem;
    content: '';
    display: block;
    height: 1.125rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    position: absolute;
    user-select: none;
    width: 1.125rem;
  }
  &:after {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1.125rem;
    position: absolute;
    width: 1.125rem;
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const Checkbox = styled.div`
  min-height: 1.5rem;
  padding-left: 2.25rem;
  position: relative;
  input:checked ~ label:before {
    background-color: ${(props): string =>
      props.theme.palette.secondary.contrast};
  }
  input:checked ~ label:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }
`;
