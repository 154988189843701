import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Redo: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="corner-up-right">
        <g transform="translate(10.000000, 10.000000)">
          <polyline
            id="Path"
            stroke={props.color || 'currentColor'}
            strokeWidth="2"
            points="11 10 16 5 11 0"
          ></polyline>
          <path
            d="M0,16 L0,9 C0,6.790861 1.790861,5 4,5 L16,5"
            id="Path"
            stroke={props.color || 'currentColor'}
            strokeWidth="2"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Redo.displayName = 'Redo';

export default Redo;
