import * as React from 'react';
import * as Styled from './tab.styled';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Whether or not the tab is currently active.
   */
  active?: boolean;
}

export const Tab: React.FC<Props> = ({ children, ...props }) => (
  <Styled.Tab {...props}>{children}</Styled.Tab>
);

Tab.displayName = 'Tab';

export default Tab;
