import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Grip: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g id="Tours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group">
        <g transform="translate(13.000000, 10.000000)">
          <rect
            id="Rectangle"
            fill={props.color}
            x="0"
            y="0"
            width="3"
            height="3"
          ></rect>
          <rect
            id="Rectangle"
            fill={props.color}
            x="0"
            y="7"
            width="3"
            height="3"
          ></rect>
          <rect
            id="Rectangle"
            fill={props.color}
            x="0"
            y="14"
            width="3"
            height="3"
          ></rect>
          <rect
            id="Rectangle"
            fill={props.color}
            x="6.42857143"
            y="14"
            width="3"
            height="3"
          ></rect>
          <rect
            id="Rectangle"
            fill={props.color}
            x="6.42857143"
            y="7"
            width="3"
            height="3"
          ></rect>
          <rect
            id="Rectangle"
            fill={props.color}
            x="6.42857143"
            y="6.66133815e-16"
            width="3"
            height="3"
          ></rect>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Grip.displayName = 'Grip';

export default Grip;
