import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Booking: React.FC<Props> = (props) => (
  <SvgIcon viewBox="8 8 20 20" {...props}>
    <g id="Tours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icon-/-Tours-/--Calendly"
        fill={props.color || 'currentColor'}
        fillRule="nonzero"
      >
        <g id="Calendly" transform="translate(8.000000, 8.000000)">
          <path
            d="M17.5,0.64516129 L16.875,0.64516129 C16.875,0.288848548 16.595178,0 16.25,0 C15.904822,0 15.625,0.288848548 15.625,0.64516129 L10.625,0.64516129 C10.625,0.288848548 10.345178,0 10,0 C9.65482203,0 9.375,0.288848548 9.375,0.64516129 L4.375,0.64516129 C4.375,0.288848548 4.09517797,0 3.75,0 C3.40482203,0 3.125,0.288848548 3.125,0.64516129 L2.5,0.64516129 C1.11994491,0.64679673 0.00158433195,1.80123346 0,3.22580645 L0,17.4193548 C0.00158433195,18.8439278 1.11994491,19.9983646 2.5,20 L17.5,20 C18.8800551,19.9983646 19.9984157,18.8439278 20,17.4193548 L20,3.22580645 C19.9984157,1.80123346 18.8800551,0.64679673 17.5,0.64516129 Z M1.25,3.22580645 C1.25,2.51318097 1.80964406,1.93548387 2.5,1.93548387 L3.125,1.93548387 L3.125,3.22580645 C3.125,3.58211919 3.40482203,3.87096774 3.75,3.87096774 C4.09517797,3.87096774 4.375,3.58211919 4.375,3.22580645 L4.375,1.93548387 L9.375,1.93548387 L9.375,3.22580645 C9.375,3.58211919 9.65482203,3.87096774 10,3.87096774 C10.345178,3.87096774 10.625,3.58211919 10.625,3.22580645 L10.625,1.93548387 L15.625,1.93548387 L15.625,3.22580645 C15.625,3.58211919 15.904822,3.87096774 16.25,3.87096774 C16.595178,3.87096774 16.875,3.58211919 16.875,3.22580645 L16.875,1.93548387 L17.5,1.93548387 C18.1903559,1.93548387 18.75,2.51318097 18.75,3.22580645 L18.75,5.16129032 L1.25,5.16129032 L1.25,3.22580645 Z M18.75,17.4193548 C18.75,18.1319803 18.1903559,18.7096774 17.5,18.7096774 L2.5,18.7096774 C1.80964406,18.7096774 1.25,18.1319803 1.25,17.4193548 L1.25,6.4516129 L18.75,6.4516129 L18.75,17.4193548 Z"
            id="Shape"
          ></path>
          <path
            d="M3.75,15.483871 L5,15.483871 C5.34517797,15.483871 5.625,15.1950224 5.625,14.8387097 C5.625,14.4823969 5.34517797,14.1935484 5,14.1935484 L3.75,14.1935484 C3.40482203,14.1935484 3.125,14.4823969 3.125,14.8387097 C3.125,15.1950224 3.40482203,15.483871 3.75,15.483871 Z"
            id="Path"
          ></path>
          <path
            d="M7.5,15.483871 L8.75,15.483871 C9.09517797,15.483871 9.375,15.1950224 9.375,14.8387097 C9.375,14.4823969 9.09517797,14.1935484 8.75,14.1935484 L7.5,14.1935484 C7.15482203,14.1935484 6.875,14.4823969 6.875,14.8387097 C6.875,15.1950224 7.15482203,15.483871 7.5,15.483871 Z"
            id="Path"
          ></path>
          <path
            d="M11.25,15.483871 L12.5,15.483871 C12.845178,15.483871 13.125,15.1950224 13.125,14.8387097 C13.125,14.4823969 12.845178,14.1935484 12.5,14.1935484 L11.25,14.1935484 C10.904822,14.1935484 10.625,14.4823969 10.625,14.8387097 C10.625,15.1950224 10.904822,15.483871 11.25,15.483871 Z"
            id="Path"
          ></path>
          <path
            d="M3.75,12.9032258 L5,12.9032258 C5.34517797,12.9032258 5.625,12.6143773 5.625,12.2580645 C5.625,11.9017518 5.34517797,11.6129032 5,11.6129032 L3.75,11.6129032 C3.40482203,11.6129032 3.125,11.9017518 3.125,12.2580645 C3.125,12.6143773 3.40482203,12.9032258 3.75,12.9032258 Z"
            id="Path"
          ></path>
          <path
            d="M7.5,12.9032258 L8.75,12.9032258 C9.09517797,12.9032258 9.375,12.6143773 9.375,12.2580645 C9.375,11.9017518 9.09517797,11.6129032 8.75,11.6129032 L7.5,11.6129032 C7.15482203,11.6129032 6.875,11.9017518 6.875,12.2580645 C6.875,12.6143773 7.15482203,12.9032258 7.5,12.9032258 Z"
            id="Path"
          ></path>
          <path
            d="M11.25,12.9032258 L12.5,12.9032258 C12.845178,12.9032258 13.125,12.6143773 13.125,12.2580645 C13.125,11.9017518 12.845178,11.6129032 12.5,11.6129032 L11.25,11.6129032 C10.904822,11.6129032 10.625,11.9017518 10.625,12.2580645 C10.625,12.6143773 10.904822,12.9032258 11.25,12.9032258 Z"
            id="Path"
          ></path>
          <path
            d="M15,12.9032258 L16.25,12.9032258 C16.595178,12.9032258 16.875,12.6143773 16.875,12.2580645 C16.875,11.9017518 16.595178,11.6129032 16.25,11.6129032 L15,11.6129032 C14.654822,11.6129032 14.375,11.9017518 14.375,12.2580645 C14.375,12.6143773 14.654822,12.9032258 15,12.9032258 Z"
            id="Path"
          ></path>
          <path
            d="M7.5,10.3225806 L8.75,10.3225806 C9.09517797,10.3225806 9.375,10.0337321 9.375,9.67741935 C9.375,9.32110661 9.09517797,9.03225806 8.75,9.03225806 L7.5,9.03225806 C7.15482203,9.03225806 6.875,9.32110661 6.875,9.67741935 C6.875,10.0337321 7.15482203,10.3225806 7.5,10.3225806 Z"
            id="Path"
          ></path>
          <path
            d="M11.25,10.3225806 L12.5,10.3225806 C12.845178,10.3225806 13.125,10.0337321 13.125,9.67741935 C13.125,9.32110661 12.845178,9.03225806 12.5,9.03225806 L11.25,9.03225806 C10.904822,9.03225806 10.625,9.32110661 10.625,9.67741935 C10.625,10.0337321 10.904822,10.3225806 11.25,10.3225806 Z"
            id="Path"
          ></path>
          <path
            d="M15,10.3225806 L16.25,10.3225806 C16.595178,10.3225806 16.875,10.0337321 16.875,9.67741935 C16.875,9.32110661 16.595178,9.03225806 16.25,9.03225806 L15,9.03225806 C14.654822,9.03225806 14.375,9.32110661 14.375,9.67741935 C14.375,10.0337321 14.654822,10.3225806 15,10.3225806 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Booking.displayName = 'Booking';

export default Booking;
