import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const UploadPending: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 36 36">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={props.color} strokeWidth="1.5">
        <g id="Icon-upload-pending" transform="translate(8.000000, 9.000000)">
          <path d="M3,14 L2,14 C0.8954305,14 0,13.1045695 0,12 L0,2 C0,0.8954305 0.8954305,0 2,0 L18,0 C19.1045695,0 20,0.8954305 20,2 L20,12 C20,13.1045695 19.1045695,14 18,14 L17,14" />
          <polyline points="14 10 10 6 6 10" />
          <line x1="10" y1="6" x2="10" y2="19" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

UploadPending.displayName = 'UploadPending';

export default UploadPending;
