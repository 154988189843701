import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Pencil: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Edit" stroke={props.color} strokeWidth="1.5">
        <path
          d="M24,19.2944444 L24,24.8888889 C24,26.0489444 23.05,27 21.8888889,27 L7.11111111,27 C5.95,27 5,26.0489444 5,24.8888889 L5,10.1111111 C5,8.95 5.95,8 7.11111111,8 L12.7055556,8"
          id="Stroke-1"
        ></path>
        <path
          d="M22.7142857,5 L27,9.28571429 L16.2857143,20 L12.8571429,20 C12.3214286,20 12,19.5714286 12,19.1428571 L12,15.7142857 L22.7142857,5 Z"
          id="Stroke-3"
        ></path>
      </g>
    </g>
  </SvgIcon>
);

Pencil.displayName = 'Pencil';

export default Pencil;
