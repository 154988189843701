import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const FloorPlan: React.FC<Props> = ({
  color = 'currentColor',
  ...props
}) => (
  <SvgIcon viewBox="0 0 36 36" color={color} {...props}>
    <g id="Common" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-/-Blueprint" stroke={color} strokeWidth="1.5">
        <g id="Group" transform="translate(7.000000, 7.000000)">
          <rect id="Rectangle" x="0" y="0" width="22" height="22" rx="1.5" />
          <line
            x1="16.6828444"
            y1="14.8504889"
            x2="21.9396222"
            y2="14.8504889"
            id="Stroke-1"
            strokeLinecap="round"
          />
          <line
            x1="0.916911111"
            y1="14.8504889"
            x2="11.1652444"
            y2="14.8504889"
            id="Stroke-3"
            strokeLinecap="round"
          />
          <line
            x1="11.9169111"
            y1="13.5053111"
            x2="11.9169111"
            y2="16.1942"
            id="Stroke-5"
            strokeLinecap="round"
          />
          <line
            x1="16.4393778"
            y1="13.5053111"
            x2="16.4393778"
            y2="16.1942"
            id="Stroke-7"
            strokeLinecap="round"
          />
          <line
            x1="4.82765556"
            y1="0.916666667"
            x2="4.82765556"
            y2="14.6055556"
            id="Stroke-9"
            strokeLinecap="round"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

FloorPlan.displayName = 'FloorPlan';

export default FloorPlan;
