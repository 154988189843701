
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Hotspot",
        "possibleTypes": [
          {
            "name": "Tourpath"
          },
          {
            "name": "Annotation"
          },
          {
            "name": "TextAnnotation"
          },
          {
            "name": "ImageAnnotation"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "Position",
        "possibleTypes": [
          {
            "name": "NormalPosition"
          },
          {
            "name": "EquirectPosition"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Page",
        "possibleTypes": [
          {
            "name": "RoomsPage"
          },
          {
            "name": "ToursPage"
          },
          {
            "name": "TeamMembersPage"
          },
          {
            "name": "RoomAutoCropJobsPage"
          },
          {
            "name": "RoomStagingJobsPage"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PageableItems",
        "possibleTypes": [
          {
            "name": "Tour"
          },
          {
            "name": "Room"
          },
          {
            "name": "RoomAutoCropJob"
          },
          {
            "name": "RoomStagingJob"
          },
          {
            "name": "TeamMember"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Job",
        "possibleTypes": [
          {
            "name": "RoomAutoCropJob"
          },
          {
            "name": "RoomStagingJob"
          },
          {
            "name": "ManualCropJob"
          },
          {
            "name": "AutoCropJob"
          },
          {
            "name": "StagingJob"
          },
          {
            "name": "BlurJob"
          },
          {
            "name": "ArtifactsZipJob"
          },
          {
            "name": "ImageEnhancementJob"
          },
          {
            "name": "VideoGenerationJob"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "View",
        "possibleTypes": [
          {
            "name": "NormalView"
          },
          {
            "name": "EquirectView"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Event",
        "possibleTypes": [
          {
            "name": "ArtifactsZipJobEvent"
          },
          {
            "name": "AutoCropJobEvent"
          },
          {
            "name": "BlurJobEvent"
          },
          {
            "name": "FloorEvent"
          },
          {
            "name": "FloorMarkerEvent"
          },
          {
            "name": "HotspotEvent"
          },
          {
            "name": "ManualCropJobEvent"
          },
          {
            "name": "ReferrerRewardsEvent"
          },
          {
            "name": "RoomEvent"
          },
          {
            "name": "StagingJobEvent"
          },
          {
            "name": "TeamEvent"
          },
          {
            "name": "TeamMemberEvent"
          },
          {
            "name": "TeamSubscriptionEvent"
          },
          {
            "name": "TourAuthorizationEvent"
          },
          {
            "name": "TourEvent"
          },
          {
            "name": "VideoGenerationJobEvent"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "SearchedPage",
        "possibleTypes": [
          {
            "name": "SearchedUsersPage"
          },
          {
            "name": "SearchedTeamsPage"
          },
          {
            "name": "SearchedToursPage"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchPageableItems",
        "possibleTypes": [
          {
            "name": "User"
          },
          {
            "name": "Team"
          },
          {
            "name": "Tour"
          }
        ]
      }
    ]
  }
};
      export default result;
    