import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const ReferralMale: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 170 170" {...props}>
    <defs>
      <path
        d="M170,85 C170,131.944385 131.944385,170 85,170 C38.0556148,170 0,131.944385 0,85 C0,38.0556148 38.0556148,0 85,0 C131.944385,0 170,38.0556148 170,85"
        id="referral-male-path-1"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-377.000000, -259.000000)">
        <g transform="translate(272.000000, 128.000000)">
          <g transform="translate(105.000000, 131.000000)">
            <mask id="referral-male-mask-2" fill="white">
              <use xlinkHref="#referral-male-path-1" />
            </mask>
            <use fill="#FFEDED" xlinkHref="#referral-male-path-1" />
            <g mask="url(#referral-male-mask-2)">
              <g transform="translate(32.674025, 22.313431)">
                <g transform="translate(41.457572, 29.129521)">
                  <path
                    d="M18.877968,11.7074977 L19.988688,26.2024608 C19.988688,26.2024608 20.546896,28.8741095 19.988688,29.5475623 C18.7256,31.0696228 15.278096,31.3999149 11.818488,31.6937462 C6.82808,32.1169777 0,20.62753 0,20.62753 L6.662896,0 L18.877968,11.7074977 Z"
                    id="Fill-3"
                    fill="#FFA7CA"
                  />
                </g>
                <g transform="translate(72.498494, 64.521538)">
                  <polygon
                    fill="#FFD63D"
                    points="4.237824 0 28.012928 31.4928543 0 41.752643"
                  />
                </g>
                <g transform="translate(5.922934, 45.854814)">
                  <path
                    d="M0,5.01729475 L36.645216,0 L55.523184,11.7074977 L55.523184,9.47766836 C55.523184,9.47766836 67.005608,14.800948 70.455248,18.2647259 C76.464528,24.2986341 81.90136,62.0841969 81.90136,62.0841969 L79.17796,76.4819311 L82.312184,139.871568 L17.676112,138.024935 L0,5.01729475 Z"
                    id="Fill-9"
                    fill="#FFD63D"
                  />
                </g>
                <g transform="translate(67.575370, 83.207994)">
                  <path
                    d="M42.4204611,4.72846787 L28.8703891,40.2892057 C28.3954851,41.1900025 27.4492371,41.7290507 26.4460291,41.6697125 L2.43311708,40.2470256 C0.71150108,40.1455072 -0.42912292,38.3889536 0.15400508,36.7389228 L12.5385331,1.73153148 C12.8881251,0.743514754 13.7831091,0.0621978689 14.8140851,0 L39.9932531,0.869340328 C42.0082131,0.747804262 43.3738291,2.91901033 42.4204611,4.72846787"
                    id="Fill-12"
                    fill="#3A3553"
                  />
                </g>
                <g transform="translate(94.438062, 104.052863)">
                  <path
                    d="M2.336784,11.692338 C3.266656,10.758655 3.218952,8.45947865 4.291936,7.38209717 C5.365632,6.30471569 6.534024,8.48378586 6.820248,6.04162586 C7.106472,3.59875094 5.389128,-0.184595289 7.058056,0.00700274375 C8.727696,0.198600777 11.017488,7.09470012 11.017488,7.09470012 C12.219344,9.9086175 6.820248,20.4086186 3.962992,19.6929857 C1.106448,18.9773527 0,15.5714832 0,13.9907995 C0,12.4108306 1.4062,12.6260209 2.336784,11.692338"
                    id="Fill-15"
                    fill="#FFA7CA"
                  />
                </g>
                <g transform="translate(45.985871, 4.553785)">
                  <path
                    d="M25.4614972,2.60544739 C25.4614972,2.60544739 25.9093452,33.406261 17.1617132,36.9601185 C8.41479322,40.5139761 1.03277722,34.8125048 0.152745217,24.361833 C-0.726574783,13.9111612 1.50056122,-7.40054539 25.4614972,2.60544739"
                    id="Fill-18"
                    fill="#FFA7CA"
                  />
                </g>
                <g transform="translate(38.790466, 0.000000)">
                  <path
                    d="M41.4543028,0.789741204 C46.3229588,-0.209714206 36.9494788,9.31799842 33.1267508,9.6425712 C29.3033108,9.96714399 19.5325348,9.46241186 16.5627828,12.9948219 C13.5930308,16.5265169 8.27652683,32.8516702 8.27652683,32.8516702 L5.44347883,40.8373046 C5.44347883,40.8373046 -3.99550517,14.0764928 1.98529483,3.22475202 C5.58303083,-3.30316453 33.7604308,2.36899514 41.4543028,0.789741204"
                    id="Fill-21"
                    fill="#3A3553"
                  />
                </g>
                <g transform="translate(45.318733, 17.751429)">
                  <path
                    d="M6.6388461,3.7614264 C6.6388461,3.7614264 5.0525101,-0.553103925 2.7014861,0.0595808288 C-2.6335299,1.45152624 1.2810461,10.9849582 3.0339901,11.2459033 C6.5954141,11.7756576 8.0357901,8.95316116 6.6388461,3.7614264"
                    id="Fill-24"
                    fill="#FFA7CA"
                  />
                </g>
                <g transform="translate(0.000000, 50.871823)">
                  <path
                    d="M55.7674906,46.3717284 C55.7375866,46.3860267 55.7019866,46.40104 55.6735066,46.4146234 C51.5766586,48.389227 26.6780186,51.2896495 18.1382906,53.5209087 C18.9229146,49.1520446 17.8606106,28.277153 17.6989866,18.9295997 C17.5281066,9.07016508 5.92321863,0 5.92321863,0 C5.92321863,0 -6.90630937,23.6580675 5.19769063,73.7023298 C16.3027546,72.5870577 45.5267946,63.3424526 57.2420426,57.4293656 L55.7674906,46.3717284 Z"
                    id="Fill-27"
                    fill="#FFD63D"
                  />
                </g>
                <g transform="translate(67.982847, 89.450671)">
                  <path
                    d="M15.644776,14.5135383 C16.312632,12.3108759 19.842016,10.9453824 20.891504,8.57471422 C21.411976,7.39938897 14.214368,5.98885569 14.214368,5.03158045 C14.214368,4.31451766 21.36356,2.4964811 24.958448,1.62857061 C25.864112,1.40980569 25.743072,0.0922117603 24.813912,0.0271542193 C19.144968,-0.369625289 3.998592,3.68324504 0,5.56276455 L1.474552,16.6204018 C3.4888,15.6037883 4.805288,14.9918185 5.171968,14.9918185 C7.672512,14.9918185 14.977632,16.7162008 15.644776,14.5135383"
                    id="Fill-30"
                    fill="#FFA7CA"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ReferralMale.displayName = 'ReferralMale';

export default ReferralMale;
