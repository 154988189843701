import 'isomorphic-unfetch';
import * as React from 'react';
import App, { AppContext, AppInitialProps } from 'next/app';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/react-common';
import { getDataFromTree } from '@apollo/react-ssr';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import AWSAppSyncClient from 'aws-appsync';
import { URL } from 'url';
import {
  createApiKeyClient,
  createIAMClient,
  createUserPoolClient,
} from './create-apollo-client';
import { extractIAMCredentials } from './extract-iam-credentials';
import redirect from './redirect';
let apolloClient: AWSAppSyncClient<NormalizedCacheObject>;

export interface Props {
  initialState: NormalizedCacheObject;
  url?: string;
}

export interface WithApolloOptions {
  useApiKeyAuth?: boolean;
  disableAuthRedirect?: boolean;
}

const WithApollo = (
  Application: typeof App,
  withApolloOptions: WithApolloOptions = {},
): typeof App => {
  return class WithApollo extends App<Props> {
    public static createClient(
      initialState: NormalizedCacheObject,
      url?: string,
    ): AWSAppSyncClient<NormalizedCacheObject> {
      const creds = extractIAMCredentials(url);
      if (creds) {
        return createIAMClient(initialState, creds);
      } else if (withApolloOptions.useApiKeyAuth === true) {
        return createApiKeyClient(initialState);
      } else {
        return createUserPoolClient(initialState);
      }
    }

    public static getClient(
      initialState: NormalizedCacheObject,
      url?: string,
    ): AWSAppSyncClient<NormalizedCacheObject> {
      if (typeof window === 'undefined') {
        return WithApollo.createClient(initialState, url);
      }

      if (!apolloClient) {
        apolloClient = WithApollo.createClient(initialState, url);
      }

      return apolloClient;
    }

    public static async getInitialProps(
      context: AppContext,
    ): Promise<AppInitialProps & Props> {
      // Initial serverState with apollo (empty)
      let initialState: NormalizedCacheObject = {};

      // Evaluate the composed component's getInitialProps()
      let initialProps = { pageProps: {} };
      if (Application.getInitialProps) {
        initialProps = await Application.getInitialProps(context);
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      if (context.ctx.res) {
        const client = WithApollo.getClient(initialState, context.ctx.req?.url);

        try {
          // Run all GraphQL queries
          await getDataFromTree(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <ApolloProvider client={client as any}>
              <Application
                {...initialProps}
                Component={context.Component}
                router={context.router}
              />
            </ApolloProvider>,
          );
        } catch (error) {
          // Handle "Unauthorized" newtork errors by redirecting to authenticator.
          if (
            error.networkError &&
            error.networkError.statusCode === 401 &&
            !withApolloOptions.disableAuthRedirect
          ) {
            const url = new URL('/login', process.env.AUTH_URL);

            if (context.ctx.req) {
              let protocol = 'http';

              if (process.env.NODE_ENV === 'production') {
                protocol = 'https';
              }

              url.searchParams.append(
                'from',
                `${protocol}://${context.ctx.req.headers.host}${context.ctx.req.url}`,
              );
            } else {
              url.searchParams.append('from', window.location.href);
            }

            redirect(context.ctx, url.toString());
          }

          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          // eslint-disable-next-line no-console
          console.error('Error while running `getDataFromTree`', error);
        } finally {
          // getDataFromTree does not call componentWillUnmount
          // head side effect therefore need to be cleared manually
          Head.rewind();

          // Extract query data from the Apollo store
          initialState = client.cache.extract();
        }
      }

      return {
        ...initialProps,
        initialState,
        url: context.ctx.req?.url,
      };
    }

    public render(): React.ReactElement {
      const { initialState, url, ...props } = this.props;

      return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <ApolloProvider client={WithApollo.getClient(initialState, url) as any}>
          <Application {...props} />
        </ApolloProvider>
      );
    }
  };
};

export default WithApollo;
