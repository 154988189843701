import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Previous: React.FC<Props> = (props) => (
  <SvgIcon {...props}>
    <path d="M18.163 23.774a.5.5 0 0 1-.707.707l-11.31-11.31a.5.5 0 0 1 0-.706l11.31-11.318a.5.5 0 1 1 .707.706L7.207 12.818l10.956 10.956z" />
  </SvgIcon>
);

Previous.displayName = 'Previous';

export default Previous;
