import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Account: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 80 80" {...props}>
    <defs>
      <polygon
        id="path-1"
        points="0 0 80 0 80 79.9999389 0 79.9999389"
      ></polygon>
      <path
        d="M40,0 C17.9084508,0 -3.25573627e-15,17.9084508 -3.25573627e-15,40 L-3.25573627e-15,40 C-3.25573627e-15,62.0915492 17.9084508,80 40,80 L40,80 C62.0915492,80 80,62.0915492 80,40 L80,40"
        id="path-3"
      ></path>
      <path
        d="M40,0 C17.9084508,0 -3.25573627e-15,17.9084508 -3.25573627e-15,40 L-3.25573627e-15,40 C-3.25573627e-15,62.0915492 17.9084508,80 40,80 L40,80 C62.0915492,80 80,62.0915492 80,40 L80,40"
        id="path-5"
      ></path>
      <path
        d="M40,0 C17.9084508,0 -3.25573627e-15,17.9084508 -3.25573627e-15,40 L-3.25573627e-15,40 C-3.25573627e-15,62.0915492 17.9084508,80 40,80 L40,80 C62.0915492,80 80,62.0915492 80,40 L80,40"
        id="path-7"
      ></path>
      <path
        d="M40,0 C17.9084508,0 -3.25573627e-15,17.9084508 -3.25573627e-15,40 L-3.25573627e-15,40 C-3.25573627e-15,62.0915492 17.9084508,80 40,80 L40,80 C62.0915492,80 80,62.0915492 80,40 L80,40"
        id="path-9"
      ></path>
      <path
        d="M40,0 C17.9084508,0 -3.25573627e-15,17.9084508 -3.25573627e-15,40 L-3.25573627e-15,40 C-3.25573627e-15,62.0915492 17.9084508,80 40,80 L40,80 C62.0915492,80 80,62.0915492 80,40 L80,40 C80,17.9084508 62.0915492,0 40,0 Z"
        id="path-11"
      ></path>
      <path
        d="M40,0 C17.9084508,0 -3.25573627e-15,17.9084508 -3.25573627e-15,40 L-3.25573627e-15,40 C-3.25573627e-15,62.0915492 17.9084508,80 40,80 L40,80 C62.0915492,80 80,62.0915492 80,40 L80,40 C80,17.9084508 62.0915492,0 40,0 Z"
        id="path-13"
      ></path>
      <path
        d="M40,0 C17.9084508,0 -3.25573627e-15,17.9084508 -3.25573627e-15,40 L-3.25573627e-15,40 C-3.25573627e-15,62.0915492 17.9084508,80 40,80 L40,80 C62.0915492,80 80,62.0915492 80,40 L80,40 C80,17.9084508 62.0915492,0 40,0 Z"
        id="path-15"
      ></path>
      <path
        d="M40,0 C17.9084508,0 -3.25573627e-15,17.9084508 -3.25573627e-15,40 L-3.25573627e-15,40 C-3.25573627e-15,62.0915492 17.9084508,80 40,80 L40,80 C62.0915492,80 80,62.0915492 80,40 L80,40 C80,17.9084508 62.0915492,0 40,0 Z"
        id="path-17"
      ></path>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="For-those-who-are-referred-for-PC."
        transform="translate(-88.000000, -337.000000)"
      >
        <g id="img" transform="translate(88.000000, 337.000000)">
          <g id="Group-3">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path
              d="M40,-6.1093886e-05 C62.0915492,-6.1093886e-05 80,17.9083897 80,39.9999389 C80,62.0914881 62.0915492,79.9999389 40,79.9999389 C17.9084508,79.9999389 0,62.0914881 0,39.9999389 C0,17.9083897 17.9084508,-6.1093886e-05 40,-6.1093886e-05"
              id="Fill-1"
              fill="#FCEEED"
              mask="url(#mask-2)"
            ></path>
          </g>
          <g id="Group-28">
            <g id="Group-6">
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-3"></use>
              </mask>
              <g id="Clip-5"></g>
              <path
                d="M82.3997678,55.4731874 C71.4951201,47.7796344 55.7909367,44.4182487 40.0006109,44.4903395 C24.2090633,44.4182487 8.50487987,47.7796344 -2.39854597,55.4731874 C-0.84676126,66.8775831 8.18719167,101.801292 13.9068013,120.320071 L14.0638126,147.445756 C14.0851954,149.833305 16.0261482,151.757763 18.4136972,151.757763 L61.3016052,151.757763 C63.7038168,151.757763 65.6514899,149.81009 65.650879,147.407267 C65.650879,147.326623 65.6484352,147.245368 65.6441587,147.164113 L64.6629909,120.318849 C70.3813786,101.801292 80.8479831,66.8775831 82.3997678,55.4731874"
                id="Fill-4"
                fill="#2A80F7"
                mask="url(#mask-4)"
              ></path>
            </g>
            <g id="Group-9">
              <mask id="mask-6" fill="white">
                <use xlinkHref="#path-5"></use>
              </mask>
              <g id="Clip-8"></g>
              <path
                d="M9.66749653,50.9720343 C14.9429536,52.6307333 18.0947872,58.0247125 16.9504987,63.435187 L10.3853497,84.5810029 L-8.27394498,79.0788875 C-6.97447803,72.1239595 -5.50944664,64.9558138 -3.97415729,58.6460373 C-2.56472134,52.8940479 3.24103065,49.3738182 8.99302002,50.7832542 C9.21967834,50.8388496 9.44450384,50.9017763 9.66749653,50.9720343"
                id="Fill-7"
                fill="#2A80F7"
                mask="url(#mask-6)"
              ></path>
            </g>
            <g id="Group-12">
              <mask id="mask-8" fill="white">
                <use xlinkHref="#path-7"></use>
              </mask>
              <g id="Clip-11"></g>
              <path
                d="M70.3325035,50.9720343 C65.0570464,52.6301223 61.9046019,58.0247125 63.0495013,63.435187 L69.6134284,84.5785591 L88.2727231,79.0764437 C86.9726452,72.1215157 85.5082248,64.9533701 83.9729354,58.6435935 C82.5616666,52.8916042 76.7546928,49.3732073 71.0027034,50.784476 C70.777267,50.8394605 70.5536633,50.9023872 70.3325035,50.9720343"
                id="Fill-10"
                fill="#2A80F7"
                mask="url(#mask-8)"
              ></path>
            </g>
            <g id="Group-15">
              <mask id="mask-10" fill="white">
                <use xlinkHref="#path-9"></use>
              </mask>
              <g id="Clip-14"></g>
              <path
                d="M46.6696195,5.40124937 L33.3291586,5.40124937 C32.325997,5.40124937 31.4083668,5.96697875 30.9587158,6.863837 L26.9112459,14.9233424 C26.599667,15.5452782 26.5440716,16.2637423 26.7572893,16.926 L28.6438685,22.7910131 C28.9933255,23.8748186 29.9958762,24.6152765 31.1346662,24.6299391 L31.1346662,21.1072656 C31.1340553,19.6428451 32.3204985,18.45518 33.784919,18.4545691 L33.7861409,18.4545691 L46.2114154,18.4545691 C47.6764468,18.4545691 48.8641119,19.6422342 48.8641119,21.1072656 L48.8641119,24.6299391 C50.002902,24.6146656 51.0048417,23.8748186 51.3542987,22.7910131 L53.2408779,16.926 C53.4540956,16.2637423 53.3985001,15.5452782 53.0869213,14.9233424 L49.0394514,6.863837 C48.5898004,5.96758969 47.6727811,5.40124937 46.6696195,5.40124937"
                id="Fill-13"
                fill="#37384F"
                mask="url(#mask-10)"
              ></path>
            </g>
            <g id="Group-18">
              <mask id="mask-12" fill="white">
                <use xlinkHref="#path-11"></use>
              </mask>
              <g id="Clip-17"></g>
              <polygon
                id="Fill-16"
                fill="#F4ACC8"
                mask="url(#mask-12)"
                points="46.5565958 50.9376995 44.9987018 31.9790448 35.0006873 31.9790448 33.4421823 50.9376995"
              ></polygon>
            </g>
            <g id="Group-21">
              <mask id="mask-14" fill="white">
                <use xlinkHref="#path-13"></use>
              </mask>
              <g id="Clip-20"></g>
              <path
                d="M44.156828,11.3009638 C48.903212,11.3009638 51.690926,18.1025461 50.0438349,24.5320667 C49.9534159,24.8845784 49.8513891,25.2328135 49.7383654,25.5761612 L46.3244391,35.9468483 C45.5711515,38.2348144 43.9747682,39.886793 42.148672,40.4036473 L41.1956073,40.6742932 C40.3726727,40.9082828 39.500252,40.905839 38.6791502,40.6657401 L37.8238358,40.4176989 C36.0105692,39.8910696 34.4288485,38.2464222 33.6810594,35.9737296 L30.2598018,25.5767721 C30.1461671,25.2334245 30.0441403,24.8858003 29.9543323,24.5326776 C28.3047974,18.103768 31.0955661,11.3015747 35.8407282,11.3015747 L44.156828,11.3009638 Z"
                id="Fill-19"
                fill="#F4ACC8"
                mask="url(#mask-14)"
              ></path>
            </g>
            <g id="Group-24">
              <mask id="mask-16" fill="white">
                <use xlinkHref="#path-15"></use>
              </mask>
              <g id="Clip-23"></g>
              <path
                d="M50.4458326,11.3352374 C44.9699876,16.6797306 34.2974967,12.5198479 29.9555542,24.5315168 L28.7990469,17.7916393 L29.7557772,10.3772853 L50.4458326,11.3352374 Z"
                id="Fill-22"
                fill="#37384F"
                mask="url(#mask-16)"
              ></path>
            </g>
            <g id="Group-27">
              <mask id="mask-18" fill="white">
                <use xlinkHref="#path-17"></use>
              </mask>
              <g id="Clip-26"></g>
              <path
                d="M51.4502161,21.775816 C51.0390543,21.6243031 50.5808501,21.6719564 50.2093993,21.9047241 L48.9264277,27.9120859 C49.8611641,28.1497411 51.1123669,27.1642967 51.8137247,25.5813541 C52.5596811,23.8982176 52.3959495,22.1943091 51.4520489,21.775816 L51.4502161,21.775816 Z M28.5174041,21.775816 C27.5710598,22.1943091 27.4079392,23.8988285 28.1508408,25.5813541 C28.8521986,27.1642967 30.1058452,28.1472974 31.0387488,27.9120859 L29.7557772,21.9047241 C29.3849373,21.6725673 28.927955,21.6243031 28.5174041,21.775816 L28.5174041,21.775816 Z"
                id="Fill-25"
                fill="#F4ACC8"
                mask="url(#mask-18)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Account.displayName = 'Account';

export default Account;
