import styled from 'styled-components';

export const Scene = styled.div`
  -ms-content-zooming: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  box-sizing: border-box;
  display: block;
  position: relative;
  user-select: none;
`;
