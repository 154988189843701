import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 16px 16px;
  color: ${(props): string => props.theme.palette.text.primary};
`;

export const CloseButton = styled.div`
  text-align: right;
  height: 16px;
  button {
    padding: 8px 8px 0 0;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  margin-top: 16px;
  margin-left: 24px;
`;
