import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const TryFree: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 99 80" {...props}>
    <defs>
      <path
        d="M-1.07720166e-15,0 L-1.07720166e-15,48.5370072 C-1.07720166e-15,50.4494002 1.56959284,52 3.50565983,52 L3.50565983,52 L24.494138,52 C26.4304072,52 28,50.4495999 28,48.5370072 L28,48.5370072 L28,0 L-1.07720166e-15,0 Z"
        id="try-free-path-1"
      ></path>
      <polygon
        id="try-free-path-3"
        points="0 0 29.6289038 0 29.6289038 59.9412871 0 59.9412871"
      ></polygon>
      <polygon id="try-free-path-5" points="0 64 99 64 99 0 0 0"></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="For-those-who-are-referred-for-PC."
        transform="translate(-78.000000, -461.000000)"
      >
        <g id="Group-10" transform="translate(78.000000, 461.000000)">
          <g
            id="Rectangle-2-Copy-3"
            transform="translate(10.000000, 0.000000)"
            fill="#FFEDED"
          >
            <rect
              id="Rectangle"
              x="0"
              y="0"
              width="80"
              height="80"
              rx="40"
            ></rect>
          </g>
          <g id="Group-63-Copy-2" transform="translate(-0.000000, 8.000000)">
            <g id="Group-5" transform="translate(1.000000, 5.000000)">
              <path
                d="M3.50565983,0 L24.4943402,0 C26.4304072,0 28,1.55133098 28,3.46487228 L28,54.5351277 C28,56.448669 26.4304072,58 24.4943402,58 L3.50565983,58 C1.56959284,58 -7.18134443e-16,56.448669 -7.18134443e-16,54.5351277 L-7.18134443e-16,3.46487228 C-7.18134443e-16,1.55133098 1.56959284,0 3.50565983,0"
                id="Fill-1"
                fill="#E6E6E6"
              ></path>
              <path
                d="M3.50565983,57.0961524 C1.56959284,57.0961524 -7.18134443e-16,55.5446216 -7.18134443e-16,53.6310804 L-7.18134443e-16,6.42626622 L28,6.42626622 L28,53.6310804 C28,55.5448214 26.4304072,57.0961524 24.494138,57.0961524 L3.50565983,57.0961524 Z"
                id="Fill-3"
                fill="#FFFFFF"
              ></path>
            </g>
            <g id="Group-16" transform="translate(1.000000, 10.000000)">
              <mask id="try-free-mask-2" fill="white">
                <use xlinkHref="#try-free-path-1"></use>
              </mask>
              <g id="Clip-7"></g>
              <polygon
                id="Fill-6"
                fill="#C5DCFD"
                mask="url(#try-free-mask-2)"
                points="0.099047069 35.2135496 28.3925498 35.2135496 28.3925498 0.641124883 0.099047069 0.641124883"
              ></polygon>
              <polygon
                id="Fill-8"
                fill="#7ABEFF"
                mask="url(#try-free-mask-2)"
                points="19.1308605 13.9057012 0.0990672827 6.19662873 0.0990672827 -0.719573331 28.39257 -0.719573331 28.39257 7.4335545"
              ></polygon>
              <polygon
                id="Fill-9"
                fill="#7ABEFF"
                mask="url(#try-free-mask-2)"
                points="0.099047069 52.5283141 28.3925498 52.5283141 28.3925498 30.3700717 0.099047069 30.3700717"
              ></polygon>
              <polygon
                id="Fill-10"
                fill="#E3EFFD"
                mask="url(#try-free-mask-2)"
                points="19.1308605 13.9057012 19.1308605 27.7724477 28.3927722 35.2137692 28.3927722 7.4335545"
              ></polygon>
              <path
                d="M4.84342189,17.7620344 L11.1175484,17.7620344 C11.3409096,17.7620344 11.5218221,17.9407348 11.5218221,18.1613648 L11.5218221,30.3700917 L4.43914814,30.3700917 L4.43914814,18.1613648 C4.43914814,17.9407348 4.62006064,17.7620344 4.84342189,17.7620344"
                id="Fill-11"
                fill="#EED86D"
                mask="url(#try-free-mask-2)"
              ></path>
              <path
                d="M6.51689287,19.6071403 L9.4442391,19.6071403 C9.66760035,19.6071403 9.84851285,19.7858407 9.84851285,20.0064707 L9.84851285,22.7442796 C9.84851285,22.9649096 9.66760035,23.1436099 9.4442391,23.1436099 L6.51689287,23.1436099 C6.29353162,23.1436099 6.11261912,22.9649096 6.11261912,22.7442796 L6.11261912,20.0064707 C6.11261912,19.7858407 6.29353162,19.6071403 6.51689287,19.6071403"
                id="Fill-12"
                fill="#FFFFFF"
                mask="url(#try-free-mask-2)"
              ></path>
              <polygon
                id="Fill-13"
                fill="#FFFFFF"
                mask="url(#try-free-mask-2)"
                points="21.0642795 17.4503571 21.0642795 26.3244759 24.8116951 28.231478 24.8116951 16.44085"
              ></polygon>
              <polygon
                id="Fill-14"
                fill="#E3EFFD"
                mask="url(#try-free-mask-2)"
                points="16.6401299 30.3700917 28.39257 46.5709241 30.7996159 45.1674776 30.7996159 42.484377 16.6401299 27.7722481"
              ></polygon>
              <polygon
                id="Fill-15"
                fill="#7ABEFF"
                mask="url(#try-free-mask-2)"
                points="19.1308605 27.7722481 16.6403321 27.7722481 30.7998181 42.484377 30.7998181 36.187137"
              ></polygon>
            </g>
            <g id="Group-62">
              <path
                d="M28.7205751,9.68041298 L0.908529499,9.68041298 L0.908529499,8.43717548 C0.908529499,6.51935801 2.4675862,4.96456027 4.39065704,4.96456027 L25.2382468,4.96456027 C27.1613176,4.96456027 28.7205751,6.51935801 28.7205751,8.43717548 L28.7205751,9.68041298 Z"
                id="Fill-17"
                fill="#FFFFFF"
              ></path>
              <g id="Group-21" transform="translate(0.000000, 4.058713)">
                <mask id="try-free-mask-4" fill="white">
                  <use xlinkHref="#try-free-path-3"></use>
                </mask>
                <g id="Clip-20"></g>
                <path
                  d="M27.8120456,55.5628445 C27.8120456,56.9804837 26.6597692,58.1296124 25.2382468,58.1296124 L25.2382468,58.1296124 L4.39065704,58.1296124 C2.96913464,58.1296124 1.81685822,56.9804837 1.81685822,55.5628445 L1.81685822,4.3784626 C1.81685822,2.96082345 2.96913464,1.81169477 4.39065704,1.81169477 L4.39065704,1.81169477 L25.2382468,1.81169477 C26.6597692,1.81169477 27.8120456,2.96082345 27.8120456,4.3784626 L27.8120456,55.5628445 Z M25.2384475,0 L4.39065704,0 C1.96583698,0 0,1.96046679 0,4.37866283 L0,55.5628445 C0,57.9810406 1.96583698,59.9413071 4.39065704,59.9413071 L25.2384475,59.9413071 C27.6632676,59.9413071 29.6289038,57.9810406 29.6289038,55.5628445 L29.6289038,4.37866283 C29.6289038,1.96046679 27.6634684,0 25.2386483,0 L25.2384475,0 Z"
                  id="Fill-19"
                  fill="#3F4448"
                  mask="url(#try-free-mask-4)"
                ></path>
              </g>
              <path
                d="M3.95396054,7.58621156 C4.23264318,7.58621156 4.45872146,7.81167225 4.45872146,8.08959359 C4.45872146,8.36751494 4.23264318,8.59297563 3.95396054,8.59297563 C3.6752779,8.59297563 3.44919962,8.36751494 3.44919962,8.08959359 C3.44919962,7.81167225 3.6752779,7.58621156 3.95396054,7.58621156"
                id="Fill-22"
                fill="#CCCCCC"
              ></path>
              <path
                d="M5.56903486,7.58621156 C5.8477175,7.58621156 6.07379578,7.81167225 6.07379578,8.08959359 C6.07379578,8.36751494 5.8477175,8.59297563 5.56903486,8.59297563 C5.29035222,8.59297563 5.06427394,8.36751494 5.06427394,8.08959359 C5.06427394,7.81167225 5.29035222,7.58621156 5.56903486,7.58621156"
                id="Fill-24"
                fill="#CCCCCC"
              ></path>
              <path
                d="M7.1839084,7.58621156 C7.46259104,7.58621156 7.68866932,7.81167225 7.68866932,8.08959359 C7.68866932,8.36751494 7.46259104,8.59297563 7.1839084,8.59297563 C6.90522576,8.59297563 6.67914748,8.36751494 6.67914748,8.08959359 C6.67914748,7.81167225 6.90522576,7.58621156 7.1839084,7.58621156"
                id="Fill-26"
                fill="#CCCCCC"
              ></path>
              <path
                d="M34.5124958,3.2373632 C36.2624943,3.2373632 37.6812058,4.65219911 37.6812058,6.39741701"
                id="Stroke-28"
                stroke="#EA5851"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M34.5124958,0.45436536 C37.8036816,0.45436536 40.471847,3.115242 40.471847,6.39743704"
                id="Stroke-30"
                stroke="#EA5851"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M34.5124757,5.49857773 C35.0102094,5.49857773 35.4137772,5.90104308 35.4137772,6.39741701 C35.4137772,6.89379095 35.0102094,7.2962563 34.5124757,7.2962563 C34.0147421,7.2962563 33.6111743,6.89379095 33.6111743,6.39741701 C33.6111743,5.90104308 34.0147421,5.49857773 34.5124757,5.49857773"
                id="Fill-32"
                fill="#EA5851"
              ></path>
              <mask id="mask-6" fill="white">
                <use xlinkHref="#try-free-path-5"></use>
              </mask>
              <g id="Clip-35"></g>
              <polygon
                id="Fill-34"
                fill="#E7F0FE"
                mask="url(#try-free-mask-6)"
                points="41.4596645 49.8716641 93.1747699 49.8716641 93.1747699 15.5049276 41.4596645 15.5049276"
              ></polygon>
              <polygon
                id="Fill-36"
                fill="#FFFFFF"
                mask="url(#try-free-mask-6)"
                points="41.3903954 19.5135626 93.2438382 19.5135626 93.2438382 16.2199543 41.3903954 16.2199543"
              ></polygon>
              <path
                d="M44.1415031,17.4580058 C44.3677821,17.4580058 44.551295,17.6410174 44.551295,17.8666783 C44.551295,18.0923393 44.3677821,18.2753509 44.1415031,18.2753509 C43.915224,18.2753509 43.7317111,18.0923393 43.7317111,17.8666783 C43.7317111,17.6410174 43.915224,17.4580058 44.1415031,17.4580058"
                id="Fill-37"
                fill="#CCCCCC"
                mask="url(#try-free-mask-6)"
              ></path>
              <path
                d="M45.4527972,17.4580058 C45.6790763,17.4580058 45.8625892,17.6410174 45.8625892,17.8666783 C45.8625892,18.0923393 45.6790763,18.2753509 45.4527972,18.2753509 C45.2265182,18.2753509 45.0430053,18.0923393 45.0430053,17.8666783 C45.0430053,17.6410174 45.2265182,17.4580058 45.4527972,17.4580058"
                id="Fill-38"
                fill="#CCCCCC"
                mask="url(#try-free-mask-6)"
              ></path>
              <path
                d="M46.7642922,17.4580058 C46.9905712,17.4580058 47.1740842,17.6410174 47.1740842,17.8666783 C47.1740842,18.0923393 46.9905712,18.2753509 46.7642922,18.2753509 C46.5380131,18.2753509 46.3545002,18.0923393 46.3545002,17.8666783 C46.3545002,17.6410174 46.5380131,17.4580058 46.7642922,17.4580058"
                id="Fill-39"
                fill="#CCCCCC"
                mask="url(#try-free-mask-6)"
              ></path>
              <path
                d="M92.409015,49.1566574 L42.2254595,49.1566574 L42.2254595,16.2207753 L92.409015,16.2207753 L92.409015,49.1566574 Z M92.0861608,14.6107136 L42.548113,14.6107136 C41.4787587,14.6107136 40.6117907,15.4753133 40.6117907,16.5417464 L40.6117907,50.7659181 L94.0226839,50.7659181 L94.0226839,16.5411457 C94.0226839,15.4749129 93.1553143,14.6107136 92.0861608,14.6107136 L92.0861608,14.6107136 Z"
                id="Fill-40"
                fill="#CCCCCC"
                mask="url(#try-free-mask-6)"
              ></path>
              <path
                d="M35.6338521,50.7658981 L99.0000201,50.7658981 L99.0000201,51.8541564 C99.0000201,52.7343741 98.2844402,53.4479992 97.4018113,53.4479992 L37.2320609,53.4479992 C36.349432,53.4479992 35.6338521,52.7343741 35.6338521,51.8541564 L35.6338521,50.7658981 Z"
                id="Fill-41"
                fill="#B3B3B3"
                mask="url(#try-free-mask-6)"
              ></path>
              <path
                d="M48.6281129,23.635929 L56.6844103,23.635929 L56.6844103,23.0324312 L48.6281129,23.0324312 L48.6281129,23.635929 Z M48.6281129,22.5022182 L59.3013769,22.5022182 L59.3013769,21.8987204 L48.6281129,21.8987204 L48.6281129,22.5022182 Z"
                id="Fill-42"
                fill="#CCDBFF"
                mask="url(#try-free-mask-6)"
              ></path>
              <path
                d="M44.5925754,22.4629127 C44.7612306,21.5638732 45.628801,20.9715884 46.5303032,21.1397829 C47.4318054,21.3081776 48.0257126,22.173378 47.8570574,23.0722173 C47.6882014,23.9712568 46.8206311,24.5635416 45.9193296,24.3953471 C45.0178274,24.2271526 44.4239202,23.3619523 44.5925754,22.4629127"
                id="Fill-43"
                fill="#3998FE"
                mask="url(#try-free-mask-6)"
              ></path>
              <polygon
                id="Fill-44"
                fill="#FFFFFF"
                mask="url(#try-free-mask-6)"
                points="57.2371577 42.0305979 68.963312 42.0305979 68.963312 41.5722679 57.2371577 41.5722679"
              ></polygon>
              <polygon
                id="Fill-45"
                fill="#FFFFFF"
                mask="url(#try-free-mask-6)"
                points="57.2371577 43.5773864 68.963312 43.5773864 68.963312 43.1190564 57.2371577 43.1190564"
              ></polygon>
              <polygon
                id="Fill-46"
                fill="#FFFFFF"
                mask="url(#try-free-mask-6)"
                points="57.2371577 45.1241748 68.963312 45.1241748 68.963312 44.6658449 57.2371577 44.6658449"
              ></polygon>
              <polygon
                id="Fill-47"
                fill="#FFFFFF"
                mask="url(#try-free-mask-6)"
                points="57.2371577 46.6707631 64.1516193 46.6707631 64.1516193 46.2124331 57.2371577 46.2124331"
              ></polygon>
              <polygon
                id="Fill-48"
                fill="#FFFFFF"
                mask="url(#try-free-mask-6)"
                points="75.4495099 42.0305979 87.1756642 42.0305979 87.1756642 41.5722679 75.4495099 41.5722679"
              ></polygon>
              <polygon
                id="Fill-49"
                fill="#FFFFFF"
                mask="url(#try-free-mask-6)"
                points="75.4495099 43.5773864 87.1756642 43.5773864 87.1756642 43.1190564 75.4495099 43.1190564"
              ></polygon>
              <polygon
                id="Fill-50"
                fill="#FFFFFF"
                mask="url(#try-free-mask-6)"
                points="75.4495099 45.1241748 87.1756642 45.1241748 87.1756642 44.6658449 75.4495099 44.6658449"
              ></polygon>
              <polygon
                id="Fill-51"
                fill="#FFFFFF"
                mask="url(#try-free-mask-6)"
                points="75.4495099 46.6707631 82.3639716 46.6707631 82.3639716 46.2124331 75.4495099 46.2124331"
              ></polygon>
              <polygon
                id="Fill-52"
                fill="#EED86D"
                mask="url(#try-free-mask-6)"
                points="55.6967735 39.0369365 57.4815069 39.0369365 57.4815069 32.4917686 55.6967735 32.4917686"
              ></polygon>
              <polygon
                id="Fill-53"
                fill="#EED86D"
                mask="url(#try-free-mask-6)"
                points="52.7131827 39.0369365 54.4979161 39.0369365 54.4979161 30.75456 52.7131827 30.75456"
              ></polygon>
              <polygon
                id="Fill-54"
                fill="#EED86D"
                mask="url(#try-free-mask-6)"
                points="49.7295919 39.0371367 51.5143253 39.0371367 51.5143253 35.9663861 49.7295919 35.9663861"
              ></polygon>
              <polygon
                id="Fill-55"
                fill="#EED86D"
                mask="url(#try-free-mask-6)"
                points="46.7460011 39.0369365 48.5307346 39.0369365 48.5307346 37.3559929 46.7460011 37.3559929"
              ></polygon>
              <polygon
                id="Fill-56"
                fill="#EED86D"
                mask="url(#try-free-mask-6)"
                points="61.6639551 39.0369365 63.4486885 39.0369365 63.4486885 25.542934 61.6639551 25.542934"
              ></polygon>
              <polygon
                id="Fill-57"
                fill="#EED86D"
                mask="url(#try-free-mask-6)"
                points="58.6803643 39.0369365 60.4650977 39.0369365 60.4650977 27.2801427 58.6803643 27.2801427"
              ></polygon>
              <polygon
                id="Fill-58"
                fill="#DD5A4F"
                mask="url(#try-free-mask-6)"
                points="67.6311367 39.0369365 69.4158701 39.0369365 69.4158701 30.75456 67.6311367 30.75456"
              ></polygon>
              <polygon
                id="Fill-59"
                fill="#DD5A4F"
                mask="url(#try-free-mask-6)"
                points="64.6475459 39.0369365 66.4322793 39.0369365 66.4322793 29.0173513 64.6475459 29.0173513"
              ></polygon>
              <path
                d="M81.3126875,25.8867716 C84.858663,25.8867716 87.7334311,28.7534862 87.7334311,32.2899753 C87.7334311,35.8264643 84.858663,38.693179 81.3126875,38.693179 C77.7665111,38.693179 74.8919439,35.8264643 74.8919439,32.2899753 C74.8919439,28.7534862 77.7665111,25.8867716 81.3126875,25.8867716"
                id="Fill-60"
                fill="#EED86D"
                mask="url(#try-free-mask-6)"
              ></path>
              <path
                d="M81.3126875,32.2899553 L87.7334311,32.2899553 C87.7332303,28.7534662 84.8582615,25.8867516 81.3120851,25.8869518 C77.7659088,25.887152 74.8913415,28.7540669 74.8915423,32.290556 C74.8917431,35.8272453 77.7667119,38.6939599 81.3128883,38.6937597 C82.1736321,38.6935594 83.0257424,38.5209599 83.8182211,38.1859725 L81.3126875,32.2899553 Z"
                id="Fill-61"
                fill="#7ABEFF"
                mask="url(#try-free-mask-6)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

TryFree.displayName = 'TryFree';

export default TryFree;
