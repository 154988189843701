import { S3Object } from '@r360-tours/graph';

type S3ObjectKeys =
  | 'original'
  | 'side-1536-{f}'
  | 'side-1536-b'
  | 'side-1536-d'
  | 'side-1536-f'
  | 'side-1536-l'
  | 'side-1536-r'
  | 'side-1536-u'
  | 'th-320'
  | 'th-640'
  | 'th-1280'
  | 'th-2048'
  | 'th-4096';

export default function getS3Url(
  file: S3Object,
  thumbnail?: S3ObjectKeys,
): string {
  let key = file.key;

  if (thumbnail && file.bucket !== process.env.S3_UPLOAD_BUCKET) {
    key = file.key.replace(/\/original$/, `/${thumbnail}`);
  }

  return `https://s3-${file.region}.amazonaws.com/${file.bucket}/${key}`;
}
