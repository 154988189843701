import { i18n } from '@r360-tours/core';
import {
  translationEn,
  translationEs,
  translationFr,
  translationJa,
} from './locales';

const resources: i18n.Resource = {
  en: { translation: translationEn },
  es: { translation: translationEs },
  fr: { translation: translationFr },
  ja: { translation: translationJa },
};

export default resources;
