import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const TourPathMini: React.FC<Props> = ({ ...props }) => (
  <SvgIcon viewBox="0 0 27 27" {...props}>
    <g id="Tours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Tour">
        <rect
          id="Rectangle"
          fillOpacity="0.4"
          fill="#000000"
          x="0"
          y="0"
          width="27"
          height="25"
          rx="2"
        ></rect>
        <circle
          id="Oval"
          stroke="#FFFFFF"
          strokeWidth="1.5"
          cx="13.68"
          cy="12.68"
          r="7.65"
        ></circle>
        <polyline
          id="Path-2"
          stroke="#FFFFFF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(13.680000, 12.400000) scale(-1, 1) rotate(-90.000000) translate(-13.680000, -12.400000) "
          points="11.72 16.32 15.64 12.3991495 11.72 8.48"
        ></polyline>
      </g>
    </g>
  </SvgIcon>
);

TourPathMini.displayName = 'TourPathMini';

export default TourPathMini;
