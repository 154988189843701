import * as React from 'react';
import * as Styled from './button-group.styled';

export const ButtonGroup: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = ({ children, ...props }) => (
  <Styled.ButtonGroup {...props}>{children}</Styled.ButtonGroup>
);

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
