import * as React from 'react';
import getMapQueryString from '@r360-tours/core/lib/get-map-query-string';
import { Address } from '@r360-tours/graph';
import Map from './map';

interface Props {
  address: Address;
  height?: string | number;
  width?: string | number;
}

const MapContainer: React.FunctionComponent<Props> = ({
  address,
  ...props
}) => {
  const mapUrl = `https://www.google.com/maps/embed/v1/place?${getMapQueryString(
    address,
  )}`;
  return <Map {...props} src={mapUrl} />;
};

MapContainer.displayName = 'MapContainer';

export default MapContainer;
