import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Sync: React.FC<Props> = ({ color = '#000000', ...props }) => (
  <SvgIcon viewBox="0 0 36 36" {...props} spinDirection="reverse">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(8.000000, 9.000000)" stroke={color}>
        <polyline points="0.552742105 14.1438316 0.552742105 10.7175158 4.20011053 10.7175158" />
        <path d="M0.552742105,10.7179579 L5.08432105,14.6969053 C7.18432105,16.6852737 10.2790579,17.5705895 13.1527421,16.9074316 C16.0264263,16.2442737 18.4580053,14.2548 19.4527421,11.6021684" />
        <polyline points="20.3368421 3.53374737 20.3368421 6.96006316 16.6894737 6.96006316" />
        <path d="M20.3368421,6.95962105 L15.8052632,2.98067368 C13.7052632,0.9912 10.6105263,0.106989474 7.73684211,0.770147368 C4.86315789,1.43330526 2.43157895,3.42277895 1.43684211,6.07541053" />
      </g>
    </g>
  </SvgIcon>
);

Sync.displayName = 'Sync';

export default Sync;
