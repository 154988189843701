import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Info: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(7.000000, 7.000000)"
        stroke={props.color || 'currentColor'}
        strokeWidth="1.5"
      >
        <path d="M10.75,0.75 C16.272,0.75 20.75,5.227 20.75,10.75 C20.75,16.272 16.272,20.75 10.75,20.75 C5.227,20.75 0.75,16.272 0.75,10.75 C0.75,5.227 5.227,0.75 10.75,0.75 Z" />
        <line x1="10.75" y1="14.75" x2="10.75" y2="10.75" />
        <line x1="10.75" y1="8.25" x2="10.75" y2="6.75" />
      </g>
    </g>
  </SvgIcon>
);

Info.displayName = 'Info';

export default Info;
