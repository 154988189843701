import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Email: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={props.color} strokeWidth="1.5">
        <g transform="translate(6.000000, 8.000000)">
          <path d="M2.4,0 L21.6,0 C22.92,0 24,1.08 24,2.4 L24,16.8 C24,18.12 22.92,19.2 21.6,19.2 L2.4,19.2 C1.08,19.2 0,18.12 0,16.8 L0,2.4 C0,1.08 1.08,0 2.4,0 Z" />
          <polyline points="24 2.4 12 10.8 0 2.4" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

Email.displayName = 'Email';

export default Email;
