import * as React from 'react';
import * as Styled from './media.styled';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The aspect ratio you want the media to render in.
   *
   * @default 2 / 1
   */
  aspectRatio?: number;

  /**
   * The image source.
   */
  image: string | undefined;

  /**
   * Will be used as the alt text for the media.
   */
  title: string;
}

export const CardMedia: React.FC<Props> = ({
  aspectRatio = 2 / 1,
  ...props
}) => <Styled.Media aspectRatio={aspectRatio} {...props} />;

CardMedia.displayName = 'CardMedia';

export default CardMedia;
