import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Star: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 36 36">
    <g id="Tours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Atom-/-Star" fill="currentColor">
        <polygon
          id="Star"
          points="18 22.0194847 12.1221475 26.0901699 14.1772428 19.2420891 8.48943484 14.9098301 15.6374061 14.7481685 18 8 20.3625939 14.7481685 27.5105652 14.9098301 21.8227572 19.2420891 23.8778525 26.0901699"
        ></polygon>
      </g>
    </g>
  </SvgIcon>
);

Star.displayName = 'Star';

export default Star;
