import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Close: React.FC<Props> = ({ color = '#000000', ...props }) => (
  <SvgIcon viewBox="0 0 32 32" color={color} {...props}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Close" stroke={color} strokeWidth="1.5">
        <polyline
          id="Path-2"
          transform="translate(11.500000, 16.500000) scale(-1, 1) rotate(-180.000000) translate(-11.500000, -16.500000) "
          points="7 25 16 16.4981558 7 8"
        ></polyline>
        <polyline
          id="Path-2"
          transform="translate(20.500000, 16.500000) rotate(-180.000000) translate(-20.500000, -16.500000) "
          points="16 25 25 16.4981558 16 8"
        ></polyline>
      </g>
    </g>
  </SvgIcon>
);

Close.displayName = 'Close';

export default Close;
