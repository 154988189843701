import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Facebook: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 22 21" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-911.000000, -437.000000)">
        <g transform="translate(793.000000, 247.000000)">
          <g transform="translate(107.000000, 178.000000)">
            <g transform="translate(9.000000, 9.000000)">
              <g transform="translate(2.437500, 3.250000)">
                <path
                  d="M10.5625,0 C4.72898804,0 0,4.55964314 0,10.1842572 C0,15.2675144 3.86255359,19.4807735 8.91210937,20.2447917 L8.91210937,13.1281441 L6.23022461,13.1281441 L6.23022461,10.1842572 L8.91210937,10.1842572 L8.91210937,7.94053807 C8.91210937,5.3881086 10.4890164,3.97822548 12.9017224,3.97822548 C14.0573466,3.97822548 15.2661133,4.17713676 15.2661133,4.17713676 L15.2661133,6.68341881 L13.9342068,6.68341881 C12.6220844,6.68341881 12.2128906,7.46846194 12.2128906,8.27385369 L12.2128906,10.1842572 L15.142334,10.1842572 L14.6740356,13.1281441 L12.2128906,13.1281441 L12.2128906,20.2447917 C17.2624464,19.4807735 21.125,15.2675144 21.125,10.1842572 C21.125,4.55964314 16.396012,0 10.5625,0 Z"
                  fill={props.color}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Facebook.displayName = 'Facebook';

export default Facebook;
