import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Mail: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 20 16" {...props}>
    <defs>
      <filter id="mail-filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1116.000000, -439.000000)">
        <g transform="translate(793.000000, 247.000000)">
          <g transform="translate(107.000000, 178.000000)">
            <g transform="translate(204.000000, 0.000000)">
              <g
                transform="translate(9.000000, 9.000000)"
                filter="url(#mail-filter-1)"
              >
                <g
                  id="iconmonstr-email-3"
                  transform="translate(3.250000, 5.687500)"
                  fill={props.color}
                  fillRule="nonzero"
                >
                  <path d="M9.75,7.8918125 L0.0121875,0 L19.4878125,0 L9.75,7.8918125 Z M9.75,9.9831875 L0,2.081625 L0,14.625 L19.5,14.625 L19.5,2.081625 L9.75,9.9831875 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Mail.displayName = 'Mail';

export default Mail;
