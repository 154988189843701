import 'dayjs/locale/ja';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import * as React from 'react';
import { initReactI18next } from 'react-i18next';
import App, { AppContext } from 'next/app';
import dayjs from 'dayjs';
import i18n, { Resource } from 'i18next';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';
import { LanguageDetector as ServerLanguageDetector } from 'i18next-express-middleware';

export function changeLocale(locale: string): void {
  void i18n.changeLanguage(locale);

  if (typeof window !== 'undefined') {
    document.cookie = locale
      ? `locale=${locale}; domain=${process.env.AUTH_COOKIE_DOMAIN}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
      : `locale=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}

export default function WithI18next(
  Application: typeof App,
  resources: Resource,
): typeof App {
  void i18n
    .use(initReactI18next)
    .use(
      typeof window !== 'undefined'
        ? new BrowserLanguageDetector()
        : new ServerLanguageDetector(),
    )
    .init({
      detection: {
        caches: [],
        cookieDomain: process.env.AUTH_COOKIE_DOMAIN,
        lookupCookie: 'locale',
        lookupHeader: 'accept-language',
        order:
          typeof window !== 'undefined'
            ? ['cookie', 'navigator']
            : ['cookie', 'header'],
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
        format: function (value, format, lng) {
          if (value instanceof Date)
            return dayjs(value)
              .locale(lng || 'en')
              .format(format);
          return value;
        },
      },
      lng: typeof window !== 'undefined' ? undefined : 'en',
      resources,
    });
  return class WithI18next extends App {
    public static async getInitialProps(context: AppContext) {
      let initialProps = { pageProps: {} };
      if (Application.getInitialProps) {
        initialProps = await Application.getInitialProps(context);
      }

      if (context.ctx.req) {
        // NOTE:
        // Use i18next-express-middleware/LanguageDetector directly to extract from req.headers['accept-language'].
        // See https://github.com/i18next/i18next-express-middleware/blob/master/src/index.js
        const lang = i18n.services.languageDetector.detect(
          context.ctx.req,
          null,
        );
        await i18n.changeLanguage(lang);
      }

      return initialProps;
    }
    public render() {
      return <Application {...this.props} />;
    }
  };
}
