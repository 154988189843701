import { CredentialsOptions } from 'aws-sdk/lib/credentials';

export const adminViewerQueryTypes = {
  accessKeyId: 'X-admin-accessKeyId',
  secretAccessKey: 'X-admin-secretAccessKey',
  sessionToken: 'X-admin-sessionToken',
};

export const extractIAMCredentials = (
  sourceUrl?: string,
): CredentialsOptions | undefined => {
  const url =
    !sourceUrl && typeof window !== 'undefined'
      ? window.location.href
      : sourceUrl;
  if (!url) {
    return undefined;
  }

  const splittedUrl = url.split('?');
  if (splittedUrl.length < 2) {
    return undefined;
  }

  const queryParams = new URLSearchParams(decodeURIComponent(splittedUrl[1]));

  const accessKeyId = queryParams.get(adminViewerQueryTypes.accessKeyId);
  const secretAccessKey = queryParams
    .get(adminViewerQueryTypes.secretAccessKey)
    ?.replace(/ /g, '+');
  const sessionToken = queryParams
    .get(adminViewerQueryTypes.sessionToken)
    ?.replace(/ /g, '+');

  return accessKeyId && secretAccessKey && sessionToken
    ? {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      }
    : undefined;
};
