import * as React from 'react';
import theme from '../theme/default';
import * as Styled from './circular-image.styled';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  /**
   * The diameter (in pixels) you want the image to render in.
   *
   * @default 108
   */
  diameter?: number;

  /**
   * Any valid image source.
   */
  image?: string | null;

  /**
   * The name to print below the image.
   */
  name?: string | null;

  /**
   * The type of avatar to use.
   *
   * @default user
   */
  type?: 'team' | 'user' | 'checkmark';

  /**
   * Only when type is team.
   * Whether a team is selected.
   */
  selected?: boolean;
}

export const CircularImage: React.FC<Props> = ({
  diameter = 108,
  image,
  name,
  type = 'user',
  children,
  selected,
  ...props
}) => {
  const border = React.useMemo(() => {
    let b = 'none';
    if (type === 'team') {
      b = '1px solid ';
      if (selected) {
        b += 'red';
      } else {
        b += theme.palette.primary.contrast;
      }
    }
    return b;
  }, [selected, type]);

  return (
    <Styled.Figure {...props}>
      {image ? (
        <Styled.Image
          alt={name || ''}
          height={diameter}
          src={image}
          width={diameter}
        />
      ) : (
        <Styled.Default
          style={{
            backgroundColor:
              type === 'user'
                ? theme.avatar.picture.background
                : type === 'team'
                ? theme.avatar.picture.background
                : theme.avatar.picture.background,
            border: border,
            height: diameter,
            width: diameter,
          }}
        >
          {children}
        </Styled.Default>
      )}
      {name && <Styled.Caption>{name}</Styled.Caption>}
    </Styled.Figure>
  );
};

CircularImage.displayName = 'CircularImage';

export default CircularImage;
