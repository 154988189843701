import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

const SlideInOut = keyframes`
  0% { 
    opacity: 0; 
    transform: translate(-50%, 0);
  }
  10% { 
    opacity: 1; 
    transform: translate(-50%, -110%);
  }
  75% {
    opacity: 1;
    transform: translate(-50%, -110%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -110%);
  }
`;

export const Copied = styled.span`
  animation: ${SlideInOut} 2s linear;
  position: absolute;
  top: 0;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, 0);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props): string => props.theme.palette.primary.contrast};
  background: ${(props): string => props.theme.palette.text.primary};
  min-width: 150px;
  height: 30px;
  padding: 8px;
  border-radius: 6px;
  white-space: nowrap;
`;
