import * as React from 'react';
import classNames from 'classnames';
import * as Styled from './header.styled';

export interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Turns off the bottom border on the header
   *
   * @default false
   */
  noBorder?: boolean;
  /**
   * Sets the display variation of the header
   *
   * @default default
   */
  variant?: 'primary' | 'secondary' | 'default';
}

const Header: React.FunctionComponent<HeaderProps> = ({
  children,
  className,
  noBorder = false,
  variant = 'default',
  ...props
}) => {
  const headerClasses = classNames(className, {
    noBorder,
    primary: variant === 'primary',
    secondary: variant === 'secondary',
  });
  return (
    <Styled.Header className={headerClasses} {...props}>
      {children}
    </Styled.Header>
  );
};

Header.displayName = 'Header';

export default Header;
