import * as React from 'react';
import classNames from 'classnames';
import * as Styled from './menu-item.styled';

export interface MenuItemProps extends React.HTMLAttributes<HTMLLIElement> {
  /**
   * Adds the alert class to the menu item
   *
   * @default false
   */
  alert?: boolean;

  /**
   * Indicates the menu item is disabled
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * Indicates the mnu item is selected
   *
   * @default false
   */
  selected?: boolean;
}

const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  alert = false,
  children,
  className,
  selected = false,
  ...props
}) => (
  <Styled.MenuItem
    {...props}
    className={classNames(className, { alert, selected })}
  >
    {children}
  </Styled.MenuItem>
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
