import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const minimize: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        stroke={props.color}
        transform="translate(16.000000, 16.000000) scale(-1, 1) translate(-16.000000, -16.000000) translate(8.000000, 8.000000)"
      >
        <path
          d="M14.7692308,15.5076923 L0.738461538,15.5076923 C0.330620529,15.5076923 0,15.1770714 0,14.7692308 L0,0.738461538 C0,0.330620529 0.330620529,0 0.738461538,0 L14.7692308,0 C15.1770714,0 15.5076923,0.330620529 15.5076923,0.738461538 L15.5076923,14.7692308 C15.5076923,15.1770714 15.1770714,15.5076923 14.7692308,15.5076923 Z"
          transform="translate(7.753846, 7.753846) scale(-1, 1) translate(-7.753846, -7.753846) "
        />
        <polyline
          transform="translate(12.430769, 12.430769) scale(-1, 1) translate(-12.430769, -12.430769) "
          points="9.35384615 9.35384615 15.5076923 9.35384615 15.5076923 15.5076923"
        />
        <line x1="7.69230769" y1="7.69230769" x2="3.07692308" y2="3.07692308" />
        <polyline
          transform="translate(6.584615, 6.584615) scale(-1, 1) rotate(-180.000000) translate(-6.584615, -6.584615) "
          points="5.47692308 5.47692308 7.69230769 5.47692308 7.69230769 7.69230769"
        />
      </g>
    </g>
  </SvgIcon>
);

minimize.displayName = 'minimize';

export default minimize;
