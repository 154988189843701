import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Logout: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Common"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Logout" stroke="#000000" strokeWidth="1.5">
        <g id="Group" transform="translate(7.000000, 7.000000)">
          <path
            d="M15,3.78888889 L15,2.44444444 C15,1.09388889 13.88,0 12.5,0 L2.5,0 C1.12,0 0,1.09388889 0,2.44444444 L0,19.5555556 C0,20.9048889 1.12,22 2.5,22 L12.5,22 C13.88,22 15,20.9048889 15,19.5555556 L15,18.2111111"
            id="Stroke-1"
          ></path>
          <g id="Group-5" transform="translate(9.000000, 8.000000)">
            <polyline
              id="Stroke-1"
              points="9.45454545 7 13 3.5 9.45454545 0"
            ></polyline>
            <line
              x1="5.376255e-13"
              y1="3.5"
              x2="13"
              y2="3.5"
              id="Stroke-3"
            ></line>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Logout.displayName = 'Logout';

export default Logout;
