import styled from 'styled-components';

export const Card = styled.figure`
  background-color: #ffffff;
  border-radius: 6px;
  display: inline-block;
  margin: 0 0 40px 40px;
  overflow: hidden;
  transition-duration: 250ms;
  transition-property: box-shadow;

  &:focus,
  &:focus-within,
  &:hover {
    box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.25);
    outline: none;
  }

  *:focus {
    outline: none;
  }
`;
