import * as React from 'react';
import Panorama from '../panorama';
import * as Styled from './error-screen.styled';

interface Props {
  backgroundImage: string;
  type?: 'default' | 'circularImage';
}

const ErrorScreen: React.FunctionComponent<Props> = ({
  backgroundImage,
  children,
  type = 'default',
}) => (
  <Styled.ErrorScreen>
    <Panorama
      autoRotate
      height="100%"
      src={backgroundImage}
      type="equirectangular"
      width="100%"
    />
    <Styled.Overlay>
      {type === 'default' ? (
        <Styled.Modal>{children}</Styled.Modal>
      ) : (
        <Styled.ModalCircularImage>{children}</Styled.ModalCircularImage>
      )}
    </Styled.Overlay>
  </Styled.ErrorScreen>
);

ErrorScreen.displayName = 'ErrorScreen';

export default ErrorScreen;
