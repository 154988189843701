import * as React from 'react';
import { Formik, FormikHelpers, Yup } from '@r360-tours/ui';
import Auth from '../../../../core/auth';
import ResetPasswordForm, { FormValues } from './reset-password-form';

const formSchema = Yup.object().shape({
  code: Yup.string().required('Validation.VerificationCodeRequired'),
  confirmPassword: Yup.string()
    .when('newPassword', (value: string, schema: Yup.Schema<string>) =>
      schema.oneOf([value], 'Validation.ConfirmPasswordDoesNotMatch'),
    )
    .required('Validation.ConfirmPasswordRequired'),
  newPassword: Yup.string()
    .min(6, 'Validation.PasswordTooShort')
    .max(99, 'Validation.PasswordTooLong')
    .required('Validation.NewPasswordRequired'),
  username: Yup.string()
    .email('Validation.EmailInvalid')
    .required('Validation.EmailRequired'),
});

interface Props {
  onSubmitted: () => void;
  username: string;
}

const ResetPasswordFormContainer: React.FunctionComponent<Props> = ({
  onSubmitted,
  username,
}) => {
  const onSubmit = React.useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        await Auth.forgotPasswordSubmit(
          values.username.toLowerCase(),
          values.code,
          values.newPassword,
        );
        onSubmitted();
      } catch (error) {
        actions.setStatus({
          errors: [error],
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [onSubmitted],
  );

  return (
    <Formik<FormValues>
      initialValues={{
        code: '',
        confirmPassword: '',
        newPassword: '',
        username,
      }}
      initialStatus={{ errors: [] }}
      onSubmit={onSubmit}
      validationSchema={formSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        status,
        touched,
      }) => (
        <ResetPasswordForm
          fieldErrors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          isValid={isValid}
          status={status}
          touched={touched}
          username={username}
        />
      )}
    </Formik>
  );
};

ResetPasswordFormContainer.displayName = 'ResetPasswordFormContainer';

export default ResetPasswordFormContainer;
