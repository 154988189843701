import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

const Text: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <g
      strokeWidth="1.5"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke={props.color || 'black'}
    >
      <path d="M18.42 13c0 1.105-.867 2-1.936 2H4.87L1 19V3c0-1.105.867-2 1.935-2h13.549c1.069 0 1.935.895 1.935 2v10zM15.159 6.414H4.074M15.159 10.505H4.074" />
    </g>
  </SvgIcon>
);
Text.displayName = 'Text';
export default Text;
