import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const ImageEnhancementStatus: React.FC<Props> = ({
  stroke,
  ...props
}) => (
  <SvgIcon {...props} width="36px" height="36px" viewBox="0 0 36 36">
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Enhancement-Icon" strokeWidth="1.5">
        <g id="Group" transform="translate(6.000000, 6.000000)">
          <path
            d="M19.8721,1.0269 L21.2791,3.5999 C21.3281,3.6889 21.4011,3.7629 21.4911,3.8109 L24.0631,5.2179 C24.4331,5.4199 24.4331,5.9499 24.0631,6.1519 L21.4911,7.5599 C21.4011,7.6089 21.3281,7.6819 21.2791,7.7709 L19.8721,10.3439 C19.6701,10.7139 19.1411,10.7139 18.9381,10.3439 L17.5311,7.7709 C17.4821,7.6819 17.4081,7.6089 17.3191,7.5599 L14.7461,6.1529 C14.3771,5.9499 14.3771,5.4209 14.7461,5.2189 L17.3191,3.8119 C17.4081,3.7629 17.4821,3.6889 17.5311,3.5999 L18.9381,1.0269 C19.1411,0.6579 19.6701,0.6579 19.8721,1.0269 Z"
            id="Stroke-1"
            stroke={stroke}
          ></path>
          <path
            d="M12.1465,9.3218 L13.3415,11.5068 C13.3825,11.5828 13.4455,11.6448 13.5215,11.6858 L15.7055,12.8808 C16.0185,13.0518 16.0185,13.5028 15.7055,13.6738 L13.5215,14.8688 C13.4455,14.9098 13.3825,14.9728 13.3415,15.0478 L12.1465,17.2328 C11.9755,17.5458 11.5255,17.5458 11.3535,17.2328 L10.1585,15.0478 C10.1175,14.9728 10.0545,14.9098 9.9795,14.8688 L7.7945,13.6738 C7.4805,13.5028 7.4805,13.0518 7.7945,12.8818 L9.9795,11.6868 C10.0545,11.6448 10.1175,11.5828 10.1585,11.5068 L11.3535,9.3218 C11.5255,9.0088 11.9755,9.0088 12.1465,9.3218 Z"
            id="Stroke-3"
            stroke={stroke}
          ></path>
          <path
            d="M22.75,12.148 L22.75,21.135 C22.75,22.863 21.336,24.277 19.607,24.277 L3.893,24.277 C2.164,24.277 0.75,22.863 0.75,21.135 L0.75,5.42 C0.75,3.691 2.164,2.277 3.893,2.277 L13.29,2.277"
            id="Stroke-5"
            stroke={stroke}
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ImageEnhancementStatus.displayName = 'ImageEnhancementStatus';

export default ImageEnhancementStatus;
