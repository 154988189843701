import * as React from 'react';
import * as Styled from './error-message.styled';

const ErrorMessage: React.FunctionComponent<
  React.HTMLAttributes<HTMLParagraphElement>
> = ({ children, ...props }) => {
  if (!children) {
    return null;
  }

  return <Styled.ErrorMessage {...props}>{children}</Styled.ErrorMessage>;
};

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
