import styled from 'styled-components';

export const Label = styled.p``;

export const Tooltip = styled.div`
  background-color: #ffffff;
  border-radius: 13px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 14px;

  ${Label} {
    margin: 0;
  }
`;
