import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const CloseMini: React.FC<Props> = ({ color = '#000000', ...props }) => (
  <SvgIcon viewBox="0 0 36 36" color={color} {...props}>
    <g
      id="Common"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Mini-Icon-/-Close" stroke={color} strokeWidth="1.5">
        <g id="Group-" transform="translate(11.000000, 11.000000)">
          <polyline
            id="Stroke-1"
            points="0.538461538 0.538461538 7 7 0.538461538 13.4615385"
          ></polyline>
          <polyline
            id="Stroke-3"
            points="13.4615385 0.538461538 7 7 13.4615385 13.4615385"
          ></polyline>
        </g>
      </g>
    </g>
  </SvgIcon>
);

CloseMini.displayName = 'CloseMini';

export default CloseMini;
