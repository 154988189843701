import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  padding-right: 1rem;

  @media all and (max-width: 402px) {
    margin-bottom: 10px;
  }
`;

export const UnsupportedMessage = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.palette.danger.main};
  border-radius: 4px;
  display: flex;
  min-width: 120px;
  padding: 0.5rem 1rem;
  position: fixed;
  top: 10px;
  left: 300px;
  right: 300px;
  z-index: 2;

  @media all and (max-width: 402px) {
    flex-direction: column;
  }

  ${Content},
  svg {
    color: ${(props) => props.theme.palette.danger.contrast};
  }
`;
