import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const ReferralFemale: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 170 170" {...props}>
    <defs>
      <path
        d="M170,85 C170,131.944385 131.944385,170 85,170 C38.0556148,170 0,131.944385 0,85 C0,38.0556148 38.0556148,0 85,0 C131.944385,0 170,38.0556148 170,85"
        id="referral-female-path-1"
      />
      <filter id="referral-female-filter-3">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 1.000000 0 0 0 0 0.654902 0 0 0 0 0.792157 0 0 0 1.000000 0"
        />
      </filter>
      <linearGradient
        x1="177.287388%"
        y1="263.996394%"
        x2="0%"
        y2="50%"
        id="referral-female-linearGradient-4"
      >
        <stop stopColor="#2222BA" offset="0%" />
        <stop stopColor="#EA5851" offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-734.000000, -259.000000)">
        <g transform="translate(272.000000, 128.000000)">
          <g transform="translate(462.000000, 131.000000)">
            <g>
              <mask id="referral-female-mask-2" fill="white">
                <use xlinkHref="#referral-female-path-1" />
              </mask>
              <use fill="#FFEDED" xlinkHref="#referral-female-path-1" />
              <g mask="url(#referral-female-mask-2)">
                <g transform="translate(23.946834, 20.073437)">
                  <g transform="translate(0.000000, 54.983579)">
                    <path
                      d="M10.1008886,24.1776293 L12.7090271,22.8971617 L1.63106171,0 C0.190845262,0.70733412 -0.408202357,2.45713341 0.293875565,3.90813223 L10.1008886,24.1776293 Z"
                      fill="#3A3553"
                    />
                  </g>
                  <g transform="translate(37.053166, 0.000000)">
                    <path
                      d="M1.61653559,33.3070919 C0.0588175755,26.1362455 -1.46664652,18.9193607 2.6398647,11.0408601 C5.96201908,4.66528932 11.7054171,0.997071316 18.353391,0.198082941 C37.1889508,-2.06596497 33.1623413,15.7456839 59.4881424,19.6017681 C67.6681779,20.7995081 75.2676427,21.9512097 82.0974115,26.0723858 C94.4294069,33.513522 99.0842349,45.4500816 90.9056655,64.8968349 C70.8796426,112.514611 21.7231933,72.0899579 15.6513917,62.7137254 C11.135109,55.7389133 2.61860643,37.9235517 1.61653559,33.3070919"
                      fill="#3A3553"
                    />
                  </g>
                  <g transform="translate(35.367296, 55.545961)">
                    <path
                      d="M32.3324627,0 C32.3324627,0 41.8804281,1.09140024 47.5912506,7.35063991 C53.302809,13.6091381 38.3597354,63.9210579 37.3220731,71.8870933 C36.2829389,79.8523872 45.8316402,98.4358489 45.8316402,98.4358489 C45.8316402,98.4358489 10.1537181,105.078413 0.461510342,98.6293648 C0.461510342,98.6293648 -0.576887927,41.3479055 0.461510342,35.6580921 C1.50064454,29.9675372 10.1537181,6.827183 12.2312506,4.55170248 C14.3080471,2.27548052 32.3324627,0 32.3324627,0"
                      fill="#989AFF"
                    />
                  </g>
                  <g transform="translate(49.984213, 35.827206)">
                    <path
                      d="M12.909697,0.323268005 C12.909697,0.323268005 12.984026,0.751079103 13.1187013,1.47843211 C13.7626407,4.96765053 17.9154978,22.2773176 17.9154978,22.2773176 L4.31255411,24.4749469 C4.31255411,24.4749469 3.28666667,16.7357922 2.68320346,13.6654876 C2.34246753,11.9334829 1.99731602,10.1770106 1.67277056,8.52656434 C0.751385281,3.83621251 0,0 0,0 L12.909697,0.323268005 Z"
                      fill="#FFA7CA"
                    />
                  </g>
                  <g transform="translate(41.883750, 5.230621)">
                    <path
                      d="M13.2445452,0.00705367421 C13.2445452,0.00705367421 18.5093937,0.742562529 22.3237227,5.70279866 C26.1380517,10.6630348 30.2489608,26.0019533 20.7738526,34.3416747 C11.2987443,42.6813961 1.93696945,33.2413772 0.549004081,23.2326735 C-0.839697218,13.2239698 -0.558571677,-0.35402779 13.2445452,0.00705367421"
                      fill="#FFA7CA"
                    />
                  </g>
                  <g transform="translate(65.445876, 19.626465)">
                    <path
                      d="M0.12144997,4.11563303 C-0.351753493,6.70622571 0.599804948,9.08180068 2.246082,9.42138037 C3.89309499,9.76170151 5.61075733,7.93701674 6.08322486,5.3471655 C6.55642832,2.75657282 5.60486988,0.380997846 3.95932876,0.0406767129 C2.31231577,-0.29890298 0.594653433,1.52504035 0.12144997,4.11563303"
                      fill="#FFA7CA"
                    />
                  </g>
                  <g transform="translate(39.933018, 3.264034)">
                    <path
                      d="M29.7419048,16.5763825 L0,19.7638347 L0,12.1136529 L4.92558442,3.70349469 L12.6565368,0 L20.2498701,0 C21.515671,0 30.3748052,7.01328453 30.3748052,7.01328453 L29.7419048,16.5763825 Z"
                      fill="#3A3553"
                    />
                  </g>
                  <g transform="translate(11.870581, 64.214766)">
                    <path
                      d="M69.3312987,3.40473607 C68.4106494,0.601350002 65.2557143,-0.748812926 62.5658874,0.42266287 C58.8796104,2.02862273 53.7899134,5.07594268 51.3039394,10.2979073 C47.1503463,19.0231776 32.6120346,48.9914563 28.1125541,50.1295672 C24.4005195,51.0682308 11.2693074,29.2958341 6.78307359,21.6397207 L0,26.8238718 C4.96164502,37.5599285 21.0461472,71.2739639 27.592987,71.752193 C34.8463203,72.2823228 76.6206926,25.603461 69.3312987,3.40473607"
                      fill="#989AFF"
                    />
                  </g>
                  <g transform="translate(4.208410, 68.598787)">
                    <path
                      d="M11.8903137,5.72444805 C10.3330842,2.49992385 5.65992405,-0.914409091 4.96741322,0.223701889 C4.2749024,1.36181287 7.56304093,4.2067196 7.56304093,4.77614581 C7.56304093,5.34483058 6.17875522,5.72444805 4.2749024,4.58633707 C2.37104959,3.44822609 1.34001063,1.14679516 1.34001063,1.14679516 C1.34001063,1.14679516 -1.78254349,6.86255903 1.50559504,11.9836877 C4.7944695,17.1048164 6.17875522,19.1912296 6.17875522,19.1912296 C6.17875522,19.1912296 6.7218721,20.4042261 7.66239158,22.4402214 L14.4454652,17.2553288 C13.4931708,15.6300915 12.9287119,14.6387857 12.9287119,14.6387857 C12.9287119,14.6387857 13.4475431,8.94897225 11.8903137,5.72444805 Z"
                      fill="#FFA7CA"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g filter="url(#referral-female-filter-3)">
              <g transform="translate(30.000000, 31.384615) scale(-1, 1) translate(-30.000000, -31.384615) translate(10.000000, 15.384615)">
                <path
                  d="M12.2427692,23.4495385 C11.5993846,23.4495385 11.0769231,23.972 11.0769231,24.6153846 C11.0769231,25.2587692 11.5993846,25.7812308 12.2427692,25.7812308 C15.0313846,25.7849231 17.292,28.0455385 17.2956923,30.8341538 C17.2956923,31.4775385 17.8181538,32 18.4615385,32 C19.1049231,32 19.6273846,31.4775385 19.6273846,30.8341538 C19.6301538,28.0455385 21.8907692,25.7849231 24.6803077,25.7812308 C25.3236923,25.7812308 25.8461538,25.2587692 25.8461538,24.6153846 C25.8461538,23.972 25.3236923,23.4495385 24.6803077,23.4495385 C21.8907692,23.4467692 19.6301538,21.1861538 19.6273846,18.3966154 C19.6273846,17.7532308 19.1049231,17.2307692 18.4615385,17.2307692 C17.8181538,17.2307692 17.2956923,17.7532308 17.2956923,18.3966154 C17.292,21.1861538 15.0313846,23.4467692 12.2427692,23.4495385"
                  fill="url(#referral-female-linearGradient-4)"
                />
                <path
                  d="M3.28205128,29.5384615 C3.28205128,29.9913846 2.91446154,30.3589744 2.46153846,30.3589744 C2.00861538,30.3589744 1.64102564,29.9913846 1.64102564,29.5384615 C1.64102564,29.0855385 2.00861538,28.7179487 2.46153846,28.7179487 C2.91446154,28.7179487 3.28205128,29.0855385 3.28205128,29.5384615 M0,29.5384615 C0,30.8955897 1.10441026,32 2.46153846,32 C3.81866667,32 4.92307692,30.8955897 4.92307692,29.5384615 C4.92307692,28.1813333 3.81866667,27.0769231 2.46153846,27.0769231 C1.10441026,27.0769231 0,28.1813333 0,29.5384615"
                  fill="#A74589"
                />
                <path
                  d="M38.6074893,14.3160137 C39.0369537,14.3160137 39.3846154,13.9674999 39.3846154,13.5388876 C39.3846154,13.1094232 39.0369537,12.7617615 38.6074893,12.7617615 C36.7481809,12.7592051 35.2407949,11.2526712 35.2390906,9.39251073 C35.2390906,8.96389841 34.8905768,8.61538462 34.4619645,8.61538462 C34.0325001,8.61538462 33.6839863,8.96389841 33.6839863,9.39251073 C33.6822821,11.2526712 32.1757481,12.7592051 30.3155877,12.7617615 C29.8869753,12.7617615 29.5384615,13.1094232 29.5384615,13.5388876 C29.5384615,13.9674999 29.8869753,14.3160137 30.3155877,14.3160137 C32.1757481,14.3177179 33.6822821,15.825104 33.6839863,17.6844123 C33.6839863,18.1138768 34.0325001,18.4615385 34.4619645,18.4615385 C34.8905768,18.4615385 35.2390906,18.1138768 35.2390906,17.6844123 C35.2407949,15.825104 36.7481809,14.3177179 38.6074893,14.3160137"
                  fill="#D26E99"
                />
                <g transform="translate(18.461538, 0.000000)" fill="#A74589">
                  <path d="M7.09764103,4.00806838 C5.65121368,4.00615385 4.47952137,2.83446154 4.47760684,1.38803419 C4.47760684,1.05490598 4.20670085,0.784 3.87357265,0.784 C3.53948718,0.784 3.2685812,1.05490598 3.2685812,1.38803419 C3.26666667,2.83446154 2.09497436,4.00615385 0.648547009,4.00806838 C0.314461538,4.00806838 0.0445128205,4.27897436 0.0445128205,4.61305983 C0.0445128205,4.94618803 0.314461538,5.21709402 0.648547009,5.21709402 C2.09497436,5.21900855 3.26666667,6.39070085 3.2685812,7.83712821 C3.2685812,8.17121368 3.53948718,8.44211966 3.87357265,8.44211966 C4.20670085,8.44211966 4.47760684,8.17121368 4.47760684,7.83712821 C4.47952137,6.39070085 5.65121368,5.21900855 7.09764103,5.21709402 C7.4317265,5.21709402 7.70263248,4.94618803 7.70263248,4.61305983 C7.70263248,4.27897436 7.4317265,4.00806838 7.09764103,4.00806838" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ReferralFemale.displayName = 'ReferralFemale';

export default ReferralFemale;
