import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Web: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={props.color} strokeWidth="1.5">
        <g transform="translate(6.000000, 6.000000)">
          <circle id="Oval" cx="12" cy="12" r="12" />
          <line x1="0" y1="12" x2="24" y2="12" id="Path" />
          <path d="M12,0 C15.0015365,3.28602347 16.7073033,7.55044062 16.8,12 C16.7073033,16.4495594 15.0015365,20.7139765 12,24 C8.99846355,20.7139765 7.29269669,16.4495594 7.2,12 C7.29269669,7.55044062 8.99846355,3.28602347 12,0 L12,0 Z" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

Web.displayName = 'Web';

export default Web;
