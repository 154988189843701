import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const StartingView: React.FC<Props> = (props) => (
  <SvgIcon viewBox="-3 -2 24 24" {...props}>
    <g
      id="Edit-Tour-Panorama-Starting-View"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="01.00.1-Edit-Tour-Panorama-Starting-View"
        transform="translate(-588.000000, -227.000000)"
        stroke={props.color}
        strokeWidth="1.5"
      >
        <g id="icon" transform="translate(585.000000, 225.000000)">
          <polyline
            id="Icon"
            points="4 12.9 19.4 12.9 14.45 7.95 19.4 3 4 3 4 20.6"
          ></polyline>
        </g>
      </g>
    </g>
  </SvgIcon>
);

StartingView.displayName = 'StartingView';

export default StartingView;
