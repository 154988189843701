import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Theta: React.FC<Props> = (props) => (
  <SvgIcon stroke="black" {...props} viewBox="0 0 36 36">
    <g
      id="Icon-/-Theta"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Group-"
        transform="translate(12.000000, 1.000000)"
        stroke="#000000"
      >
        <path
          d="M2.20048903,1.551 C3.60264387,1.0496129 5.13236645,0.798387097 6.78856645,0.798387097 C8.45130839,0.798387097 9.99520516,1.05067742 11.4202568,1.55525806 C12.2576245,1.85332258 12.8115084,2.63787097 12.8115084,3.50864516 L12.8115084,31.1434839 C12.8115084,32.0259677 12.0788116,32.733871 11.1760245,32.733871 L2.45344387,32.733871 C1.54956645,32.733871 0.81796,32.0185161 0.81796,31.1370968 L0.81796,3.50332258 C0.81796,2.63574194 1.36639226,1.84906452 2.20048903,1.551 Z"
          id="Stroke-1"
          strokeWidth="1.3"
        ></path>
        <path
          d="M6.81473419,4.19665012 L6.81473419,4.19665012 C8.62030839,4.19665012 10.0857019,5.62629529 10.0857019,7.39019851 L10.0857019,16.9708437 C10.0857019,18.7347469 8.62030839,20.1643921 6.81473419,20.1643921 C5.00806968,20.1643921 3.54376645,18.7347469 3.54376645,16.9708437 L3.54376645,7.39019851 C3.54376645,5.62629529 5.00806968,4.19665012 6.81473419,4.19665012 Z"
          id="Stroke-3"
          strokeWidth="1.3"
        ></path>
        <path
          d="M6.81473419,4.19665012 C8.62030839,4.19665012 10.0857019,5.62629529 10.0857019,7.39019851 C10.0857019,9.15410174 8.62030839,10.5837469 6.81473419,10.5837469 C5.00806968,10.5837469 3.54376645,9.15410174 3.54376645,7.39019851 C3.54376645,5.62629529 5.00806968,4.19665012 6.81473419,4.19665012 Z"
          id="Stroke-5"
          strokeWidth="1.3"
        ></path>
        <path
          d="M6.81473419,15.6500985 C7.48964387,15.6500985 8.03589548,16.1834211 8.03589548,16.8423566 C8.03589548,17.5012921 7.48964387,18.0356792 6.81473419,18.0356792 C6.13982452,18.0356792 5.59248258,17.5012921 5.59248258,16.8423566 C5.59248258,16.1834211 6.13982452,15.6500985 6.81473419,15.6500985 Z"
          id="Stroke-7"
        ></path>
      </g>
    </g>
  </SvgIcon>
);

Theta.displayName = 'Theta';

export default Theta;
