import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const ArrowDownBold: React.FC<Props> = ({
  color = '#000000',
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 16" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.8"
    >
      <g transform="translate(-176.000000, -1019.000000)">
        <g transform="translate(0.000000, 730.000000)">
          <g transform="translate(178.000000, 291.000000)">
            <polygon fill={color} points="20 0 10.0001648 12 0 0" />
            <polygon
              stroke={color}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="20 0 10.0001648 12 0 0"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ArrowDownBold.displayName = 'ArrowDownBold';

export default ArrowDownBold;
