import * as React from 'react';
import _ from 'lodash';
import theme from '../theme/default';
import * as Styled from './text-field.styled';

export type Variant = 'normal' | 'viewer';

export interface Props
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  /**
   * placeholder and floating label
   */
  label: string;
  /**
   * Error text to display with the input.
   */
  error?: string;
  /**
   * Whether validation is OK.
   */
  validated?: boolean;
  /**
   * style variant
   */
  variant?: Variant;
  /**
   * component width
   */
  width?: string;
  /**
   * Whether to make it multiline
   */
  multiline?: boolean;
  /**
   * row number when multiline
   */
  rows?: number;
  /**
   * Whether to display the required mark
   */
  requiredMark?: boolean;
}

export const TextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      width = 'auto',
      multiline = false,
      rows = 3,
      requiredMark = false,
      variant = 'normal',
      ...props
    },
    ref,
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
    return (
      <Styled.Wrapper width={width}>
        <Styled.InputWrapper multiline={multiline}>
          {multiline ? (
            <Styled.Textarea
              {..._.omit(props, ['label'])}
              placeholder="&nbsp;"
              as="textarea"
              rows={rows}
              variant={variant}
            />
          ) : (
            <Styled.Input
              {..._.omit(props, ['label'])}
              placeholder="&nbsp;"
              ref={ref}
              variant={variant}
              type={
                props.type === 'password' && isPasswordVisible
                  ? 'text'
                  : props.type
              }
            />
          )}
          <Styled.Label
            error={props.error}
            disabled={props.disabled}
            requiredMark={requiredMark}
            variant={variant}
          >
            {props.label}
          </Styled.Label>
          <Styled.Border error={props.error} variant={variant} />
          <Styled.Icons>
            {props.type === 'password' && (
              <Styled.PasswordVisibleIcon
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? (
                  <Styled.EyeIcon color={theme.palette.text.secondary} />
                ) : (
                  <Styled.EyeSlashIcon color={theme.palette.text.secondary} />
                )}
              </Styled.PasswordVisibleIcon>
            )}
            {props.validated && (
              <Styled.Validated
                diameter={16}
                viewBox="8 9 20 15"
                color={theme.palette.success.main}
              />
            )}
            {props.error && <Styled.Error diameter={16} variant={variant} />}
          </Styled.Icons>
        </Styled.InputWrapper>
        {props.error && (
          <Styled.ErrorMessage variant={variant}>
            {props.error}
          </Styled.ErrorMessage>
        )}
      </Styled.Wrapper>
    );
  },
);

TextField.displayName = 'TextField';

export default TextField;
