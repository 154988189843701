import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Ellipsis: React.FC<Props> = ({ rotate = 0, ...props }) => (
  <SvgIcon viewBox="0 0 36 36" {...props} rotate={rotate}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke={props.color} strokeWidth="1.2">
        <g transform="translate(6.000000, 15.000000)">
          <path d="M12.2857143,0.857142857 C13.5474286,0.857142857 14.5714286,1.88114286 14.5714286,3.14285714 C14.5714286,4.40457143 13.5474286,5.42857143 12.2857143,5.42857143 C11.024,5.42857143 10,4.40457143 10,3.14285714 C10,1.88114286 11.024,0.857142857 12.2857143,0.857142857 Z" />
          <path d="M21.4285714,0.857142857 C22.6902857,0.857142857 23.7142857,1.88114286 23.7142857,3.14285714 C23.7142857,4.40457143 22.6902857,5.42857143 21.4285714,5.42857143 C20.1668571,5.42857143 19.1428571,4.40457143 19.1428571,3.14285714 C19.1428571,1.88114286 20.1668571,0.857142857 21.4285714,0.857142857 Z" />
          <path d="M3.14285714,0.857142857 C4.40457143,0.857142857 5.42857143,1.88114286 5.42857143,3.14285714 C5.42857143,4.40457143 4.40457143,5.42857143 3.14285714,5.42857143 C1.88114286,5.42857143 0.857142857,4.40457143 0.857142857,3.14285714 C0.857142857,1.88114286 1.88114286,0.857142857 3.14285714,0.857142857 Z" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

Ellipsis.displayName = 'Ellipsis';

export default Ellipsis;
