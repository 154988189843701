import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Captures: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Common"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Captures" stroke={props.color} strokeWidth="1.5">
        <g id="Group" transform="translate(7.000000, 7.000000)">
          <path
            d="M13.7607474,20.7236842 L2.48706316,20.7236842 C1.5708,20.7236842 0.829168421,19.9809474 0.829168421,19.0657895 L0.829168421,7.79210526 C0.829168421,6.87694737 1.5708,6.13421053 2.48706316,6.13421053 L13.7607474,6.13421053 C14.6759053,6.13421053 15.4186421,6.87694737 15.4186421,7.79210526 L15.4186421,19.0657895 C15.4186421,19.9809474 14.6759053,20.7236842 13.7607474,20.7236842 Z"
            id="Stroke-1"
            strokeLinecap="round"
          />
          <path
            d="M3.48146842,4.29206842 C3.48146842,3.84443684 3.8451,3.48191053 4.29162632,3.48191053 L16.4130474,3.48191053 C17.3293105,3.48191053 18.0709421,4.22354211 18.0709421,5.13980526 L18.0709421,17.2601211 C18.0709421,17.7077526 17.7084158,18.0702789 17.2596789,18.0702789"
            id="Stroke-3"
          />
          <path
            d="M6.13398947,1.63954737 C6.13398947,1.19191579 6.49762105,0.829389474 6.94414737,0.829389474 L19.0655684,0.829389474 C19.9807263,0.829389474 20.7234632,1.57102105 20.7234632,2.48728421 L20.7234632,14.6076 C20.7234632,15.0563368 20.3609368,15.4188632 19.9133053,15.4188632"
            id="Stroke-5"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

Captures.displayName = 'Captures';

export default Captures;
