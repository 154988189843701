import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Upload: React.FC<Props> = ({ color = '#000000', ...props }) => (
  <SvgIcon color={color} {...props} viewBox="0 0 100 100">
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Atom-/-Information-States-/-Drop-files-to-upload"
        stroke={color}
        strokeWidth="1.5"
      >
        <g id="Group" transform="translate(14.000000, 16.000000)">
          <line
            x1="36.4555634"
            y1="37.7909069"
            x2="36.4555634"
            y2="67.5849572"
            id="Stroke-2"
          ></line>
          <path
            d="M64.2420903,50.9433584 C72.2737399,46.5702539 75.2313742,36.5164172 70.8561952,28.4885758 C67.9581773,23.1719931 62.3840468,19.861543 56.3263614,19.8582326 L52.1565276,19.8582326 C48.490121,5.69943777 34.0364637,-2.81172928 19.8709522,0.849628459 C5.70212867,4.5109862 -2.81307594,18.9544797 0.850018692,33.116585 C1.89330514,37.1586445 3.88051741,40.899453 6.6460545,44.0245179"
            id="Stroke-4"
          ></path>
          <polyline
            id="Stroke-6"
            points="49.7036453 51.032707 36.4555634 37.7909069 23.2074816 51.032707"
          ></polyline>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Upload.displayName = 'Upload';

export default Upload;
