import * as React from 'react';
import * as Styled from './input.styled';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Error text to display with the input.
   */
  error?: string;
}

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => (
  <Styled.Input {...props} ref={ref} />
));

Input.displayName = 'Input';

export default Input;
