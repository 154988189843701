import * as React from 'react';
import { useTranslation } from '@r360-tours/core/i18n';
import { FormikErrors, FormikTouched } from 'formik';
import { TextField } from '../../index';
import * as Styled from './login-form.styled';

export interface LoginError {
  code?: string;
  message: string;
  name: string;
}

export interface LoginFormValues {
  username: string;
  password: string;
  [key: string]: string;
}

export interface LoginFormTranslationKeys {
  passwordPlaceholder?: string;
  submitButtonText?: string;
  usernamePlaceholder?: string;
}

export interface LoginFormProps extends React.HTMLAttributes<HTMLFormElement> {
  values: LoginFormValues;
  setValues: (values: LoginFormValues, shouldValidate?: boolean) => void;
  fieldErrors: FormikErrors<LoginFormValues>;
  setFieldErrors: (errors: FormikErrors<LoginFormValues>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;
  isProcessing: boolean;
  isSubmitting: boolean;
  status: {
    errors: LoginError[];
  };
  touched: FormikTouched<LoginFormValues>;
  setTouched: (
    touched: FormikTouched<LoginFormValues>,
    shouldValidate?: boolean,
  ) => void;
  validateForm: (
    values: LoginFormValues,
  ) => Promise<FormikErrors<LoginFormValues>>;
  translationKeys?: LoginFormTranslationKeys;
}

const LoginForm: React.FC<LoginFormProps> = ({
  values,
  setValues,
  fieldErrors,
  setFieldErrors,
  handleBlur,
  handleChange,
  handleSubmit,
  isProcessing,
  isSubmitting,
  status,
  touched,
  setTouched,
  validateForm,
  translationKeys = {},
  ...props
}) => {
  const { t } = useTranslation();

  const onSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newValues = { ...values };
    let changed = false;
    if (values.username === '') {
      // for browser autocomplete
      newValues.username = e.currentTarget.username.value;
      changed = true;
    }
    if (values.password === '') {
      // for browser autocomplete
      newValues.password = e.currentTarget.password.value;
      changed = true;
    }
    if (changed) {
      setValues(newValues, true);
    }
    const formikErrors = await validateForm(newValues);
    if (Object.keys(formikErrors).length > 0) {
      setTouched({
        password: touched.password || !!formikErrors.password,
        username: touched.username || !!formikErrors.username,
      });
      setFieldErrors(formikErrors);
    } else {
      handleSubmit(e);
    }
  };

  return (
    <Styled.Form
      id="tours-login"
      {...props}
      hidden={isProcessing}
      method="post"
      onSubmit={onSubmit}
      noValidate
    >
      <Styled.TextFieldsWrapper>
        <TextField
          label={t(
            translationKeys.usernamePlaceholder ||
              'LoginForm.UsernamePlaceholder',
          )}
          autoComplete="username"
          data-cy="loginFormUsername"
          error={
            touched.username && fieldErrors.username
              ? t(fieldErrors.username)
              : undefined
          }
          validated={
            touched.username && !!values.username && !fieldErrors.username
          }
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          required
          type="email"
          width="100%"
        />
        <TextField
          label={t(
            translationKeys.passwordPlaceholder ||
              'LoginForm.PasswordPlaceholder',
          )}
          autoComplete="current-password"
          error={
            touched.password && fieldErrors.password
              ? t(fieldErrors.password)
              : undefined
          }
          validated={
            touched.password && !!values.password && !fieldErrors.password
          }
          data-cy="loginFormPassword"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          required
          type="password"
          width="100%"
        />
      </Styled.TextFieldsWrapper>
      {/* isValid is not added to the disabled condition. */}
      {/* Because the button may not be clickable with autofill */}
      {/* @see https://github.com/ricohapi/r360-tours-web/issues/178#issuecomment-645933385 */}
      <Styled.Button
        data-cy="loginFormSubmit"
        disabled={isSubmitting}
        type="submit"
        variant="secondary"
        heightType="big"
      >
        {t(translationKeys.submitButtonText || 'LoginForm.SubmitButtonText')}
      </Styled.Button>

      {status.errors && (
        <div>
          {status.errors.map((error, index) => (
            <Styled.ErrorMessage key={index} data-cy="loginFormErrorMessage">
              {t([
                `Error.${error.code}`,
                `Error.${error.message}`,
                `${error.code}`,
                `${error.message}`,
                'Error.Uncaught',
              ])}
            </Styled.ErrorMessage>
          ))}
        </div>
      )}
    </Styled.Form>
  );
};

LoginForm.displayName = 'LoginForm';

export default LoginForm;
