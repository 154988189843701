import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const InfoInner: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 21 21" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={props.color}>
        <path d="M9,8.925 C9,8.281 9.521,7.76 10.165,7.76 C10.809,7.76 11.329,8.281 11.329,8.925 L11.329,16.307 C11.329,16.949 10.809,17.471 10.165,17.471 C9.521,17.471 9,16.949 9,16.307 L9,8.925 Z M10.165,4 C10.606,4 11.005,4.267 11.174,4.675 C11.344,5.083 11.25,5.553 10.937,5.865 C10.625,6.178 10.155,6.272 9.747,6.103 C9.338,5.934 9.072,5.535 9.072,5.093 C9.072,4.489 9.562,4 10.165,4 L10.165,4 Z" />
      </g>
    </g>
  </SvgIcon>
);

InfoInner.displayName = 'InfoInner';

export default InfoInner;
