import * as React from 'react';
import * as Styled from './toolbar.styled';

const Toolbar: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = ({ children, ...props }) => (
  <Styled.Toolbar {...props}>{children}</Styled.Toolbar>
);

Toolbar.displayName = 'Toolbar';

export default Toolbar;
