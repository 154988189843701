import styled from 'styled-components';

export const HeaderBottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props): string => props.theme.background.primary.main};
  width: 100%;
`;

export const StepsOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1000px;
  padding: 0.5rem 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StepsTextWrapper = styled.div<{ stepCount: number }>`
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 500px;
  margin: 15px 0;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
  > p {
    text-align: center;
    color: ${(props): string => props.theme.palette.grey.normal};
    width: 120px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
    &[data-activestep='true'] {
      color: ${(props): string => props.theme.palette.primary.main};
    }
  }
`;

export const StepsProgressBar = styled.div<{ stepCount: number }>`
  display: flex;
  background-color: ${(props): string => props.theme.palette.grey.light};
  height: 2px;
  width: 72%;
  max-width: 384px;
  > aside {
    background-color: ${(props): string => props.theme.palette.grey.light};
    border-radius: 50%;
    width: 14px;
    height: 14px;
    transform: translate(0px, -6px);
    &[data-activestep='true'] {
      background-color: ${(props): string => props.theme.palette.primary.main};
      transition: all 0.2s ease;
      transition-delay: 0.4s;
    }
    &[data-alwaysactive='true'] {
      background-color: ${(props): string => props.theme.palette.primary.main};
    }
  }
  > div {
    width: calc(
      (100% - (14px * ${(props): number => props.stepCount})) /
        ${(props): number => props.stepCount - 1}
    );
    height: 100%;
  }
  @media (max-width: 768px) {
    width: 72%;
    > aside {
      width: 14px;
      height: 14px;
    }
  }
`;

export const FilledProgressBar = styled.section<{ width: string }>`
  transition: all 0.6s;
  width: ${(props): string => props.width};
  height: 2px;
  margin-left: 14px;
  position: absolute;
  background-color: ${(props): string => props.theme.palette.primary.main};
`;
