/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import * as Styled from './image-map.styled';

export type ImageMapOnClick = (
  event: React.MouseEvent<HTMLImageElement>,
) => void;

export interface ImageMapProps {
  alt: string;
  className?: string;
  onClick?: ImageMapOnClick;
  src: string;
  overlayRef?: React.RefObject<HTMLDivElement>;
}

interface State {
  readonly height?: number;
  readonly width?: number;
  readonly isLoaded: boolean;
}

class ImageMap extends React.PureComponent<ImageMapProps, State> {
  public static displayName = 'ImageMap';

  public static defaultProps = {};

  public readonly state: State = { isLoaded: false };

  private container: React.RefObject<HTMLDivElement> = React.createRef();
  private image: React.RefObject<HTMLImageElement> = React.createRef();

  public componentDidMount(): void {
    if (!this.container.current) {
      return;
    }

    const image = this.image.current;

    if (image && image.complete) {
      this.onImageLoad();
    }

    window.addEventListener('resize', this.onWindowResize);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.onWindowResize);
  }

  public render(): JSX.Element {
    return (
      <Styled.Container className={this.props.className} ref={this.container}>
        <Styled.Overlay
          onClick={this.props.onClick}
          ref={this.props.overlayRef}
          style={{
            height: this.state.height,
            width: this.state.width,
          }}
        >
          {this.state.isLoaded &&
            React.Children.map(this.props.children, this.getHotspotElement)}
        </Styled.Overlay>
        <Styled.Image
          alt={this.props.alt}
          className="ImageMap__image"
          onClick={this.onImageClick}
          onLoad={this.onImageLoad}
          src={this.props.src}
          ref={this.image}
        />
      </Styled.Container>
    );
  }

  private getHotspotElement = (child: any): any => {
    if (!child) {
      return;
    }

    const style: React.CSSProperties = {
      left: `${child.props.x}%`,
      position: 'absolute',
      top: `${child.props.y}%`,
    };

    return React.cloneElement(child, {
      ...child.props,
      style,
    });
  };

  private onWindowResize = (): void => {
    if (!this.container.current) {
      return;
    }

    const image: HTMLImageElement | null = this.container.current.querySelector(
      'img.ImageMap__image',
    );

    if (!image) {
      return;
    }

    this.setState({ height: image.height, width: image.width });
  };

  private onImageClick = (event: any): void => {
    if (event.isDefaultPrevented()) {
      return;
    }
  };

  private onImageLoad = (): void => {
    if (!this.container.current) {
      return;
    }

    const image: HTMLImageElement | null = this.container.current.querySelector(
      'img.ImageMap__image',
    );

    if (!image) {
      return;
    }

    this.setState({ height: image.height, isLoaded: true, width: image.width });
  };
}

export default ImageMap;
