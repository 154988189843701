import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Spinner: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 38 38" className="spin">
    <defs>
      <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
        <stop stopColor={props.color} stopOpacity="0" offset="0%" />
        <stop stopColor={props.color} stopOpacity=".631" offset="63.146%" />
        <stop stopColor={props.color} offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          id="Oval-2"
          stroke="url(#a)"
          strokeWidth="2"
        />
        <circle fill={props.color} cx="36" cy="18" r="1" />
      </g>
    </g>
  </SvgIcon>
);

Spinner.displayName = 'Spinner';

export default Spinner;
