import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

interface AspectRatioProps {
  active?: boolean;
}

export const ThreeToTwo: React.FC<Props & AspectRatioProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 22" width="32px" height="22px">
    <g
      id="Cropping"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop-/-02.08.-Photo-/-Manual-Crop-/-Ratio-Selection-Change-the-ratio-of-the-clipping."
        transform="translate(-809.000000, -592.000000)"
        stroke={props.active ? '#2C2828' : '#FFFFFF'}
      >
        <g id="Drop-down" transform="translate(768.000000, 531.000000)">
          <g id="Group-5" transform="translate(41.000000, 56.000000)">
            <rect id="Rectangle" x="0.5" y="5.5" width="31" height="21"></rect>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ThreeToTwo.displayName = 'ThreeToTwo';

export default ThreeToTwo;
