import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const BrushSlider: React.FC<Props> = (props) => (
  <SvgIcon width="130px" height="14px" viewBox="0 0 130 14" {...props}>
    <g
      id="Image-Blur"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.4"
    >
      <g
        id="Desktop-/-04.00-Image-Blur---Blur-mode-Restore-the-history."
        transform="translate(-575.000000, -739.000000)"
        fill="#FFFFFF"
      >
        <g id="BrushSlider" transform="translate(551.000000, 727.000000)">
          <path
            d="M25.4751369,26 L152,26 C153.104569,26 154,25.1045695 154,24 L154,14.1953589 C154,13.0907894 153.104569,12.1953589 152,12.1953589 C151.937946,12.1953589 151.875926,12.1982469 151.814141,12.2040135 L25.3822075,24.0043273 C24.8323126,24.0556508 24.4281407,24.5430346 24.4794642,25.0929295 C24.527442,25.6069769 24.9588553,26 25.4751369,26 Z"
            id="Path-2"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

BrushSlider.displayName = 'BrushSlider';

export default BrushSlider;
