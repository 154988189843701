import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const DottedLineArrowDown: React.FC<Props> = ({ ...props }) => (
  <SvgIcon viewBox="0 0 10 62" {...props}>
    <defs>
      <polygon
        id="dotted-line-arrow-down-path-1"
        points="0 -3.61908299e-15 9.92787156 -3.61908299e-15 9.92787156 6.30461857 0 6.30461857"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-76.000000, -509.000000)">
        <g transform="translate(26.000000, 390.000000)">
          <g transform="translate(50.000000, 119.000000)">
            <g transform="translate(0.000000, 55.695881)">
              <mask id="dotted-line-arrow-down-mask-2" fill="white">
                <use xlinkHref="#dotted-line-arrow-down-path-1" />
              </mask>
              <g />
              <path
                d="M0.1218,0.841430754 C-0.1572,0.510359332 0.0738,-3.61908299e-15 0.5008,-3.61908299e-15 L4.9638,-3.61908299e-15 L9.4268,-3.61908299e-15 C9.8548,-3.61908299e-15 10.0848,0.510359332 9.8058,0.841430754 L5.3428,6.12736802 C5.1438,6.36370209 4.7838,6.36370209 4.5848,6.12736802 L0.1218,0.841430754 Z"
                fill="#0080FF"
                mask="url(#dotted-line-arrow-down-mask-2)"
              />
            </g>
            <path
              d="M3.4598,1.5 C3.4598,0.67 4.1398,0 4.9598,0 C5.7898,0 6.4598,0.67 6.4598,1.5 C6.4598,2.33 5.7898,3 4.9598,3 C4.1398,3 3.4598,2.33 3.4598,1.5"
              fill="#0080FF"
            />
            <path
              d="M3.4598,8.5 C3.4598,7.67 4.1398,7 4.9598,7 C5.7898,7 6.4598,7.67 6.4598,8.5 C6.4598,9.33 5.7898,10 4.9598,10 C4.1398,10 3.4598,9.33 3.4598,8.5"
              fill="#0080FF"
            />
            <path
              d="M3.4598,15.5 C3.4598,14.67 4.1398,14 4.9598,14 C5.7898,14 6.4598,14.67 6.4598,15.5 C6.4598,16.33 5.7898,17 4.9598,17 C4.1398,17 3.4598,16.33 3.4598,15.5"
              fill="#0080FF"
            />
            <path
              d="M3.4598,22.5 C3.4598,21.67 4.1398,21 4.9598,21 C5.7898,21 6.4598,21.67 6.4598,22.5 C6.4598,23.33 5.7898,24 4.9598,24 C4.1398,24 3.4598,23.33 3.4598,22.5"
              fill="#0080FF"
            />
            <path
              d="M3.4598,29.5 C3.4598,28.67 4.1398,28 4.9598,28 C5.7898,28 6.4598,28.67 6.4598,29.5 C6.4598,30.32 5.7898,31 4.9598,31 C4.1398,31 3.4598,30.32 3.4598,29.5"
              fill="#0080FF"
            />
            <path
              d="M3.4598,36.5 C3.4598,35.67 4.1398,35 4.9598,35 C5.7898,35 6.4598,35.67 6.4598,36.5 C6.4598,37.32 5.7898,38 4.9598,38 C4.1398,38 3.4598,37.32 3.4598,36.5"
              fill="#0080FF"
            />
            <path
              d="M3.4598,43.5 C3.4598,42.67 4.1398,42 4.9598,42 C5.7898,42 6.4598,42.67 6.4598,43.5 C6.4598,44.32 5.7898,45 4.9598,45 C4.1398,45 3.4598,44.32 3.4598,43.5"
              fill="#0080FF"
            />
            <path
              d="M3.4598,50.49 C3.4598,49.67 4.1398,49 4.9598,49 C5.7898,49 6.4598,49.67 6.4598,50.49 C6.4598,51.32 5.7898,52 4.9598,52 C4.1398,52 3.4598,51.32 3.4598,50.49"
              fill="#0080FF"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

DottedLineArrowDown.displayName = 'DottedLineArrowDown';

export default DottedLineArrowDown;
