import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Talk: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 57 57" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-369.000000, -746.000000)">
        <g transform="translate(0.000000, 579.000000)">
          <g transform="translate(335.000000, 133.000000)">
            <g transform="translate(34.000000, 34.000000)">
              <path
                d="M18.1157795,10.8107288 L50.8838968,10.8107288 C54.2615986,10.8107288 57,13.5406066 57,16.9084629 L57,38.1887681 L57,40.6048909 L57,55.4919521 C57,56.8328135 55.3736991,57.5042911 54.4226986,56.5561468 L44.8613936,46.702625 L18.1157795,46.702625 C14.7380778,46.702625 11.9999763,43.9727472 11.9999763,40.6048909 L11.9999763,16.9084629 C11.9999763,13.5406066 14.7380778,10.8107288 18.1157795,10.8107288"
                fill="#0080FF"
              />
              <path
                d="M38.8839205,0 L6.11580322,0 C2.73810144,0 0,2.7298778 0,6.09773405 L0,27.3780393 L0,29.7944612 L0,44.6812233 C0,46.0220847 1.62600086,46.6935623 2.57700136,45.745418 L12.1383064,35.8918961 L38.8839205,35.8918961 C42.2616222,35.8918961 45.0000237,33.1620183 45.0000237,29.7944612 L45.0000237,6.09773405 C45.0000237,2.7298778 42.2616222,0 38.8839205,0"
                fill="#B8DEFF"
              />
              <path
                d="M29.8839157,12.7142855 L15.6864083,12.7142855 C14.9031078,12.7142855 14.3157075,13.3100909 14.3157075,14.1044982 C14.3157075,14.8989055 14.9031078,15.494711 15.6864083,15.494711 L29.9820158,15.494711 C30.6672161,15.494711 31.2546164,14.8989055 31.2546164,14.1044982 C31.2546164,13.3100909 30.6672161,12.7142855 29.8839157,12.7142855"
                fill="#0080FF"
              />
              <path
                d="M23.4558502,22.0645161 L15.1573522,22.0645161 C14.3760215,22.0645161 13.7903226,22.65553 13.7903226,23.4435484 C13.7903226,24.2315668 14.3760215,24.8225806 15.1573522,24.8225806 L23.4558502,24.8225806 C24.2368817,24.8225806 24.8225806,24.2315668 24.8225806,23.4435484 C24.8225806,22.65553 24.2368817,22.0645161 23.4558502,22.0645161"
                fill="#0080FF"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Talk.displayName = 'Talk';

export default Talk;
