import * as React from 'react';
import * as Styled from './caption.styled';

export const CardCaption: React.FC<React.HTMLAttributes<HTMLElement>> = (
  props,
) => <Styled.Caption {...props} />;

CardCaption.displayName = 'CardCaption';

export default CardCaption;
