import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const CreateTourStep1: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 170 170" {...props}>
    <defs>
      <path
        d="M0,0 L0,115.87726 C0,120.574556 3.80766401,124.38271 8.50545041,124.38271 L8.50545041,124.38271 L59.4282976,124.38271 C64.126084,124.38271 67.933748,120.574556 67.933748,115.87726 L67.933748,115.87726 L67.933748,0"
        id="step1-path-1"
      ></path>
      <path
        d="M0,0 L0,115.87726 C0,120.574556 3.80766401,124.38271 8.50545041,124.38271 L8.50545041,124.38271 L59.4282976,124.38271 C64.126084,124.38271 67.933748,120.574556 67.933748,115.87726 L67.933748,115.87726 L67.933748,0"
        id="step1-path-3"
      ></path>
      <path
        d="M0,0 L0,115.87726 C0,120.574556 3.80766401,124.38271 8.50545041,124.38271 L8.50545041,124.38271 L59.4282976,124.38271 C64.126084,124.38271 67.933748,120.574556 67.933748,115.87726 L67.933748,115.87726 L67.933748,0"
        id="step1-path-5"
      ></path>
      <path
        d="M0,0 L0,115.87726 C0,120.574556 3.80766401,124.38271 8.50545041,124.38271 L8.50545041,124.38271 L59.4282976,124.38271 C64.126084,124.38271 67.933748,120.574556 67.933748,115.87726 L67.933748,115.87726 L67.933748,0 L0,0 Z"
        id="step1-path-7"
      ></path>
      <path
        d="M0,0 L0,115.87726 C0,120.574556 3.80766401,124.38271 8.50545041,124.38271 L8.50545041,124.38271 L59.4282976,124.38271 C64.126084,124.38271 67.933748,120.574556 67.933748,115.87726 L67.933748,115.87726 L67.933748,0"
        id="step1-path-9"
      ></path>
      <polygon
        id="step1-path-11"
        points="1.84741111e-13 170 150.560646 170 150.560646 0 1.84741111e-13 0"
      ></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="For-those-who-are-referred-for-PC."
        transform="translate(-235.000000, -942.000000)"
      >
        <g id="How-it-work-?" transform="translate(0.000000, 738.000000)">
          <g id="Group" transform="translate(235.000000, 204.000000)">
            <rect
              id="Rectangle"
              fill="#ED7872"
              opacity="0.3"
              x="0"
              y="0"
              width="170"
              height="170"
              rx="85"
            ></rect>
            <g id="Group-60" transform="translate(10.000000, 0.000000)">
              <g id="Group-5" transform="translate(80.433224, 17.404400)">
                <path
                  d="M8.50545041,0 L59.4282976,0 C64.1255935,0 67.933748,3.80815444 67.933748,8.50545041 L67.933748,133.870973 C67.933748,138.568269 64.1255935,142.376424 59.4282976,142.376424 L8.50545041,142.376424 C3.80815444,142.376424 0,138.568269 0,133.870973 L0,8.50545041 C0,3.80815444 3.80815444,0 8.50545041,0"
                  id="Fill-1"
                  fill="#E6E6E6"
                ></path>
                <path
                  d="M8.50545041,140.157739 C3.80815444,140.157739 0,136.349584 0,131.652288 L0,131.652288 L0,12.4332687 L67.933748,12.4332687 L67.933748,131.652288 C67.933748,136.349584 64.1255935,140.157739 59.4282976,140.157739 L8.50545041,140.157739 Z"
                  id="Fill-3"
                  fill="#FFFFFF"
                ></path>
              </g>
              <g id="Group-21" transform="translate(80.433224, 33.179429)">
                <g id="Group-8">
                  <mask id="step1-mask-2" fill="white">
                    <use xlinkHref="#step1-path-1"></use>
                  </mask>
                  <g id="Clip-7"></g>
                  <path
                    d="M33.966874,1.18633942 C96.2523908,1.18633942 146.745135,24.5467799 146.745135,53.3636951 C146.745135,82.1806104 96.2523908,105.541051 33.966874,105.541051 C-28.3186429,105.541051 -78.8113868,82.1806104 -78.8113868,53.3636951 C-78.8113868,24.5467799 -28.3186429,1.18633942 33.966874,1.18633942"
                    id="Fill-6"
                    fill="#C5DCFD"
                    mask="url(#step1-mask-2)"
                  ></path>
                </g>
                <g id="Group-11">
                  <mask id="step1-mask-4" fill="white">
                    <use xlinkHref="#step1-path-3"></use>
                  </mask>
                  <g id="Clip-10"></g>
                  <path
                    d="M33.966874,16.6553423 C96.2523908,16.6553423 146.745135,33.0899931 146.745135,53.3632047 C146.745135,73.6364163 96.2523908,90.0710671 33.966874,90.0710671 C-28.3186429,90.0710671 -78.8113868,73.6364163 -78.8113868,53.3632047 C-78.8113868,33.0899931 -28.3186429,16.6553423 33.966874,16.6553423"
                    id="Fill-9"
                    fill="#7ABEFF"
                    mask="url(#step1-mask-4)"
                  ></path>
                </g>
                <g id="Group-14">
                  <mask id="step1-mask-6" fill="white">
                    <use xlinkHref="#step1-path-5"></use>
                  </mask>
                  <g id="Clip-13"></g>
                  <path
                    d="M60.9152678,57.8785528 C54.5397356,57.7696783 48.1436055,57.6995475 41.727368,57.6676698 C41.0898148,57.6647273 40.5748679,57.2753294 40.5748679,56.8020687 L40.5748679,41.0790254 C40.5748679,40.603803 41.091286,40.211953 41.727368,40.2026349 C48.1087853,40.1070019 54.4754899,39.8941572 60.8274817,39.5650817 C61.4037317,39.464054 61.9530083,39.8495285 62.0535456,40.4257786 C62.0564881,40.442453 62.0594307,40.4591275 62.0613924,40.4762924 L62.0613924,56.9957868 C61.9873381,57.5553624 61.4748434,57.9496645 60.9152678,57.8785528"
                    id="Fill-12"
                    fill="#FFFFFF"
                    mask="url(#step1-mask-6)"
                  ></path>
                </g>
                <g id="Group-17">
                  <mask id="step1-mask-8" fill="white">
                    <use xlinkHref="#step1-path-7"></use>
                  </mask>
                  <g id="Clip-16"></g>
                  <path
                    d="M29.5824695,75.825676 C22.4011681,75.9296462 15.2345795,76.2891282 8.08368443,76.9046122 L8.08368443,37.9491295 C8.08368443,37.4135848 8.63590361,37.0129071 9.31808556,37.0521412 C15.6406518,37.4116231 21.9818543,37.632805 28.3416929,37.7151965 C29.0282886,37.7240242 29.5824695,38.143338 29.5824695,38.6543614 L29.5824695,75.825676 Z"
                    id="Fill-15"
                    fill="#EED86D"
                    mask="url(#step1-mask-8)"
                  ></path>
                </g>
                <g id="Group-20">
                  <mask id="step1-mask-10" fill="white">
                    <use xlinkHref="#step1-path-9"></use>
                  </mask>
                  <g id="Clip-19"></g>
                  <path
                    d="M11.640741,51.6736887 L11.640741,39.935353 C16.419938,40.13937 21.209434,40.27767 26.0102098,40.3507434 L26.0102098,51.7256738 C21.2123766,51.7168461 16.4228806,51.6996812 11.640741,51.6736887"
                    id="Fill-18"
                    fill="#FFFFFF"
                    mask="url(#step1-mask-10)"
                  ></path>
                </g>
              </g>
              <g id="Group-59">
                <path
                  d="M148.366972,28.9549031 L80.4332241,28.9549031 L80.4332241,25.9103413 C80.4332241,21.2130453 84.2413785,17.4048909 88.9386745,17.4044005 L139.861522,17.4044005 C144.558818,17.4048909 148.366972,21.2130453 148.366972,25.9103413 L148.366972,28.9549031 Z"
                  id="Fill-22"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M146.148287,151.275374 C146.148287,154.747587 143.333735,157.562139 139.861522,157.562139 L88.9386745,157.562139 C85.4664616,157.562139 82.6509285,154.749549 82.6499476,151.277336 L82.6499476,151.275374 L82.6499476,25.9103413 C82.6499476,22.4381284 85.4644999,19.6235761 88.9367128,19.6235761 L139.861522,19.6235761 C143.333735,19.6235761 146.148287,22.4381284 146.148287,25.9103413 L146.148287,151.275374 Z M139.861522,15.1857152 L88.9386745,15.1857152 C83.0158051,15.1857152 78.2145389,19.9874719 78.2145389,25.9103413 L78.2145389,151.275374 C78.2145389,157.198243 83.0158051,162 88.9386745,162 L139.861522,162 C145.784391,162 150.586148,157.198243 150.586148,151.275374 L150.586148,151.275374 L150.586148,25.9103413 C150.586148,19.9874719 145.784391,15.1857152 139.861522,15.1857152 Z"
                  id="Fill-24"
                  fill="#3F4448"
                ></path>
                <path
                  d="M87.8724894,23.8260327 C88.5532,23.8260327 89.1054192,24.3782519 89.1054192,25.0589625 C89.1054192,25.7396732 88.5532,26.2918924 87.8724894,26.2918924 C87.1917787,26.2918924 86.6395595,25.7396732 86.6395595,25.0589625 C86.6395595,24.3782519 87.1917787,23.8260327 87.8724894,23.8260327"
                  id="Fill-26"
                  fill="#CCCCCC"
                ></path>
                <path
                  d="M91.8169821,23.8260327 C92.4976928,23.8260327 93.0499119,24.3782519 93.0499119,25.0589625 C93.0499119,25.7396732 92.4976928,26.2918924 91.8169821,26.2918924 C91.1362714,26.2918924 90.5840523,25.7396732 90.5840523,25.0589625 C90.5840523,24.3782519 91.1362714,23.8260327 91.8169821,23.8260327"
                  id="Fill-28"
                  fill="#CCCCCC"
                ></path>
                <path
                  d="M95.7614748,23.8260327 C96.4421855,23.8260327 96.9944047,24.3782519 96.9944047,25.0589625 C96.9944047,25.7396732 96.4421855,26.2918924 95.7614748,26.2918924 C95.0807642,26.2918924 94.528545,25.7396732 94.528545,25.0589625 C94.528545,24.3782519 95.0807642,23.8260327 95.7614748,23.8260327"
                  id="Fill-30"
                  fill="#CCCCCC"
                ></path>
                <path
                  d="M0,146.083729 L0,38.5584164 C0.00294255333,35.1715375 2.08038521,32.1318799 5.23529281,30.8994405 C10.5578814,28.7964957 16.2252391,27.7003946 21.9480149,27.6665552 C27.6707907,27.7003946 33.3381484,28.7964957 38.660737,30.8994405 C41.8156446,32.1318799 43.8930872,35.1715375 43.8960298,38.5584164 L43.8960298,146.083729 C43.8960298,147.981185 42.3575648,149.51965 40.4601083,149.51965 L40.4601083,149.51965 L3.43592144,149.51965 C1.53846497,149.51965 0,147.981185 0,146.083729 Z"
                  id="Fill-32"
                  fill="#838287"
                ></path>
                <path
                  d="M21.9480149,35.5771194 L21.9485053,35.5771194 C31.1631111,35.5771194 38.6332731,43.0472815 38.6332731,52.2618872 L38.6332731,101.803696 C38.6332731,111.018302 31.1631111,118.488464 21.9485053,118.488464 L21.9480149,118.488464 C12.7334091,118.488464 5.26324706,111.018302 5.26324706,101.803696 L5.26324706,52.2618872 C5.26324706,43.0472815 12.7334091,35.5771194 21.9480149,35.5771194"
                  id="Fill-34"
                  fill="#676767"
                ></path>
                <path
                  d="M21.9480149,38.9242738 C29.3142067,38.9242738 35.2856283,44.8956954 35.2856283,52.2618872 C35.2856283,59.6280791 29.3142067,65.5995007 21.9480149,65.5995007 C14.581823,65.5995007 8.61040148,59.6280791 8.61040148,52.2618872 C8.61040148,44.8956954 14.581823,38.9242738 21.9480149,38.9242738"
                  id="Fill-36"
                  fill="#333333"
                ></path>
                <path
                  d="M21.9480149,40.220959 C28.5981854,40.220959 33.9889431,45.6117167 33.9889431,52.2618872 C33.9889431,58.9120578 28.5981854,64.3028155 21.9480149,64.3028155 C15.2978444,64.3028155 9.90708665,58.9120578 9.90708665,52.2618872 C9.90708665,45.6117167 15.2978444,40.220959 21.9480149,40.220959"
                  id="Fill-38"
                  fill="#333333"
                ></path>
                <path
                  d="M21.9480149,95.634633 C25.3550012,95.634633 28.1165875,98.3962193 28.1165875,101.803206 C28.1165875,105.210192 25.3550012,107.971778 21.9480149,107.971778 C18.5410286,107.971778 15.7794423,105.210192 15.7794423,101.803206 C15.7794423,98.3962193 18.5410286,95.634633 21.9480149,95.634633 Z"
                  id="Stroke-40"
                  stroke="#333333"
                  strokeWidth="1.557"
                ></path>
                <path
                  d="M21.9480149,47.7926392 C24.4163267,47.7926392 26.417263,49.7935754 26.417263,52.2618872 C26.417263,54.7301991 24.4163267,56.7311353 21.9480149,56.7311353 C19.4797031,56.7311353 17.4787668,54.7301991 17.4787668,52.2618872 C17.4787668,49.7935754 19.4797031,47.7926392 21.9480149,47.7926392 Z"
                  id="Stroke-42"
                  stroke="#F2F2F2"
                  strokeWidth="1.823"
                ></path>
                <path
                  d="M20.1103903,143.947926 C20.1138233,144.514858 20.576785,144.971934 21.143717,144.968501 C21.1834414,144.968011 21.2231659,144.965559 21.2628904,144.960654 C21.5689159,144.96654 21.8724894,144.902294 22.1505606,144.773802 L22.4202947,145.142602 C22.026483,145.354957 21.5836287,145.458436 21.136851,145.442743 C19.9868031,145.442743 19.140819,144.889052 19.140819,143.948416 C19.140819,143.00827 19.9868031,142.454089 21.136851,142.454089 C21.5689159,142.438886 21.9990191,142.522749 22.3938117,142.699302 L22.1172117,143.12303 C21.8543436,142.98522 21.5595979,142.919994 21.2628904,142.934216 C20.7003723,142.864576 20.1878776,143.264272 20.1182371,143.826791 C20.1133329,143.866515 20.1108808,143.90624 20.1103903,143.945964"
                  id="Fill-44"
                  fill="#333333"
                ></path>
                <path
                  d="M24.4992086,144.979291 C23.979848,144.987628 23.5521969,144.573709 23.5438596,144.054348 C23.5433692,144.019528 23.5448405,143.984217 23.5482735,143.949397 C23.4982501,143.432979 23.8768586,142.97345 24.3932767,142.923917 C24.4285874,142.920484 24.463898,142.919013 24.4992086,142.919503 C25.0180789,142.910676 25.44573,143.324595 25.4545576,143.843465 C25.455048,143.878776 25.4535768,143.914086 25.4501438,143.949397 C25.4996768,144.466306 25.1210682,144.925344 24.6041597,144.974877 C24.5693395,144.97831 24.5340288,144.979781 24.4992086,144.979291 M24.4992086,142.45458 C23.3859426,142.45458 22.5826256,143.008761 22.5826256,143.949397 C22.5826256,144.890033 23.3859426,145.444214 24.4992086,145.444214 C25.6124746,145.444214 26.4157917,144.890524 26.4157917,143.949397 C26.4157917,143.00827 25.6129651,142.45458 24.4992086,142.45458"
                  id="Fill-46"
                  fill="#333333"
                ></path>
                <polygon
                  id="Fill-48"
                  fill="#333333"
                  points="29.3112642 144.164203 29.3112642 145.367217 30.2313025 145.367217 30.2313025 142.528144 29.3112642 142.528144 29.3112642 143.610513 27.9047237 143.610513 27.9047237 142.528144 26.986647 142.528144 26.986647 145.367217 27.9052141 145.367217 27.9052141 144.164203"
                ></polygon>
                <mask id="step1-mask-12" fill="white">
                  <use xlinkHref="#step1-path-11"></use>
                </mask>
                <g id="Clip-51"></g>
                <polygon
                  id="Fill-50"
                  fill="#333333"
                  mask="url(#step1-mask-12)"
                  points="17.6195189 145.367217 18.5885998 145.367217 18.5885998 142.528144 17.6195189 142.528144"
                ></polygon>
                <path
                  d="M14.8373348,143.827771 L14.8373348,144.099467 C15.3086337,144.468267 15.7323614,144.893957 16.0987093,145.367217 L17.1835306,145.367217 C16.7799104,144.895428 16.3144965,144.480037 15.8005306,144.131835 C16.301255,144.111237 16.8593593,143.908692 16.8593593,143.333913 C16.8593593,142.598275 16.0712455,142.461936 15.2747944,142.461936 C14.6239996,142.461936 14.0237188,142.491362 13.6652177,142.521278 L13.6652177,145.365746 L14.569072,145.365746 L14.569072,142.947948 C14.8044762,142.920484 15.0418422,142.907243 15.2787178,142.909695 C15.744622,142.909695 15.9805167,143.063688 15.9805167,143.332442 C15.9805167,143.56147 15.8824316,143.836109 14.8378252,143.8263"
                  id="Fill-52"
                  fill="#333333"
                  mask="url(#step1-mask-12)"
                ></path>
                <path
                  d="M9.69914621,124.167592 L34.197374,124.167592 C35.5563432,124.167592 36.6583294,125.269578 36.6583294,126.628547 L36.6583294,136.821062 C36.6583294,138.180031 35.5563432,139.282017 34.197374,139.282017 L9.69914621,139.282017 C8.340177,139.282017 7.23819078,138.180031 7.23819078,136.821062 L7.23819078,126.628547 C7.23819078,125.269578 8.340177,124.167592 9.69914621,124.167592"
                  id="Fill-53"
                  fill="#333333"
                  mask="url(#step1-mask-12)"
                ></path>
                <path
                  d="M47.2784948,15.9292004 C51.553044,15.9292004 55.018391,19.3945474 55.018391,23.6690965 L55.018391,23.6695869"
                  id="Stroke-54"
                  stroke="#EA5851"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  mask="url(#step1-mask-12)"
                ></path>
                <path
                  d="M47.2784948,9.11326601 C55.3175505,9.11277559 61.8343253,15.6295504 61.8348158,23.6686061 L61.8348158,23.6695869"
                  id="Stroke-55"
                  stroke="#EA5851"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  mask="url(#step1-mask-12)"
                ></path>
                <path
                  d="M47.2784948,21.4680666 C48.4942598,21.4680666 49.4800152,22.453822 49.4800152,23.6695869 C49.4800152,24.8853519 48.4942598,25.8711072 47.2784948,25.8711072 C46.0627299,25.8711072 45.0769745,24.8853519 45.0769745,23.6695869 C45.0769745,22.453822 46.0627299,21.4680666 47.2784948,21.4680666"
                  id="Fill-56"
                  fill="#EA5851"
                  mask="url(#step1-mask-12)"
                ></path>
                <path
                  d="M114.400098,132.021757 C118.683475,132.021757 122.156178,135.49446 122.156178,139.777837 C122.156178,144.061214 118.683475,147.533917 114.400098,147.533917 C110.116721,147.533917 106.644018,144.061214 106.644018,139.777837 C106.644018,135.49446 110.116721,132.021757 114.400098,132.021757"
                  id="Fill-57"
                  fill="#2071EE"
                  mask="url(#step1-mask-12)"
                ></path>
                <polyline
                  id="Stroke-58"
                  stroke="#FFFFFF"
                  strokeWidth="3.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  mask="url(#step1-mask-12)"
                  points="111.165251 139.281036 113.372166 141.487951 117.31764 137.542968"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

CreateTourStep1.displayName = 'Step1';

export default CreateTourStep1;
