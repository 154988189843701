import * as React from 'react';
import { i18next } from '@r360-tours/core';
import { removeAuthCookies } from '@r360-tours/core/lib/remove-cookie';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Auth, { CognitoUserAttribute } from '../../../core/auth';
import Spinner from '../form-loading-spinner';
import SignupForm, {
  SignupFormTranslationKeys,
  SignupFormValues,
} from './signup-form';
import * as Styled from './signup-form.styled';

export interface SignupFormContainerProps
  extends React.HTMLAttributes<HTMLFormElement> {
  email?: string;
  inviteCode?: string;
  referralCode?: string;
  onComplete: () => void;
  setIsProcessing?: React.Dispatch<React.SetStateAction<boolean>>;
  translationKeys?: SignupFormTranslationKeys;
  marketing?: boolean;
}

const formSchema = Yup.object().shape({
  name: Yup.string().trim().required('Validation.NameRequired'),
  password: Yup.string()
    .min(6, 'Validation.PasswordTooShort')
    .max(99, 'Validation.PasswordTooLong')
    .required('Validation.PasswordRequired'),
  username: Yup.string()
    .email('Validation.EmailInvalid')
    .required('Validation.EmailRequired'),
});

const SignupFormContainer: React.FC<SignupFormContainerProps> = ({
  email,
  inviteCode,
  referralCode,
  onComplete,
  setIsProcessing,
  marketing,
  ...props
}) => {
  const [isComplete, setIsComplete] = React.useState(false);

  const onSubmit = async (
    values: SignupFormValues,
    actions: FormikHelpers<SignupFormValues>,
  ) => {
    removeAuthCookies();
    try {
      const signupData = {
        clientMetadata: {
          locale: i18next.language,
        },
        password: values.password,
        username: values.username.trim().toLowerCase(),
        validationData: [
          new CognitoUserAttribute({
            Name: 'name',
            Value: values.name,
          }),
          new CognitoUserAttribute({
            Name: 'rawEmail',
            Value: values.username.trim(),
          }),
        ],
      };

      if (inviteCode) {
        signupData.validationData.push(
          new CognitoUserAttribute({
            Name: 'teamInviteCode',
            Value: inviteCode,
          }),
        );
      }

      if (referralCode) {
        signupData.validationData.push(
          new CognitoUserAttribute({
            Name: 'referralCode',
            Value: referralCode,
          }),
        );
      }

      await Auth.signUp(signupData);

      // Attempt to automatically sign in the new user so they won't have to
      // reenter their username and password again.
      await Auth.signIn(values.username.trim().toLowerCase(), values.password);

      onComplete();
      setIsComplete(true);
    } catch (error) {
      if (error.message === 'Username should be an email.') {
        actions.setStatus({
          errors: [
            {
              ...error,
              code: 'Validation.EmailInvalid',
            },
          ],
        });
      } else {
        actions.setStatus({
          errors: [error],
        });
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik<SignupFormValues>
      initialValues={{
        name: '',
        password: '',
        username: email || '',
      }}
      initialStatus={{ errors: [] }}
      isInitialValid={false}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnMount
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        status,
        touched,
      }): JSX.Element => {
        const isProcessing = isComplete || (isSubmitting && isValid);
        if (setIsProcessing) setIsProcessing(isProcessing);

        return (
          <>
            {isProcessing && (
              <Styled.SpinnerContainer>
                <Spinner />
              </Styled.SpinnerContainer>
            )}
            <SignupForm
              {...props}
              fieldErrors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isProcessing={isProcessing}
              isSubmitting={isSubmitting}
              isValid={isValid}
              status={status}
              touched={touched}
              username={email}
              usernameReadOnly={!!email}
              marketing={marketing}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default SignupFormContainer;
