import styled from 'styled-components';

export const Tab = styled.div<{ active?: boolean }>`
  border-bottom-color: ${(props): string =>
    props.active ? props.theme.palette.primary.main : 'transparent'};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  cursor: pointer;
  font-size: ${(props): string | number => props.theme.tabs.fontSize};
  font-weight: ${(props): string | number => props.theme.tabs.fontWeight};
  transition-duration: 300ms;
  transition-property: border;
  user-select: none;
  margin: 0 20px;
  padding: 13px 0;
  color: ${(props): string =>
    props.active
      ? props.theme.palette.text.primary
      : props.theme.palette.grey.dark};

  a {
    color: inherit;
  }

  &:hover {
    color: ${(props): string => props.theme.palette.text.primary};
  }

  &:focus,
  &:focus-within {
    border-bottom-color: ${(props): string => props.theme.palette.primary.main};
    outline: none;
  }

  *:focus {
    outline: none;
  }
`;
