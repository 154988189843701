import * as CSS from 'csstype';
import styled from 'styled-components';
import { ButtonVariant } from './button';

interface Style {
  background: CSS.BackgroundColorProperty;
  borderColor: CSS.BorderColorProperty;
  borderRadius: CSS.BorderRadiusProperty<number | string>;
  borderStyle: CSS.BorderStyleProperty;
  borderWidth: CSS.BorderWidthProperty<number | string>;
  height: CSS.HeightProperty<number | string>;
  hover: {
    background: CSS.BackgroundColorProperty;
    borderColor: CSS.BorderColorProperty;
    fontColor: CSS.Color;
  };
  fontColor: CSS.Color;
}

const HeightDict = {
  big: 54,
  default: 44,
  mini: 30,
};

export const Button = styled.button.attrs<
  {
    heightType: 'default' | 'big' | 'mini';
    variant?: ButtonVariant;
  },
  Style
>(
  ({ heightType = 'default', theme, variant }): Style => {
    const variantLocal = variant || 'primary';
    const height = HeightDict[heightType];
    return {
      background: theme.button[variantLocal].default.background,
      borderColor: theme.button[variantLocal].default.borderColor,
      borderRadius: String(height / 2) + 'px',
      borderStyle: theme.button.borderStyle,
      borderWidth: theme.button.borderWidth,
      fontColor: theme.button[variantLocal].default.fontColor,
      height: height + 'px',
      hover: {
        background: theme.button[variantLocal].hover.background,
        borderColor: theme.button[variantLocal].hover.borderColor,
        fontColor: theme.button[variantLocal].hover.fontColor,
      },
    };
  },
)`
  align-items: center;
  background-color: ${(props): string => props.background};
  border-color: ${(props): string => props.borderColor};
  border-radius: ${(props): string | number => props.borderRadius};
  border-style: ${(props): string => props.borderStyle};
  border-width: ${(props): string | number => props.borderWidth};
  color: ${(props): string => props.fontColor};
  cursor: pointer;
  display: inline-flex;
  font-family: ${(props): string => props.theme.typography.fontFamily};
  font-size: 1rem;
  justify-content: center;
  min-width: 154px;
  height: ${(props): string | number => props.height};
  margin: ${(props): string | number => props.theme.button.margin};
  padding: ${(props): string | number => props.theme.button.padding};
  vertical-align: middle;
  white-space: nowrap;

  &:not([disabled]):hover {
    background: ${(props): string => props.hover.background};
    border-color: ${(props): string => props.hover.borderColor};
    color: ${(props): string => props.hover.fontColor};
  }

  &.mouseDown {
    outline: none;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.3;
  }
`;

export const Span = styled.span`
  align-items: center;
  display: flex;

  > svg {
    display: inline-block;
    fill: currentColor;
    font-size: 24px;
    height: 1em;
    width: 1em;
    margin-right: 16px;
  }
`;
