import styled from 'styled-components';

export const Toolbar = styled.section`
  align-items: baseline;
  display: flex;

  > * {
    margin: 0 20px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
