import * as React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import theme from '../theme/default';
import * as Styled from './unique-counter.styled';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  /**
   * Total count
   */
  total: number;

  /**
   * Unique count
   */
  unique: number;

  /**
   * Width of counter chart
   *
   * @default 205
   */
  width?: number;

  /**
   * Height of the counter chart
   *
   * @default 205
   */
  height?: number;

  /**
   * Label to display under the unique count
   *
   * @default Unique
   */
  label?: string;
}

export const UniqueCounter: React.FC<Props> = ({
  total,
  unique,
  width = 170,
  height = 170,
  label = 'Unique',
  ...props
}) => (
  <Styled.UniqueCounter {...props}>
    <PieChart height={height} width={width}>
      <Pie
        data={[{ value: unique }, { value: (total || 1) - unique }]}
        dataKey="value"
        innerRadius="90%"
        outerRadius="100%"
        startAngle={90}
        endAngle={-360}
        blendStroke={true}
      >
        <Cell fill={theme.palette.primary.main} />
        <Cell fill={theme.palette.grey.light} />
      </Pie>
    </PieChart>
    <Styled.UniqueLabel height={height} width={width}>
      <strong>{Intl.NumberFormat('en-US').format(unique)}</strong>
      <span>{label}</span>
    </Styled.UniqueLabel>
  </Styled.UniqueCounter>
);

UniqueCounter.displayName = 'UniqueCounter';

export default UniqueCounter;
