import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Trash: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Common"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Delete" stroke={props.color} strokeWidth="1.5">
        <g id="Group-" transform="translate(8.000000, 8.000000)">
          <polyline
            id="Stroke-1"
            points="0.789473684 4.75 2.89473684 4.75 19.7368421 4.75"
          />
          <path
            d="M6.05263158,4.75 L6.05263158,2.75 C6.05263158,1.646 6.99578947,0.75 8.15789474,0.75 L12.3684211,0.75 C13.5305263,0.75 14.4736842,1.646 14.4736842,2.75 L14.4736842,4.75 M17.6315789,4.75 L17.6315789,18.75 C17.6315789,19.854 16.6884211,20.75 15.5263158,20.75 L5,20.75 C3.83789474,20.75 2.89473684,19.854 2.89473684,18.75 L2.89473684,4.75"
            id="Stroke-3"
          />
          <line
            x1="8.15789474"
            y1="9.75"
            x2="8.15789474"
            y2="15.75"
            id="Stroke-5"
          />
          <line
            x1="12.3684211"
            y1="9.75"
            x2="12.3684211"
            y2="15.75"
            id="Stroke-7"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

Trash.displayName = 'DeleteIcon';

export default Trash;
