import styled from 'styled-components';

export const Input = styled.input<{ error?: string }>`
  background-color: ${(props): string =>
    props.error
      ? props.theme.palette.danger.light
      : props.theme.input.background};
  border-color: ${(props): string => props.theme.input.borderColor};
  border-radius: ${(props): string | number => props.theme.input.borderRadius};
  border-style: ${(props): string => props.theme.input.borderStyle};
  border-width: ${(props): string | number => props.theme.input.borderWidth};
  color: ${(props): string => props.theme.palette.text.primary};
  font-size: ${(props): string | number => props.theme.input.fontSize};
  margin: ${(props): string | number => props.theme.input.margin};
  padding: ${(props): string | number => props.theme.input.padding};
  width: ${(props): string | number | undefined => props.width};
  &:disabled {
    background-color: ${(props): string =>
      props.theme.input.disabledBackground};
  }
  &::placeholder {
    color: ${(props): string => props.theme.input.placeholderTextColor};
  }
`;
