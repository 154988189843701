import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Cursor: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <g
        id="Icon-/-Tours-/-Cursor"
        stroke={props.color || 'currentColor'}
        strokeWidth="1.5"
      >
        <polygon
          id="Stroke-1"
          points="27 25.0229751 20.1056218 17.6845794 26.6939755 15.0492601 8 8 13.7811161 27.0001947 16.8894223 20.6616044 23.7847814 28"
        ></polygon>
      </g>
    </g>
  </SvgIcon>
);

Cursor.displayName = 'Cursor';

export default Cursor;
