import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Home: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Common"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Home" stroke="#000000" strokeWidth="1.5">
        <path
          strokeWidth={props.strokeWidth}
          d="M20.0166667,7.49597546 L28.3011667,13.7208247 C28.7666667,14.0603619 29,14.5130782 29,15.0789736 L29,27.6418511 C29,28.4341046 28.4166667,29 27.6,29 L23.4,29 C22.5833333,29 22,28.4341046 22,27.6418511 L22,20.172032 C22,19.3797785 21.4166667,18.8138831 20.6,18.8138831 L16.4,18.8138831 C15.5833333,18.8138831 15,19.3797785 15,20.172032 L15,27.6418511 C15,28.4341046 14.4166667,29 13.6,29 L9.4,29 C8.58333333,29 8,28.4341046 8,27.6418511 L8,15.1921527 C8,14.6262573 8.23333333,14.173541 8.7,13.8340037 L16.9833333,7.60915454 C17.9166667,6.816901 19.0833333,6.816901 20.0166667,7.49597546 Z"
          id="Stroke-1"
        />
      </g>
    </g>
  </SvgIcon>
);

Home.displayName = 'Home';

export default Home;
