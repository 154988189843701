import * as React from 'react';
import Steps from './steps';

interface Props {
  steps: string[];
  currentStepIndex: number;
  removeSteps?: boolean;
}

const StepsContainer: React.FunctionComponent<Props> = ({
  steps,
  currentStepIndex,
  removeSteps,
}) => {
  let rawProgressBarWidth = 0;
  const [progressBarWidth, setProgressBarWidth] = React.useState<number>(0);
  const barDiv = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleResize(): void {
      if (barDiv.current) {
        setProgressBarWidth(
          (barDiv.current.offsetWidth + 14) * currentStepIndex,
        );
      }
    }
    if (window) {
      window.removeEventListener('resize', handleResize);
      window.addEventListener('resize', handleResize);
    }
  }, [currentStepIndex, setProgressBarWidth]);

  if (barDiv.current) {
    rawProgressBarWidth = (barDiv.current.offsetWidth + 14) * currentStepIndex;
  }
  React.useEffect(() => {
    setProgressBarWidth(rawProgressBarWidth);
  }, [rawProgressBarWidth]);

  const renderSteps = (): JSX.Element[] => {
    return steps.map((step, index) => {
      return (
        <p
          key={index}
          data-activestep={currentStepIndex === index ? true : false}
        >
          {step}
        </p>
      );
    });
  };

  const renderProgressMarker = (): JSX.Element[] => {
    const progressMarker = [];
    for (const [index] of steps.entries()) {
      const alwaysActive = index === 0 ? true : false;
      const currentStep = currentStepIndex >= index ? true : false;
      progressMarker.push(
        <React.Fragment key={index}>
          <aside
            data-alwaysactive={alwaysActive}
            data-activestep={currentStep}
          ></aside>
          {index !== steps.length - 1 && (
            <div key={`${index}/${steps.length}`} ref={barDiv} />
          )}
        </React.Fragment>,
      );
    }
    return progressMarker;
  };

  return (
    <Steps
      renderSteps={renderSteps}
      renderProgressMarker={renderProgressMarker}
      currentStepIndex={currentStepIndex}
      progressBarWidth={progressBarWidth}
      setProgressBarWidth={setProgressBarWidth}
      removeSteps={removeSteps}
      stepCount={steps.length}
    />
  );
};

export default StepsContainer;
