import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const List: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g id="Tours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" fill="#000000" transform="translate(9.000000, 11.000000)">
        <rect
          id="Rectangle"
          x="0"
          y="0"
          width="18"
          height="1.5"
          rx="0.75"
        ></rect>
        <rect
          id="Rectangle"
          x="0"
          y="4"
          width="18"
          height="1.5"
          rx="0.75"
        ></rect>
        <rect
          id="Rectangle"
          x="0"
          y="8"
          width="18"
          height="1.5"
          rx="0.75"
        ></rect>
        <rect
          id="Rectangle"
          x="0"
          y="12"
          width="18"
          height="1.5"
          rx="0.75"
        ></rect>
      </g>
    </g>
  </SvgIcon>
);

List.displayName = 'List';

export default List;
