import * as React from 'react';
import * as Styled from './heading.styled';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  /**
   * Size of the heading
   *
   * @default 1
   */
  size?: number;
}

export const Heading: React.FC<Props> = ({
  children,
  size = 1,
  style,
  ...props
}) => (
  <Styled.Heading
    data-cy="heading"
    style={{
      ...style,
      fontSize: `${1.75 * size}em`,
    }}
    {...props}
  >
    {children}
  </Styled.Heading>
);

Heading.displayName = 'Heading';

export default Heading;
