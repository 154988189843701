import * as React from 'react';
import { LinkifyIt } from '../../../core/linkify-it-utils';

const linkify = LinkifyIt();

export interface Props {
  text: string;
}

export const LinkifyInner: React.FC<Props> = ({ text: s }) => {
  const matches = linkify.match(s) ?? [];
  const ss: string[] = [];
  for (let i = 0; i <= matches.length; i++) {
    const start = i > 0 ? matches[i - 1].lastIndex : 0;
    const end = i < matches.length ? matches[i].index : undefined;
    ss.push(s.slice(start, end));
  }
  return (
    <>
      <span key={0}>{ss[0]}</span>
      {matches.map((match, i) => (
        <>
          <a
            key={i * 2 + 1}
            href={match.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {match.text}
          </a>
          {ss[i + 1]}
        </>
      ))}
    </>
  );
};

export const Linkify: React.FC<Props> = ({ text }) => {
  return (
    <>
      {text.split('\n').map((s, i) => (
        <>
          {i > 0 && <br />}
          <LinkifyInner key={i} text={s} />
        </>
      ))}
    </>
  );
};

Linkify.displayName = 'Linkify';
export default Linkify;
