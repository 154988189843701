import * as React from 'react';
import App, { AppContext } from 'next/app';
import Auth from './auth';
import SsrAuthStorage from './ssr-auth-storage';

export default function WithAmplifyAuth(Application: typeof App): typeof App {
  return class WithAmplifyAuth extends App {
    public static configure(storage: SsrAuthStorage) {
      Auth.configure({
        identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
        region: process.env.COGNITO_REGION,
        storage,
        userPoolId: process.env.COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.COGNITO_USER_POOL_WEB_CLIENT_ID,
      });
    }

    public static async getInitialProps(context: AppContext) {
      let initialProps = { pageProps: {} };

      const storage = new SsrAuthStorage(context.ctx);
      WithAmplifyAuth.configure(storage);

      if (Application.getInitialProps) {
        initialProps = await Application.getInitialProps(context);
      }

      return initialProps;
    }

    public componentDidMount() {
      const storage = new SsrAuthStorage();
      WithAmplifyAuth.configure(storage);
    }

    public render() {
      return <Application {...this.props} />;
    }
  };
}
