import { WidthProperty } from 'csstype';
import styled, { keyframes } from 'styled-components';

interface SpinProps {
  spinDirection: 'normal' | 'reverse';
  disabledCursor: 'default' | 'not-allowed';
  diameter: WidthProperty<number>;
  fill: string;
  matrixValues: string;
  rotate: number;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Svg = styled('svg')<SpinProps>`
  display: inline-block;
  fill: ${({ color }) => color};
  height: ${({ diameter }) =>
    String(diameter) + (typeof diameter === 'number' ? 'px' : '')};
  transform: ${({ rotate, matrixValues }) =>
    `rotate(${rotate}deg) ${matrixValues}`};
  transition-duration: 300ms;
  transition-property: fill, opacity;
  user-select: none;
  width: ${({ diameter }) =>
    String(diameter) + (typeof diameter === 'number' ? 'px' : '')};
  &.spin {
    animation: ${spin} 0.9s linear infinite
      ${({ spinDirection }) => spinDirection};
  }
  &[disabled] {
    cursor: ${({ disabledCursor }) => disabledCursor};
    opacity: 0.2;
  }
`;
