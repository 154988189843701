import * as React from 'react';
import { useTranslation } from 'react-i18next';
import fillMissingAnalytics, {
  convertDateToUTC,
} from '@r360-tours/core/lib/fillMissingAnalytics';
import dayjs from 'dayjs';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import theme from '../theme/default';
import CustomTooltip from './custom-tooltip';
import * as Styled from './histogram-chart.styled';

export interface Props {
  /**
   * Array of data elements to display in the histogram
   *
   * @default []
   */
  data?: Array<{
    key: string;
    count: number;
  }>;

  /**
   * Height of the histogram
   */
  height?: number;

  /**
   * Minimum number of days to display in the histogram
   *
   * @default 30
   */
  minimumDays?: number;

  /**
   * Width of the histogram
   */
  width?: number;
}

export const HistogramChart: React.FC<Props> = ({
  data = [],
  height,
  minimumDays = 30,
  width,
}) => {
  const { t } = useTranslation();
  let localData = fillMissingAnalytics(data);

  // Check to see if we need to pad the dataset with additional days
  const padWith = Math.max(0, minimumDays - localData.length);
  if (padWith > 0) {
    const lastTime = new Date(localData.length ? localData[0].key : new Date());

    localData = Array(padWith)
      .fill({ count: 0 })
      .map((entry) => {
        lastTime.setUTCSeconds(lastTime.getUTCSeconds() - 86400);
        return {
          ...entry,
          key: convertDateToUTC(dayjs(lastTime).format('YYYY-MM-DD')),
        };
      })
      .reverse()
      .concat(localData);
  }

  return (
    <Styled.Container>
      <ResponsiveContainer height={height} width={width}>
        <BarChart data={localData}>
          <XAxis
            axisLine={false}
            dataKey="key"
            interval="preserveStartEnd"
            minTickGap={100}
            tick={{ fontSize: 12 }}
            tickFormatter={(value): string =>
              t('Date', { date: new Date(value) })
            }
            tickLine={false}
            tickMargin={15}
          />
          <YAxis
            axisLine={false}
            dataKey="count"
            interval="preserveStartEnd"
            minTickGap={100}
            tick={{ fontSize: 12 }}
            tickLine={false}
            tickMargin={15}
          />
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Bar
            dataKey="count"
            minPointSize={1}
            fill={theme.palette.primary.main}
          />
        </BarChart>
      </ResponsiveContainer>
    </Styled.Container>
  );
};

HistogramChart.displayName = 'HistoricalVisitChart';

export default HistogramChart;
