import * as React from 'react';
import * as Styled from './progress-bar.styled';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Completion percentage you want to render
   *
   * @default 0
   */
  percentage?: number;
}

export const ProgressBar: React.FC<Props> = ({ percentage = 0, ...props }) => (
  <Styled.ProgressBar {...props}>
    <Styled.Background />
    <Styled.Bar style={{ width: `${percentage}%` }} />
  </Styled.ProgressBar>
);

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
