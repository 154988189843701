import styled from 'styled-components';

export const RadioWrapper = styled.label<{ disabled: boolean }>`
  position: relative;
  ${(props): string => (props.disabled ? 'opacity: 0.3;' : '')}
  cursor: pointer;
  display: flex;
`;

const radioSize = '18px';

export const Circle = styled.span`
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: ${radioSize};
  height: ${radioSize};
  border: 1px solid ${(props): string => props.theme.palette.text.primary};
  border-radius: 50%;
`;

export const Check = styled.span`
  background-color: ${(props): string => props.theme.palette.primary.contrast};
  border-style: solid;
  border-color: ${(props): string => props.theme.palette.text.primary};
  border-width: 0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.1s linear;
  will-change: width, height, border-width;
  display: inline-block;
  width: 0;
  height: 0;
`;

export const Radio = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  :checked {
    + ${Circle} {
      ${Check} {
        width: ${radioSize};
        height: ${radioSize};
        border-width: 5px;
      }
    }
  }
`;

export const Label = styled.span`
  margin-left: 33px;
`;
