import * as React from 'react';

interface Props {
  height?: string | number;
  src: string;
  width?: string | number;
}

const Map: React.FunctionComponent<Props> = ({
  height = '100%',
  src,
  width = '100%',
}) => (
  <iframe
    allowFullScreen
    frameBorder="0"
    height={height}
    src={src}
    width={width}
  />
);

Map.displayName = 'Map';

export default Map;
