import * as React from 'react';
import { TooltipProps } from 'recharts';
import { useTranslation } from '../../../../core/i18n';
import * as Styled from './custom-tooltip.styled';

export const CustomTooltip: React.FC<TooltipProps> = (props) => {
  const { t } = useTranslation();

  if (!props.label || !props.payload || props.payload.length === 0) {
    return null;
  }

  return (
    <Styled.Tooltip {...props}>
      <Styled.Label>{t('Date', { date: new Date(props.label) })}</Styled.Label>
      <Styled.Label>
        {props.payload[0] && props.payload[0].value} {t('Visits')}
      </Styled.Label>
    </Styled.Tooltip>
  );
};

CustomTooltip.displayName = 'CustomTooltip';

export default CustomTooltip;
