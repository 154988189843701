import * as React from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Manager, Popper, Reference } from 'react-popper';
import classNames from 'classnames';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import { Placement } from 'popper.js';
import * as Styled from './dropdown-menu.styled';

export interface Props {
  hasArrow?: boolean;
  buttonTitle?: string | null;
  buttonTitleIcon?: JSX.Element;
  disabled?: boolean;
  onChange?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedItem: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stateAndhelpers: ControllerStateAndHelpers<any>,
  ) => void;
  onSelect?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedItem: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stateAndHelpers: ControllerStateAndHelpers<any>,
  ) => void;
  placement?: Placement;
  style?: React.CSSProperties;
  popperStyle?: React.CSSProperties;
  toggleType?: 'ellipsis' | 'button';
  // height type for toggleType 'button'
  heightType?: 'default' | 'mini';
  buttonStyle?: React.CSSProperties;
  toggleStyle?: React.CSSProperties;
  rotateArrow?: boolean;
  aspectRatioPopper?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function itemToString(item: any): string {
  if (!item) {
    return '';
  }

  if (Object.prototype.hasOwnProperty.call(item, 'props')) {
    return item.props.value;
  }

  return item;
}

function defaultOnSelect(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedItem: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stateAndHelpers: ControllerStateAndHelpers<any>,
): void {
  if (!selectedItem || !selectedItem.props || !selectedItem.props.onSelect) {
    return;
  }

  if (selectedItem.props.disabled) {
    return;
  }

  selectedItem.props.onSelect(selectedItem, stateAndHelpers);
}

const DropdownMenu: React.FunctionComponent<Props> = ({
  hasArrow = false,
  buttonTitle,
  buttonTitleIcon,
  children,
  disabled = false,
  onChange,
  onSelect = defaultOnSelect,
  placement = 'bottom',
  style,
  popperStyle,
  toggleType = 'ellipsis',
  heightType = 'default',
  buttonStyle,
  toggleStyle,
  rotateArrow = false,
  aspectRatioPopper = false,
}) => {
  const [prevIsOpen, setPrevIsOpen] = React.useState(false);
  const [mouseDown, setMouseDown] = React.useState(false);
  const onMouseDown = (): void => {
    setMouseDown(true);
  };
  const onKeyDown = (): void => {
    setMouseDown(false);
  };
  React.useEffect(() => {
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('keydown', onKeyDown);
    return (): void => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const focusable = React.useMemo(() => {
    return toggleType === 'ellipsis' ? { tabIndex: 0 } : {};
  }, [toggleType]);

  return (
    <Downshift
      itemToString={itemToString}
      onChange={onChange}
      onSelect={onSelect}
    >
      {({
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
      }): JSX.Element => (
        <div>
          <Manager>
            <Reference>
              {({ ref }): JSX.Element => (
                <Styled.Toggle
                  {...getToggleButtonProps()}
                  className={classNames({ mouseDown })}
                  ref={ref}
                  {...focusable}
                  style={toggleStyle}
                >
                  {toggleType === 'ellipsis' ? (
                    <Styled.Ellipsis diameter={32} />
                  ) : (
                    <Styled.Button
                      variant="dropdown"
                      disabled={disabled}
                      heightType={heightType}
                      style={buttonStyle}
                    >
                      {buttonTitleIcon ?? null}
                      <span className="buttonTitle">{buttonTitle}</span>
                      <MdKeyboardArrowDown
                        style={{
                          transform: `${
                            rotateArrow ? 'rotate(180deg)' : 'unset'
                          }`,
                        }}
                      />
                    </Styled.Button>
                  )}
                </Styled.Toggle>
              )}
            </Reference>
            <Popper placement={placement}>
              {(popperProps): JSX.Element => {
                if (isOpen !== prevIsOpen) {
                  if (isOpen) {
                    popperProps.scheduleUpdate();
                  }
                  setPrevIsOpen(isOpen);
                }
                return (
                  <>
                    {isOpen && !disabled && (
                      <Styled.Popper
                        data-placement={popperProps.placement}
                        aspectRatioPopper={aspectRatioPopper}
                        ref={popperProps.ref}
                        style={
                          toggleType === 'ellipsis'
                            ? {
                                ...popperProps.style,
                                left: hasArrow
                                  ? '-5px'
                                  : popperProps.style.left,
                                ...popperStyle,
                              }
                            : {
                                ...popperProps.style,
                                ...popperStyle,
                                minWidth: '182px',
                                paddingTop: 0,
                              }
                        }
                      >
                        <Styled.Menu
                          {...getMenuProps({ refKey: 'innerRef' })}
                          style={style}
                        >
                          {React.Children.map(children, (child, index) =>
                            React.cloneElement(
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              child as any,
                              {
                                ...getItemProps({ index, item: child }),
                                className: classNames({
                                  highlighted: highlightedIndex === index,
                                }),
                              },
                            ),
                          )}
                        </Styled.Menu>
                        {hasArrow && (
                          <Styled.Arrow
                            className={classNames({ isOpen })}
                            ref={popperProps.arrowProps.ref}
                            style={popperProps.arrowProps.style}
                          />
                        )}
                      </Styled.Popper>
                    )}
                  </>
                );
              }}
            </Popper>
          </Manager>
        </div>
      )}
    </Downshift>
  );
};

DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
