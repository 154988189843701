import { i18next } from '@r360-tours/core';

const ZERO_DECIMAL_CURRENCIES: readonly string[] = [
  'bif',
  'clp',
  'djf',
  'gnf',
  'jpy',
  'kmf',
  'krw',
  'mga',
  'pyg',
  'rwf',
  'ugx',
  'vnd',
  'vuv',
  'xaf',
  'xof',
  'xpf',
];

const formatCurrency = (
  currency: string | undefined,
  amount: number | undefined,
  minimumFractionDigits?: number,
): string | null => {
  if (amount === null || amount === undefined) {
    return null;
  }
  const formattedAmount =
    currency && ZERO_DECIMAL_CURRENCIES.includes(currency.toLowerCase())
      ? amount
      : amount / 100;
  return formattedAmount.toLocaleString(i18next.language || 'en-US', {
    currency,
    minimumFractionDigits,
    style: 'currency',
  });
};

export const formatAnalyticsCurrency = (
  currency: string | undefined,
  amount: number | undefined,
): number | null => {
  if (amount === null || amount === undefined) {
    return null;
  }
  const formattedAmount =
    currency && ZERO_DECIMAL_CURRENCIES.includes(currency.toLowerCase())
      ? amount
      : amount / 100;
  return formattedAmount;
};

export default formatCurrency;
