import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Card: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Atom-/-Business-Csrd" strokeWidth="1.5">
        <g id="Group" transform="translate(6.000000, 8.000000)">
          <line x1="15" y1="6" x2="20" y2="6" id="Path" stroke="#000000"></line>
          <line
            x1="15"
            y1="10"
            x2="20"
            y2="10"
            id="Path"
            stroke="#000000"
          ></line>
          <path
            d="M2.4,2.30926389e-14 L21.6,2.30926389e-14 C22.92,2.30926389e-14 24,1.08 24,2.4 L24,16.8 C24,18.12 22.92,19.2 21.6,19.2 L2.4,19.2 C1.08,19.2 0,18.12 0,16.8 L0,2.4 C0,1.08 1.08,2.30926389e-14 2.4,2.30926389e-14 Z"
            id="Path"
            stroke="#000000"
          ></path>
          <g
            id="Group-5"
            transform="translate(4.000000, 3.000000)"
            stroke="#050101"
          >
            <path
              d="M3.7328,5.1289 L3.7328,5.1289 C2.7808,5.1289 1.9558,4.3039 1.9558,3.3519 L1.9558,2.5269 C1.9558,1.5749 2.7808,0.7499 3.7328,0.7499 C4.6848,0.7499 5.5098,1.5749 5.5098,2.5269 L5.5098,3.4159 C5.5098,4.3039 4.6848,5.1289 3.7328,5.1289 Z"
              id="Stroke-1"
            ></path>
            <path
              d="M6.7157,12.1294 L6.7157,10.2884 C6.7157,8.6394 5.3827,7.3054 3.7327,7.3054 C2.0827,7.3054 0.7497,8.6394 0.7497,10.2884 L0.7497,12.1294"
              id="Stroke-3"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Card.displayName = 'Card';

export default Card;
