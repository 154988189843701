import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../theme/default';
import * as Styled from './hotspot.styled';

export interface HotspotProps {
  /**
   * The vertical field of view, in the [0, π] range.
   *
   * @default 0
   */
  fov?: number;

  /**
   * The optional callback event for when the MouseClick event is triggered.
   */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;

  /**
   * The optional callback event for when the MouseEnter event is triggered.
   */
  onEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;

  /**
   * The optional callback event for when the MouseLeave event is triggered.
   */
  onLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;

  /**
   * Additional options for the rectilinear view.
   *
   * @default {}
   */
  options?: {
    perspective?: {
      /**
       * This value will be added to the transform CSS rule that places the
       * hotspot in its position. This enables transforming the hotspot to
       * overlay a certain surface on the panorama. For instance, one possible
       * value would be rotateX(0.5rad) rotateY(-0.1rad).
       */
      extraTransforms?: string;

      /**
       * Transform hotspot as if it were on the surface of a sphere. The
       * hotspot will then always cover the same part of the 360° image. This
       * feature will only work on browsers with CSS 3D Transforms. When radius
       * is enabled the hotspots are automatically centered. This value
       * represents the radius of the sphere where the hotspot is. Therefore,
       * the smaller this value, the larger the hotspot will appear on the
       * screen.
       */
      radius?: number;
    };
  };

  /**
   * The pitch angle, in the [-π, π] range. When params.pitch < 0, the hotspot
   * moves downwards. When params.pitch > 0, the hotspot moves upwards.
   *
   * @default 0
   */
  pitch?: number;

  /**
   * The yaw angle, in the [-π, π] range. When params.yaw < 0, the hotspot
   * moves to the left. When params.yaw > 0, the hotspot is moves to the right.
   *
   * @default 0
   */
  yaw?: number;

  /**
   * The x coordinate, in the [0 , 1] range. When params.x < 0.5, the hotspot
   * moves left of center. When params.x > 0.5, the hotspot moves right of
   * center.
   *
   * @default 0
   */
  x?: number;

  /**
   * The y coordinate, in the [0 , 1] range. When params.y < 0.5, the hotspot
   * moves above center. When params.y > 0.5, the hotspot moves below center.
   *
   * @default 0
   */
  y?: number;
}

const Hotspot: React.FC<HotspotProps> = ({
  children,
  fov = 0,
  options = {},
  pitch = 0,
  x = 0,
  y = 0,
  yaw = 0,
  ...props
}) => (
  <Styled.Hotspot
    {...props}
    data-fov={fov}
    data-hotspot
    data-options={JSON.stringify(options)}
    data-pitch={pitch}
    data-x={x}
    data-y={y}
    data-yaw={yaw}
  >
    <ThemeProvider theme={theme}>
      <>{children}</>
    </ThemeProvider>
  </Styled.Hotspot>
);

Hotspot.displayName = 'Hotspot';

export default Hotspot;
