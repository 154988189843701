import * as React from 'react';
import * as Styled from './rounded-square-image.styled';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  /**
   * The diameter (in pixels) you want the image to render in.
   *
   */
  diameter?: number;

  /**
   * Any valid image source.
   */
  image?: string | null;
}

export const RoundedSquareImage: React.FC<Props> = ({
  diameter,
  image,
  children,
  ...props
}) => (
  <Styled.Figure {...props}>
    {image ? (
      <Styled.Image height={diameter} src={image} width={diameter} />
    ) : (
      <Styled.Default
        style={{
          height: diameter,
          width: diameter,
        }}
      >
        {children}
      </Styled.Default>
    )}
  </Styled.Figure>
);

RoundedSquareImage.displayName = 'RoundedSquareImage';

export default RoundedSquareImage;
