import * as cookie from 'cookie';

function removeCookieInner(name: string, path: string, domain?: string) {
  const expires = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
  document.cookie = cookie.serialize(name, '', {
    expires,
    path,
    ...(domain && { domain }),
  });
}

export function removeCookie(
  name: string,
  path?: string,
  domain?: string,
): void {
  const paths = path
    ? [path]
    : window.location.pathname
        .split('/')
        .map((_, i, arr) => '/' + arr.slice(1, i + 1).join('/'));
  paths.map((p) => removeCookieInner(name, p, domain));
}

export function removeAuthCookie(name: string): void {
  removeCookie(
    name,
    process.env.AUTH_COOKIE_PATH,
    process.env.AUTH_COOKIE_DOMAIN,
  );
}

// Note: https://github.com/ricohapi/r360-tours-web/issues/761#issuecomment-654614274
export function removeAuthCookies(): void {
  const cookies = cookie.parse(document.cookie);
  Object.keys(cookies)
    .filter((name) => name.startsWith('CognitoIdentityServiceProvider.'))
    .forEach(removeAuthCookie);
}
