import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

const Image: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 22 18" {...props}>
    <g
      strokeWidth="1.5"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke={props.color || 'black'}
    >
      <path d="M21 15.392a1.81 1.81 0 0 1-1.818 1.798H2.818A1.81 1.81 0 0 1 1 15.392V5.497a1.81 1.81 0 0 1 1.818-1.799h3.637l1.223-1.816A2 2 0 0 1 9.337 1h3.326a2 2 0 0 1 1.659.882l1.223 1.816h3.637A1.81 1.81 0 0 1 21 5.498v9.894z" />
      <path d="M11 6.397c2.008 0 3.636 1.61 3.636 3.598 0 1.987-1.628 3.598-3.636 3.598s-3.636-1.611-3.636-3.598c0-1.987 1.628-3.598 3.636-3.598z" />
    </g>
  </SvgIcon>
);
Image.displayName = 'Image';
export default Image;
