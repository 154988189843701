import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const FullTour: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 60 60" {...props}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Full-Tour" stroke="#2C2828" strokeWidth="1.2">
        <g id="Full-Tour-Icon" transform="translate(3.000000, 6.000000)">
          <path
            d="M51.9272727,48 L2.47272727,48 C1.11272727,48 0,47.0181818 0,45.8181818 L0,2.18181818 C0,0.981818182 1.11272727,0 2.47272727,0 L51.9272727,0 C53.2872727,0 54.4,0.981818182 54.4,2.18181818 L54.4,45.8181818 C54.4,47.0181818 53.2872727,48 51.9272727,48 Z"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <circle id="Oval" cx="39.8716418" cy="4.05970149" r="1"></circle>
          <circle id="Oval" cx="44.7432836" cy="4.05970149" r="1"></circle>
          <circle id="Oval" cx="49.6149254" cy="4.05970149" r="1"></circle>
          <rect
            id="Rectangle"
            strokeLinecap="round"
            strokeLinejoin="round"
            x="0"
            y="7.30746269"
            width="54.4"
            height="21.1104478"
          ></rect>
          <line
            x1="8.93134328"
            y1="34.1253731"
            x2="30.041791"
            y2="34.1253731"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="0.618181818"
            y1="44.2985075"
            x2="53.7818182"
            y2="44.2985075"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="37.2"
            y1="43.6"
            x2="37.3492537"
            y2="29.2298507"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <line
            x1="30.041791"
            y1="38.1970149"
            x2="8.93134328"
            y2="38.1970149"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <path
            d="M4.54824901,22.4883345 C5.29883268,22.5961448 6.04941634,22.6500499 6.8,22.6500499 C7.55058087,22.6500499 8.30116174,22.5961452 9.05174261,22.4883357 C9.31415159,22.4506314 9.55743246,22.6328026 9.59512376,22.8952135 C9.59837045,22.9178172 9.6,22.9406239 9.6,22.9634596 L9.6,25.0319792 C9.60009327,25.2971274 9.38514819,25.5120724 9.12,25.5120724 C9.09605808,25.5120724 9.0721497,25.5102815 9.04847497,25.5067146 C8.29898331,25.393794 7.54949166,25.3373337 6.8,25.3373337 C6.05051288,25.3373337 5.30102576,25.3937933 4.55153864,25.5067125 C4.28938932,25.5462367 4.04485363,25.3657376 4.00535706,25.1035841 C4.00179068,25.0799128 4,25.0560078 4,25.0320693 L4,22.9634896 C3.99996847,22.6983755 4.21488591,22.4834581 4.48,22.4834581 C4.5028367,22.4834581 4.5256443,22.4850877 4.54824901,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M12.548249,22.4883345 C13.2988327,22.5961448 14.0494163,22.6500499 14.8,22.6500499 C15.5505809,22.6500499 16.3011617,22.5961452 17.0517426,22.4883357 C17.3141516,22.4506314 17.5574325,22.6328026 17.5951238,22.8952135 C17.5983704,22.9178172 17.6,22.9406239 17.6,22.9634596 L17.6,25.0319792 C17.6000933,25.2971274 17.3851482,25.5120724 17.12,25.5120724 C17.0960581,25.5120724 17.0721497,25.5102815 17.048475,25.5067146 C16.2989833,25.393794 15.5494917,25.3373337 14.8,25.3373337 C14.0505129,25.3373337 13.3010258,25.3937933 12.5515386,25.5067125 C12.2893893,25.5462367 12.0448536,25.3657376 12.0053571,25.1035841 C12.0017907,25.0799128 12,25.0560078 12,25.0320693 L12,22.9634896 C11.9999685,22.6983755 12.2148859,22.4834581 12.48,22.4834581 C12.5028367,22.4834581 12.5256443,22.4850877 12.548249,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M20.548249,22.4883345 C21.2988327,22.5961448 22.0494163,22.6500499 22.8,22.6500499 C23.5505809,22.6500499 24.3011617,22.5961452 25.0517426,22.4883357 C25.3141516,22.4506314 25.5574325,22.6328026 25.5951238,22.8952135 C25.5983704,22.9178172 25.6,22.9406239 25.6,22.9634596 L25.6,25.0319792 C25.6000933,25.2971274 25.3851482,25.5120724 25.12,25.5120724 C25.0960581,25.5120724 25.0721497,25.5102815 25.048475,25.5067146 C24.2989833,25.393794 23.5494917,25.3373337 22.8,25.3373337 C22.0505129,25.3373337 21.3010258,25.3937933 20.5515386,25.5067125 C20.2893893,25.5462367 20.0448536,25.3657376 20.0053571,25.1035841 C20.0017907,25.0799128 20,25.0560078 20,25.0320693 L20,22.9634896 C19.9999685,22.6983755 20.2148859,22.4834581 20.48,22.4834581 C20.5028367,22.4834581 20.5256443,22.4850877 20.548249,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M28.548249,22.4883345 C29.2988327,22.5961448 30.0494163,22.6500499 30.8,22.6500499 C31.5505809,22.6500499 32.3011617,22.5961452 33.0517426,22.4883357 C33.3141516,22.4506314 33.5574325,22.6328026 33.5951238,22.8952135 C33.5983704,22.9178172 33.6,22.9406239 33.6,22.9634596 L33.6,25.0319792 C33.6000933,25.2971274 33.3851482,25.5120724 33.12,25.5120724 C33.0960581,25.5120724 33.0721497,25.5102815 33.048475,25.5067146 C32.2989833,25.393794 31.5494917,25.3373337 30.8,25.3373337 C30.0505129,25.3373337 29.3010258,25.3937933 28.5515386,25.5067125 C28.2893893,25.5462367 28.0448536,25.3657376 28.0053571,25.1035841 C28.0017907,25.0799128 28,25.0560078 28,25.0320693 L28,22.9634896 C27.9999685,22.6983755 28.2148859,22.4834581 28.48,22.4834581 C28.5028367,22.4834581 28.5256443,22.4850877 28.548249,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M36.548249,22.4883345 C37.2988327,22.5961448 38.0494163,22.6500499 38.8,22.6500499 C39.5505809,22.6500499 40.3011617,22.5961452 41.0517426,22.4883357 C41.3141516,22.4506314 41.5574325,22.6328026 41.5951238,22.8952135 C41.5983704,22.9178172 41.6,22.9406239 41.6,22.9634596 L41.6,25.0319792 C41.6000933,25.2971274 41.3851482,25.5120724 41.12,25.5120724 C41.0960581,25.5120724 41.0721497,25.5102815 41.048475,25.5067146 C40.2989833,25.393794 39.5494917,25.3373337 38.8,25.3373337 C38.0505129,25.3373337 37.3010258,25.3937933 36.5515386,25.5067125 C36.2893893,25.5462367 36.0448536,25.3657376 36.0053571,25.1035841 C36.0017907,25.0799128 36,25.0560078 36,25.0320693 L36,22.9634896 C35.9999685,22.6983755 36.2148859,22.4834581 36.48,22.4834581 C36.5028367,22.4834581 36.5256443,22.4850877 36.548249,22.4883345 Z"
            id="Rectangle"
          ></path>
          <path
            d="M44.548249,22.4883345 C45.2988327,22.5961448 46.0494163,22.6500499 46.8,22.6500499 C47.5505809,22.6500499 48.3011617,22.5961452 49.0517426,22.4883357 C49.3141516,22.4506314 49.5574325,22.6328026 49.5951238,22.8952135 C49.5983704,22.9178172 49.6,22.9406239 49.6,22.9634596 L49.6,25.0319792 C49.6000933,25.2971274 49.3851482,25.5120724 49.12,25.5120724 C49.0960581,25.5120724 49.0721497,25.5102815 49.048475,25.5067146 C48.2989833,25.393794 47.5494917,25.3373337 46.8,25.3373337 C46.0505129,25.3373337 45.3010258,25.3937933 44.5515386,25.5067125 C44.2893893,25.5462367 44.0448536,25.3657376 44.0053571,25.1035841 C44.0017907,25.0799128 44,25.0560078 44,25.0320693 L44,22.9634896 C43.9999685,22.6983755 44.2148859,22.4834581 44.48,22.4834581 C44.5028367,22.4834581 44.5256443,22.4850877 44.548249,22.4883345 Z"
            id="Rectangle"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

FullTour.displayName = 'FullTour';

export default FullTour;
