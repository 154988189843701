import * as React from 'react';
import classnames from 'classnames';
import * as Styled from './size-button.styled';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The size you want to render.
   *
   * @default 'small'
   */
  size?: 'small' | 'medium' | 'large';
}

const SizeButton: React.FC<Props> = ({
  className,
  size = 'small',
  ...props
}) => {
  const buttonClasses = classnames(className, {
    lg: size === 'large',
    md: size === 'medium',
    sm: size === 'small',
  });
  return <Styled.Size type="button" className={buttonClasses} {...props} />;
};

SizeButton.displayName = 'SizeButton';

export default SizeButton;
