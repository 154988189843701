import * as React from 'react';
import Button, { ButtonProps } from '../button';
import * as Styled from './copy-button.styled';

export interface CopyButtonProps extends ButtonProps {
  copiedLabel: string;
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  children,
  copiedLabel,
  onClick,
  ...props
}) => {
  const [showCopied, setShowCopied] = React.useState(false);
  const onClickButton = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
    onClick?.(e);
    setShowCopied(true);
  };

  return (
    <Styled.Wrapper>
      <Button onClick={onClickButton} {...props}>
        {showCopied && (
          <Styled.Copied onAnimationEnd={(): void => setShowCopied(false)}>
            {copiedLabel}
          </Styled.Copied>
        )}
        {children}
      </Button>
    </Styled.Wrapper>
  );
};

CopyButton.displayName = 'Button';

export default CopyButton;
