import * as React from 'react';
import * as ReactDOM from 'react-dom';

export type Props = {
  style?: React.CSSProperties;
};

const Overlay: React.FC<Props> = ({ style, children }) => {
  const div = (
    <div
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        height: '100vh',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: 1,
        ...style,
      }}
    >
      {children}
    </div>
  );
  return ReactDOM.createPortal(div, document.body);
};

Overlay.displayName = 'Overlay';
export default Overlay;
