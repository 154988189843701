import styled from 'styled-components';

export const Autocomplete = styled.div`
  position: relative;
`;

export const Dropdown = styled.ul`
  background-color: ${(props): string => props.theme.background.primary.main};
  border: 1px solid ${(props): string => props.theme.palette.grey.action};
  list-style: none;
  margin: 0;
  max-height: 16rem;
  overflow-y: auto;
  padding: 0;
  width: 100%;
`;

export const DropdownItem = styled.li`
  cursor: pointer;
  padding: 1rem;
  font-size: 1rem;

  &:hover,
  &.highlighted {
    background-color: ${(props): string => props.theme.palette.grey.action};
  }

  &:last-child {
    border-bottom: none;
  }
`;
