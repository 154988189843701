import styled from 'styled-components';

export const Hotspot = styled.div.attrs({
  role: 'button',
})`
  z-index: 0;

  &:hover {
    z-index: 1;
  }
`;
