import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Blur: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Icon-/-Tours-/-blur"
        stroke={props.color || 'currentColor'}
        strokeWidth="1.5"
      >
        <path
          d="M11.0696908,17.3548918 C9.13130794,20.6189483 9.88576246,24.7628693 12.8592231,27.1840869 C15.8326838,29.6053044 20.1673162,29.6053044 23.1407769,27.1840869 C26.1142375,24.7628693 26.8686921,20.6189483 24.9303092,17.3548918 L18.0013333,7 L11.0683575,17.3548918 L11.0696908,17.3548918 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </SvgIcon>
);

Blur.displayName = 'Blur';

export default Blur;
