import styled from 'styled-components';

export const Information = styled.div`
  flex: 1;
  margin-left: 20px;

  > div {
    border-top: 1px solid ${(props): string => props.theme.palette.grey.light};
    display: flex;
    height: 64px;
    padding: 12.5px 0;
    white-space: nowrap;
    align-items: center;

    span:first-child {
      flex: 2;
      margin: 0;
      line-height: 1rem;
      color: ${(props): string => props.theme.palette.grey.dark};
    }

    span:last-child {
      flex: 1;
      line-height: 1rem;
      text-align: right;
    }
  }

  .notAvailable span:last-child {
    color: ${(props): string => props.theme.palette.grey.normal};
  }

  @media all and (max-width: 1160px) {
    flex: 2;
  }
`;
