import * as React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import classNames from 'classnames';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import { Placement } from 'popper.js';
import theme from '../theme/default';
import * as Styled from './floating-label-dropdown.styled';

export interface Props {
  aspectRatioPopper?: boolean;
  disabled?: boolean;
  error?: string;
  label: string | null;
  onChange?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedItem: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stateAndhelpers: ControllerStateAndHelpers<any>,
  ) => void;
  onSelect?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedItem: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stateAndHelpers: ControllerStateAndHelpers<any>,
  ) => void;
  placement?: Placement;
  popperStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  toggleStyle?: React.CSSProperties;
  value?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function itemToString(item: any): string {
  if (!item) {
    return '';
  }

  if (Object.prototype.hasOwnProperty.call(item, 'props')) {
    return item.props.value;
  }

  return item;
}

function defaultOnSelect(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedItem: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stateAndHelpers: ControllerStateAndHelpers<any>,
): void {
  if (!selectedItem || !selectedItem.props || !selectedItem.props.onSelect) {
    return;
  }

  if (selectedItem.props.disabled) {
    return;
  }

  selectedItem.props.onSelect(selectedItem, stateAndHelpers);
}

const FloatingLabelDropdown: React.FunctionComponent<Props> = ({
  aspectRatioPopper = false,
  children,
  disabled = false,
  error,
  label,
  onChange,
  onSelect = defaultOnSelect,
  placement = 'bottom-end',
  style,
  toggleStyle,
  popperStyle,
  value,
}) => {
  const [prevIsOpen, setPrevIsOpen] = React.useState(false);
  const [mouseDown, setMouseDown] = React.useState(false);
  const selectRef = React.useRef<HTMLDivElement>(null);
  const onMouseDown = (): void => {
    setMouseDown(true);
  };
  const onKeyDown = (): void => {
    setMouseDown(false);
  };
  React.useEffect(() => {
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('keydown', onKeyDown);
    return (): void => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <Downshift
      itemToString={itemToString}
      onChange={onChange}
      onSelect={onSelect}
    >
      {({
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        highlightedIndex,
        isOpen,
      }): JSX.Element => (
        <div>
          <Manager>
            <Reference>
              {({ ref }): JSX.Element => (
                <Styled.Toggle
                  {...getToggleButtonProps()}
                  className={classNames({ mouseDown })}
                  disabled={disabled}
                  ref={ref}
                  style={toggleStyle}
                >
                  <Styled.Wrapper disabled={disabled}>
                    <Styled.Label error={error} float={value}>
                      {label}
                    </Styled.Label>
                    <Styled.Select ref={selectRef} error={error}>
                      {value}
                    </Styled.Select>
                    <Styled.ArrowDownIcon
                      diameter={36}
                      color={theme.palette.text.primary}
                    />
                    <Styled.Border
                      disabled={disabled}
                      error={error}
                      show={isOpen || !!value}
                    />
                  </Styled.Wrapper>
                  {error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}
                </Styled.Toggle>
              )}
            </Reference>
            <Popper placement={placement}>
              {(popperProps): JSX.Element => {
                if (isOpen !== prevIsOpen) {
                  if (isOpen) {
                    popperProps.scheduleUpdate();
                  }
                  setPrevIsOpen(isOpen);
                }
                return (
                  <Styled.Popper
                    data-placement={popperProps.placement}
                    aspectRatioPopper={aspectRatioPopper}
                    ref={popperProps.ref}
                    style={{
                      width: selectRef.current
                        ? selectRef.current.clientWidth
                        : undefined,
                      ...popperProps.style,
                      ...popperStyle,
                      paddingTop: 0,
                    }}
                  >
                    {isOpen && !disabled && (
                      <Styled.Menu
                        {...getMenuProps({ refKey: 'innerRef' })}
                        style={style}
                      >
                        {React.Children.map(children, (child, index) =>
                          React.cloneElement(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            child as any,
                            {
                              ...getItemProps({ index, item: child }),
                              className: classNames({
                                highlighted: highlightedIndex === index,
                              }),
                            },
                          ),
                        )}
                      </Styled.Menu>
                    )}
                  </Styled.Popper>
                );
              }}
            </Popper>
          </Manager>
        </div>
      )}
    </Downshift>
  );
};

FloatingLabelDropdown.displayName = 'DropdownMenu';

export default FloatingLabelDropdown;
