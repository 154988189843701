import styled from '@r360-tours/ui';

export const ResetPassword = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 380px;
`;

export const FinishdResetPassword = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  max-width: 380px;
`;

export const Message = styled.p`
  text-align: center;
  margin: 0;
`;

export const Links = styled.p`
  margin-bottom: 0;
  margin-top: 1.5rem;
  text-align: center;
`;
