import { NextPageContext } from 'next';

export default function redirect(context: NextPageContext, url: string): void {
  if (context.res) {
    context.res.writeHead(302, {
      'Cache-Control': 'max-age=0',
      Location: url,
    });
    context.res.end();
  } else {
    window.location.href = url;
  }
}
