import styled from 'styled-components';
import { AnchorButton } from '../anchor-button/anchor-button.styled';

export const ErrorScreen = styled.main`
  height: 100%;
  width: 100%;
`;

export const Overlay = styled.section`
  align-items: center;
  background-color: ${(props): string => props.theme.viewerOverlay.background};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

export const Modal = styled.div`
  align-items: center;
  background: ${(props): string => props.theme.viewerOverlay.color};
  border-radius: 4px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 100%;
  padding: 40px;
  width: 720px;

  svg {
    margin-bottom: 30px;
  }

  p {
    color: ${(props): string => props.theme.palette.text.muted};
    margin: 1rem 0 2rem;
    max-width: 420px;
    text-align: center;
  }

  ${AnchorButton} {
    min-width: 274px;
  }

  @media all and (max-width: 720px) {
    border-radius: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
`;

export const ModalCircularImage = styled(Modal)`
  svg {
    margin-bottom: 0;
  }

  @media all and (max-width: 812px) {
    svg {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`;
