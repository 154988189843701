import * as React from 'react';
import * as Styled from './anchor-button.styled';

const AnchorButton: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  ...props
}) => (
  <Styled.AnchorButton {...props}>
    <Styled.Span>{children}</Styled.Span>
  </Styled.AnchorButton>
);

export default AnchorButton;
