import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const RewardWithCircleColor: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 80 80" {...props}>
    <defs>
      <polygon
        id="reward-with-circle-path-1"
        points="0 0 29.3333333 0 29.3333333 9.42833571 0 9.42833571"
      ></polygon>
      <polygon
        id="reward-with-circle-path-3"
        points="0 44 44 44 44 0 0 0"
      ></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="For-those-who-are-referred-for-PC."
        transform="translate(-88.000000, -585.000000)"
      >
        <g id="Rectangle-2-Copy-2" transform="translate(88.000000, 585.000000)">
          <rect
            id="Rectangle"
            fill="#FFEDED"
            x="0"
            y="0"
            width="80"
            height="80"
            rx="40"
          ></rect>
          <g id="illustration-gift" transform="translate(18.000000, 18.000000)">
            <path
              d="M42.8421053,22.0000786 L1.15789474,22.0000786 C0.518736842,22.0000786 0,21.4720786 0,20.8215071 L0,10.6072214 C0,9.95665 0.518736842,9.42865 1.15789474,9.42865 L42.8421053,9.42865 C43.4812632,9.42865 44,9.95665 44,10.6072214 L44,20.8215071 C44,21.4720786 43.4812632,22.0000786 42.8421053,22.0000786"
              id="Fill-1"
              fill="#FFD63D"
            ></path>
            <path
              d="M38.5964912,44 L5.40350877,44 C4.76435088,44 4.24561404,43.472 4.24561404,42.8214286 L4.24561404,22 L39.754386,22 L39.754386,42.8214286 C39.754386,43.472 39.2356491,44 38.5964912,44"
              id="Fill-3"
              fill="#FFD63D"
            ></path>
            <g id="Group-7" transform="translate(7.333179, 0.000000)">
              <mask id="reward-with-circle-color-mask-2" fill="white">
                <use xlinkHref="#reward-with-circle-path-1"></use>
              </mask>
              <g id="Clip-6"></g>
              <path
                d="M27.313193,5.30255 C27.258386,7.07590714 25.7701053,8.46583571 23.9244211,8.46583571 L16.8844211,8.46583571 C17.2032281,7.63769286 17.5698947,6.85983571 17.9767018,6.14640714 C19.5946667,3.31783571 21.5399298,1.94283571 23.9244211,1.94283571 C25.7932632,1.94283571 27.3139649,3.40583571 27.3139649,5.18940714 L27.313193,5.30255 Z M5.40891228,8.46583571 C3.54007018,8.46583571 2.01936842,7.00283571 2.01936842,5.20355 C2.01936842,3.40583571 3.54007018,1.94283571 5.40891228,1.94283571 C7.79340351,1.94283571 9.7394386,3.31783571 11.3558596,6.14640714 C11.7642105,6.86140714 12.1308772,7.63847857 12.4489123,8.46583571 L5.40891228,8.46583571 Z M23.9244211,-0.000235714286 C20.7803509,-0.000235714286 18.1851228,1.75347857 16.2074386,5.21062143 C15.6045614,6.26662143 15.0873684,7.44126429 14.6666667,8.69919286 C14.2459649,7.43969286 13.7287719,6.26583571 13.1258947,5.21062143 C11.1489825,1.75347857 8.55221053,-0.000235714286 5.40891228,-0.000235714286 C2.42694737,-0.000235714286 0,2.33490714 0,5.20355 C0,6.94390714 0.895438596,8.48312143 2.26252632,9.42833571 L27.0893333,9.42833571 C28.4077895,8.51533571 29.2792982,7.04526429 29.3317895,5.34576429 L29.3333333,5.20355 C29.3333333,2.33490714 26.9071579,-0.000235714286 23.9244211,-0.000235714286 L23.9244211,-0.000235714286 Z"
                id="Fill-5"
                fill="#EA5851"
                mask="url(#reward-with-circle-color-mask-2)"
              ></path>
            </g>
            <mask id="reward-with-circle-color-mask-4" fill="white">
              <use xlinkHref="#reward-with-circle-path-3"></use>
            </mask>
            <g id="Clip-9"></g>
            <polygon
              id="Fill-8"
              fill="#D9B742"
              mask="url(#reward-with-circle-color-mask-4)"
              points="4.24561404 24.3571429 39.754386 24.3571429 39.754386 22 4.24561404 22"
            ></polygon>
            <polygon
              id="Fill-10"
              fill="#EA5851"
              mask="url(#reward-with-circle-color-mask-4)"
              points="20.4561404 22 23.5438596 22 23.5438596 9.42857143 20.4561404 9.42857143"
            ></polygon>
            <polygon
              id="Fill-11"
              fill="#EA5851"
              mask="url(#reward-with-circle-color-mask-4)"
              points="23.1578947 44 20.8421053 44 20.8421053 22 23.1578947 22"
            ></polygon>
            <polygon
              id="Fill-12"
              fill="#B6615D"
              mask="url(#reward-with-circle-color-mask-4)"
              points="20.8421053 24.3571429 23.1578947 24.3571429 23.1578947 22 20.8421053 22"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

RewardWithCircleColor.displayName = 'RewardWithCircleColor';

export default RewardWithCircleColor;
