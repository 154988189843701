import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Analytics: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Icon-/-Analytics" stroke="#000000" strokeWidth="1.5">
        <path
          d="M31,18 L26.526487,18 C26.0932875,18 25.7093489,18.2789264 25.575453,18.6909139 L22.8510341,27.0737413 C22.6803305,27.5989829 22.1161555,27.8863928 21.5909139,27.7156893 C21.2865319,27.6167652 21.0478901,27.3781234 20.9489659,27.0737413 L15.0510341,8.92625867 C14.8803305,8.40101706 14.3161555,8.11360715 13.7909139,8.28431068 C13.4865319,8.38323485 13.2478901,8.62187661 13.1489659,8.92625867 L10.424547,17.3090861 C10.2906511,17.7210736 9.90671245,18 9.47351296,18 L5,18 L5,18"
          id="Path"
        ></path>
      </g>
    </g>
  </SvgIcon>
);

Analytics.displayName = 'Analytics';

export default Analytics;
