import * as React from 'react';
import image from './images/LI-In-Bug pc@3x.png';

export const LinkedInWhite: React.FC<React.HTMLAttributes<HTMLImageElement>> = (
  props,
) => <img src={image} alt="linkedin" {...props} />;

LinkedInWhite.displayName = 'LinkedInWhite';

export default LinkedInWhite;
