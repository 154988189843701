import * as React from 'react';
import SvgIcon, { Props } from '../core/svg-icon/svg-icon';

export const Undo: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 36 36" {...props}>
    <g
      id="Tours"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="corner-up-left">
        <g transform="translate(10.000000, 10.000000)">
          <polyline
            id="Path"
            stroke={props.color || 'currentColor'}
            strokeWidth="2"
            points="5 10 0 5 5 0"
          ></polyline>
          <path
            d="M16,16 L16,9 C16,6.790861 14.209139,5 12,5 L0,5"
            id="Path"
            stroke={props.color || 'currentColor'}
            strokeWidth="2"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Undo.displayName = 'Undo';

export default Undo;
