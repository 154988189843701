import dayjs from 'dayjs';

export const convertDateToUTC = (date: string): number => {
  const parsed = dayjs(date).toDate();
  return parsed.setUTCSeconds(parsed.getUTCSeconds());
};

export default function fillMissingAnalytics(
  data: Array<{ count: number; key: string }>,
): Array<{ count: number; key: number }> | [] {
  if (!data || data.length <= 0) {
    return [];
  }
  const parsed = data.map((entry) => ({
    ...entry,
    key: convertDateToUTC(entry.key),
  }));

  const sortedData = [...parsed].sort((a, b) => a.key - b.key);
  const filledResponse = [...sortedData];

  for (let x = 0; x < sortedData.length - 1; x++) {
    const currentDate = dayjs(sortedData[x].key);
    const nextDate = dayjs(sortedData[x + 1].key);
    const differenceInDays = nextDate.diff(currentDate, 'day');

    if (differenceInDays !== 1) {
      const daysToFill = [];
      for (let y = 1; y < differenceInDays; y++) {
        daysToFill.push({
          count: 0,
          key: convertDateToUTC(currentDate.add(y, 'day').toISOString()),
        });
      }
      const diff = filledResponse.length - sortedData.length;
      filledResponse.splice(x + 1 + diff, 0, ...daysToFill);
    }
  }

  return filledResponse;
}
