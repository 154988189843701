import * as React from 'react';
import compareVersionNumbers from '@r360-tours/core/lib/versionCompare';
import { detect } from 'detect-browser';
import { Times } from '../../icons';
import * as Styled from './browser-unsupported.styled';

export interface BrowserObject {
  name: string;
  version: string | 'all';
  message: string;
}

interface BrowserUnsupportedProps extends React.HTMLAttributes<HTMLDivElement> {
  /** A list of unsupported browsers. The message will be displayed if the current browser's name matches a value in the list
   *   and the current version is less than or equal to the cooresponding browser version in the list.
   *   Adding 'all' for version will always show message for that browser.
   */
  unsupported: BrowserObject[];
}

const BrowserUnsupported: React.FC<BrowserUnsupportedProps> = ({
  unsupported,
  ...props
}) => {
  const [unsupportedCurrent, setUnsupportedCurrent] = React.useState<
    BrowserObject | undefined
  >(undefined);
  const browser = detect();
  const session =
    typeof window === 'object'
      ? window.sessionStorage.getItem('unsupportedMessage')
      : null;
  React.useEffect(() => {
    if (session && JSON.parse(session).sessionMessageHidden) {
      setUnsupportedCurrent(undefined);
      return;
    }

    if (!browser || !browser.version) {
      return;
    }

    const unsupportedBrowsers = unsupported.filter(
      (unsupportedBrowser) =>
        unsupportedBrowser.name === browser.name &&
        (!compareVersionNumbers(browser.version, unsupportedBrowser.version) ||
          compareVersionNumbers(browser.version, unsupportedBrowser.version) <=
            0),
    );
    if (unsupportedBrowsers.length > 0) {
      setUnsupportedCurrent(unsupportedBrowsers[0]);
    }
  }, [browser, session, unsupported]);

  const hideMessage = () => {
    setUnsupportedCurrent(undefined);
    window.sessionStorage.setItem(
      'unsupportedMessage',
      JSON.stringify({ sessionMessageHidden: true }),
    );
  };

  return unsupportedCurrent ? (
    <Styled.UnsupportedMessage {...props}>
      <Styled.Content>{unsupportedCurrent.message}</Styled.Content>
      <Times diameter={19} onClick={hideMessage} />
    </Styled.UnsupportedMessage>
  ) : null;
};

BrowserUnsupported.displayName = 'BrowserUnsupported';

export default BrowserUnsupported;
