import styled, { IconButton as UIIconButton } from '@r360-tours/ui';

interface Props {
  isStop: boolean;
}

export const IconButton = styled(UIIconButton)<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  color: inherit;
  padding: 0px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: ${(props): string => props.theme.viewerOverlay.background};
  border-radius: 6px;
  opacity: 1;
  color: ${(props): string =>
    props.isStop
      ? props.theme.palette.text.disabled
      : props.theme.viewerOverlay.color};
  cursor: ${(props): string => (props.isStop ? 'not-allowed' : 'default')};

  :first-child {
    margin-right: 9px;
  }

  > * {
    position: absolute;
  }

  :focus {
    outline: none;
  }

  :hover {
    background: ${(props): string =>
      props.isStop
        ? props.theme.viewerOverlay.disabledBackground
        : props.theme.viewerOverlay.hoverBackground};
    color: ${(props): string =>
      props.isStop ? '' : props.theme.viewerOverlay.hoverColor};
  }

  :active {
    background: ${(props): string =>
      props.isStop
        ? props.theme.viewerOverlay.disabledBackground
        : props.theme.viewerOverlay.activeBackground};
    color: ${(props): string =>
      props.isStop ? '' : props.theme.viewerOverlay.activeColor};
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  visibility: ${(props): string => (props.hidden ? 'hidden' : 'visible')};
  margin: 0 20px 0 8px;
`;
