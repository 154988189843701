import styled from 'styled-components';

const TRANSITION_MS = 600;

export const Figure = styled.figure`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0;
`;

export const Image = styled.img`
  border-radius: 4px;
  object-fit: cover;
  overflow: hidden;
  transition-duration: ${TRANSITION_MS}ms;
  transition-property: height, width;
  white-space: nowrap;
`;

export const Default = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props): string => props.theme.avatar.picture.borderColor};
  border-radius: 4px;
  overflow: hidden;
  transition-duration: ${TRANSITION_MS}ms;
  transition-property: height, width;
  white-space: nowrap;
`;
